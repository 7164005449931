import { BaseApiService } from "./baseApiService";

export class PurchasingService extends BaseApiService {
    async getSyrxControllerSizes() {
        return await this.axios.get('/syrx-controller-sizes');
    }

    async getSyrxControllerNodeTypes() {
        return await this.axios.get('/syrx-controller-node-types');
    }

    async getSyrxControllerNodeCategories() {
        return await this.axios.get('/syrx-controller-node-categories');
    }

    async initiatePurchase(body: {
        group_id: number,
        user_id: string,
        syrx_controller_size_quantities: {
            syrx_controller_size_id: string,
            quantity: any
        }[],
        syrx_controller_node_type_quantities: {
            syrx_controller_node_type_id: string,
            quantity: any
        }[]
    }) {
        return await this.axios.post('/purchasing/initiate', body);
    }

    async updatePurchaseStatus(id: string) {
        return await this.axios.post(`/purchasing/${id}/update-status`);
    }
}
