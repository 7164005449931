import React, {useCallback, useEffect, useMemo} from 'react'
import {Button} from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import dayjs from 'dayjs';
import {
    GroupType,
    syrxControllerDiagnosticServiceMetricsActions,
    useSyrxControllerDiagnosticServiceMetricsSelector
} from './syrxControllerDiagnosticServiceMetricsSlice';
import Select from 'react-select';
import {syrxControllerDiagnosticServiceMetricsActionCreators} from './syrxControllerDiagnosticServiceMetricsActionCreators';
import {LoadingSpinner} from '../../loadingSpinner/LoadingSpinner';
import {SyrxControllerDiagnosticServiceMetricsChart} from './syrxControllerDiagnosticServiceMetricsChart';


const applyDateInputValue = (dateInputValue: string, dateToUpdate: Date) => {
    if (dateInputValue === "") {
        return dateToUpdate;
    }

    const [yearStr, monthStr, dateStr] = dateInputValue.split("-");
    const year = parseInt(yearStr);
    const month = parseInt(monthStr) - 1;
    const date = parseInt(dateStr);

    const newDate = dayjs.utc(dateToUpdate ?? undefined).local().set("year", year).set("month", month).set("date", date).toDate();
    return newDate;
};

const applyTimeInputValue = (timeInputValue: string, dateToUpdate: Date) => {
    if (timeInputValue === "") {
        return dateToUpdate;
    }

    const [hourStr, minuteStr] = timeInputValue.split(":");
    const hour = parseInt(hourStr);
    const minute = parseInt(minuteStr);

    const newDate = dayjs.utc(dateToUpdate ?? undefined).local().set("hour", hour).set("minute", minute).toDate();
    return newDate;
};

export interface SyrxControllerDiagnosticServiceMetrics {
    syrxControllerId: string;
}

export const SyrxControllerDiagnosticServiceMetrics: React.FunctionComponent<SyrxControllerDiagnosticServiceMetrics> = ({syrxControllerId}) => {
    const dispatch = useDispatch();
    const {isUpdating, startDate, endDate, availableGroups, groupType, selectedGroups, resolution, metric, aggregation} = useSyrxControllerDiagnosticServiceMetricsSelector(state => state);

    useEffect(() => {
        dispatch(syrxControllerDiagnosticServiceMetricsActions.setStartDate(dayjs.utc().add(-10, "minute").format()));
        dispatch(syrxControllerDiagnosticServiceMetricsActions.setEndDate(dayjs.utc().format()));
    }, [dispatch, syrxControllerId]);

    useEffect(() => {
        dispatch(syrxControllerDiagnosticServiceMetricsActionCreators.loadMetricsGroups(syrxControllerId));
    }, [dispatch, syrxControllerId, groupType, startDate, endDate]);

    const updateStartDateDate = useCallback((newDateValue: string) => {
        const newStartDate = applyDateInputValue(newDateValue, dayjs.utc(startDate ?? undefined).toDate());
        dispatch(syrxControllerDiagnosticServiceMetricsActions.setStartDate(dayjs.utc(newStartDate).format()));
    }, [dispatch, startDate]);

    const updateStartDateTime = useCallback((newTimeValue: string) => {
        const newStartDate = applyTimeInputValue(newTimeValue, dayjs.utc(startDate ?? undefined).toDate());
        dispatch(syrxControllerDiagnosticServiceMetricsActions.setStartDate(dayjs.utc(newStartDate).format()));
    }, [dispatch, startDate]);

    const updateEndDateDate = useCallback((newDateValue: string) => {
        const newEndDate = applyDateInputValue(newDateValue, dayjs.utc(endDate ?? undefined).toDate());
        dispatch(syrxControllerDiagnosticServiceMetricsActions.setEndDate(dayjs.utc(newEndDate).format()));
    }, [dispatch, endDate]);

    const updateEndDateTime = useCallback((newTimeValue: string) => {
        const newEndDate = applyTimeInputValue(newTimeValue, dayjs.utc(endDate ?? undefined).toDate());
        dispatch(syrxControllerDiagnosticServiceMetricsActions.setEndDate(dayjs.utc(newEndDate).format()));
    }, [dispatch, endDate]);

    const setGroupType = useCallback((newGroupType: GroupType) => {
        dispatch(syrxControllerDiagnosticServiceMetricsActions.setGroupType(newGroupType));
    }, [dispatch]);

    const setResolution = useCallback((newResolution: string) => {
        dispatch(syrxControllerDiagnosticServiceMetricsActions.setResolution(newResolution));
    }, [dispatch]);

    const setMetric = useCallback((newMetric: string) => {
        dispatch(syrxControllerDiagnosticServiceMetricsActions.setMetric(newMetric));
    }, [dispatch]);

    const setAggregation = useCallback((newAggregation: string) => {
        dispatch(syrxControllerDiagnosticServiceMetricsActions.setAggregation(newAggregation));
    }, [dispatch]);

    const loadReport = useCallback(() => {
        dispatch(syrxControllerDiagnosticServiceMetricsActionCreators.loadMetrics(syrxControllerId));
    }, [dispatch, syrxControllerId]);

    const availableGroupOptions = useMemo(() => availableGroups.map(group => ({value: group, label: group})), [availableGroups]);
    const selectedGroupOptions = selectedGroups.map(selectedGroup => availableGroupOptions.find(x => x.value === selectedGroup));

    const startDateDateValue = useMemo(() => startDate != null ? dayjs.utc(startDate).local().format("YYYY-MM-DD") : "", [startDate]);
    const startDateTimeValue = useMemo(() => startDate != null ? dayjs.utc(startDate).local().format("HH:mm") : "", [startDate]);
    const endDateDateValue = useMemo(() => endDate != null ? dayjs.utc(endDate).local().format("YYYY-MM-DD") : "", [endDate]);
    const endDateTimeValue = useMemo(() => endDate != null ? dayjs.utc(endDate).local().format("HH:mm") : "", [endDate]);

    return (
        <>
            {isUpdating ? <LoadingSpinner /> : null}
            <table style={{width: "800px"}}>
                <tbody>
                    <tr>
                        <td>Start date</td>
                        <td>
                            <input type="date" style={{display: "inline-block", width: "50%"}} className="form-control" value={startDateDateValue} onChange={e => updateStartDateDate(e.target.value)} />
                            <input type="time" style={{display: "inline-block", width: "50%"}} className="form-control" value={startDateTimeValue} onChange={e => updateStartDateTime(e.target.value)} />
                        </td>
                    </tr>
                    <tr>
                        <td>End date</td>
                        <td>
                            <input type="date" style={{display: "inline-block", width: "50%"}} className="form-control" value={endDateDateValue} onChange={e => updateEndDateDate(e.target.value)} />
                            <input type="time" style={{display: "inline-block", width: "50%"}} className="form-control" value={endDateTimeValue} onChange={e => updateEndDateTime(e.target.value)} />
                        </td>
                    </tr>
                    <tr>
                        <td>Metric</td>
                        <td>
                            <select className="form-control" value={metric} onChange={e => setMetric(e.target.value)}>
                                <option value="cpu_percent">CPU (%)</option>
                                <option value="mem_percent">Memory (%)</option>
                                <option value="mem">Memory (KB)</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>Resolution</td>
                        <td>
                            <select className="form-control" value={resolution} onChange={e => setResolution(e.target.value)}>
                                <option>15s</option>
                                <option>1m</option>
                                <option>5m</option>
                                <option>15m</option>
                                <option>1h</option>
                                <option>4h</option>
                                <option>6h</option>
                                <option>12h</option>
                                <option>1d</option>
                                <option>7d</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>Data set</td>
                        <td>
                            <select className="form-control" value={groupType} onChange={e => setGroupType(e.target.value as GroupType)}>
                                <option value="overall">Overall</option>
                                <option value="process">Processes</option>
                                <option value="systemd_service">Services</option>
                            </select>
                        </td>
                    </tr>
                    {groupType !== "overall" && (
                        <>
                            <tr>
                                <td>Aggregation</td>
                                <td>
                                    <select className="form-control" value={aggregation} onChange={e => setAggregation(e.target.value)}>
                                        <option value="avg">Average</option>
                                        <option value="min">Minimum</option>
                                        <option value="max">Maximum</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {groupType === "process" ? "Processes" : null}
                                    {groupType === "systemd_service" ? "Services" : null}
                                </td>
                                <td>
                                    <Select
                                        value={selectedGroupOptions}
                                        onChange={selectedOptions => dispatch(syrxControllerDiagnosticServiceMetricsActions.setSelectedGroups((selectedOptions ?? []).map((selectedOption: any) => selectedOption.value)))}
                                        options={availableGroupOptions}
                                        isMulti={true}
                                    />
                                </td>
                            </tr>
                        </>
                    )}
                    <tr>
                        <td>&nbsp;</td>
                        <td><Button onClick={loadReport}>Submit</Button></td>
                    </tr>
                </tbody>
            </table>
            <SyrxControllerDiagnosticServiceMetricsChart />
        </>
    )
};