import {BaseApiService} from "./baseApiService";
import {FilterCollection, SortCollection} from '@sht-dev/fancy-grid';
import qs from 'qs';
import {Role} from '../models/role';
import {AxiosError} from 'axios';

export class RolesService extends BaseApiService {
    async getRolesForGrid(pageNum: number, pageSize: number, sort: SortCollection = [], filter: FilterCollection = []) {
        const queryString = qs.stringify({
            skip: pageNum * pageSize,
            take: pageSize,
            page: pageNum,
            pageSize,
            sort: sort.map(x => ({
                field: x.fieldName,
                dir: x.dir
            })),
            filter: {
                filters: filter.map(x => ({
                    field: x.fieldName,
                    field_type: x.fieldType,
                    operator: x.operator,
                    value: x.value
                }))
            }
        });
        const res = await this.axios.get<{data: Role[], total: number}>(`/roles?${queryString}`);
        return res.data;
    }

    async getRoleById(roleId: number) {
        try {
            const res = await this.axios.get<Role>(`roles/${roleId}`);
            return res.data;
        } catch (e) {
            if (e.isAxiosError && (e as AxiosError).response?.status === 404) {
                return null;
            }
            throw e;
        }
    }

    async saveRole(role: Role): Promise<Role> {
        if (role.id == null) {
            const res = await this.axios.post<Role>(`/roles`, role);
            return res.data;
        } else {
            const res = await this.axios.put<Role>(`/roles/${role.id}`, role);
            return res.data
        }
    }

    async deleteRoleById(roleId: number): Promise<void> {
        await this.axios.delete(`/roles/${roleId}`);
    }
}

