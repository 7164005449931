import React from 'react'
import {
    useSyrxControllerDiagnosticServiceDetailsSelector
} from './syrxControllerDiagnosticServiceDetailsSlice';

export const SyrxControllerDiagnosticServiceRabbitmqMetricsConnections: React.FunctionComponent = () => {
    const connections = useSyrxControllerDiagnosticServiceDetailsSelector(state => state.latestRabbitmqMetricsCollectionRecord?.connections);

    return (
        <>
            <table className="table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Client</th>
                        <th>Cluster</th>
                        <th>First seen</th>
                        <th>Last updated</th>
                    </tr>
                </thead>
                <tbody>
                    {connections?.map(x => (
                        <tr key={x.name}>
                            <td>{x.name}</td>
                            <td>{x.peerHost}:{x.peerPort}</td>
                            <td>{x.host}:{x.port}</td>
                            <td>{x.firstSeenTimestamp}</td>
                            <td>{x["@timestamp"]}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
};