import React, {useEffect, useState} from 'react';
import {useField, useForm} from 'react-final-form';
import {LoadingSpinner} from '../../loadingSpinner/LoadingSpinner';
import {SyrxControllerServiceArtifact} from '../../../models/syrxControllerServiceArtifact';
import {SyrxControllerServiceArtifactsService} from '../../../services/syrxControllerServiceArtifactsService';
import dayjs from 'dayjs';

export const SyrxControllerServiceArtifactAdapter: React.FunctionComponent<{serviceId: string | null, name: string}> = ({serviceId, name}) => {
    const field = useField<string | null>(name);
    const {change: formChange} = useForm();
    const {value: selectedSyrxControllerServiceArtifactId} = field.input;

    const [isLoading, setIsLoading] = useState(false);
    const [syrxControllerServiceArtifacts, setSyrxControllerServiceArtifacts] = useState([] as SyrxControllerServiceArtifact[]);

    useEffect(() => {
        async function doTheThing() {
            let newSyrxControllerServiceArtifacts = null as null | SyrxControllerServiceArtifact[];
            setIsLoading(true);
            try {
                if (serviceId == null) {
                    setSyrxControllerServiceArtifacts([]);
                    formChange(name, null);
                    return;
                }

                newSyrxControllerServiceArtifacts = await SyrxControllerServiceArtifactsService.getArtifactsForServiceType(serviceId);
                setSyrxControllerServiceArtifacts(newSyrxControllerServiceArtifacts ?? []);
            } catch (e) {
                formChange(name, null);
                throw e;
            } finally {
                const artifactIds = (newSyrxControllerServiceArtifacts ?? []).map(x => x.id);
                if (selectedSyrxControllerServiceArtifactId != null && !artifactIds.includes(selectedSyrxControllerServiceArtifactId)) {
                    formChange(name, null);
                }
                setIsLoading(false);
            }
        }

        void doTheThing();
    }, [name, serviceId, selectedSyrxControllerServiceArtifactId, formChange])

    return (
        <>
            {isLoading ? <LoadingSpinner /> : null}
            <select
                onChange={field.input.onChange}
                value={selectedSyrxControllerServiceArtifactId ?? undefined}
                className="form-control"
                required
            >
                {syrxControllerServiceArtifacts.length === 0 ? (
                    <option>(no artifacts available)</option>
                ) : null}
                {syrxControllerServiceArtifacts.length > 0 && selectedSyrxControllerServiceArtifactId != null ? (
                    <option>(select one)</option>
                ) : null}
                {syrxControllerServiceArtifacts.map(syrxControllerServiceArtifact => (
                    <option
                        key={syrxControllerServiceArtifact.id}
                        value={syrxControllerServiceArtifact.id}
                    >
                        {dayjs.utc(syrxControllerServiceArtifact.created_date).format()} - {syrxControllerServiceArtifact.git_revision} (
                            {syrxControllerServiceArtifact.syrx_controller_service_versions == null ? "Usage information unavailable" : null}
                            {syrxControllerServiceArtifact.syrx_controller_service_versions != null ? `Used by ${syrxControllerServiceArtifact.syrx_controller_service_versions.length} versions` : null}
                        )
                    </option>
                ))}
            </select>
        </>
    );
}