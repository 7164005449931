import React, { useEffect } from 'react'
import { Form, FormGroup, Row, Col, Button } from 'react-bootstrap';
import { Redirect } from 'react-router';
import {Field, Form as ReactFinalForm} from 'react-final-form';
import { LinkContainer } from 'react-router-bootstrap';
import { useDispatch } from 'react-redux';

import {roleEditorActions, useRoleEditorSelector} from './roleEditorSlice';
import {roleEditorActionCreators} from './roleEditorActionCreators';
import {LoadingSpinner} from '../../../loadingSpinner/LoadingSpinner';
import {Role} from '../../../../models/role';
import {RolePermissionSelectorAdapter} from './rolePermissionSelectorAdapter';

export const RoleEditor: React.FunctionComponent<{roleId: number | null}> = ({roleId}) => {
    const dispatch = useDispatch();

    const isLoading = useRoleEditorSelector(state => state.isLoading);
    const isDoneLoading = useRoleEditorSelector(state => state.isDoneLoading);
    const isSubmitting = useRoleEditorSelector(state => state.isSubmitting);
    const isDoneSubmitting = useRoleEditorSelector(state => state.isDoneSubmitting);
    const formInitialValues = useRoleEditorSelector(state => state.formInitialValues);

    useEffect(() => {
        dispatch(roleEditorActionCreators.loadRoleEditor(roleId));

        return () => {
            dispatch(roleEditorActions.editorUnload());
        }
    }, [dispatch, roleId]);

    const submit = (values: Role) => {
        dispatch(roleEditorActionCreators.submitRoleEditor(values));
    }

    if (isLoading) {
        return <LoadingSpinner />
    }

    return isDoneLoading && formInitialValues != null ? (
        <ReactFinalForm onSubmit={submit} initialValues={formInitialValues}>
            {({handleSubmit}) => (
                <>
                    {isSubmitting ? <LoadingSpinner /> : null}
                    {isDoneSubmitting ? <Redirect to={`/admin/roles`} /> : null}
                    <Form onSubmit={handleSubmit}>
                        <fieldset>
                            <legend>{roleId != null ? "Edit" : "Create"} role</legend>
                            <FormGroup as={Row}>
                                <Form.Label column lg={2}>Name</Form.Label>
                                <Col lg={6}>
                                    <Field
                                        name="name"
                                        component="input"
                                        type="text"
                                        className="form-control"
                                        required
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup as={Row}>
                                <Form.Label column lg={2}>Permissions</Form.Label>
                                <Col lg={10}>
                                    <RolePermissionSelectorAdapter name="permissions" />
                                </Col>
                            </FormGroup>
                        </fieldset>
                        <FormGroup as={Row}>
                            <Form.Label column lg={2} />
                            <Col lg={4}>
                                <Button type="submit" variant="primary">Submit</Button>
                                <LinkContainer to={`/admin/roles`}><Button type="submit" variant="secondary">Cancel</Button></LinkContainer>
                            </Col>
                        </FormGroup>
                    </Form>
                </>
            )}
        </ReactFinalForm>
    ) : null
}