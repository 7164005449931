import React from 'react'
import {Nav, Navbar, NavDropdown} from "react-bootstrap";
import { LinkContainer } from 'react-router-bootstrap';
import { LogoutLink } from './logoutLink';
import { legacyAppUrl } from '../../config';
import {useAuthSessionSelector} from '../authSession/authSessionSlice';

export const MainNavBar = () => {
    const user = useAuthSessionSelector(state => state.user);
    const userPermissions = user != null && user.permissions != null ? user.permissions : [];

    const adminMenuItemVisibilities = {
        users: userPermissions.includes("users_manage"),
        roles: userPermissions.includes("roles_manage"),
        weatherstations: userPermissions.includes("weatherstations_manage"),
        groups: userPermissions.includes("groups_manage"),
        accounts: userPermissions.some((userPermission: string) => ["accounts_manage", "groups_manage"].some(x => userPermission === x)),
        components: userPermissions.includes("components_manage"),
        meetingTypes: userPermissions.includes("meeting_types_manage"),
        utilityCompanies: userPermissions.includes("utility_companies_manage"),
        actionItemPriorities: userPermissions.includes("action_item_field_options_manage"),
        actionItemStatuses: userPermissions.includes("action_item_field_options_manage"),
        actionItemTypes: userPermissions.includes("action_item_field_options_manage"),
        taskPriorities: userPermissions.includes("task_field_options_manage"),
        taskStatuses: userPermissions.includes("task_field_options_manage"),
        taskTypes: userPermissions.includes("task_field_options_manage"),
        issuePriorities: userPermissions.includes("issue_field_options_manage"),
        issueStatuses: userPermissions.includes("issue_field_options_manage"),
        issueTypes: userPermissions.includes("issue_field_options_manage"),
        contracts: userPermissions.includes("contracts_manage"),
        syrxCategories: userPermissions.includes("syrx_categories_manage"),
        contactCategories: userPermissions.includes("contact_categories_manage"),
        resetSchedules: userPermissions.includes("reset_schedules_manage"),
        functions: userPermissions.includes("functions_manage"),
        indexes: userPermissions.includes("indexes_manage"),
        equipmentAuditConfigManage: userPermissions.includes("equipment_audit_config_manage"),
        nightlyProcessLogs: userPermissions.includes("nightly_process_logs_view"),
        binaryPointValues: userPermissions.includes("binary_point_values_manage"),
        auditLog: userPermissions.includes("audit_log_view"),
        pcsAdmin: userPermissions.includes("pcs_admin"),
        jobs: userPermissions.includes("nightly_process_logs_view")
    };

    const reportDataMenuItemVisibilities = {
        uploadGroupData: userPermissions.includes("group_data_upload"),
        viewGroupData: userPermissions.includes("group_data_view"),
        moveVendorPointRecords: userPermissions.includes("move_vendor_point_records"),
        divider: (userPermissions.includes("group_data_upload") || userPermissions.includes("group_data_view")) && userPermissions.includes("vendor_data_view"),
        vendorData: userPermissions.includes("vendor_data_view")
    };
    
    const commissioningMenuItemVisibilities = {
        equipment: userPermissions.includes("equipment_manage"),
        contacts: userPermissions.includes("contacts_manage"),
        tasks: userPermissions.includes("tasks_manage"),
        issues: userPermissions.includes("issues_manage"),
        projects: userPermissions.includes("projects_manage"),
        eco: userPermissions.includes("ecos_manage"),
        committees: userPermissions.includes("committees_manage"),
        meetings: userPermissions.includes("meetings_manage")
    };

    const reportingMenuItemVisibilities = {
        groupReports: userPermissions.includes("reporting_groups"),
        naicsReports: userPermissions.includes("reporting_naics"),
        sicReports: userPermissions.includes("reporting_sic"),
        equipmentSyrxReports: userPermissions.includes("reporting_equipment_syrx"),
        equipmentEnergyReports: userPermissions.includes("reporting_equipment_energy"),
        equipmentAuditReports: userPermissions.includes("reporting_equipment_audit"),
        equipmentIssuesReports: userPermissions.includes("reporting_equipment_issues"),
        equipmentTasksReports: userPermissions.includes("reporting_equipment_tasks"),
        divider: userPermissions.some((userPermission: string) => ['reporting_groups', 'reporting_naics', 'reporting_sic', 'reporting_equipment_syrx', 'reporting_equipment_energy', 'reporting_equipment_issues', 'reporting_equipment_tasks'].some(x => userPermission === x)),
        queuedReports: user != null,
    };
    
    const showAdminMenu = Object.values(adminMenuItemVisibilities).some(x => x);
    const showReportDataMenu = Object.values(reportDataMenuItemVisibilities).some(x => x);
    const showCommissioningMenu = Object.values(commissioningMenuItemVisibilities).some(x => x);
    const showReportingMenu = Object.values(reportingMenuItemVisibilities).some(x => x);


    return (
        <Navbar>
            <Nav>
                <LinkContainer to="/home">
                    <Nav.Link>
                            Home
                    </Nav.Link>
                </LinkContainer>
                {showAdminMenu ? (
                    <NavDropdown title="Admin" id="admin">
                        {adminMenuItemVisibilities.users ? (<NavDropdown.Item href={`${legacyAppUrl}/#/admin/users`}>Users</NavDropdown.Item>) : null}
                        {adminMenuItemVisibilities.roles ? (<NavDropdown.Item href={`/admin/roles`}>Roles</NavDropdown.Item>) : null }
                        {adminMenuItemVisibilities.roles ? (<NavDropdown.Item href={`/admin/group-roles`}>Group roles</NavDropdown.Item>) : null }
                        {adminMenuItemVisibilities.weatherstations ? (<NavDropdown.Item href={`${legacyAppUrl}/#/admin/weatherstations`}>Weather Stations</NavDropdown.Item>) : null }
                        {adminMenuItemVisibilities.groups ? (<NavDropdown.Item href={`${legacyAppUrl}/#/admin/groups`}>Groups</NavDropdown.Item>) : null }
                        {adminMenuItemVisibilities.accounts ? (<NavDropdown.Item href={`${legacyAppUrl}/#/admin/groups/accounts`}>Accounts</NavDropdown.Item>) : null }
                        {adminMenuItemVisibilities.components ? (<NavDropdown.Item href={`${legacyAppUrl}/#/admin/components/manager`}>Components</NavDropdown.Item>) : null }
                        {adminMenuItemVisibilities.components ? (<NavDropdown.Item href={`${legacyAppUrl}/#/admin/generic_points`}>Generic points</NavDropdown.Item>) : null }
                        {adminMenuItemVisibilities.meetingTypes ? (<NavDropdown.Item href={`${legacyAppUrl}/#/admin/meetingtypes`}>Meeting Types</NavDropdown.Item>) : null }
                        {adminMenuItemVisibilities.utilityCompanies ? (<NavDropdown.Item href={`${legacyAppUrl}/#/admin/utilitycompanies`}>Utility Companies</NavDropdown.Item>) : null }
                        {adminMenuItemVisibilities.actionItemPriorities ? (<NavDropdown.Item href={`${legacyAppUrl}/#/admin/actionitempriorities`}>Action Item Priorities</NavDropdown.Item>) : null }
                        {adminMenuItemVisibilities.actionItemStatuses ? (<NavDropdown.Item href={`${legacyAppUrl}/#/admin/actionitemstatuses`}>Action Item Statuses</NavDropdown.Item>) : null }
                        {adminMenuItemVisibilities.actionItemTypes ? (<NavDropdown.Item href={`${legacyAppUrl}/#/admin/actionitemtypes`}>Action Item Types</NavDropdown.Item>) : null }
                        {adminMenuItemVisibilities.taskPriorities ? (<NavDropdown.Item href={`${legacyAppUrl}/#/admin/taskpriorities`}>Task Priorities</NavDropdown.Item>) : null }
                        {adminMenuItemVisibilities.taskStatuses ? (<NavDropdown.Item href={`${legacyAppUrl}/#/admin/taskstatuses`}>Task Statuses</NavDropdown.Item>) : null }
                        {adminMenuItemVisibilities.taskTypes ? (<NavDropdown.Item href={`${legacyAppUrl}/#/admin/tasktypes`}>Task Types</NavDropdown.Item>) : null }
                        {adminMenuItemVisibilities.issuePriorities ? (<NavDropdown.Item href={`${legacyAppUrl}/#/admin/issuepriorities`}>Issue Priorities</NavDropdown.Item>) : null }
                        {adminMenuItemVisibilities.issueStatuses ? (<NavDropdown.Item href={`${legacyAppUrl}/#/admin/issuestatuses`}>Issue Statuses</NavDropdown.Item>) : null }
                        {adminMenuItemVisibilities.issueTypes ? (<NavDropdown.Item href={`${legacyAppUrl}/#/admin/issuetypes`}>Issue Types</NavDropdown.Item>) : null }
                        {adminMenuItemVisibilities.contracts ? (<NavDropdown.Item href={`${legacyAppUrl}/#/admin/contracts`}>Contracts</NavDropdown.Item>) : null }
                        {adminMenuItemVisibilities.syrxCategories ? (<NavDropdown.Item href={`${legacyAppUrl}/#/admin/syrxcategories`}>SYRX Categories</NavDropdown.Item>) : null }
                        {adminMenuItemVisibilities.contactCategories ? (<NavDropdown.Item href={`${legacyAppUrl}/#/admin/contact_categories`}>Contact Categories</NavDropdown.Item>) : null }
                        {adminMenuItemVisibilities.resetSchedules ? (<NavDropdown.Item href={`${legacyAppUrl}/#/admin/resetschedules`}>Reset Schedules</NavDropdown.Item>) : null }
                        {adminMenuItemVisibilities.functions ? (<NavDropdown.Item href={`${legacyAppUrl}/#/admin/functions`}>Function Management</NavDropdown.Item>) : null }
                        {adminMenuItemVisibilities.indexes ? (<NavDropdown.Item href={`${legacyAppUrl}/#/admin/component_bic_indexes`}>Component BIC Indexes</NavDropdown.Item>) : null }
                        {adminMenuItemVisibilities.equipmentAuditConfigManage ? (<NavDropdown.Item href={`${legacyAppUrl}/#/admin/equipment_audit`}>Equipment Audit Report Configuration</NavDropdown.Item>) : null }
                        {adminMenuItemVisibilities.nightlyProcessLogs ? (<NavDropdown.Item href={`${legacyAppUrl}/#/admin/nightly_process_logs/new`}>Manally trigger nightly process</NavDropdown.Item>) : null }
                        {adminMenuItemVisibilities.binaryPointValues ? (<NavDropdown.Item href={`${legacyAppUrl}/#/admin/binary_point_values`}>Binary Point Values</NavDropdown.Item>) : null }
                        {adminMenuItemVisibilities.auditLog ? (<NavDropdown.Item href={`${legacyAppUrl}/#/admin/audit_log`}>Audit log</NavDropdown.Item>) : null }
                        {adminMenuItemVisibilities.pcsAdmin ? (<NavDropdown.Item href={`${legacyAppUrl}/#/admin/pcs/sites`}>PCS sites</NavDropdown.Item>) : null }
                        {adminMenuItemVisibilities.pcsAdmin ? (<NavDropdown.Item href={`/admin/syrx-controllers`}>Syrx controllers</NavDropdown.Item>) : null }
                        {adminMenuItemVisibilities.pcsAdmin ? (<NavDropdown.Item href={`/admin/syrx-controller-services`}>Syrx controller services</NavDropdown.Item>) : null }
                        {adminMenuItemVisibilities.jobs ? (<NavDropdown.Item href={`/admin/jobs`}>View job server jobs</NavDropdown.Item>) : null }
                    </NavDropdown>
                ) : null}
                {showReportDataMenu ? (
                    <NavDropdown title="Report Data" id="reportData">
                        {reportDataMenuItemVisibilities.uploadGroupData ? (<NavDropdown.Item href={`${legacyAppUrl}/#/report_data/uploadGroupData`}>Upload group data</NavDropdown.Item>) : null }
                        {reportDataMenuItemVisibilities.viewGroupData ? (<NavDropdown.Item href={`${legacyAppUrl}/#/report_data/accounts/viewData`}>View group data</NavDropdown.Item>) : null }
                        {reportDataMenuItemVisibilities.moveVendorPointRecords ? (<NavDropdown.Item href={`/admin/move-vendor-point-data`}>Move vendor point records</NavDropdown.Item>) : null }
                        {reportDataMenuItemVisibilities.divider ? (<NavDropdown.Divider />): null }
                        {reportDataMenuItemVisibilities.vendorData ? (<NavDropdown.Item href={`${legacyAppUrl}/#/point_reporting/equipment_point_record_browser`}>Equipment Point Record Browser</NavDropdown.Item>) : null }
                        {reportDataMenuItemVisibilities.vendorData ? (<NavDropdown.Item href={`${legacyAppUrl}/#/point_reporting/equipment_point_error_report`}>Equipment Point Error Report</NavDropdown.Item>) : null }
                        {reportDataMenuItemVisibilities.vendorData ? (<NavDropdown.Item href={`${legacyAppUrl}/#/point_reporting/vendor_point_record_browser`}>Vendor Point Record Browser</NavDropdown.Item>) : null }
                    </NavDropdown>
                ) : null}
                {showCommissioningMenu ? (
                    <NavDropdown title="Commissioning" id="commissioning">
                        {commissioningMenuItemVisibilities.equipment ? (<NavDropdown.Item href={`${legacyAppUrl}/#/commissioning/equipment`}>Equipment</NavDropdown.Item>) : null }
                        {commissioningMenuItemVisibilities.contacts ? (<NavDropdown.Item href={`${legacyAppUrl}/#/commissioning/contacts`}>Contacts</NavDropdown.Item>) : null }
                        {commissioningMenuItemVisibilities.tasks ? (<NavDropdown.Item href={`${legacyAppUrl}/#/commissioning/tasks`}>Tasks</NavDropdown.Item>) : null }
                        {commissioningMenuItemVisibilities.issues ? (<NavDropdown.Item href={`${legacyAppUrl}/#/commissioning/issues`}>Issues</NavDropdown.Item>) : null }
                        {commissioningMenuItemVisibilities.projects ? (<NavDropdown.Item href={`/projects`}>Projects</NavDropdown.Item>) : null }
                        {commissioningMenuItemVisibilities.eco ? (<NavDropdown.Item href={`${legacyAppUrl}/#/commissioning/eco`}>ECO</NavDropdown.Item>) : null }
                        {commissioningMenuItemVisibilities.committees ? (<NavDropdown.Item href={`${legacyAppUrl}/#/commissioning/committees`}>Committees</NavDropdown.Item>) : null }
                        {commissioningMenuItemVisibilities.meetings ? (<NavDropdown.Item href={`${legacyAppUrl}/#/commissioning/meetings`}>Meetings</NavDropdown.Item>) : null }
                    </NavDropdown>
                ) : null}
                {showReportingMenu ? (
                    <NavDropdown title="Reporting" id="reporting">
                        {reportingMenuItemVisibilities.groupReports ? (<NavDropdown.Item href={`${legacyAppUrl}/#/reporting/groups`}>Groups</NavDropdown.Item>) : null }
                        {reportingMenuItemVisibilities.naicsReports ? (<NavDropdown.Item href={`${legacyAppUrl}/#/reporting/naics`}>NAICS</NavDropdown.Item>) : null }
                        {reportingMenuItemVisibilities.sicReports ? (<NavDropdown.Item href={`${legacyAppUrl}/#/reporting/sic`}>SIC</NavDropdown.Item>) : null }
                        {reportingMenuItemVisibilities.equipmentSyrxReports ? (<NavDropdown.Item href={`${legacyAppUrl}/#/reporting/equipment`}>Equipment Syrx</NavDropdown.Item>) : null }
                        {reportingMenuItemVisibilities.equipmentEnergyReports ? (<NavDropdown.Item href={`${legacyAppUrl}/#/reporting/equipment_energy`}>Equipment Energy</NavDropdown.Item>) : null }
                        {reportingMenuItemVisibilities.equipmentAuditReports ? (<NavDropdown.Item href={`${legacyAppUrl}/#/reporting/equipment_audit`}>Equipment Audit</NavDropdown.Item>) : null }
                        {reportingMenuItemVisibilities.equipmentIssuesReports ? (<NavDropdown.Item href={`${legacyAppUrl}/#/reporting/equipment_issues`}>Equipment Issues</NavDropdown.Item>) : null }
                        {reportingMenuItemVisibilities.equipmentTasksReports ? (<NavDropdown.Item href={`${legacyAppUrl}/#/reporting/equipment_tasks`}>Equipment Tasks</NavDropdown.Item>) : null }
                        {reportingMenuItemVisibilities.divider ? (<NavDropdown.Divider />): null }
                        {reportingMenuItemVisibilities.queuedReports ? (<NavDropdown.Item href={`${legacyAppUrl}/#/reporting/progress`}>Queued reports</NavDropdown.Item>) : null }
                    </NavDropdown>
                ) : null}
                {user != null ? <LogoutLink /> : <LinkContainer to="/login"><Nav.Link className="nav-link">Login</Nav.Link></LinkContainer>}
            </Nav>
        </Navbar>
    );
};