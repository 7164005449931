import React, { CSSProperties, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Table, Button } from 'react-bootstrap';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router';

import { purchasingActionCreators } from '../../actionCreators/purchasingActionCreators';
import { usePurchasingSelector } from '../purchasing/purchasingSlice';
import { SyrxControllerNodeCategory, SyrxControllerNodeType, SyrxControllerSize } from './types';
import { useAuthSessionSelector } from '../authSession/authSessionSlice';

import { LoadingSpinner } from '../loadingSpinner/LoadingSpinner';

const table: CSSProperties = {
    tableLayout: 'fixed'
}

const itemDescriptionRow: CSSProperties = {
    width: '40%'
}

const nodeCategory: CSSProperties = {
    fontWeight: 'bold',
    textAlign: 'center'
}

const buttonRow: CSSProperties = {
    display: 'flex',
    justifyContent: 'flex-end'
}

const button: CSSProperties = {
    background: '#FFFFFF',
    border: '1px solid #DDDDDD',
    color: '#373A3C'
}

const errorMessageRow: CSSProperties = {
    color: '#FF0000'
}

const stripeRow: CSSProperties = {
    width: '400px',
    marginBottom: '0.5em'
}

const errorMessage: CSSProperties = {
    color: 'red'
}

const container: CSSProperties = {
    display: 'flex',
    flex: '1 0',
    flexDirection: 'column',
    alignItems: 'center'
}

const cardElementStyle = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "18px",
            "::placeholder": {
                color: "#aab7c4",
            }
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        }
    }
}

export const PurchasingSummary: React.FunctionComponent = () => {
    const { syrxControllerSizeCart, syrxControllerNodeTypeCart, syrxControllerNodeCategories, syrxControllerNodeTypes, syrxControllerSizes, syrxControllerOrder, syrxControllerNodeCount, otherNodeCount, stripeErrorMessage, isProcessingPayment } = usePurchasingSelector(x => x);
    const { currentGroup, user } = useAuthSessionSelector(x => x);
    const dispatch = useDispatch();
    const history = useHistory();
    const stripe = useStripe();
    const elements = useElements();

    const changePage = useCallback(async (direction) => {
        dispatch(purchasingActionCreators.changePage(direction));
    }, [dispatch]);

    const initiatePurchase = useCallback(async () => {
        dispatch(purchasingActionCreators.initiatePurchase(currentGroup.id, user.id, syrxControllerSizeCart, syrxControllerNodeTypeCart));
    }, [dispatch, currentGroup, user, syrxControllerSizeCart, syrxControllerNodeTypeCart]);

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        dispatch(purchasingActionCreators.updateIsProcessingPayment(true));

        const result = await stripe.confirmCardPayment(syrxControllerOrder.payment_intent_client_secret, {
            payment_method: {
                card: elements.getElement(CardElement)!
            }
        });

        if (result.error) {
            dispatch(purchasingActionCreators.updateStripeErrorMessage(result.error.message))
        } else {
            if (result.paymentIntent?.status === 'succeeded') {
                dispatch(purchasingActionCreators.completePurchase(syrxControllerOrder.id));
                setTimeout(() => { history.push('/projects') }, 5000);
            }
        }
    };

    return (
        <>
            <Table bordered style={table}>
                <thead>
                    <tr>
                        <td style={itemDescriptionRow}>
                            Item Description
                        </td>
                        <td>
                            Quantity
                        </td>
                        <td>
                            Price per item
                        </td>
                        <td>
                            Total
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <>
                        <tr>
                            <td style={nodeCategory}>
                                {'Syrx Controller'}
                            </td>
                        </tr>
                        {Object.keys(syrxControllerSizeCart).map((productId: any) => {
                            return (
                                <tr key={productId}>
                                    <td>
                                        {syrxControllerSizes.find((syrxController: SyrxControllerSize) => syrxController.id === productId)?.name}
                                    </td>
                                    <td>
                                        {syrxControllerSizeCart[productId]}
                                    </td>
                                    <td>
                                        ${syrxControllerSizes.find((syrxController: SyrxControllerSize) => syrxController.id === productId)?.price}
                                    </td>
                                    <td>
                                        ${(syrxControllerSizeCart[productId] * syrxControllerSizes.find((syrxController: SyrxControllerSize) => syrxController.id === productId)?.price!).toFixed(2)}
                                    </td>
                                </tr>
                            )
                        })}
                    </>
                    {Object.keys(syrxControllerNodeTypeCart).map(syrxControllerNodeCategoryId => {
                        return (
                            <React.Fragment key={syrxControllerNodeCategoryId}>
                                <tr>
                                    <td style={nodeCategory}>
                                        {syrxControllerNodeCategories.find((syrxControllerNodeCategory: SyrxControllerNodeCategory) => syrxControllerNodeCategory.id === syrxControllerNodeCategoryId)?.name}
                                    </td>
                                </tr>
                                {Object.keys(syrxControllerNodeTypeCart[syrxControllerNodeCategoryId]).map(productId => {
                                    return (
                                        <tr key={productId}>
                                            <td>
                                                {syrxControllerNodeTypes[syrxControllerNodeCategoryId].find((syrxControllerNodeType: SyrxControllerNodeType) => syrxControllerNodeType.id === productId)?.name}
                                            </td>
                                            <td>
                                                {syrxControllerNodeTypeCart[syrxControllerNodeCategoryId][productId]}
                                            </td>
                                            <td>
                                                ${syrxControllerNodeTypes[syrxControllerNodeCategoryId].find((syrxControllerNodeType: SyrxControllerNodeType) => syrxControllerNodeType.id === productId)?.price}
                                            </td>
                                            <td>
                                                ${(syrxControllerNodeTypeCart[syrxControllerNodeCategoryId][productId] * syrxControllerNodeTypes[syrxControllerNodeCategoryId].find((syrxControllerNodeType: SyrxControllerNodeType) => syrxControllerNodeType.id === productId)?.price!).toFixed(2)}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </React.Fragment>
                        )
                    })}
                    <tr>
                        <td>
                            Total
                        </td>
                        <td></td>
                        <td></td>
                        <td>
                            ${(Object.keys(syrxControllerNodeTypeCart).reduce((outerAcc: number, productCategory: string) => {
                                return outerAcc + Object.keys(syrxControllerNodeTypeCart[productCategory]).reduce((innerAcc: number, productId: string) => {
                                    return innerAcc + syrxControllerNodeTypeCart[productCategory][productId] * syrxControllerNodeTypes[productCategory].find((syrxControllerNodeType: SyrxControllerNodeType) => syrxControllerNodeType.id === productId)?.price!
                                }, 0);
                            }, 0) + Object.keys(syrxControllerSizeCart).reduce((acc, productId) => {
                                return acc + syrxControllerSizeCart[productId] * syrxControllerSizes.find((syrxController: SyrxControllerSize) => syrxController.id === productId)?.price!
                            }, 0)).toFixed(2)}
                        </td>
                    </tr>
                </tbody>
            </Table>
            <div style={errorMessageRow}>
                {(syrxControllerNodeCount > otherNodeCount) &&
                    `Your order includes more syrx controller nodes (${syrxControllerNodeCount}) than nodes (${otherNodeCount}), you will be unable to add more nodes to fill those empty slots at a later date if you do not add them now.`
                }
                {(syrxControllerNodeCount < otherNodeCount) &&
                    `Your order includes less syrx controller nodes (${syrxControllerNodeCount}) than nodes (${otherNodeCount}), you will be unable to use all of your nodes if you do not add more syrx controller nodes now.`
                }
            </div>
            {isProcessingPayment ? <LoadingSpinner /> : null}
            {syrxControllerOrder.id &&
                <form style={container}>
                    <div style={stripeRow}>
                        <div style={{ border: '1px solid #dedede', height: '25px', borderRadius: '7px' }}>
                            <CardElement options={cardElementStyle} />
                        </div>
                    </div>
                    {stripeErrorMessage &&
                        <div style={errorMessage}>
                            {stripeErrorMessage}
                        </div>
                    }
                    <div>
                        <Button variant="primary" disabled={!stripe || isProcessingPayment} onClick={handleSubmit}>Submit Payment</Button>
                    </div>
                </form>
            }
            {syrxControllerOrder.payment_intent_status === 'succeeded' &&
                <div style={container}>
                    Thank you for your purchase, check your email for a detailed receipt.
                </div>
            }
            {!syrxControllerOrder.id &&
                <div style={buttonRow}>
                    <Button style={button} onClick={() => changePage(-1)}><i className="fas fa-arrow-left"></i> Previous Page</Button>
                    <Button style={button} onClick={initiatePurchase}>Confirm Order</Button>
                </div>
            }
        </>
    )
}
