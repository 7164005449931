import {CaseReducer, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {buildSubStateSelector} from '../../../store/utilities/useAppStateSelector';
import {SyrxControllerBuildingPressureControlServiceConfiguration} from '../../../models/syrxControllerBuildingPressureControlServiceConfiguration';
import {SyrxControllerBacnetPoint} from '../../../models/syrxControllerBacnetPoint';
import {SyrxControllerBacnetDevice} from '../../../models/syrxControllerBacnetDevice';

const initialState = {
    isLoading: false,
    isDoneLoading: false,
    syrxControllerBuildingPressureControlServiceConfiguration: null as SyrxControllerBuildingPressureControlServiceConfiguration | null,
    oatSyrxControllerBacnetPoint: null as SyrxControllerBacnetPoint | null,
    oatSyrxControllerBacnetDevice: null as SyrxControllerBacnetDevice | null
};

export type AppStateSyrxControllerBuildingPressureControlServiceDetails = typeof initialState;

export const slice = createSlice<AppStateSyrxControllerBuildingPressureControlServiceDetails, {
    startLoading: CaseReducer<AppStateSyrxControllerBuildingPressureControlServiceDetails>;
    setSyrxControllerPressureConfiguration: CaseReducer<AppStateSyrxControllerBuildingPressureControlServiceDetails, PayloadAction<{syrxControllerBuildingPressureControlServiceConfiguration: SyrxControllerBuildingPressureControlServiceConfiguration | null}>>;
    setOatSyrxControllerBacnetPoint: CaseReducer<AppStateSyrxControllerBuildingPressureControlServiceDetails, PayloadAction<{oatSyrxControllerBacnetPoint: SyrxControllerBacnetPoint | null}>>;
    setOatSyrxControllerBacnetDevice: CaseReducer<AppStateSyrxControllerBuildingPressureControlServiceDetails, PayloadAction<{oatSyrxControllerBacnetDevice: SyrxControllerBacnetDevice | null}>>;
    finishLoading: CaseReducer<AppStateSyrxControllerBuildingPressureControlServiceDetails>;
    resetState: CaseReducer<AppStateSyrxControllerBuildingPressureControlServiceDetails>;
}>({
    name: "syrxControllerBuildingPressureControlServiceDetails",
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
            state.isDoneLoading = false;
        },
        setSyrxControllerPressureConfiguration(state, {payload: {syrxControllerBuildingPressureControlServiceConfiguration}}) {
            state.syrxControllerBuildingPressureControlServiceConfiguration = syrxControllerBuildingPressureControlServiceConfiguration;
        },
        setOatSyrxControllerBacnetPoint(state, {payload: {oatSyrxControllerBacnetPoint}}) {
            state.oatSyrxControllerBacnetPoint = oatSyrxControllerBacnetPoint;
        },
        setOatSyrxControllerBacnetDevice(state, {payload: {oatSyrxControllerBacnetDevice}}) {
            state.oatSyrxControllerBacnetDevice = oatSyrxControllerBacnetDevice;
        },
        finishLoading(state) {
            state.isLoading = false;
            state.isDoneLoading = true;
        },
        resetState(state) {
            state.syrxControllerBuildingPressureControlServiceConfiguration = null;
            state.isLoading = false;
            state.isDoneLoading = false;
        }
    }
});

export const useSyrxControllerBuildingPressureControlServiceDetailsSelector = buildSubStateSelector(state => state.syrxControllerBuildingPressureControlServiceDetails);
export const syrxControllerBuildingPressureControlServiceDetailsActions = slice.actions;
export const syrxControllerBuildingPressureControlServiceDetailsReducer = slice.reducer;