import React, {FunctionComponent, useCallback} from 'react'
import FancyGrid, { SortCollection } from '@sht-dev/fancy-grid';

import { getSyrxControllerServiceLogsForGrid } from '../../../services/syrxControllersService';
import {
    syrxControllerServiceLogsGridActions,
    useSyrxControllerServiceLogsGridSelector
} from './syrxControllerServiceLogsGridSlice';
import {useDispatch} from 'react-redux';

export interface SyrxControllerServiceLogsGridProps {
    syrxControllerId: string;
    serviceId: string;
}

export const SyrxControllerServiceLogsGrid: FunctionComponent<SyrxControllerServiceLogsGridProps> = ({syrxControllerId, serviceId}) => {
    const dispatch = useDispatch();

    const minLogLevel = useSyrxControllerServiceLogsGridSelector(state => state.minLogLevel);
    const dataRetrievalFunction = (pageNumber: number, pageSize: number, sort: SortCollection) => getSyrxControllerServiceLogsForGrid(syrxControllerId, serviceId, minLogLevel, pageNumber, pageSize, sort);

    const setMinLogLevel = useCallback((newMinLogLevel: string) => {
        dispatch(syrxControllerServiceLogsGridActions.setMinLogLevel(newMinLogLevel));
    }, [dispatch]);

    return (
        <>

            <div>
                <select className="form-control" value={minLogLevel} onChange={e => setMinLogLevel(e.target.value)}>
                    <option>TRACE</option>
                    <option>DEBUG</option>
                    <option>INFO</option>
                    <option>WARN</option>
                    <option>ERROR</option>
                    <option>FATAL</option>
                </select>
            </div>
            <FancyGrid.ReduxGrid
                gridName={"SyrxControllerInstalledServiceLogsGrid"}
                dataRetrievalFunction={dataRetrievalFunction}
                updateTriggers={[syrxControllerId, serviceId, minLogLevel]}
            >
                <FancyGrid.ColumnList>
                    <FancyGrid.Column
                        name="timestamp"
                        title="Timestamp"
                    />
                    <FancyGrid.Column
                        name="level"
                        title="Level"
                    />
                    <FancyGrid.Column
                        name="logger"
                        title="Logger"
                    />
                    <FancyGrid.Column
                        name="message"
                        title="Message"
                    >
                        <FancyGrid.CellRenderer>
                            {message => message ? <pre>{message}</pre> : <></>}
                        </FancyGrid.CellRenderer>
                    </FancyGrid.Column>
                    <FancyGrid.Column
                        name="exception"
                        title="Exception"
                        tdProps={{style: {maxWidth: "700px"}}}
                    >
                        <FancyGrid.CellRenderer>
                            {exception => exception ? <pre>{exception}</pre> : <></>}
                        </FancyGrid.CellRenderer>
                    </FancyGrid.Column>
                </FancyGrid.ColumnList>
                <FancyGrid.ReduxSortable />
                <FancyGrid.ReduxPager />
            </FancyGrid.ReduxGrid>
        </>
    )
}