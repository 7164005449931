import {CaseReducer, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {buildSubStateSelector} from '../../../../store/utilities/useAppStateSelector';

const initialState = {
    isLoading: false,
    isSelectedPointUpdating: false,
    isDoneLoading: false,
    isSubmitted: false,
    pnormOutputSyrxControllerBacnetPointId: null as string | null
};

export type AppStateSyrxControllerBuildingPressureControlServicePnormOutputEditor = typeof initialState;

export const slice = createSlice<AppStateSyrxControllerBuildingPressureControlServicePnormOutputEditor, {
    startLoading: CaseReducer<AppStateSyrxControllerBuildingPressureControlServicePnormOutputEditor>;
    setPnormOutputSyrxControllerBacnetPointId: CaseReducer<AppStateSyrxControllerBuildingPressureControlServicePnormOutputEditor, PayloadAction<string | null>>;
    finishLoading: CaseReducer<AppStateSyrxControllerBuildingPressureControlServicePnormOutputEditor>;
    startSubmitting: CaseReducer<AppStateSyrxControllerBuildingPressureControlServicePnormOutputEditor>;
    finishSubmitting: CaseReducer<AppStateSyrxControllerBuildingPressureControlServicePnormOutputEditor>;
    resetState: CaseReducer<AppStateSyrxControllerBuildingPressureControlServicePnormOutputEditor>;
    startSelectedPointUpdating: CaseReducer<AppStateSyrxControllerBuildingPressureControlServicePnormOutputEditor>;
    finishSelectedPointUpdating: CaseReducer<AppStateSyrxControllerBuildingPressureControlServicePnormOutputEditor>;
}>({
    name: "syrxControllerBuildingPressureControlServicePnormOutputEditor",
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
            state.isDoneLoading = false;
        },
        setPnormOutputSyrxControllerBacnetPointId(state, {payload: pnormOutputSyrxControllerBacnetPointId}) {
            state.pnormOutputSyrxControllerBacnetPointId = pnormOutputSyrxControllerBacnetPointId;
        },
        finishLoading(state) {
            state.isLoading = false;
            state.isDoneLoading = true;
        },
        startSubmitting(state) {
            state.isLoading = true;
        },
        finishSubmitting(state) {
            state.isLoading = false;
            state.isSubmitted = true;
        },
        resetState(state) {
            state.pnormOutputSyrxControllerBacnetPointId = null;
            state.isLoading = false;
            state.isSelectedPointUpdating = false;
            state.isDoneLoading = false;
            state.isSubmitted = false;
        },
        startSelectedPointUpdating(state) {
            state.isSelectedPointUpdating = true;
        },
        finishSelectedPointUpdating(state) {
            state.isSelectedPointUpdating = false;
        }
    }
});

export const useSyrxControllerBuildingPressureControlServicePnormOutputEditorSelector = buildSubStateSelector(state => state.syrxControllerBuildingPressureControlServicePnormOutputEditor);
export const syrxControllerBuildingPressureControlServicePnormOutputEditorActions = slice.actions;
export const syrxControllerBuildingPressureControlServicePnormOutputEditorReducer = slice.reducer;