import React, { useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom';
import { useParams, Redirect } from 'react-router';
import { Form } from 'react-final-form';
import { Field } from 'react-final-form-html5-validation';
import { Button, FormGroup, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { buildingMeterAccountsEditorActionCreators } from '../../actionCreators/buildingMeterAccountsEditorActionCreators';
import { BuildingMeterAccount } from './types';
import { useBuildingMeterAccountsEditorSelector } from './buildingMeterAccountsEditorSlice';
import { useAuthSessionSelector } from '../authSession/authSessionSlice';

export const BuildingMeterAccountsEditor: React.FunctionComponent = () => {
    const buildingMeterAccountsEditorState = useBuildingMeterAccountsEditorSelector(x => x);
    const currentGroup = useAuthSessionSelector(state => state.currentGroup);
    const dispatch = useDispatch();

    const { id } = useParams<{ id: string | undefined }>();

    useEffect(() => {
        async function fetchData() {
            if (id) {
                dispatch(buildingMeterAccountsEditorActionCreators.getBuildingMeterAccountByIdActionCreator(id));
            }
            dispatch(buildingMeterAccountsEditorActionCreators.getWeatherStationsActionCreator());
            dispatch(buildingMeterAccountsEditorActionCreators.getTimeZonesActionCreator());
        }

        fetchData();
    }, [dispatch, id]);

    const onSubmit = useCallback(async (values: BuildingMeterAccount) => {
        if (id) {
            dispatch(buildingMeterAccountsEditorActionCreators.editBuilingMeterAccountActionCreator(id, currentGroup.id, values));
        } else {
            dispatch(buildingMeterAccountsEditorActionCreators.submitNewBuilingMeterAccountActionCreator(currentGroup.id, values));
        }
    }, [dispatch]);

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={buildingMeterAccountsEditorState}
        >
            {({ handleSubmit, values, initialValues }) => (
                <form onSubmit={handleSubmit}>
                    {buildingMeterAccountsEditorState.isSuccessful ? (
                        <Redirect to="/admin/groups/accounts" />
                    ) : null}
                    <FormGroup>
                        <Col md={4}>
                            <h2>{id ? "Edit" : "Add"} Building Meter Account</h2>
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col md={4}>
                            <Field
                                name="name"
                                component="input"
                                type="text"
                                placeholder="Name"
                                className="form-control"
                                required
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col md={4}>
                            <Field
                                name="accountNumber"
                                component="input"
                                type="text"
                                placeholder="Account Number"
                                className="form-control"
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col md={4}>
                            <Field
                                name="type"
                                component="select"
                                className="form-control"
                                required
                            >
                                {values.type === initialValues?.type ? <option value=''>Type</option> : null}
                                <option value="electric">Electric</option>
                                <option value="gas">Gas</option>
                            </Field>
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col md={4}>
                            <Field
                                name="weatherStationId"
                                component="select"
                                className="form-control"
                                required
                            >
                                {values.weatherStationId === initialValues?.weatherStationId ? <option>Weather Station</option> : null}
                                {buildingMeterAccountsEditorState.weatherStations.map(weatherStation => {
                                    return (<option value={weatherStation.id} key={weatherStation.id}>{weatherStation.name}</option>);
                                })}
                            </Field>
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col md={4}>
                            <Field
                                name="timezone"
                                component="select"
                                className="form-control"
                                required
                            >
                                {values.timezone === initialValues?.timezone ? <option value=''>Time Zone</option> : null}
                                {buildingMeterAccountsEditorState.timeZones.map(timeZone => {
                                    return (<option value={timeZone.name} key={timeZone.name}>{timeZone.name}</option>);
                                })}
                            </Field>
                        </Col>
                    </FormGroup>
                    {!id &&
                        <>
                            <FormGroup>
                                <Col md={4}>
                                    <Field
                                        name="buildingSquareFootage"
                                        component="input"
                                        type="text"
                                        placeholder="Building Square Footage"
                                        className="form-control"
                                        required
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col md={4}>
                                    <Field
                                        name="utilityCostPerUnit"
                                        component="input"
                                        type="text"
                                        placeholder="Utility Cost/Unit"
                                        className="form-control"
                                        required
                                    />
                                </Col>
                            </FormGroup>
                        </>
                    }
                    <FormGroup>
                        <Col md={4}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div>
                                    <Button variant="primary" type="submit">
                                        Submit
                                    </Button>
                                </div>
                                <div>
                                    <Link to="/admin/groups/accounts">
                                        <Button variant="secondary">
                                            Cancel
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </FormGroup>
                </form>
            )}
        </Form>
    );
}