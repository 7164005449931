import {CaseReducer, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {buildSubStateSelector} from '../../store/utilities/useAppStateSelector';
import {User} from '../../models/user';
import {Group} from '../../models/group';

const initialState = {
    initializationState: "not_initialized" as "not_initialized" | "initializing" | "initialized",
    user: null as User | null,
    currentGroup: null as Group | null,
    showCurrentGroupSelectionDialog: false
};

export type AppStateAuthSession = typeof initialState;

export const slice = createSlice<AppStateAuthSession, {
    setInitializationState: CaseReducer<AppStateAuthSession, PayloadAction<"not_initialized" | "initializing" | "initialized">>;
    updateUser: CaseReducer<AppStateAuthSession, PayloadAction<User>>;
    updateCurrentGroup: CaseReducer<AppStateAuthSession, PayloadAction<Group>>;
    setShowCurrentGroupSelectionDialog: CaseReducer<AppStateAuthSession, PayloadAction<boolean>>;
}>({
    name: "authSession",
    initialState,
    reducers: {
        setInitializationState(state, action) {
            state.initializationState = action.payload;
        },
        updateUser(state, action) {
            state.user = action.payload;
        },
        updateCurrentGroup(state, action) {
            state.currentGroup = action.payload;
        },
        setShowCurrentGroupSelectionDialog(state, action) {
            state.showCurrentGroupSelectionDialog = action.payload;
        }
    }
});

export const useAuthSessionSelector = buildSubStateSelector(state => state.authSession);
export const authSessionActions = slice.actions;
export const authSessionReducer = slice.reducer;