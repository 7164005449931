import {configureStore, Reducer} from '@reduxjs/toolkit';
import {connectRouter} from "connected-react-router";
import {history} from './history';
import {reducer as formReducer} from 'redux-form';
import {authSessionReducer} from '../features/authSession/authSessionSlice';
import {loginReducer} from '../features/login/loginSlice';
import {userRegistrationReducer} from '../features/userRegistration/userRegistrationSlice';
import {syrxControllerRegistrationReducer} from '../features/syrxControllerRegistration/syrxControllerRegistrationSlice';
import {syrxControllersListReducer} from '../features/syrxControllers/syrxControllersListSlice';
import {syrxControllerEditorReducer} from '../features/syrxControllers/editor/syrxControllerEditorSlice';
import {syrxControllerEnabledServiceDetailsReducer} from '../features/syrxControllers/enabledServices/syrxControllerEnabledServiceDetailsSlice';
import {syrxControllerServiceLogsGridReducer} from '../features/syrxControllers/logs/syrxControllerServiceLogsGridSlice';
import {syrxControllerBacnetDevicesGridReducer} from '../features/syrxControllers/bacnetNetworkQueryService/syrxControllerBacnetDevicesGridSlice';
import {syrxControllerBacnetPointsGridReducer} from '../features/syrxControllers/bacnetNetworkQueryService/syrxControllerBacnetPointsGridSlice';
import {syrxControllerReportingPointsGridReducer} from '../features/syrxControllers/reportingService/syrxControllerReportingPointsGridSlice';
import {syrxControllerBacnetNetworkQueryServiceMessagesGridReducer} from '../features/syrxControllers/bacnetNetworkQueryService/syrxControllerBacnetNetworkQueryServiceMessagesGridSlice';
import {syrxControllerDiagnosticServiceDetailsReducer} from '../features/syrxControllers/diagnosticService/syrxControllerDiagnosticServiceDetailsSlice';
import {syrxControllerDiagnosticServiceMetricsReducer} from '../features/syrxControllers/diagnosticService/syrxControllerDiagnosticServiceMetricsSlice';
import {syrxControllerBacnetDeviceEditorReducer} from '../features/syrxControllers/bacnetNetworkQueryService/syrxControllerBacnetDeviceEditorSlice';
import {syrxControllerBuildingPressureControlServiceDetailsReducer} from '../features/syrxControllers/buildingPressureControlService/syrxControllerBuildingPressureControlServiceDetailsSlice';
import {syrxControllerBuildingPressureControlServiceBasicConfigurationEditorReducer} from '../features/syrxControllers/buildingPressureControlService/syrxControllerBuildingPressureControlServiceBasicConfigurationEditorSlice';
import {syrxControllerBuildingPressureControlServiceOatSensorEditorReducer} from '../features/syrxControllers/buildingPressureControlService/oatSensor/syrxControllerBuildingPressureControlServiceOatSensorEditorSlice';
import {syrxControllerBuildingPressureControlServicePnormOutputEditorReducer} from '../features/syrxControllers/buildingPressureControlService/pnormOutput/syrxControllerBuildingPressureControlServicePnormOutputEditorSlice';
import {syrxControllerBuildingPressureTransmitterEditorReducer} from '../features/syrxControllers/buildingPressureControlService/buildingPressureTransmitters/syrxControllerBuildingPressureTransmitterEditorSlice';
import {fancyGridReducer} from '@sht-dev/fancy-grid';
import {buildingMeterAccountsGridReducer} from '../features/buildingMeterAccounts/buildingMeterAccountsGridSlice';
import {buildingMeterAccountsEditorReducer} from '../features/buildingMeterAccounts/buildingMeterAccountsEditorSlice';
import {purchasingReducer} from '../features/purchasing/purchasingSlice';
import {projectsReducer} from '../features/projects/projectsSlice';
import {projectEditorReducer} from '../features/projects/projectEditorSlice';
import {projectScheduleReducer} from '../features/projects/projectScheduleSlice';
import {moveVendorPointDataNewJobReducer} from '../features/vendorPoints/moveVendorPointData/newJob/moveVendorPointDataNewJobSlice';
import {syrxControllerServicesManagerListReducer} from '../features/syrxControllerServices/list/syrxControllerServicesManagerListSlice';
import {syrxControllerServiceVersionEditorReducer} from '../features/syrxControllerServices/versionEditor/syrxControllerServiceVersionEditorSlice';
import {syrxControllerEnabledServicesManagerReducer} from '../features/syrxControllers/enabledServices/enabledServicesManager/syrxControllerEnabledServicesManagerSlice';
import {roleEditorReducer} from '../features/admin/roles/editor/roleEditorSlice';
import {groupRoleEditorReducer} from '../features/admin/groupRoles/editor/groupRoleEditorSlice';
import {equipmentEditorReducer} from '../features/equipment/equipmentEditorSlice';


export const reducer = {
    router: connectRouter(history) as Reducer,
    form: formReducer,
    authSession: authSessionReducer,
    login: loginReducer,
    userRegistration: userRegistrationReducer,
    syrxControllerRegistration: syrxControllerRegistrationReducer,
    syrxControllersList: syrxControllersListReducer,
    syrxControllerEditor: syrxControllerEditorReducer,
    syrxControllerEnabledServiceDetails: syrxControllerEnabledServiceDetailsReducer,
    syrxControllerServiceLogsGrid: syrxControllerServiceLogsGridReducer,
    syrxControllerBacnetDevicesGrid: syrxControllerBacnetDevicesGridReducer,
    syrxControllerBacnetPointsGrid: syrxControllerBacnetPointsGridReducer,
    syrxControllerReportingPointsGrid: syrxControllerReportingPointsGridReducer,
    syrxControllerBacnetNetworkQueryServiceMessagesGrid: syrxControllerBacnetNetworkQueryServiceMessagesGridReducer,
    syrxControllerDiagnosticServiceDetails: syrxControllerDiagnosticServiceDetailsReducer,
    syrxControllerDiagnosticServiceMetrics: syrxControllerDiagnosticServiceMetricsReducer,
    syrxControllerBacnetDeviceEditor: syrxControllerBacnetDeviceEditorReducer,
    syrxControllerBuildingPressureControlServiceDetails: syrxControllerBuildingPressureControlServiceDetailsReducer,
    syrxControllerBuildingPressureControlServiceBasicConfigurationEditor: syrxControllerBuildingPressureControlServiceBasicConfigurationEditorReducer,
    syrxControllerBuildingPressureControlServiceOatSensorEditor: syrxControllerBuildingPressureControlServiceOatSensorEditorReducer,
    syrxControllerBuildingPressureControlServicePnormOutputEditor: syrxControllerBuildingPressureControlServicePnormOutputEditorReducer,
    syrxControllerBuildingPressureTransmitterEditor: syrxControllerBuildingPressureTransmitterEditorReducer,
    syrxControllerServicesManagerList: syrxControllerServicesManagerListReducer,
    syrxControllerServiceVersionEditor: syrxControllerServiceVersionEditorReducer,
    syrxControllerEnabledServicesManager: syrxControllerEnabledServicesManagerReducer,
    moveVendorPointDataNewJob: moveVendorPointDataNewJobReducer,
    fancyGrid: fancyGridReducer as Reducer,
    roleEditor: roleEditorReducer,
    groupRoleEditor: groupRoleEditorReducer,
    buildingMeterAccountsGrid: buildingMeterAccountsGridReducer,
    buildingMeterAccountsEditor: buildingMeterAccountsEditorReducer,
    purchasing: purchasingReducer,
    projects: projectsReducer,
    projectEditor: projectEditorReducer,
    projectSchedule: projectScheduleReducer,
    equipmentEditor: equipmentEditorReducer
};

export function configureAppStore() {
    const store = configureStore({
        reducer
    });
    return store
}