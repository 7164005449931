import React from 'react'
import {RolesListGrid} from './rolesListGrid';
import {LinkContainer} from 'react-router-bootstrap';
import {Button} from 'react-bootstrap';

export const RolesList: React.FunctionComponent = () => {
    return (
        <>
            <h3>Roles</h3>
            <LinkContainer to={"/admin/roles/new"}><Button>Create new</Button></LinkContainer>
            <RolesListGrid />
        </>
    );
};