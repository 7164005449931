import React, {useCallback, useEffect, useMemo} from 'react'
import {useDispatch} from 'react-redux';
import {LoadingSpinner} from '../../../loadingSpinner/LoadingSpinner';
import {syrxControllerBuildingPressureTransmitterEditorActionCreators} from './syrxControllerBuildingPressureTransmitterEditorActionCreators';
import {Redirect} from 'react-router';
import {
    syrxControllerBuildingPressureTransmitterEditorActions,
    useSyrxControllerBuildingPressureTransmitterEditorSelector
} from './syrxControllerBuildingPressureTransmitterEditorSlice';
import {Field, Form as ReactFinalForm} from 'react-final-form';
import {Button, Col, Form, FormGroup, Row} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import {SyrxControllerBACnetPointSelectionTreeFieldAdapter} from '../../bacnetPoints/syrxControllerBACnetPointSelectionTreeFieldAdapter';

interface FormValues {
    num: string;
    name: string;
    transmitter_height: number;
    syrx_controller_bacnet_point_id: string;
}

export const SyrxControllerBuildingPressureTransmitterEditor: React.FunctionComponent<{ syrxControllerId: string, syrxControllerBuildingPressureTransmitterId: string | null }> = ({syrxControllerId, syrxControllerBuildingPressureTransmitterId}) => {
    const dispatch = useDispatch();
    const isLoading = useSyrxControllerBuildingPressureTransmitterEditorSelector(x => x.isLoading);
    const isDoneLoading = useSyrxControllerBuildingPressureTransmitterEditorSelector(x => x.isDoneLoading);
    const isSubmitted = useSyrxControllerBuildingPressureTransmitterEditorSelector(x => x.isSubmitted);
    const syrxControllerBuildingPressureTransmitter = useSyrxControllerBuildingPressureTransmitterEditorSelector(x => x.syrxControllerBuildingPressureTransmitter);

    useEffect(() => {
        dispatch(syrxControllerBuildingPressureTransmitterEditorActionCreators.load(syrxControllerId, syrxControllerBuildingPressureTransmitterId));

        return () => {
            dispatch(syrxControllerBuildingPressureTransmitterEditorActions.resetState())
        };
    }, [dispatch, syrxControllerId, syrxControllerBuildingPressureTransmitterId]);

    const submit = useCallback((values: FormValues) => {
        dispatch(syrxControllerBuildingPressureTransmitterEditorActionCreators.submit(syrxControllerId, syrxControllerBuildingPressureTransmitterId, values));
    }, [dispatch, syrxControllerId, syrxControllerBuildingPressureTransmitterId]);

    const initialValues = useMemo<FormValues | undefined>(() => syrxControllerBuildingPressureTransmitter == null ? undefined : {
        num: syrxControllerBuildingPressureTransmitter.num,
        name: syrxControllerBuildingPressureTransmitter.name ?? "",
        transmitter_height: syrxControllerBuildingPressureTransmitter.transmitter_height,
        syrx_controller_bacnet_point_id: syrxControllerBuildingPressureTransmitter.syrx_controller_bacnet_point_id
    }, [syrxControllerBuildingPressureTransmitter]);

    return (
        <>
            {isLoading ? <LoadingSpinner /> : null}
            {isDoneLoading ? (
                <ReactFinalForm onSubmit={submit} initialValues={initialValues}>
                    {({handleSubmit}) => (
                        <Form onSubmit={handleSubmit}>
                            <h4>Building Pressure Transmitter</h4>
                            <fieldset>
                                <FormGroup as={Row}>
                                    <Form.Label column md={6} lg={4}>Number</Form.Label>
                                    <Col md={6}>
                                        <Field
                                            name="num"
                                            component="input"
                                            className="form-control"
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup as={Row}>
                                    <Form.Label column md={6} lg={4}>Name</Form.Label>
                                    <Col md={6}>
                                        <Field
                                            name="name"
                                            component="input"
                                            className="form-control"
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup as={Row}>
                                    <Form.Label column md={6} lg={4}>Transmitter height (ft)</Form.Label>
                                    <Col md={6}>
                                        <Field
                                            name="transmitter_height"
                                            component="input"
                                            type="number"
                                            className="form-control"
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup as={Row}>
                                    <Form.Label column md={6} lg={4}>BACnet point</Form.Label>
                                    <Col md={6}>
                                        <SyrxControllerBACnetPointSelectionTreeFieldAdapter
                                            syrxControllerId={syrxControllerId}
                                            name="syrx_controller_bacnet_point_id"
                                        />
                                    </Col>
                                </FormGroup>

                            </fieldset>
                            <Button variant="primary" type="submit">Submit</Button>
                            <LinkContainer to={`/admin/syrx-controllers/${syrxControllerId}/services/Pathian.Services.BuildingPressureControlService`} exact={true}><Button variant="outline-secondary">Cancel</Button></LinkContainer>
                        </Form>
                    )}
                </ReactFinalForm>
            ) : null}
            {isSubmitted ? <Redirect to={`/admin/syrx-controllers/${syrxControllerId}/services/Pathian.Services.BuildingPressureControlService`} /> : null}
        </>
    )
};