import {FilterCollection, SortCollection} from '@sht-dev/fancy-grid';
import qs from "qs";
import {axios} from "./axiosService";
import { SyrxController } from '../models/syrxController';
import {SyrxControllerBacnetConnectionConfiguration} from '../models/syrxControllerBacnetConnectionConfiguration';
import {SyrxControllerBacnetConnectionBbmdServer} from '../models/syrxControllerBacnetConnectionBbmdServer';
import {SyrxControllerEnabledService} from '../models/syrxControllerEnabledService';
import {SyrxControllerBacnetDevice, SyrxControllerBacnetDeviceUpdateModel} from '../models/syrxControllerBacnetDevice';
import {SyrxControllerBacnetPoint, SyrxControllerBacnetPointUpdateModel} from '../models/syrxControllerBacnetPoint';
import {SyrxControllerBacnetNetworkQueryServiceMessage} from '../models/syrxControllerBacnetNetworkQueryServiceMessage';
import dayjs from 'dayjs';
import {AppStateSyrxControllerBacnetNetworkQueryServiceMessagesFilterCriteria} from '../features/syrxControllers/bacnetNetworkQueryService/syrxControllerBacnetNetworkQueryServiceMessagesGridSlice';
import {MetricsAggregates, MetricsAggregatesWithStringDate} from '../models/metricsAggregates';
import {AxiosError} from 'axios';
import {SyrxControllerBuildingPressureControlServiceConfiguration} from '../models/syrxControllerBuildingPressureControlServiceConfiguration';
import {QueryResult} from '../models/queryResult';
import {SyrxControllerBuildingPressureTransmitter} from '../models/syrxControllerBuildingPressureTransmitter';
import {SyrxControllerServiceType} from '../models/syrxControllerServiceType';
import {SyrxControllerEnabledServiceModel} from '../features/syrxControllers/enabledServices/enabledServicesManager/syrxControllerEnabledServicesManagerActionCreators';
import {
    SyrxControllerRabbitmqMetricsActiveChannelRecords, SyrxControllerRabbitmqMetricsActiveConnectionRecords,
    SyrxControllerRabbitmqMetricsActiveQueueRecords
} from '../models/syrxControllerDiagnosticServiceRabbitmqMetricsCollectionRecord';

export const getSyrxControllersForGrid = async (rootGroupId: number | null, pageNum: number, pageSize: number, sort: SortCollection) => {
    const queryString = qs.stringify({
        ...(rootGroupId != null ? {root_group_id: rootGroupId} : {}),
        skip: pageNum * pageSize,
        take: pageSize,
        page: pageNum,
        pageSize,
        sort: sort.map(x => ({
            field: x.fieldName,
            dir: x.dir
        }))
    });
    const res = await axios.get<{data: SyrxController[], total: number}>(`/SyrxControllers?${queryString}`);
    return res.data;
};

export const getSyrxControllers = async (rootGroupId: number | null) => {
    const queryString = qs.stringify({
        ...(rootGroupId != null ? {root_group_id: rootGroupId} : {})
    });
    const res = await axios.get<SyrxController[]>(`/SyrxControllers?${queryString}`);
    return res.data;
};

export const getSyrxControllersCount = async (rootGroupId: number | null) => {
    const queryString = qs.stringify({
        ...(rootGroupId != null ? {root_group_id: rootGroupId} : {})
    });
    const {data: {count}} = await axios.get<{count: number}>(`/SyrxControllers/count?${queryString}`);
    return count;
};

export const getSyrxControllerById = async (syrxControllerId: string) => {
    const res = await axios.get<SyrxController>(`/SyrxControllers/${syrxControllerId}`);
    return res.data;
};

export const saveSyrxController = async (syrxController: SyrxController): Promise<SyrxController> => {
    if (syrxController.id == null) {
        const res = await axios.post<SyrxController>(`/SyrxControllers`, syrxController);
        return res.data;
    } else {
        const res = await axios.put<SyrxController>(`/SyrxControllers/${syrxController.id}`, syrxController);
        return res.data
    }
};

export const getSyrxControllerBacnetConnectionConfiguration = async (syrxControllerId: string) => {
    const res = await axios.get<SyrxControllerBacnetConnectionConfiguration>(`/SyrxControllers/${syrxControllerId}/bacnet-connection-configuration`);
    return res.data;
};

export const saveSyrxControllerBacnetConnectionConfiguration = async (syrxControllerId: string, syrxControllerBacnetConnectionConfiguration: SyrxControllerBacnetConnectionConfiguration): Promise<SyrxControllerBacnetConnectionConfiguration> => {
    const res = await axios.put<SyrxControllerBacnetConnectionConfiguration>(`/SyrxControllers/${syrxControllerId}/bacnet-connection-configuration`, syrxControllerBacnetConnectionConfiguration);
    return res.data
};

export const removeSyrxControllerBacnetConnectionConfiguration = async (syrxControllerId: string): Promise<void> => {
    await axios.delete<SyrxControllerBacnetConnectionConfiguration>(`/SyrxControllers/${syrxControllerId}/bacnet-connection-configuration`);
};

export const getSyrxControllerBacnetConnectionBbmdServers = async (syrxControllerId: string) => {
    const res = await axios.get<SyrxControllerBacnetConnectionBbmdServer[]>(`/SyrxControllers/${syrxControllerId}/bacnet-connection-bbmd-servers`);
    return res.data;
};

export const saveSyrxControllerBacnetConnectionBbmdServers = async (syrxControllerId: string, syrxControllerBacnetConnectionBbmdServers: SyrxControllerBacnetConnectionBbmdServer[]): Promise<SyrxControllerBacnetConnectionBbmdServer[]> => {
    const res = await axios.put<SyrxControllerBacnetConnectionBbmdServer[]>(`/SyrxControllers/${syrxControllerId}/bacnet-connection-bbmd-servers`, syrxControllerBacnetConnectionBbmdServers);
    return res.data
};


export const getSyrxControllerServicesForEnabling = async (syrxControllerId: string) => {
    const res = await axios.get<{syrx_controller_service_type: SyrxControllerServiceType, syrx_controller_enabled_service: SyrxControllerEnabledService | null}[]>(`/SyrxControllers/${syrxControllerId}/services`);
    return res.data;
};


export const getSyrxControllerEnabledServicesForGrid = async (syrxControllerId: string, pageNum: number, pageSize: number, sort: SortCollection) => {
    const queryString = qs.stringify({
        skip: pageNum * pageSize,
        take: pageSize,
        page: pageNum,
        pageSize,
        sort: sort.map(x => ({
            field: x.fieldName,
            dir: x.dir
        }))
    });
    const res = await axios.get<{data: SyrxControllerEnabledService[], total: number}>(`/SyrxControllers/${syrxControllerId}/enabled-services?${queryString}`);
    return res.data;
};

export const getSyrxControllerEnabledServices = async (syrxControllerId: string) => {
    const res = await axios.get<SyrxControllerEnabledService[]>(`/SyrxControllers/${syrxControllerId}/enabled-services`);
    return res.data;
};

export const setSyrxControllerEnabledServices = async (syrxControllerId: string, syrxControllerEnabledServiceModels: SyrxControllerEnabledServiceModel[]) => {
    const res = await axios.put<SyrxControllerEnabledService[]>(`/SyrxControllers/${syrxControllerId}/enabled-services`, syrxControllerEnabledServiceModels);
    return res.data;
};

export const getSyrxControllerEnabledServiceById = async (syrxControllerId: string, serviceId: string) => {
    const res = await axios.get<SyrxControllerEnabledService>(`/SyrxControllers/${syrxControllerId}/enabled-services/${serviceId}`);
    return res.data;
};

export const getSyrxControllerServiceLogsForGrid = async (syrxControllerId: string, serviceId: string, minLogLevel: string, pageNum: number, pageSize: number, sort: SortCollection) => {
    const queryString = qs.stringify({
        minLogLevel,
        skip: pageNum * pageSize,
        take: pageSize,
        page: pageNum,
        pageSize,
        sort: sort.map(x => ({
            field: x.fieldName,
            dir: x.dir
        }))
    });
    const res = await axios.get<{data: SyrxController[], total: number}>(`/SyrxControllers/${syrxControllerId}/services/${serviceId}/logs?${queryString}`);
    return res.data;
};

export const getSyrxControllerBacnetDevicesForGrid = async (syrxControllerId: string, showUnavailable: boolean, pageNum: number, pageSize: number, sort: SortCollection) => {
    const queryString = qs.stringify({
        skip: pageNum * pageSize,
        take: pageSize,
        page: pageNum,
        pageSize,
        sort: sort.map(x => ({
            field: x.fieldName,
            dir: x.dir
        })),
        showUnavailable
    });
    const res = await axios.get<{data: SyrxControllerBacnetDevice[], total: number}>(`/SyrxControllers/${syrxControllerId}/bacnet-devices?${queryString}`);
    return res.data;
};

export const getSyrxControllerBacnetDevicesCount = async (syrxControllerId: string, showUnavailable: boolean) => {
    const queryString = qs.stringify({
        showUnavailable
    });
    const {data: {count}} = await axios.get<{count: number}>(`/SyrxControllers/${syrxControllerId}/bacnet-devices/count?${queryString}`);
    return count;
};

export const getSyrxControllerBacnetDevices = async (syrxControllerId: string, showUnavailable: boolean) => {
    const queryString = qs.stringify({
        showUnavailable
    });
    const res = await axios.get<SyrxControllerBacnetDevice[]>(`/SyrxControllers/${syrxControllerId}/bacnet-devices?${queryString}`);
    return res.data;
};

export const getSyrxControllerBacnetDevice = async (syrxControllerId: string, syrxControllerBacnetDeviceId: string) => {
    const res = await axios.get<SyrxControllerBacnetDevice>(`/SyrxControllers/${syrxControllerId}/bacnet-devices/${syrxControllerBacnetDeviceId}`);
    return res.data;
};

export const reloadPropertiesForDevice = async (syrxControllerId: string, syrxControllerBacnetDeviceId: string) => {
    await axios.post(`/SyrxControllers/${syrxControllerId}/bacnet-devices/${syrxControllerBacnetDeviceId}/reload-properties`);
};

export const ignoreDevice = async (syrxControllerId: string, syrxControllerBacnetDeviceId: string) => {
    await axios.put(`/SyrxControllers/${syrxControllerId}/bacnet-devices/${syrxControllerBacnetDeviceId}/ignored`, {ignored: true});
};

export const unignoreDevice = async (syrxControllerId: string, syrxControllerBacnetDeviceId: string) => {
    await axios.put(`/SyrxControllers/${syrxControllerId}/bacnet-devices/${syrxControllerBacnetDeviceId}/ignored`, {ignored: false});
};

export const reloadPointsForDevice = async (syrxControllerId: string, syrxControllerBacnetDeviceId: string) => {
    await axios.post(`/SyrxControllers/${syrxControllerId}/bacnet-devices/${syrxControllerBacnetDeviceId}/reload-points`);
};

export const getSyrxControllerBacnetPointsForGrid = async (syrxControllerId: string, showUnavailable: boolean, pageNum: number, pageSize: number, sort: SortCollection = [], filter: FilterCollection = []) => {
    const queryString = qs.stringify({
        skip: pageNum * pageSize,
        take: pageSize,
        page: pageNum,
        pageSize,
        sort: sort.map(x => ({
            field: x.fieldName,
            dir: x.dir
        })),
        filter: {
            filters: filter.map(x => ({
                field: x.fieldName,
                field_type: x.fieldType,
                operator: x.operator,
                value: x.value
            }))
        },
        showUnavailable
    });
    console.log(queryString);
    const res = await axios.get<{data: SyrxControllerBacnetPoint[], total: number}>(`/SyrxControllers/${syrxControllerId}/bacnet-points?${queryString}`);
    return res.data;
};

export const getSyrxControllerBacnetPoints = async (syrxControllerId: string, showUnavailable: boolean) => {
    const queryString = qs.stringify({
        showUnavailable
    });
    const res = await axios.get<SyrxControllerBacnetPoint[]>(`/SyrxControllers/${syrxControllerId}/bacnet-points?${queryString}`);
    return res.data;
};

export const getSyrxControllerBacnetPoint = async (syrxControllerId: string, syrxControllerBacnetPointId: string) => {
    const {data: syrxControllerBacnetPoint} = await axios.get<SyrxControllerBacnetPoint>(`/SyrxControllers/${syrxControllerId}/bacnet-points/${syrxControllerBacnetPointId}`);
    return syrxControllerBacnetPoint;
};

export const getSyrxControllerBacnetPointsByDeviceForGrid = async (syrxControllerId: string, syrxControllerBacnetDeviceId: string, showUnavailable: boolean, pageNum: number, pageSize: number, sort: SortCollection = [], filter: FilterCollection = []) => {
    const queryString = qs.stringify({
        skip: pageNum * pageSize,
        take: pageSize,
        page: pageNum,
        pageSize,
        sort: sort.map(x => ({
            field: x.fieldName,
            dir: x.dir
        })),
        filter: {
            filters: filter.map(x => ({
                field: x.fieldName,
                field_type: x.fieldType,
                operator: x.operator,
                value: x.value
            }))
        },
        showUnavailable
    });
    const res = await axios.get<{data: SyrxControllerBacnetPoint[], total: number}>(`/SyrxControllers/${syrxControllerId}/bacnet-devices/${syrxControllerBacnetDeviceId}/bacnet-points?${queryString}`);
    return res.data;
};

export const getSyrxControllerBacnetPointsByDeviceCount = async (syrxControllerId: string, syrxControllerBacnetDeviceId: string, showUnavailable: boolean) => {
    const queryString = qs.stringify({
        showUnavailable
    });
    const {data: {count}} = await axios.get<{count: number}>(`/SyrxControllers/${syrxControllerId}/bacnet-devices/${syrxControllerBacnetDeviceId}/bacnet-points/count?${queryString}`);
    return count;
};

export const getSyrxControllerBacnetPointsByDevice = async (syrxControllerId: string, syrxControllerBacnetDeviceId: string, showUnavailable: boolean) => {
    const queryString = qs.stringify({
        showUnavailable
    });
    const res = await axios.get<SyrxControllerBacnetPoint[]>(`/SyrxControllers/${syrxControllerId}/bacnet-devices/${syrxControllerBacnetDeviceId}/bacnet-points?${queryString}`);
    return res.data;
};

export const getSyrxControllerBacnetNetworkQueryServiceMessagesForGrid = async (syrxControllerId: string, filterCriteria: AppStateSyrxControllerBacnetNetworkQueryServiceMessagesFilterCriteria, pageNum: number, pageSize: number, sort: SortCollection) => {
    const queryString = qs.stringify({
        skip: pageNum * pageSize,
        take: pageSize,
        page: pageNum,
        pageSize,
        sort: sort.map(x => ({
            field: x.fieldName,
            dir: x.dir
        })),
        filter: {
            filters: [
                ...(filterCriteria === 'hide_acknowledged_hide_completed' ? [
                    {
                        field: 'acknowledged_on',
                        operator: 'doesnotexist'
                    }
                ] : []),
                ...(filterCriteria === 'hide_acknowledged_hide_completed' || filterCriteria === 'show_acknowledged_hide_completed' ? [
                    {
                        field: 'completed_on',
                        operator: 'doesnotexist'
                    }
                ] : [])
            ]
        }
    });
    const res = await axios.get<{data: SyrxControllerBacnetNetworkQueryServiceMessage[], total: number}>(`/SyrxControllers/${syrxControllerId}/services/Pathian.BACnetNetworkQueryService/messages?${queryString}`);
    return res.data;
};

export const patchBacnetDevices = async(syrxControllerId: string, deviceUpdateModels: SyrxControllerBacnetDeviceUpdateModel[]) => {
    // sometimes the collection comes in with additional properties that the api doesn't like (because the interface
    // gets extended)

    deviceUpdateModels = deviceUpdateModels.map(deviceUpdateModel => ({
        mac_address: deviceUpdateModel.mac_address,
        network: deviceUpdateModel.network,
        destination_mac_address: deviceUpdateModel.destination_mac_address,
        destination_network: deviceUpdateModel.destination_network,
        instance_number: deviceUpdateModel.instance_number,
        name: deviceUpdateModel.name,
        vendor_name: deviceUpdateModel.vendor_name,
        description: deviceUpdateModel.description,
        status: deviceUpdateModel.status
    }))
    const res = await axios.patch(`/SyrxControllers/${syrxControllerId}/bacnet-devices`, deviceUpdateModels);
    return res.data
};

export const updateBacnetPoints = async(syrxControllerId: string, syrxControllerBacnetDeviceId: string, pointUpdateModels: SyrxControllerBacnetPointUpdateModel[], progress?: (i: number) => any) => {
    // sometimes the collection comes in with additional properties that the api doesn't like (because the interface
    // gets extended)

    pointUpdateModels = pointUpdateModels.map(item => ({
        syrx_controller_bacnet_device_id: item.syrx_controller_bacnet_device_id,
        object_type: item.object_type,
        instance_number: item.instance_number,
        name: item.name,
        description: item.description,
    }))

    await axios.post(`/SyrxControllers/${syrxControllerId}/bacnet-devices/${syrxControllerBacnetDeviceId}/bacnet-points/begin`);

    const numBatches = Math.ceil(pointUpdateModels.length / 500);
    for (let i = 0; i < numBatches; ++i) {
        const batch = pointUpdateModels.slice(i * 500, i * 500 + 500);
        await axios.post(`/SyrxControllers/${syrxControllerId}/bacnet-devices/${syrxControllerBacnetDeviceId}/bacnet-points`, batch);
        progress != null && progress(Math.min(i * 500 + 500, pointUpdateModels.length));
    }

    await axios.post(`/SyrxControllers/${syrxControllerId}/bacnet-devices/${syrxControllerBacnetDeviceId}/bacnet-points/complete`);
};

export const createDiscoverDevicesSyrxControllerBacnetNetworkQueryServiceMessage = async(syrxControllerId: string) => {
    const res = await axios.post<SyrxControllerBacnetNetworkQueryServiceMessage>(`/SyrxControllers/${syrxControllerId}/services/Pathian.BACnetNetworkQueryService/messages/create-discover-devices-message`);
    return res.data
};

export const createRetrieveBasicPropertiesForDevicesSyrxControllerBacnetNetworkQueryServiceMessage = async(syrxControllerId: string) => {
    const res = await axios.post<SyrxControllerBacnetNetworkQueryServiceMessage>(`/SyrxControllers/${syrxControllerId}/services/Pathian.BACnetNetworkQueryService/messages/create-retrieve-basic-properties-for-devices-message`);
    return res.data
};

export const createDiscoverPointsSyrxControllerBacnetNetworkQueryServiceMessage = async(syrxControllerId: string) => {
    const res = await axios.post<SyrxControllerBacnetNetworkQueryServiceMessage>(`/SyrxControllers/${syrxControllerId}/services/Pathian.BACnetNetworkQueryService/messages/create-discover-points-message`);
    return res.data
};

export const setIsSelectedForReportingForSyrxControllerReportingPoint = async (syrxControllerId: string, syrxControllerBacnetPointId: string, isSelectedForReporting: boolean) => {
    const payload = {
        is_selected_for_reporting: isSelectedForReporting
    };
    await axios.patch<{}>(`/SyrxControllers/${syrxControllerId}/reporting-points/${syrxControllerBacnetPointId}`, payload);
};

export const getLatestDiagnosticsForSyrxController = async (syrxControllerId: string) => {
    const {data} = await axios.get<{
        network_interface_information: {
            timestamp: string,
            networkInterfaceInformation: string
        },
        rabbitmq_metrics_active_queues: SyrxControllerRabbitmqMetricsActiveQueueRecords[],
        rabbitmq_metrics_active_channels: SyrxControllerRabbitmqMetricsActiveChannelRecords[],
        rabbitmq_metrics_active_connections: SyrxControllerRabbitmqMetricsActiveConnectionRecords[]
    }>(`/SyrxControllers/${syrxControllerId}/services/Pathian.DiagnosticService/diagnostics/latest`);

    const diagnostics = {
        latestNetworkInterfaceInformationRecord: data.network_interface_information != null ? {
            timestamp: dayjs(data.network_interface_information.timestamp).toDate(),
            networkInterfaceInformation: data.network_interface_information.networkInterfaceInformation
        } : null,
        latestRabbitmqMetricsCollectionRecord: {
            queues: data.rabbitmq_metrics_active_queues,
            channels: data.rabbitmq_metrics_active_channels,
            connections: data.rabbitmq_metrics_active_connections
        }
    };

    return diagnostics;
};

export const getMetricsGroupsForSyrxController = async (groupType: "process" | "systemd_service", syrxControllerId: string, startDate: Date, endDate: Date) => {
    const queryString = qs.stringify({
        startDate: dayjs.utc(startDate).format(),
        endDate: dayjs.utc(endDate).format(),
        groupType
    });

    const {data} = await axios.get<string[]>(`/SyrxControllers/${syrxControllerId}/services/Pathian.DiagnosticService/metrics/groups?${queryString}`);

    return data;
};

export const getMetricsForController = async (groupType: "overall" | "process" | "systemd_service", syrxControllerId: string, startDate: Date, endDate: Date, commands: string[], resolution: string) => {
    const queryString = qs.stringify({
        startDate: dayjs.utc(startDate).format(),
        endDate: dayjs.utc(endDate).format(),
        groupType,
        ...(groupType !== "overall" ? {groups: commands} : {}),
        resolution
    });

    const {data} = await axios.get<MetricsAggregatesWithStringDate>(`/SyrxControllers/${syrxControllerId}/services/Pathian.DiagnosticService/metrics?${queryString}`);

    const dataWithParsedDate: MetricsAggregates = data.map(entry => ({
        ...entry,
        date: dayjs.utc(entry.date).toDate()
    }));

    return dataWithParsedDate;
};

export const createSyrxControllerBacnetDevice = async (syrxControllerId: string, syrxControllerBacnetDevice: SyrxControllerBacnetDevice): Promise<SyrxControllerBacnetDevice> => {
    const res = await axios.post<SyrxControllerBacnetDevice>(`/SyrxControllers/${syrxControllerId}/syrx-controller-bacnet-devices`, syrxControllerBacnetDevice);
    return res.data
};

export const getSyrxControllerCriticalSystemdServiceStatuses = async (syrxControllerId: string) => {
    try {
        const {data: systemdServiceStatuses} = await axios.get<{ [systemdServiceName: string]: string }>(`/SyrxControllers/${syrxControllerId}/critical-systemd-service-statuses`);
        return systemdServiceStatuses;
    } catch (e) {
        if (e.isAxiosError) {
            const axiosError = e as AxiosError;
            if (axiosError.response?.status === 404) {
                return null;
            }
        }

        throw e;
    }
};

export const getBuildingPressureControlServiceConfigurationForSyrxController = async (syrxControllerId: string) => {
    const {data: syrxControllerBuildingPressureControlServiceConfiguration} = await axios.get<SyrxControllerBuildingPressureControlServiceConfiguration | null>(`/SyrxControllers/${syrxControllerId}/services/Pathian.Services.BuildingPressureControlService/configuration`);
    return syrxControllerBuildingPressureControlServiceConfiguration;
};

export const patchBuildingPressureControlServiceConfigurationForSyrxController = async (syrxControllerId: string, model: Partial<SyrxControllerBuildingPressureControlServiceConfiguration>) => {
    const {data: syrxControllerBuildingPressureControlServiceConfiguration} = await axios.patch<SyrxControllerBuildingPressureControlServiceConfiguration | null>(`/SyrxControllers/${syrxControllerId}/services/Pathian.Services.BuildingPressureControlService/configuration`, model);
    return syrxControllerBuildingPressureControlServiceConfiguration;
};

export const getBuildingPressureTransmittersForSyrxController = async (syrxControllerId: string, pageNum: number, pageSize: number, sort: SortCollection) => {
    const queryString = qs.stringify({
        skip: pageNum * pageSize,
        take: pageSize,
        page: pageNum,
        pageSize,
        sort: sort.map(x => ({
            field: x.fieldName,
            dir: x.dir
        }))
    });
    const {data: queryResult} = await axios.get<QueryResult<SyrxControllerBuildingPressureTransmitter>>(`/SyrxControllers/${syrxControllerId}/services/Pathian.Services.BuildingPressureControlService/building-pressure-transmitters?${queryString}`);
    return queryResult;
};

export const getBuildingPressureTransmitter = async (syrxControllerId: string, syrxControllerBuildingPressureTransmitterId: string) => {
    const {data: buildingPressureTransmitter} = await axios.get<SyrxControllerBuildingPressureTransmitter>(`/SyrxControllers/${syrxControllerId}/services/Pathian.Services.BuildingPressureControlService/building-pressure-transmitters/${syrxControllerBuildingPressureTransmitterId}`);
    return buildingPressureTransmitter;
};

export const createBuildingPressureTransmitter = async (syrxControllerId: string, syrxControllerBuildingPressureTransmitter: Partial<SyrxControllerBuildingPressureTransmitter>) => {
    const {data: buildingPressureTransmitter} = await axios.post<SyrxControllerBuildingPressureTransmitter>(`/SyrxControllers/${syrxControllerId}/services/Pathian.Services.BuildingPressureControlService/building-pressure-transmitters`, syrxControllerBuildingPressureTransmitter);
    return buildingPressureTransmitter;
};

export const updateBuildingPressureTransmitter = async (syrxControllerId: string, syrxControllerBuildingPressureTransmitterId: string, syrxControllerBuildingPressureTransmitter: Partial<SyrxControllerBuildingPressureTransmitter>) => {
    const {data: buildingPressureTransmitter} = await axios.put<SyrxControllerBuildingPressureTransmitter>(`/SyrxControllers/${syrxControllerId}/services/Pathian.Services.BuildingPressureControlService/building-pressure-transmitters/${syrxControllerBuildingPressureTransmitterId}`, syrxControllerBuildingPressureTransmitter);
    return buildingPressureTransmitter;
};

export const deleteBuildingPressureTransmitter = async (syrxControllerId: string, syrxControllerBuildingPressureTransmitterId: string) => {
    await axios.delete(`/SyrxControllers/${syrxControllerId}/services/Pathian.Services.BuildingPressureControlService/building-pressure-transmitters/${syrxControllerBuildingPressureTransmitterId}`);
};