import EasyTable from 'easy-table';
import {bacnetObjectTypeNames} from '../../bacnet/bacnetObjectTypeSelectField';
import {SyrxControllerBacnetDeviceUpdateModel} from '../../../models/syrxControllerBacnetDevice';
import {SyrxControllerBacnetPointUpdateModel} from '../../../models/syrxControllerBacnetPoint';

export function generateDevicesEasyTable(deviceUpdateModels: SyrxControllerBacnetDeviceUpdateModel[], ignoreStatus: boolean = false) {
    const t = new EasyTable();
    deviceUpdateModels.forEach(device => {
        t.cell('MAC address', `${device.mac_address} [${device.network}]`);
        t.cell('Object ID', `OBJECT_DEVICE:${device.instance_number}`);
        t.cell('Dest. address', device.destination_mac_address != null && device.destination_network != null ? `${device.destination_mac_address} [${device.destination_network}]` : "n/a");
        t.cell('Device name', device.name);
        t.cell('Vendor name', device.vendor_name);
        t.cell('Description', device.description);
        if (!ignoreStatus) {
            t.cell('Status', device.status)
        }
        t.newRow();
    });

    return t;
}

export function generatePointsEasyTable(pointsProperties: SyrxControllerBacnetPointUpdateModel[]) {
    const t = new EasyTable();
    pointsProperties.forEach(point => {
        t.cell('Object ID', `${bacnetObjectTypeNames.get(point.object_type)}:${point.instance_number}`);
        t.cell('Name', point.name);
        t.cell('Description', point.description);
        t.newRow();
    });

    return t;
}