import { Dispatch } from "redux";
import {
    getSyrxControllerById,
    getSyrxControllerEnabledServices,
    setSyrxControllerEnabledServices
} from '../../../../services/syrxControllersService';
import {syrxControllerEnabledServicesManagerActions} from './syrxControllerEnabledServicesManagerSlice';
import {SyrxControllerServiceTypesService} from '../../../../services/syrxControllerServiceTypesService';
import {toast} from 'react-toastify';

export interface SyrxControllerEnabledServiceModel {
    service_id: string;
    assigned_version: string | null;
}

export const syrxControllerEnabledServicesManagerActionCreators = {
    load: (syrxControllerId: string) => async (dispatch: Dispatch<any>) => {
        dispatch(syrxControllerEnabledServicesManagerActions.startLoading());

        try {
            const syrxController = await getSyrxControllerById(syrxControllerId);
            const syrxControllerServiceTypes = await SyrxControllerServiceTypesService.getServiceTypes();
            const syrxControllerEnabledServices = await getSyrxControllerEnabledServices(syrxControllerId);

            dispatch(syrxControllerEnabledServicesManagerActions.setSyrxController(syrxController));
            dispatch(syrxControllerEnabledServicesManagerActions.setSyrxControllerServiceTypes(syrxControllerServiceTypes));
            dispatch(syrxControllerEnabledServicesManagerActions.setSyrxControllerEnabledServices(syrxControllerEnabledServices));
        } finally {
            dispatch(syrxControllerEnabledServicesManagerActions.finishLoading());
        }
    },

    submit: (syrxControllerId: string, enabledServiceModels: SyrxControllerEnabledServiceModel[]) => async (dispatch: Dispatch<any>) => {
        dispatch(syrxControllerEnabledServicesManagerActions.startSubmitting());

        try {
            await setSyrxControllerEnabledServices(syrxControllerId, enabledServiceModels);
            dispatch(syrxControllerEnabledServicesManagerActions.finishSubmitting());
        } catch (e) {
            toast.error("An error occurred attempting to submit the enabled services list");
            dispatch(syrxControllerEnabledServicesManagerActions.cancelSubmitting());
            throw e;
        }
    }
}
