import {CaseReducer, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {buildSubStateSelector} from '../../store/utilities/useAppStateSelector';

const initialState = {
    syrxControllerGroupScope: 'current_group' as 'current_group' | 'all_groups'
};

export type AppStateSyrxControllersList = typeof initialState;

export const slice = createSlice<AppStateSyrxControllersList, {
    setGroupScope: CaseReducer<AppStateSyrxControllersList, PayloadAction<typeof initialState.syrxControllerGroupScope>>;
}>({
    name: "syrxControllersList",
    initialState,
    reducers: {
        setGroupScope(state, action) {
            state.syrxControllerGroupScope = action.payload;
        }
    }
});

export const useSyrxControllersListSelector = buildSubStateSelector(state => state.syrxControllersList);
export const syrxControllersListActions = slice.actions;
export const syrxControllersListReducer = slice.reducer;