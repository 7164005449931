import React, { useEffect, CSSProperties, useCallback } from 'react'
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { LoadingSpinner } from '../loadingSpinner/LoadingSpinner';
import dayjs from 'dayjs';
import { Form } from 'react-final-form'
import { FormGroup, Button, Table, Breadcrumb } from 'react-bootstrap';
import { Field } from 'react-final-form-html5-validation';
import BootstrapTable from 'react-bootstrap-table-next';

import { projectScheduleActionCreators } from '../../actionCreators/projectScheduleActionCreators';
import { useProjectScheduleSelector } from './projectScheduleSlice';
import { useAuthSessionSelector } from '../authSession/authSessionSlice';
import { ComponentScheduleTree } from './types';
import { SubcomponentModal } from './subcomponentModal';

const projectHeaderRow: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginBottom: '10px'
}

const buttonLeftMargin: CSSProperties = {
    marginLeft: '10px'
}

const titleRow: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginBottom: '10px'
}

const informationSectionRow: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
}

const informationSectionColumn: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
}

const twoColumnRow: CSSProperties = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
}

const twoColumnRowElement: CSSProperties = {
    flex: '0 49%',
    padding: '5px'
}

const buttonRow: CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px'
}

const subcomponentColumn: CSSProperties = {
    flex: '0 30%',
    padding: '5px'
}

const subcomponentTreeColumn: CSSProperties = {
    flex: '0 70%',
    padding: '5px'
}

export const ProjectSchedule: React.FunctionComponent = () => {
    const { isLoading, project, componentScheduleTree, equipmentEditor, selectedComponent, projectSchedule, selectedEquipment, selectedSubcomponents, pointEditor } = useProjectScheduleSelector(x => x);
    const currentGroup = useAuthSessionSelector(state => state.currentGroup);
    const dispatch = useDispatch();

    const params = useParams<{ id: string }>();
    const projectId = parseInt(params.id);

    useEffect(() => {
        async function fetchData() {
            dispatch(projectScheduleActionCreators.get(projectId));
            dispatch(projectScheduleActionCreators.getComponentScheduleTree());
            dispatch(projectScheduleActionCreators.getProjectSchedule(projectId));
        }

        fetchData();
    }, [dispatch, projectId]);

    const openEquipmentEditor = useCallback(async (componentId: string, equipmentId?: number) => {
        await closeEquipmentEditor();
        dispatch(projectScheduleActionCreators.openEquipmentEditor(componentId, equipmentId));
    }, [dispatch])

    const closeEquipmentEditor = useCallback(async () => {
        dispatch(projectScheduleActionCreators.closeEquipmentEditor());
    }, [dispatch]);

    const deleteEquipment = useCallback(async (equipmentId: number) => {
        dispatch(projectScheduleActionCreators.deleteEquipment(equipmentId));
    }, [dispatch]);

    const showModal = useCallback(async (componentId: string) => {
        dispatch(projectScheduleActionCreators.showSubcomponentModal(componentId));
    }, [dispatch]);

    const onSubmit = useCallback(async (values: any) => {
        const { install_date_ymd, demo_date_ymd, ...rest } = values;
        const equipment = {
            ...rest,
            install_date: dayjs(install_date_ymd).isValid() ? dayjs.utc(install_date_ymd).format() : null,
            demo_date: dayjs(demo_date_ymd).isValid() ? dayjs.utc(demo_date_ymd).format() : null,
            group_id: currentGroup.id,
            component_id: selectedComponent.id
        }
        if (selectedEquipment.id) {
            dispatch(projectScheduleActionCreators.updateEquipment(selectedEquipment.id, equipment));
        } else {
            const subComponents = Object.values(selectedSubcomponents).map((array: ComponentScheduleTree[]) => {
                return array[array.length - 1].id;
            })
            dispatch(projectScheduleActionCreators.createEquipment(equipment, Object.values(subComponents), projectId));
        }
    }, [dispatch, currentGroup, selectedComponent, projectId, selectedSubcomponents]);

    const onSubmitPointSelection = useCallback(async (values: {[key: string]: string}) => {
        dispatch(projectScheduleActionCreators.addExistReplaceEquipmentTasks(projectId, selectedEquipment.id, values));
    }, [dispatch, projectId, selectedEquipment]);

    const EquipmentSubcomponents: React.FunctionComponent<{ component: ComponentScheduleTree }> = ({ component }) => (
        <>
            {
                component.children!.map((child) => (
                    <React.Fragment key={child.id}>
                        <div style={subcomponentColumn}>
                            <Button variant="link" onClick={() => showModal(child.id)}>Select Type of {child.description}</Button>
                        </div>
                        <div style={subcomponentTreeColumn}>
                            <Breadcrumb>
                                {
                                    selectedSubcomponents[child.id]?.map(component => (
                                        <Breadcrumb.Item key={component.id} active={true}>{component.description}</Breadcrumb.Item>
                                    ))
                                }
                            </Breadcrumb>
                        </div>
                    </React.Fragment>
                ))
            }
        </>
    )

    return (
        <>
            {isLoading ? <LoadingSpinner /> : null}
            <SubcomponentModal />
            <div style={projectHeaderRow}>
                <div>
                    Name: {project.name}
                </div>
                <div>
                    Start Date: {project.start_date ? dayjs.utc(project.start_date).format('MM/DD/YYYY') : ''}
                </div>
                <div>
                    Completed Date: {project.complete_date ? dayjs.utc(project.complete_date).format('MM/DD/YYYY') : ''}
                </div>
                <div>
                    Estimated Cost: ${project.estimated_cost}
                </div>
            </div>
            <div style={titleRow}>
                <h3>Equipment Schedule</h3>
            </div>
            <div style={informationSectionRow}>
                <div style={informationSectionColumn}>
                    <div>
                        <h4>Schedule</h4>
                    </div>
                    {componentScheduleTree.map((componentScheduleTreeRoot) => (
                        <div key={componentScheduleTreeRoot.id}>
                            {componentScheduleTreeRoot.description}
                            <Button variant="outline-secondary" size="sm" style={buttonLeftMargin} onClick={() => openEquipmentEditor(componentScheduleTreeRoot.id)}>Add</Button>
                            {
                                projectSchedule.some(({ component_id }) => component_id === componentScheduleTreeRoot.id) &&
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Description</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            projectSchedule.filter((equipment) => equipment.component_id === componentScheduleTreeRoot.id).map(equipment => (
                                                <tr key={equipment.id}>
                                                    <th>{equipment.name}</th>
                                                    <th>{equipment.description}</th>
                                                    <th>
                                                        <Button variant="primary" size="sm" onClick={() => openEquipmentEditor(equipment.component_id, equipment.id)}>Edit</Button>
                                                        <Button variant="danger" size="sm" onClick={() => deleteEquipment(equipment.id)}>Delete</Button>
                                                    </th>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            }
                        </div>
                    ))}
                </div>
                <div style={informationSectionColumn}>
                    {
                        equipmentEditor &&
                        <>
                            <div>
                                <h4>Equipment Information</h4>
                            </div>
                            <Form
                                onSubmit={onSubmit}
                                initialValues={selectedEquipment}
                                keepDirtyOnReinitialize={true}
                            >
                                {({ handleSubmit, initialValues }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div style={twoColumnRow}>
                                            <FormGroup style={twoColumnRowElement}>
                                                <label>Name</label>
                                                <Field
                                                    name="name"
                                                    component="input"
                                                    type="text"
                                                    placeholder="Name"
                                                    className="form-control"
                                                    required
                                                />
                                            </FormGroup>
                                            <FormGroup style={twoColumnRowElement}>
                                                <label>Description</label>
                                                <Field
                                                    name="description"
                                                    component="input"
                                                    type="text"
                                                    placeholder="Description"
                                                    className="form-control"
                                                />
                                            </FormGroup>
                                            <FormGroup style={twoColumnRowElement}>
                                                <label>Department</label>
                                                <Field
                                                    name="department"
                                                    component="input"
                                                    type="text"
                                                    placeholder="Department"
                                                    className="form-control"
                                                />
                                            </FormGroup>
                                            <FormGroup style={twoColumnRowElement}>
                                                <label>Model</label>
                                                <Field
                                                    name="model"
                                                    component="input"
                                                    type="text"
                                                    placeholder="Model"
                                                    className="form-control"
                                                />
                                            </FormGroup>
                                            <FormGroup style={twoColumnRowElement}>
                                                <label>Manufacturer</label>
                                                <Field
                                                    name="manufacturer"
                                                    component="input"
                                                    type="text"
                                                    placeholder="Manufacturer"
                                                    className="form-control"
                                                />
                                            </FormGroup>
                                            <FormGroup style={twoColumnRowElement}>
                                                <label>Serial</label>
                                                <Field
                                                    name="serial"
                                                    component="input"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Serial"
                                                />
                                            </FormGroup>
                                            <FormGroup style={twoColumnRowElement}>
                                                <label>Install Date</label>
                                                <Field
                                                    name="install_date_ymd"
                                                    component="input"
                                                    type="date"
                                                    className="form-control"
                                                    initialValue={(initialValues.install_date) ? dayjs.utc(initialValues.install_date).format('YYYY-MM-DD') : ''}
                                                />
                                            </FormGroup>
                                            <FormGroup style={twoColumnRowElement}>
                                                <label>Demo Date</label>
                                                <Field
                                                    name="demo_date_ymd"
                                                    component="input"
                                                    type="date"
                                                    className="form-control"
                                                    initialValue={(initialValues.demo_date) ? dayjs.utc(initialValues.demo_date).format('YYYY-MM-DD') : ''}
                                                />
                                            </FormGroup>
                                            <FormGroup style={twoColumnRowElement}>
                                                <label>Location</label>
                                                <Field
                                                    name="location"
                                                    component="input"
                                                    type="text"
                                                    placeholder="Location"
                                                    className="form-control"
                                                />
                                            </FormGroup>
                                        </div>
                                        {
                                            !selectedEquipment.id &&
                                            <>
                                                <div>
                                                    <h4>Equipment Subcomponents</h4>
                                                </div>
                                                <div style={twoColumnRow}>
                                                    <EquipmentSubcomponents component={selectedComponent} />
                                                </div>
                                            </>
                                        }
                                        <div style={buttonRow}>
                                            <div>
                                                <Button variant="primary" type="submit">
                                                    Submit
                                                </Button>
                                            </div>
                                            <div>
                                                <Button variant="secondary" onClick={closeEquipmentEditor}>
                                                    Cancel
                                                </Button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Form>
                        </>
                    }
                    {
                        pointEditor &&
                        <>
                            <div>
                                <h4>Point Selection</h4>
                            </div>
                            <Form
                                onSubmit={onSubmitPointSelection}
                            >
                                {({ handleSubmit }) => (
                                    <form onSubmit={handleSubmit}>
                                        <BootstrapTable
                                            keyField='id'
                                            data={selectedEquipment.points.filter((point) => point.point_type !== 'NP')}
                                            columns={[
                                                {
                                                    dataField: 'point_code',
                                                    text: 'Name'
                                                },
                                                {
                                                    dataField: 'add_exist_replace',
                                                    text: 'Add, Exist, Replace',
                                                    isDummyField: true,
                                                    formatter: (_cellContent, row) => {
                                                        return (
                                                            <Field
                                                                name={row.component_point_id}
                                                                component="select"
                                                                placeholder="Location"
                                                                className="form-control"
                                                            >
                                                                <option value="n/a">N/A</option>
                                                                <option value="add">Add</option>
                                                                <option value="exist">Exist</option>
                                                                <option value="replace">Replace</option>
                                                            </Field>
                                                        )
                                                    }
                                                }
                                            ]}
                                            expandRow={{
                                                renderer: row => (
                                                    <div>
                                                        <p>{row.id}</p>
                                                    </div>
                                                ),
                                                showExpandColumn: true,
                                                expandByColumnOnly: true
                                            }}
                                        />
                                        <div style={buttonRow}>
                                            <div>
                                                <Button variant="primary" type="submit">
                                                    Submit
                                                </Button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Form>
                        </>
                    }
                </div>
            </div>
        </>
    );
}