import React, {useCallback, useEffect} from 'react'
import {SyrxControllerBuildingPressureControlServiceOatSensorTable} from './syrxControllerBuildingPressureControlServiceOatSensorTable';
import {useDispatch} from 'react-redux';
import {LoadingSpinner} from '../../../loadingSpinner/LoadingSpinner';
import {syrxControllerBuildingPressureControlServiceOatSensorEditorActionCreators} from './syrxControllerBuildingPressureControlServiceOatSensorEditorActionCreators';
import {Redirect} from 'react-router';
import {
    syrxControllerBuildingPressureControlServiceOatSensorEditorActions,
    useSyrxControllerBuildingPressureControlServiceOatSensorEditorSelector
} from './syrxControllerBuildingPressureControlServiceOatSensorEditorSlice';

export const SyrxControllerBuildingPressureControlServiceOatSensorEditor: React.FunctionComponent<{ syrxControllerId: string }> = ({syrxControllerId}) => {
    const dispatch = useDispatch();
    const isLoading = useSyrxControllerBuildingPressureControlServiceOatSensorEditorSelector(x => x.isLoading);
    const isDoneLoading = useSyrxControllerBuildingPressureControlServiceOatSensorEditorSelector(x => x.isDoneLoading);
    const isSubmitted = useSyrxControllerBuildingPressureControlServiceOatSensorEditorSelector(x => x.isSubmitted);
    const oatSensorSyrxControllerBacnetPointId = useSyrxControllerBuildingPressureControlServiceOatSensorEditorSelector(x => x.oatSensorSyrxControllerBacnetPointId);

    useEffect(() => {
        dispatch(syrxControllerBuildingPressureControlServiceOatSensorEditorActionCreators.loadConfiguration(syrxControllerId));
        return () => {
            dispatch(syrxControllerBuildingPressureControlServiceOatSensorEditorActions.resetState())
        };
    }, [dispatch, syrxControllerId]);

    const onSubmit = useCallback((oatSensorSyrxControllerBacnetPointId: string | null) => {
        dispatch(syrxControllerBuildingPressureControlServiceOatSensorEditorActionCreators.submit(syrxControllerId, oatSensorSyrxControllerBacnetPointId));
    }, [dispatch, syrxControllerId]);
    return (
        <>
            {isLoading ? <LoadingSpinner /> : null}
            {isDoneLoading ? (
                <>
                    <h4>Outside air temperature sensor</h4>
                    <SyrxControllerBuildingPressureControlServiceOatSensorTable
                        syrxControllerId={syrxControllerId}
                        oatSensorSyrxControllerBacnetPointId={oatSensorSyrxControllerBacnetPointId}
                        editMode={true}
                        onSubmit={onSubmit}
                        cancelLinkTo={`/admin/syrx-controllers/${syrxControllerId}/services/Pathian.Services.BuildingPressureControlService`}
                    />
                </>
            ) : null}
            {isSubmitted ? <Redirect to={`/admin/syrx-controllers/${syrxControllerId}/services/Pathian.Services.BuildingPressureControlService`} /> : null}
        </>
    )
};