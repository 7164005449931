import { Dispatch } from "redux";
import * as syrxControllersService from "../services/syrxControllersService";
import {syrxControllerEnabledServiceDetailsActions} from '../features/syrxControllers/enabledServices/syrxControllerEnabledServiceDetailsSlice';


export const loadSyrxControllerEnabledServiceDetails = (syrxControllerId: string, serviceId: string) => async (dispatch: Dispatch<any>) => {
    dispatch(syrxControllerEnabledServiceDetailsActions.startLoading());

    const syrxController = await syrxControllersService.getSyrxControllerById(syrxControllerId);
    const syrxControllerEnabledService = await syrxControllersService.getSyrxControllerEnabledServiceById(syrxControllerId, serviceId);

    dispatch(syrxControllerEnabledServiceDetailsActions.detailsLoad({syrxController, syrxControllerEnabledService}));
    dispatch(syrxControllerEnabledServiceDetailsActions.finishLoading());
};

export const unloadSyrxControllerEnabledServiceDetails = () => async (dispatch: Dispatch<any>) => {
    dispatch(syrxControllerEnabledServiceDetailsActions.detailsLoad({syrxController: null, syrxControllerEnabledService: null}));
};