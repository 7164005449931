import React from 'react'
import { Form } from 'react-final-form';
import { Field } from 'react-final-form-html5-validation';
import { Alert, Button, FormGroup, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { submitSyrxRegistrationActionCreator } from '../../actionCreators/syrxControllerRegistrationActionCreators';
import { legacyAppUrl } from '../../config';

import revPi from '../../assets/revPi.jpg';
import {useSyrxControllerRegistrationSelector} from './syrxControllerRegistrationSlice';


interface SyrxRegistrationFormValues {
    syrxControllerId: string;
    password: string;
}

export const SyrxControllerRegistration: React.FunctionComponent = () => {
    const syrxRegistrationState = useSyrxControllerRegistrationSelector(x => x);
    const dispatch = useDispatch();

    const onSubmit = async (values: SyrxRegistrationFormValues) => {
        dispatch(submitSyrxRegistrationActionCreator(values.syrxControllerId, values.password));
    }

    if (syrxRegistrationState.isSuccessful) {
        window.location.href = `${legacyAppUrl}/#/admin/syrx-controllers`; 
    }

    return (
        <Form
            onSubmit={onSubmit}
        >
            {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} style={{textAlign: "center"}}>
                    <h2>Register your Pathian Syrx Controller</h2>
                    <p>Please find the controller ID located on the side of the Syrx controller device. Reference the image below to assist in locating the ID.</p>
                    <img alt="Revolution Pi" src={revPi} width={320} height={320} />
                    {syrxRegistrationState.errorMessage !== '' ? (
                        <FormGroup>
                            <Col md={{span: 4, offset: 4}}>
                                <Alert variant="danger">Error with Syrx Controller ID or Password.</Alert>
                            </Col>
                        </FormGroup>
                    ) : null}
                    <FormGroup>
                        <Col md={{span: 4, offset: 4}}>
                            <Field
                                name="syrxControllerId"
                                component="input"
                                type="text"
                                placeholder="Controller ID"
                                className="form-control"
                                required
                                pattern="^[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89abAB][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}$"
                                patternMismatch="Controller ID entered is not a valid UUID"
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col md={{span: 4, offset: 4}}>
                            <Field
                                name="password"
                                component="input"
                                type="password"
                                placeholder="Password"
                                className="form-control"
                                required
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col md={{span: 4, offset: 4}}>
                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                        </Col>
                    </FormGroup>
                </form>
            )}
        </Form>
    );
}