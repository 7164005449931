import React from 'react'
import {Nav, Navbar, Button} from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { CurrentGroupSelectorModal } from '../groups/currentGroupSelectorModal';
import {authSessionActions, useAuthSessionSelector} from '../authSession/authSessionSlice';

export const CurrentGroupNavBar = () => {
    const currentGroup = useAuthSessionSelector(state => state.currentGroup);

    const dispatch = useDispatch(); 

    const handleOpen = () => {
        dispatch(authSessionActions.setShowCurrentGroupSelectionDialog(true));
    }

    return (
        <>
            <Navbar>
                <Nav>
                    <div style={{fontSize: "14px", lineHeight: "50px", color: "#ffffff", paddingLeft: "15px"}}>
                        <span><b>Current group:</b></span>
                        <span style={{marginLeft: "5px", marginRight: "5px"}}>
                            {currentGroup == null ? <span><b>(None)</b></span> : null}
                            {currentGroup != null ? <span><b>{currentGroup.name}</b></span> : null}
                        </span>
                        <Button variant="light" onClick={handleOpen}>Change</Button>
                    </div>
                </Nav>
            </Navbar>
            <CurrentGroupSelectorModal />
        </>
    );
};