import React, {ChangeEvent, useCallback} from 'react'
import SplitterLayout from 'react-splitter-layout';
import {useDispatch} from 'react-redux';
import {Redirect, Route, Router, Switch, useHistory, useRouteMatch} from 'react-router';
import {SyrxControllerManagerTree} from './syrxControllerManagerTree';
import {syrxControllersListActions, useSyrxControllersListSelector} from '../syrxControllersListSlice';
import {useAuthSessionSelector} from '../../authSession/authSessionSlice';
import {SyrxControllersDetail} from '../syrxControllersDetail';
import {SyrxControllerEnabledServiceDetails} from '../enabledServices/syrxControllerEnabledServiceDetails';
import {SyrxControllerBacnetDevicesGrid} from '../bacnetNetworkQueryService/syrxControllerBacnetDevicesGrid';
import {SyrxControllerBacnetDevicesManager} from '../bacnetNetworkQueryService/syrxControllerBacnetDevicesManager';
import {SyrxControllerBacnetDeviceDetails} from '../bacnetNetworkQueryService/syrxControllerBacnetDeviceDetails';
import {SyrxControllerBacnetPointsGrid} from '../bacnetNetworkQueryService/syrxControllerBacnetPointsGrid';
import {SyrxControllerBuildingPressureControlServiceBasicConfigurationEditor} from '../buildingPressureControlService/syrxControllerBuildingPressureControlServiceBasicConfigurationEditor';
import {SyrxControllerServiceLogsGrid} from '../logs/syrxControllerServiceLogsGrid';
import {LinkContainer} from 'react-router-bootstrap';
import Button from 'react-bootstrap/Button';
import {SyrxControllerBacnetDeviceEditor} from '../bacnetNetworkQueryService/syrxControllerBacnetDeviceEditor';
import {discoverDevicesActionCreator} from '../../../actionCreators/syrxControllerBacnetDevicesGridActionCreators';
import {SyrxControllerBuildingPressureControlServiceOatSensorEditor} from '../buildingPressureControlService/oatSensor/syrxControllerBuildingPressureControlServiceOatSensorEditor';
import {SyrxControllerBuildingPressureTransmitterEditor} from '../buildingPressureControlService/buildingPressureTransmitters/syrxControllerBuildingPressureTransmitterEditor';
import {SyrxControllerBuildingPressureControlServicePnormOutputEditor} from '../buildingPressureControlService/pnormOutput/syrxControllerBuildingPressureControlServicePnormOutputEditor';
import {SyrxControllerEnabledServicesManager} from '../enabledServices/enabledServicesManager/syrxControllerEnabledServicesManager';
import {SyrxControllerLogViewer} from '../logViewer/syrxControllerLogViewer';

export const SyrxControllerManager: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {path: matchedRoute} = useRouteMatch();

    const syrxControllerGroupScope = useSyrxControllersListSelector(state => state.syrxControllerGroupScope);
    const currentGroup = useAuthSessionSelector(state => state.currentGroup);


    const onSyrxControllerGroupScopeChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
        dispatch(syrxControllersListActions.setGroupScope(e.target.value as 'current_group' | 'all_groups'));
    }, [dispatch]);

    const onSyrxControllerSelected = useCallback((syrxControllerId: string) => {
        history.push(`/admin/syrx-controllers/${syrxControllerId}`);
    }, [history]);

    const onSyrxControllerLogsSelected = useCallback((syrxControllerId: string) => {
        history.push(`/admin/syrx-controllers/${syrxControllerId}/logs`);
    }, [history]);

    const onSyrxControllerServicesSelected = useCallback((syrxControllerId: string) => {
        history.push(`/admin/syrx-controllers/${syrxControllerId}/services`);
    }, [history]);

    const onSyrxControllerEnabledServiceSelected = useCallback((syrxControllerId: string, serviceId: string) => {
        history.push(`/admin/syrx-controllers/${syrxControllerId}/services/${serviceId}`);
    }, [history]);

    const onSyrxControllerEnabledServiceLogsSelected = useCallback((syrxControllerId: string, serviceId: string) => {
        history.push(`/admin/syrx-controllers/${syrxControllerId}/services/${serviceId}/logs`);
    }, [history]);

    const onSyrxControllerDevicesSelected = useCallback((syrxControllerId: string) => {
        history.push(`/admin/syrx-controllers/${syrxControllerId}/devices`);
    }, [history]);

    const onSyrxControllerDeviceSelected = useCallback((syrxControllerId: string, syrxControllerBacnetDeviceId: string) => {
        history.push(`/admin/syrx-controllers/${syrxControllerId}/devices/${syrxControllerBacnetDeviceId}`);
    }, [history]);

    const onSyrxControllerDevicePointsSelected = useCallback((syrxControllerId: string, syrxControllerBacnetDeviceId: string) => {
        history.push(`/admin/syrx-controllers/${syrxControllerId}/devices/${syrxControllerBacnetDeviceId}/points`);
    }, [history]);

    const onSyrxControllerPointsSelected = useCallback((syrxControllerId: string) => {
        history.push(`/admin/syrx-controllers/${syrxControllerId}/points`);
    }, [history]);

    const createDiscoverDevicesMessage = useCallback((syrxControllerId: string) => {
        dispatch(discoverDevicesActionCreator(syrxControllerId));
    }, [dispatch]);

    return (
        <>
            <div>
                <select className="form-control" style={{display: "inline-block", width: "unset"}} value={syrxControllerGroupScope} onChange={onSyrxControllerGroupScopeChange}>
                    <option value='current_group'>Show Syrx controllers from current group</option>
                    <option value='all_groups'>Show Syrx controllers from all groups</option>
                </select>
            </div>
            <div style={{position: "relative", height: "800px"}}>
                <SplitterLayout
                    percentage={true}
                    secondaryInitialSize={80}
                >
                    <div>
                        <SyrxControllerManagerTree
                            rootGroupId={syrxControllerGroupScope === 'current_group' ? currentGroup.id : null}
                            onSyrxControllerSelected={onSyrxControllerSelected}
                            onSyrxControllerLogsSelected={onSyrxControllerLogsSelected}
                            onSyrxControllerServicesSelected={onSyrxControllerServicesSelected}
                            onSyrxControllerEnabledServiceSelected={onSyrxControllerEnabledServiceSelected}
                            onSyrxControllerEnabledServiceLogsSelected={onSyrxControllerEnabledServiceLogsSelected}
                            onSyrxControllerDevicesSelected={onSyrxControllerDevicesSelected}
                            onSyrxControllerDeviceSelected={onSyrxControllerDeviceSelected}
                            onSyrxControllerPointsSelected={onSyrxControllerPointsSelected}
                            onSyrxControllerDevicePointsSelected={onSyrxControllerDevicePointsSelected}
                        />
                    </div>
                    <div>
                        <Router history={history}>
                            <Switch>
                                <Route exact path={`${matchedRoute}/:syrxControllerId`} render={
                                    ({match}) => <SyrxControllersDetail syrxControllerId={match.params.syrxControllerId} />
                                } />
                                <Route exact path={`${matchedRoute}/:syrxControllerId/logs`} render={
                                    ({match}) => <SyrxControllerLogViewer syrxControllerId={match.params.syrxControllerId} />
                                } />
                                <Route exact path={`${matchedRoute}/:syrxControllerId/services`} render={
                                    ({match}) => <SyrxControllerEnabledServicesManager syrxControllerId={match.params.syrxControllerId} />
                                } />
                                <Route exact path={`${matchedRoute}/:syrxControllerId/services/:serviceId`} render={
                                    ({match}) => <SyrxControllerEnabledServiceDetails syrxControllerId={match.params.syrxControllerId} serviceId={match.params.serviceId} />
                                } />
                                <Route exact path={`${matchedRoute}/:syrxControllerId/services/:serviceId/logs`} render={
                                    ({match}) => <SyrxControllerServiceLogsGrid syrxControllerId={match.params.syrxControllerId} serviceId={match.params.serviceId} />
                                } />
                                <Route exact path={`${matchedRoute}/:syrxControllerId/services/Pathian.Services.BuildingPressureControlService/configuration/edit`} render={
                                    ({match}) => <SyrxControllerBuildingPressureControlServiceBasicConfigurationEditor syrxControllerId={match.params.syrxControllerId} />
                                } />
                                <Route exact path={`${matchedRoute}/:syrxControllerId/services/Pathian.Services.BuildingPressureControlService/oat-sensor/edit`} render={
                                    ({match}) => <SyrxControllerBuildingPressureControlServiceOatSensorEditor syrxControllerId={match.params.syrxControllerId} />
                                } />
                                <Route exact path={`${matchedRoute}/:syrxControllerId/services/Pathian.Services.BuildingPressureControlService/pnorm-output/edit`} render={
                                    ({match}) => <SyrxControllerBuildingPressureControlServicePnormOutputEditor syrxControllerId={match.params.syrxControllerId} />
                                } />
                                <Route exact path={`${matchedRoute}/:syrxControllerId/services/Pathian.Services.BuildingPressureControlService/building-pressure-transmitters/new`} render={
                                    ({match}) => <SyrxControllerBuildingPressureTransmitterEditor syrxControllerId={match.params.syrxControllerId} syrxControllerBuildingPressureTransmitterId={null} />
                                } />
                                <Route exact path={`${matchedRoute}/:syrxControllerId/services/Pathian.Services.BuildingPressureControlService/building-pressure-transmitters/:syrxControllerBuildingPressureTransmitterId/edit`} render={
                                    ({match}) => <SyrxControllerBuildingPressureTransmitterEditor syrxControllerId={match.params.syrxControllerId} syrxControllerBuildingPressureTransmitterId={match.params.syrxControllerBuildingPressureTransmitterId} />
                                } />
                                <Route exact path={`${matchedRoute}/:syrxControllerId/devices-old`} render={
                                    ({match}) => (
                                        <>
                                            <h4>Devices</h4>
                                            <SyrxControllerBacnetDevicesGrid syrxControllerId={match.params.syrxControllerId} />
                                            <LinkContainer to={`${matchedRoute}/${match.params.syrxControllerId}/devices/add`}>
                                                <Button variant="outline-info">Manually add device</Button>
                                            </LinkContainer>
                                            <Button variant="outline-info" onClick={() => createDiscoverDevicesMessage(match.params.syrxControllerId)}>Discover devices</Button>
                                        </>
                                    )
                                } />
                                <Route exact path={`${matchedRoute}/:syrxControllerId/devices`} render={
                                    ({match}) => <SyrxControllerBacnetDevicesManager syrxControllerId={match.params.syrxControllerId} />
                                } />
                                <Route exact path={`${matchedRoute}/:syrxControllerId/devices/add`} render={
                                    ({match}) => <SyrxControllerBacnetDeviceEditor
                                        syrxControllerId={match.params.syrxControllerId}
                                        redirectRoute={`${matchedRoute}/${match.params.syrxControllerId}/devices`}
                                    />
                                } />
                                <Route exact path={`${matchedRoute}/:syrxControllerId/devices/:syrxControllerBacnetDeviceId`} render={
                                    ({match}) => <SyrxControllerBacnetDeviceDetails syrxControllerId={match.params.syrxControllerId} syrxControllerBacnetDeviceId={match.params.syrxControllerBacnetDeviceId} />
                                } />
                                <Route exact path={`${matchedRoute}/:syrxControllerId/devices/:syrxControllerBacnetDeviceId/points`} render={
                                    ({match}) => (
                                        <>
                                            <h4>Points</h4>
                                            <SyrxControllerBacnetPointsGrid syrxControllerId={match.params.syrxControllerId} syrxControllerBacnetDeviceId={match.params.syrxControllerBacnetDeviceId} />
                                        </>
                                    )
                                } />
                                <Route exact path={`${matchedRoute}/:syrxControllerId/points`} render={
                                    ({match}) => (
                                        <>
                                            <h4>Points</h4>
                                            <SyrxControllerBacnetPointsGrid syrxControllerId={match.params.syrxControllerId} />
                                        </>
                                    )
                                } />
                                <Route path={`${matchedRoute}/:syrxControllerId`} render={
                                    ({match}) => (
                                        <Redirect to={`${matchedRoute}/${match.params.syrxControllerId}`} />
                                    )
                                }>
                                </Route>
                                <Redirect to={matchedRoute} />
                            </Switch>
                        </Router>
                    </div>
                </SplitterLayout>
            </div>
        </>
    )
}