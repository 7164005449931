import React, {useCallback, useEffect, useRef} from 'react';
import {Terminal as XTerm} from "xterm";
import { FitAddon } from "xterm-addon-fit";
import {EventEmitter} from 'events';
import ReactResizeDetector from 'react-resize-detector';

export interface TerminalProps {
    writeEmitter?: EventEmitter,
    onData?: (data: string) => any;
    onKey?: (arg1: {key: string, domEvent: KeyboardEvent}) => any;
    onResize?: (dimensions: {cols: number, rows: number}) => any;
}

export const Terminal: React.FunctionComponent<TerminalProps> = props => {
    const terminalElement = useRef(null as null | HTMLDivElement);
    const termRef = useRef(null as null | XTerm);
    const fitRef = useRef(null as null | FitAddon);

    const {onResize, writeEmitter, onData, onKey} = props;

    useEffect(() => {
        const {current: element} = terminalElement;
        if (element == null) {
            return;
        }

        const term = new XTerm();
        termRef.current = term;

        const fit = new FitAddon();
        fitRef.current = fit;

        term.loadAddon(fit);

        term.open(element);
        term.onResize(({cols, rows}) => {
            if (onResize != null) {
                onResize({cols, rows});
            }
        })

        let writeEmitterCancellation: (() => void) | null = null;

        if (writeEmitter != null) {
            const writeEmitterOnData = (data: string) => void term.write(data);

            writeEmitter.on("data", writeEmitterOnData);

            writeEmitterCancellation = () => writeEmitter?.off("data", writeEmitterOnData);
        }

        const onDataDisposable = onData != null ? term.onData(onData) : null;
        const onKeyDisposable = onKey != null ? term.onKey(onKey) : null;

        return () => {
            if (onDataDisposable != null) {
                onDataDisposable.dispose();
            }

            if (onKeyDisposable != null) {
                onKeyDisposable.dispose();
            }

            if (writeEmitterCancellation != null) {
                writeEmitterCancellation();
            }
        }
    }, [onData, onKey, writeEmitter, onResize]);

    const onResizeInternal = useCallback(() => {
        if (fitRef.current == null) {
            return;
        }

        fitRef.current.fit();
    }, []);

    return (
        <>
            <div ref={terminalElement} />
            <ReactResizeDetector handleWidth onResize={onResizeInternal} />
        </>
    );
}