import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';
import './styles/app.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {ConnectedRouter} from "connected-react-router";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import DndBackend from 'react-dnd-html5-backend';
import {ToastContainer} from 'react-toastify';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import {stripeApiKey} from './config';

import {store} from './store';
import {history} from './store/history';

import {App} from './app/App';

import "xterm/css/xterm.css"
import 'react-splitter-layout/lib/index.css';
import 'react-toastify/dist/ReactToastify.css';
import {DndProvider} from 'react-dnd';

dayjs.extend(utc);

const stripePromise = loadStripe(stripeApiKey);

export const AppContainer = () => {
    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <DndProvider backend={DndBackend}>
                    <Elements stripe={stripePromise}>
                        <ToastContainer />
                        <App />
                    </Elements>
                </DndProvider>
            </ConnectedRouter>
        </Provider>
    );
};

ReactDOM.render(
    <AppContainer/>,
    document.getElementById('react-root')
);

