import {
    getSyrxControllerBacnetDevicesCount,
    getSyrxControllerBacnetDevicesForGrid, getSyrxControllerBacnetPointsByDeviceCount,
    getSyrxControllerBacnetPointsByDeviceForGrid, getSyrxControllersCount,
    getSyrxControllersForGrid
} from '../../../services/syrxControllersService';
import {SyrxController} from '../../../models/syrxController';
import {SyrxControllerBacnetDevice} from '../../../models/syrxControllerBacnetDevice';
import {SyrxControllerBacnetPoint} from '../../../models/syrxControllerBacnetPoint';

const pageSize = 20;

const generateSubdividedTreeElementChildren = (treeElementIdPrefix: string, count: number) => {
    return Array.from(Array(Math.ceil(count / pageSize))).map((_, i) => {
        const zeroBasedFirstItemIndex = i * pageSize;
        const oneBasedFirstItemIndex = zeroBasedFirstItemIndex + 1;
        const oneBasedLastItemIndex = Math.min(count, oneBasedFirstItemIndex + pageSize - 1);
        return {
            id: `${treeElementIdPrefix}/${zeroBasedFirstItemIndex}`,
            name: `[${oneBasedFirstItemIndex} - ${oneBasedLastItemIndex}]`
        }
    });
}

export const getSyrxControllerContainerTreeElements = async (treeElementIdPrefix: string) => {
    const count = await getSyrxControllersCount(null);
    if (count > pageSize) {
        return generateSubdividedTreeElementChildren(`${treeElementIdPrefix}/pages`, count);
    }

    return await getSyrxControllerTreeElements(`${treeElementIdPrefix}/entries`, 0);
}

export const getSyrxControllerTreeElements = async (treeElementIdPrefix: string, syrxControllerStartIndex: number) => {
    const {data: syrxControllers} = await getSyrxControllersForGrid(null, syrxControllerStartIndex / pageSize, pageSize, [{fieldName: "group_name", dir: "asc"}, {fieldName: "name", dir: "asc"}]);
    return getSyrxControllerTreeElementsFromSyrxControllers(treeElementIdPrefix, syrxControllers);
}

export const getSyrxControllerTreeElementsFromSyrxControllers = (treeElementIdPrefix: string, syrxControllers: SyrxController[]) => {
    return syrxControllers.map(syrxController => ({
        id: `${treeElementIdPrefix}/${syrxController.id}`,
        name: `${syrxController.group_name} - ${syrxController.name}`
    }));
}

export const getSyrxControllerBacnetDeviceContainerTreeElements = async (treeElementIdPrefix: string, syrxControllerId: string) => {
    const count = await getSyrxControllerBacnetDevicesCount(syrxControllerId, false);
    if (count > pageSize) {
        return generateSubdividedTreeElementChildren(`${treeElementIdPrefix}/pages`, count);
    }

    return await getSyrxControllerBacnetDeviceTreeElements(`${treeElementIdPrefix}/entries`, syrxControllerId, 0);
}

export const getSyrxControllerBacnetDeviceTreeElements = async (treeElementIdPrefix: string, syrxControllerId: string, syrxControllerBacnetDeviceStartIndex: number) => {
    const {data: syrxControllerBacnetDevices} = await getSyrxControllerBacnetDevicesForGrid(syrxControllerId, false, syrxControllerBacnetDeviceStartIndex / pageSize, pageSize, [{fieldName: "name", dir: "asc"}]);
    return getSyrxControllerBacnetDeviceTreeElementsFromSyrxControllerBacnetDevices(treeElementIdPrefix, syrxControllerBacnetDevices);
}

export const getSyrxControllerBacnetDeviceTreeElementsFromSyrxControllerBacnetDevices = (treeElementIdPrefix: string, syrxControllerBacnetDevices: SyrxControllerBacnetDevice[]) => {
    return syrxControllerBacnetDevices.map(syrxControllerBacnetDevice => ({
        id: `${treeElementIdPrefix}/${syrxControllerBacnetDevice.id}`,
        name: syrxControllerBacnetDevice.name ?? ""
    }));
}

export const getSyrxControllerBacnetPointContainerTreeElements = async (treeElementIdPrefix: string, syrxControllerId: string, syrxControllerBacnetDeviceId: string) => {
    const count = await getSyrxControllerBacnetPointsByDeviceCount(syrxControllerId, syrxControllerBacnetDeviceId, false);
    if (count > pageSize) {
        return generateSubdividedTreeElementChildren(`${treeElementIdPrefix}/pages`, count);
    }

    return await getSyrxControllerBacnetPointTreeElements(`${treeElementIdPrefix}/entries`, syrxControllerId, syrxControllerBacnetDeviceId, 0);
}

export const getSyrxControllerBacnetPointTreeElements = async (treeElementIdPrefix: string, syrxControllerId: string, syrxControllerBacnetDeviceId: string, syrxControllerBacnetPointStartIndex: number) => {
    const {data: syrxControllerBacnetPoints} = await getSyrxControllerBacnetPointsByDeviceForGrid(syrxControllerId, syrxControllerBacnetDeviceId, false, syrxControllerBacnetPointStartIndex / pageSize, pageSize, [{fieldName: "name", dir: "asc"}]);
    return getSyrxControllerBacnetPointTreeElementsFromSyrxControllerBacnetPoints(treeElementIdPrefix, syrxControllerBacnetPoints);
}

export const getSyrxControllerBacnetPointTreeElementsFromSyrxControllerBacnetPoints = (treeElementIdPrefix: string, syrxControllerBacnetPoints: SyrxControllerBacnetPoint[]) => {
    return syrxControllerBacnetPoints.map(syrxControllerBacnetPoint => ({
        id: `${treeElementIdPrefix}/${syrxControllerBacnetPoint.id}`,
        name: syrxControllerBacnetPoint.name ?? "",
        hideExpandArrow: true,
        syrxControllerBacnetPointId: syrxControllerBacnetPoint.id,
        pointName: `syrx|${syrxControllerBacnetPoint.syrx_controller_name}|${syrxControllerBacnetPoint.syrx_controller_bacnet_device_name}|${syrxControllerBacnetPoint.name}`
    }));
}

export const getSyrxControllerTreeElementChildren = async (treeElementIdPrefix: string, treeElementIdWithPrefix: string) => {
    if (treeElementIdWithPrefix.indexOf(treeElementIdPrefix) !== 0) {
        throw new Error("treeElementId must begin with treeElementIdPrefix");
    }

    const treeElementIdWithLeadingSlash = treeElementIdWithPrefix.substr(treeElementIdPrefix.length);
    const treeElementId = treeElementIdWithLeadingSlash.substr(0, 1) === "/" ? treeElementIdWithLeadingSlash.substr(1) : treeElementIdWithLeadingSlash;
    const treeElementIdSplit = treeElementId === "" ? [] : treeElementId.split("/");

    // ``

    if (treeElementIdSplit.length === 0) {
        return await getSyrxControllerContainerTreeElements(treeElementIdPrefix);
    }

    const syrxControllerNodeType = treeElementIdSplit[0];

    if (syrxControllerNodeType === "pages") {
        // `pages/${syrxControllerStartIndex}`

        const syrxControllerStartIndex = parseInt(treeElementIdSplit[1]);
        return await getSyrxControllerTreeElements(`${treeElementIdPrefix}/entries`, syrxControllerStartIndex);
    }

    if (syrxControllerNodeType !== "entries") {
        return [];
    }


    // `entries/${syrxControllerId}`

    const syrxControllerId = treeElementIdSplit[1];

    if (treeElementIdSplit.length === 2) {
        return await getSyrxControllerBacnetDeviceContainerTreeElements(`${treeElementIdPrefix}/entries/${syrxControllerId}`, syrxControllerId);
    }

    const syrxControllerBacnetDeviceNodeType = treeElementIdSplit[2];

    if (syrxControllerBacnetDeviceNodeType === "pages") {
        // `entries/${syrxControllerId}/pages/${syrxControllerBacnetDeviceStartIndex}`

        const syrxControllerBacnetDeviceStartIndex = parseInt(treeElementIdSplit[3]);
        return await getSyrxControllerBacnetDeviceTreeElements(`${treeElementIdPrefix}/entries/${syrxControllerId}/entries`, syrxControllerId, syrxControllerBacnetDeviceStartIndex);
    }

    if (syrxControllerNodeType !== "entries") {
        return [];
    }


    // `entries/${syrxControllerId}/entries/${syrxControllerBacnetDeviceId}`

    const syrxControllerBacnetDeviceId = treeElementIdSplit[3];

    if (treeElementIdSplit.length === 4) {
        return await getSyrxControllerBacnetPointContainerTreeElements(`${treeElementIdPrefix}/entries/${syrxControllerId}/entries/${syrxControllerBacnetDeviceId}`, syrxControllerId, syrxControllerBacnetDeviceId);
    }

    const syrxControllerBacnetPointNodeType = treeElementIdSplit[4];

    if (syrxControllerBacnetPointNodeType === "pages") {
        // `entries/${syrxControllerId}/entries/${syrxControllerBacnetDeviceId}/pages/${syrxControllerBacnetPointStartIndex}`

        const syrxControllerBacnetPointStartIndex = parseInt(treeElementIdSplit[5]);
        return await getSyrxControllerBacnetPointTreeElements(`${treeElementIdPrefix}/entries/${syrxControllerId}/entries/${syrxControllerBacnetDeviceId}/entries`, syrxControllerId, syrxControllerBacnetDeviceId, syrxControllerBacnetPointStartIndex);
    }

    if (syrxControllerNodeType !== "entries") {
        return [];
    }

    return [];
}