import {CaseReducer, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {buildSubStateSelector} from '../../../store/utilities/useAppStateSelector';

const initialState = {
    showDateColumns: true,
    showUnavailablePoints: true,
    isUpdating: false,
    refreshTrigger: null as any
};

export type AppStateSyrxControllerReportingPointsGrid = typeof initialState;

export const slice = createSlice<AppStateSyrxControllerReportingPointsGrid, {
    setShowDateColumns: CaseReducer<AppStateSyrxControllerReportingPointsGrid, PayloadAction<boolean>>;
    setShowUnavailablePoints: CaseReducer<AppStateSyrxControllerReportingPointsGrid, PayloadAction<boolean>>;
    setIsUpdating: CaseReducer<AppStateSyrxControllerReportingPointsGrid, PayloadAction<boolean>>;
    triggerRefresh: CaseReducer<AppStateSyrxControllerReportingPointsGrid>;
}>({
    name: "syrxControllerReportingPointsGrid",
    initialState,
    reducers: {
        setShowDateColumns(state, {payload: showDateColumns}) {
            state.showDateColumns = showDateColumns;
        },
        setShowUnavailablePoints(state, {payload: showUnavailablePoints}) {
            state.showUnavailablePoints = showUnavailablePoints;
        },
        setIsUpdating(state, {payload: isUpdating}) {
            state.isUpdating = isUpdating;
        },
        triggerRefresh(state) {
            state.refreshTrigger = {};
        }
    }
});

export const useSyrxControllerReportingPointsGridSelector = buildSubStateSelector(state => state.syrxControllerReportingPointsGrid);
export const syrxControllerReportingPointsGridActions = slice.actions;
export const syrxControllerReportingPointsGridReducer = slice.reducer;