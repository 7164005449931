import React, { CSSProperties, useEffect} from 'react';
import { Table, Button } from 'react-bootstrap';
import { Form, Field } from 'react-final-form';

import { Product } from './types';

const table: CSSProperties = {
    tableLayout: 'fixed'
}

const itemDescriptionRow: CSSProperties = {
    width: '40%'
}

const buttonRow: CSSProperties = {
    display: 'flex',
    justifyContent: 'flex-end'
}

const button: CSSProperties = {
    background: '#FFFFFF',
    border: '1px solid #DDDDDD',
    color: '#373A3C'
}

const totalRow: CSSProperties = {
    fontWeight: 'bold',
    textAlign: 'center'
}

export interface purchasingProductsTableProps {
    products: {
        id: string;
        name: string;
        price: number;
    }[];
    initialValues: Product;
    onSubmit: (product: Product) => any;
    showPreviousPageButton: boolean;
    showNextPageButton: boolean;
    onChangePage: (direction: number) => any;
}


export const FormInternals: React.FunctionComponent<{handleSubmit: any, values: any, products: Product[]}> = ({handleSubmit, values, products}) => {
    useEffect(() => {
        return () => {
            handleSubmit();
        }
    }, []);

    return (
        <form onSubmit={handleSubmit}>
            <Table bordered style={table}>
                <thead>
                    <tr>
                        <td style={itemDescriptionRow}>
                            Item Description
                        </td>
                        <td>
                            Quantity
                        </td>
                        <td>
                            Price per item
                        </td>
                        <td>
                            Total
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {products.map(product => {
                        return (
                            <tr key={product.id}>
                                <td style={itemDescriptionRow}>
                                    {product.name}
                                </td>
                                <td>
                                    <Field
                                        name={product.id}
                                        component="input"
                                        type="number"
                                        placeholder="Quantity"
                                        className="form-control"
                                        min={0}
                                        required
                                    />
                                </td>
                                <td>
                                    ${product.price}
                                </td>
                                <td>
                                    ${values[product.id] ? (values[product.id] * product.price).toFixed(2) : (0).toFixed(2)}
                                </td>
                            </tr>
                        )
                    })}
                    <tr>
                        <td style={totalRow}>
                            Total
                        </td>
                        <td></td>
                        <td></td>
                        <td>
                            ${(Object.keys(values).reduce((accumulator: number, key: string) => {
                            return accumulator + values[key] * (products.find(x => x.id === key)?.price ?? 0)
                        }, 0)).toFixed(2)}
                        </td>
                    </tr>
                </tbody>
            </Table>
        </form>
    );
}

export const PurchasingProductsTable: React.FunctionComponent<purchasingProductsTableProps> = props => {
    return (
        <div>
            <Form
                onSubmit={props.onSubmit}
                initialValues={props.initialValues}
            >
                {({ handleSubmit, values }) => (
                    <FormInternals handleSubmit={handleSubmit} values={values} products={props.products} />
                )}
            </Form>
            <div style={buttonRow}>
                {props.showPreviousPageButton && <Button style={button} onClick={() => props.onChangePage(-1)}><i className="fas fa-arrow-left"></i> Previous Page</Button>}
                {props.showNextPageButton && <Button style={button} onClick={() => props.onChangePage(1)}>Next Page <i className="fas fa-arrow-right"></i></Button>}
            </div>
        </div>
    )
}