import React, { FunctionComponent } from 'react'
import FancyGrid, { SortCollection } from '@sht-dev/fancy-grid';
import { Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import { getSyrxControllersForGrid } from '../../services/syrxControllersService';

export interface SyrxControllersGridProps {
    rootGroupId: number | null;
}

export const SyrxControllersGrid: FunctionComponent<SyrxControllersGridProps> = props => {
    const dataRetrievalFunction = (pageNumber: number, pageSize: number, sort: SortCollection) => getSyrxControllersForGrid(props.rootGroupId, pageNumber, pageSize, sort);
    return (
        <FancyGrid.ReduxGrid 
            gridName={"SyrxControllersGrid"} 
            dataRetrievalFunction={dataRetrievalFunction} 
            updateTriggers={[props.rootGroupId]}
        >
            <FancyGrid.ColumnList>
                <FancyGrid.Column
                    name="id"
                    title="Syrx Controller ID"
                    tdProps={{style: {flex: "0 0 22%"}}}
                />
                <FancyGrid.Column
                    name="group_name"
                    title="Group"
                    tdProps={{style: {flex: "0 0 10%"}}}
                >
                </FancyGrid.Column>
                <FancyGrid.Column
                    name="name"
                    title="Name"
                    tdProps={{style: {flex: "0 0 20%"}}}
                />
                <FancyGrid.Column
                    name="description"
                    title="Description"
                    tdProps={{style: {flex: "0 0 10%"}}}
                >
                </FancyGrid.Column>
                <FancyGrid.Column 
                    tdProps={{style: {flex: "0 0 14%"}}}
                    sortable={false}
                    filterable={false}
                >
                    <FancyGrid.CellRenderer>
                        {(_, syrxController) => <LinkContainer to={`/admin/syrx-controllers/${syrxController.id}`}><Button variant="outline-secondary">Manage</Button></LinkContainer>}
                    </FancyGrid.CellRenderer>
                </FancyGrid.Column>
            </FancyGrid.ColumnList>
            <FancyGrid.ReduxSortable />
            <FancyGrid.ReduxPager />
        </FancyGrid.ReduxGrid>
    )
}