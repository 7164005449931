import React, { useState } from 'react'
import {Button, Modal} from "react-bootstrap";
import { useDispatch } from 'react-redux';

import { submitCurrentGroupSelectionDialog } from '../../actionCreators/authActionCreators';
import { GroupSelectionTree } from './groupSelectionTree';
import {authSessionActions, useAuthSessionSelector} from '../authSession/authSessionSlice';

export const CurrentGroupSelectorModal = () => {
    const dispatch = useDispatch();
    
    const showDialog = useAuthSessionSelector(state => state.showCurrentGroupSelectionDialog);
    const currentGroup = useAuthSessionSelector(state => state.currentGroup);
    const [selectedGroupId, setSelectedGroupId] = useState((currentGroup?.id.toString() || undefined) as string | undefined);

    const onSelect = (groupId: string) => {
        setSelectedGroupId(groupId);
    }

    const handleSubmit = () => {
        if (selectedGroupId == null) {
            return;
        }
        dispatch(submitCurrentGroupSelectionDialog(parseInt(selectedGroupId)));
    }

    const handleClose = () => {
        dispatch(authSessionActions.setShowCurrentGroupSelectionDialog(false));
    }

    return (
        <Modal show={showDialog} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Change commissioning group</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <GroupSelectionTree
                    onSelect={onSelect}
                    selectedGroupId={selectedGroupId}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};