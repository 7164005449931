import React from 'react'
import {
    useSyrxControllerDiagnosticServiceDetailsSelector
} from './syrxControllerDiagnosticServiceDetailsSlice';

export const SyrxControllerDiagnosticServiceRabbitmqMetricsChannels: React.FunctionComponent = () => {
    const channels = useSyrxControllerDiagnosticServiceDetailsSelector(state => state.latestRabbitmqMetricsCollectionRecord?.channels);

    return (
        <>
            <table className="table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Connection</th>
                        <th>Consumers</th>
                        <th>Prefetch</th>
                        <th>Unack</th>
                        <th>First seen</th>
                        <th>Last updated</th>
                    </tr>
                </thead>
                <tbody>
                    {channels?.map(x => (
                        <tr key={x.name}>
                            <td>{x.name}</td>
                            <td>{x.connectionName}</td>
                            <td>{x.numConsumers}</td>
                            <td>{x.prefetchCount}</td>
                            <td>{x.numMessagesUnacknowledged}</td>
                            <td>{x.firstSeenTimestamp}</td>
                            <td>{x["@timestamp"]}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
};