import {VendorPointsService} from '../../../../services/vendorPointsService';
import {
    getSyrxControllerTreeElementChildren
} from '../../../syrxControllers/bacnetPoints/treeBuildingUtilityFunctions';

const pageSize = 20;

export const isTreeElementAPoint = (treeElement: any) => {
    return treeElement.vendorPointId != null || treeElement.pcsPointId != null || treeElement.syrxControllerBacnetPointId != null;
}

const generateSubdividedTreeElementChildren = (treeElementIdPrefix: string, count: number) => {
    return Array.from(Array(Math.ceil(count / pageSize))).map((_, i) => {
        const zeroBasedFirstItemIndex = i * pageSize;
        const oneBasedFirstItemIndex = zeroBasedFirstItemIndex + 1;
        const oneBasedLastItemIndex = Math.min(count, oneBasedFirstItemIndex + pageSize - 1);
        return {
            id: `${treeElementIdPrefix}/${zeroBasedFirstItemIndex}`,
            name: `[${oneBasedFirstItemIndex} - ${oneBasedLastItemIndex}]`
        }
    });
}

const getVendorPointVendorsTreeElements = async () => {
    const vendors = await VendorPointsService.getVendors();

    return vendors.map(vendor => ({
        id: `vendor-points/${vendor}`,
        name: vendor
    }));
}

const getVendorPointIdField1TreeElements = async (vendor: string, idField1StartIndex: number) => {
    const idField1s = await VendorPointsService.getIdField1s(vendor, idField1StartIndex, pageSize);
    return idField1s.map(idField1 => ({
        id: `vendor-points/${vendor}/entries/${idField1}`,
        name: idField1
    }));
}

const getVendorPointIdField2TreeElements = async (vendor: string, idField1: string, idField2StartIndex: number) => {
    const idField2s = await VendorPointsService.getIdField2s(vendor, idField1, idField2StartIndex, pageSize);
    return idField2s.map(idField2 => ({
        id: `vendor-points/${vendor}/entries/${idField1}/entries/${idField2}`,
        name: idField2,
        hideExpandArrow: !["invensys", "UC"].includes(vendor),
        ...(!["invensys", "UC"].includes(vendor) ? {
            vendorPointId: `${vendor}|${idField1}|${idField2}`,
            pointName: `${vendor}|${idField1}|${idField2}`
        } : {})
    }));
}

const getVendorPointIdField3TreeElements = async (vendor: string, idField1: string, idField2: string, idField3StartIndex: number) => {
    const idField3s = await VendorPointsService.getIdField3s(vendor, idField1, idField2, idField3StartIndex, pageSize);
    return idField3s.map(idField3 => ({
        id: `vendor-points/${vendor}/entries/${idField1}/entries/${idField2}/entries/${idField3}`,
        name: idField3,
        hideExpandArrow: true,
        vendorPointId: `${vendor}|${idField1}|${idField2}|${idField3}`,
        pointName: `${vendor}|${idField1}|${idField2}|${idField3}`
    }));
}

const getPcsPointPcsSiteTreeElements = async (pcsSiteStartIndex: number) => {
    const {data: pcsSites} = await VendorPointsService.getPcsSites(pcsSiteStartIndex, pageSize);
    return pcsSites.map(pcsSite => ({
        id: `pcs-points/entries/${pcsSite.id}`,
        name: pcsSite.site_name
    }));
}

const getPcsPointPcsDeviceTreeElements = async (pcsSiteId: string, pcsDeviceStartIndex: number) => {
    const {data: pcsDevices} = await VendorPointsService.getPcsDevicesForPcsSite(pcsSiteId, pcsDeviceStartIndex, pageSize);
    return pcsDevices.map(pcsDevice => ({
        id: `pcs-points/entries/${pcsSiteId}/entries/${pcsDevice.id}`,
        name: pcsDevice.name
    }));
}

const getPcsPointPcsPointTreeElements = async (pcsSiteId: string, pcsDeviceId: string, pcsPointStartIndex: number) => {
    const {data: pcsPoints} = await VendorPointsService.getPcsPointsForPcsDevice(pcsDeviceId, pcsPointStartIndex, pageSize);
    return pcsPoints.map(pcsPoint => ({
        id: `pcs-points/entries/${pcsSiteId}/entries/${pcsDeviceId}/entries/${pcsPoint.id}`,
        name: pcsPoint.name,
        hideExpandArrow: true,
        pcsPointId: pcsPoint.id,
        pointName: "?"
    }));
}

export const getTreeItems = async (parentItemId: string | null) => {
    if (parentItemId == null) {
        return [
            {
                id: "vendor-points",
                name: "Vendor points"
            },
            {
                id: "pcs-points",
                name: "PCS points"
            },
            {
                id: "syrx-controller-points",
                name: "Syrx controller points"
            }
        ]
    }

    const parentItemIdSplit = parentItemId.split("/");

    if (parentItemIdSplit.length >= 1 && parentItemIdSplit[0] === "vendor-points") {
        // `vendor-points`

        if (parentItemIdSplit.length === 1) {
            return await getVendorPointVendorsTreeElements();
        }


        // `vendor-points/${vendor}`

        const vendor = parentItemIdSplit[1] as "johnson" | "fieldserver" | "invensys" | "siemens" | "unified" | "uc";

        if (parentItemIdSplit.length === 2) {
            const count = await VendorPointsService.getIdField1sCount(vendor);
            if (count > pageSize) {
                return generateSubdividedTreeElementChildren(`vendor-points/${vendor}/pages`, count);
            }

            return await getVendorPointIdField1TreeElements(vendor, 0);
        }

        const idField1NodeType = parentItemIdSplit[2];

        if (idField1NodeType === "pages") {
            // `vendor-points/${vendor}/pages/${idField1StartIndex}`

            const idField1StartIndex = parseInt(parentItemIdSplit[3]);
            return await getVendorPointIdField1TreeElements(vendor, idField1StartIndex);
        }

        if (idField1NodeType !== "entries") {
            return [];
        }

        // `vendor-points/${vendor}/entries/${idField1}`

        const idField1 = parentItemIdSplit[3];

        if (parentItemIdSplit.length === 4) {
            const count = await VendorPointsService.getIdField2sCount(vendor, idField1);
            if (count > pageSize) {
                return generateSubdividedTreeElementChildren(`vendor-points/${vendor}/entries/${idField1}/pages`, count);
            }

            return await getVendorPointIdField2TreeElements(vendor, idField1, 0);
        }

        const idField2NodeType = parentItemIdSplit[4];

        if (idField2NodeType === "pages") {
            // `vendor-points/${vendor}/entries/${idField1}/pages/${idField2StartIndex}`

            const idField2StartIndex = parseInt(parentItemIdSplit[5]);
            return await getVendorPointIdField2TreeElements(vendor, idField1, idField2StartIndex);
        }

        if (idField1NodeType !== "entries") {
            return [];
        }

        // `vendor-points/${vendor}/entries/${idField1}/entries/${idField2}`

        const idField2 = parentItemIdSplit[5];

        if (parentItemIdSplit.length === 6) {
            const count = await VendorPointsService.getIdField3sCount(vendor, idField1, idField2);
            if (count > pageSize) {
                return generateSubdividedTreeElementChildren(`vendor-points/${vendor}/entries/${idField1}/entries/${idField2}/pages`, count);
            }

            return await getVendorPointIdField3TreeElements(vendor, idField1, idField2, 0);
        }

        const idField3NodeType = parentItemIdSplit[6];

        if (idField3NodeType === "pages") {
            // `vendor-points/${vendor}/entries/${idField1}/entries/${idField2}/pages/${idField3StartIndex}`

            const idField3StartIndex = parseInt(parentItemIdSplit[7]);
            return await getVendorPointIdField3TreeElements(vendor, idField1, idField2, idField3StartIndex);
        }
    }

    if (parentItemIdSplit.length >= 1 && parentItemIdSplit[0] === "pcs-points") {
        // `pcs-points`

        if (parentItemIdSplit.length === 1) {
            const count = await VendorPointsService.getPcsSitesCount();
            if (count > pageSize) {
                return generateSubdividedTreeElementChildren(`pcs-points/pages`, count);
            }

            return await getPcsPointPcsSiteTreeElements(0);
        }

        const pcsSiteNodeType = parentItemIdSplit[1];

        if (pcsSiteNodeType === "pages") {
            // `pcs-points/pages/${pcsSiteStartIndex}`

            const pcsSiteStartIndex = parseInt(parentItemIdSplit[2]);
            return await getPcsPointPcsSiteTreeElements(pcsSiteStartIndex);
        }

        if (pcsSiteNodeType !== "entries") {
            return [];
        }


        // `pcs-points/pages/${pcsSiteId}`

        const pcsSiteId = parentItemIdSplit[2];

        if (parentItemIdSplit.length === 3) {
            const count = await VendorPointsService.getPcsDevicesForPcsSiteCount(pcsSiteId);
            if (count > pageSize) {
                return generateSubdividedTreeElementChildren(`pcs-points/entries/${pcsSiteId}/pages`, count);
            }

            return await getPcsPointPcsDeviceTreeElements(pcsSiteId, 0);
        }


        const pcsDeviceNodeType = parentItemIdSplit[3];

        if (pcsDeviceNodeType === "pages") {
            // `pcs-points/entries/${pcsSiteId}/pages/${pcsDeviceStartIndex}`

            const pcsDeviceStartIndex = parseInt(parentItemIdSplit[4]);
            return await getPcsPointPcsDeviceTreeElements(pcsSiteId, pcsDeviceStartIndex);
        }

        if (pcsSiteNodeType !== "entries") {
            return [];
        }


        // `pcs-points/entries/${pcsSiteId}/entries/${pcsDeviceId}`

        const pcsDeviceId = parentItemIdSplit[4];

        if (parentItemIdSplit.length === 5) {
            const count = await VendorPointsService.getPcsPointsForPcsDeviceCount(pcsDeviceId);
            if (count > pageSize) {
                return generateSubdividedTreeElementChildren(`pcs-points/entries/${pcsSiteId}/entries/${pcsDeviceId}/pages`, count);
            }

            return await getPcsPointPcsPointTreeElements(pcsSiteId, pcsDeviceId, 0);
        }


        const pcsPointNodeType = parentItemIdSplit[5];

        if (pcsPointNodeType === "pages") {
            // `pcs-points/entries/${pcsSiteId}/entries/${pcsDeviceId}/pages/${pcsPointStartIndex}`

            const pcsPointStartIndex = parseInt(parentItemIdSplit[6]);
            return await getPcsPointPcsPointTreeElements(pcsSiteId, pcsDeviceId, pcsPointStartIndex);
        }
    }

    if (parentItemIdSplit.length >= 1 && parentItemIdSplit[0] === "syrx-controller-points") {
        return await getSyrxControllerTreeElementChildren("syrx-controller-points", parentItemId);
    }

    return [];
};