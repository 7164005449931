import React, {useCallback} from 'react'
import {Button} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import {SyrxControllerBACnetPointSelectionTree} from '../../bacnetPoints/syrxControllerBACnetPointSelectionTree';
import {useDispatch} from 'react-redux';
import {
    syrxControllerBuildingPressureControlServiceOatSensorEditorActions,
    useSyrxControllerBuildingPressureControlServiceOatSensorEditorSelector
} from './syrxControllerBuildingPressureControlServiceOatSensorEditorSlice';
import {LoadingSpinner} from '../../../loadingSpinner/LoadingSpinner';
import {SyrxControllerBACnetPointInfoTable} from '../../bacnetPoints/syrxControllerBACnetPointInfoTable';

export interface SyrxControllerBuildingPressureControlServiceOatSensorProps {
    syrxControllerId: string;
    oatSensorSyrxControllerBacnetPointId: string | null;
    editMode?: boolean;
    onSubmit?: (oatSensorSyrxControllerBacnetPointId: string | null) => void;
    cancelLinkTo?: string;
}

const EditMode: React.FunctionComponent<SyrxControllerBuildingPressureControlServiceOatSensorProps> = props => {
    const {
        syrxControllerId,
        onSubmit,
        cancelLinkTo
    } = props;

    const dispatch = useDispatch();
    const isSelectedPointUpdating = useSyrxControllerBuildingPressureControlServiceOatSensorEditorSelector(x => x.isSelectedPointUpdating);
    const oatSensorSyrxControllerBacnetPointId = useSyrxControllerBuildingPressureControlServiceOatSensorEditorSelector(x => x.oatSensorSyrxControllerBacnetPointId);

    const onSyrxControllerBACnetPointSelected = useCallback((selectedSyrxControllerBacnetPointId: string) => {
        dispatch(syrxControllerBuildingPressureControlServiceOatSensorEditorActions.setOatSensorSyrxControllerBacnetPointId(selectedSyrxControllerBacnetPointId));
    }, [dispatch]);

    const submit = useCallback(() => {
        if (onSubmit != null) {
            onSubmit(oatSensorSyrxControllerBacnetPointId);
        }
    }, [onSubmit, oatSensorSyrxControllerBacnetPointId]);

    return (
        <>
            {isSelectedPointUpdating ? <LoadingSpinner /> : null}
            <DisplayMode
                {...props}
            />
            <SyrxControllerBACnetPointSelectionTree
                syrxControllerId={syrxControllerId}
                onSyrxControllerBACnetPointSelected={onSyrxControllerBACnetPointSelected}
                selectedSyrxControllerBACnetPointId={oatSensorSyrxControllerBacnetPointId}
            />
            <Button variant="primary" type="button" onClick={submit}>Submit</Button>
            {cancelLinkTo != null ? (
                <LinkContainer to={cancelLinkTo} exact={true}>
                    <Button variant="outline-secondary">Cancel</Button>
                </LinkContainer>
            ) : null}
        </>
    );
};

const DisplayMode: React.FunctionComponent<SyrxControllerBuildingPressureControlServiceOatSensorProps> = props => {
    const {
        syrxControllerId,
        oatSensorSyrxControllerBacnetPointId
    } = props;

    return (
        <SyrxControllerBACnetPointInfoTable syrxControllerId={syrxControllerId} syrxControllerBacnetPointId={oatSensorSyrxControllerBacnetPointId} />
    );
};

export const SyrxControllerBuildingPressureControlServiceOatSensorTable: React.FunctionComponent<SyrxControllerBuildingPressureControlServiceOatSensorProps> = props => {
    const {
        editMode
    } = props;

    return editMode ? (
        <EditMode {...props} />
    ) : (
        <DisplayMode {...props} />
    )
};