import React, { useCallback, CSSProperties } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { useProjectScheduleSelector } from './projectScheduleSlice';
import { projectScheduleActionCreators } from '../../actionCreators/projectScheduleActionCreators';
import { ComponentScheduleTree } from './types';

const button: CSSProperties = {
    width: '100%',
    margin: '1% 0 1% 0'
}

export const SubcomponentModal: React.FunctionComponent = () => {
    const { showSubcomponentModal, modalSelectedSubcomponentId, selectedComponent, selectedSubcomponents } = useProjectScheduleSelector(x => x);
    const dispatch = useDispatch();

    const hideModal = useCallback(async () => {
        dispatch(projectScheduleActionCreators.hideSubcomponentModal());
    }, [dispatch]);

    const selectOption = useCallback(async (component: ComponentScheduleTree) => {
        dispatch(projectScheduleActionCreators.addSelectedSubcomponent(component));
    }, [dispatch]);

    const goBack = useCallback(async () => {
        dispatch(projectScheduleActionCreators.popSelectedSubcomponent());
    }, [dispatch]);

    return (
        <Modal show={showSubcomponentModal} onHide={hideModal} centered size="sm">
            <Modal.Header>
                {
                    selectedSubcomponents.hasOwnProperty(modalSelectedSubcomponentId) && selectedSubcomponents[modalSelectedSubcomponentId].length ?
                        <p>Select Type of {selectedSubcomponents[modalSelectedSubcomponentId][selectedSubcomponents[modalSelectedSubcomponentId].length - 1]?.description}</p>
                        :
                        <p>Select Type of {selectedComponent?.children?.find(x => x.id === modalSelectedSubcomponentId)?.description}</p>
                }
            </Modal.Header>
            <Modal.Body>
                {
                    selectedSubcomponents.hasOwnProperty(modalSelectedSubcomponentId) && selectedSubcomponents[modalSelectedSubcomponentId].length ?
                        selectedSubcomponents[modalSelectedSubcomponentId][selectedSubcomponents[modalSelectedSubcomponentId].length - 1]?.children?.map(child => (
                            <Button key={child.id} style={button} onClick={() => selectOption(child)}>{child.description}</Button>
                        ))
                        :
                        selectedComponent?.children?.find(x => x.id === modalSelectedSubcomponentId)?.children?.map(child => (
                            <Button key={child.id} style={button} onClick={() => selectOption(child)}>{child.description}</Button>
                        ))
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={goBack}>
                    Go Back
                </Button>
                <Button variant="secondary" onClick={hideModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal >
    )
}