import {CaseReducer, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {buildSubStateSelector} from '../../store/utilities/useAppStateSelector';

const initialState = {
    syrxControllerId: '',
    password: '',
    isSuccessful: false,
    errorMessage: ''
};

export type AppStateSyrxControllerRegistration = typeof initialState;

export const slice = createSlice<AppStateSyrxControllerRegistration, {
    submit: CaseReducer<AppStateSyrxControllerRegistration, PayloadAction<{syrxControllerId: string}>>;
    success: CaseReducer<AppStateSyrxControllerRegistration>;
    error: CaseReducer<AppStateSyrxControllerRegistration, PayloadAction<{exceptionMessage: string}>>;
}>({
    name: "login",
    initialState,
    reducers: {
        submit(state, {payload}) {
            state.syrxControllerId = payload.syrxControllerId
        },
        success(state) {
            state.isSuccessful = true;
            state.errorMessage = '';
        },
        error(state, {payload}) {
            state.isSuccessful = false;
            state.errorMessage = payload.exceptionMessage
        }
    }
});

export const useSyrxControllerRegistrationSelector = buildSubStateSelector(state => state.syrxControllerRegistration);
export const syrxControllerRegistrationActions = slice.actions;
export const syrxControllerRegistrationReducer = slice.reducer;