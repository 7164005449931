import React from 'react'
import { Row, Col, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useParams } from 'react-router';

import { SyrxControllerServiceLogsGrid } from './syrxControllerServiceLogsGrid';


export interface SyrxControllerServiceLogsProps {
}

export const SyrxControllerServiceLogs: React.FunctionComponent<SyrxControllerServiceLogsProps> = () => {
    const {syrxControllerId, serviceId} = useParams() as {syrxControllerId: string, serviceId: string};


    return (
        <>
            <h3>{serviceId} Logs</h3>
            <SyrxControllerServiceLogsGrid
                syrxControllerId={syrxControllerId}
                serviceId={serviceId}
            />
            <Row>
                <Col md={12}>
                    <LinkContainer to={`/admin/syrx-controllers/${syrxControllerId}/services/${serviceId}`}>
                        <Button variant="outline-info">Back</Button>
                    </LinkContainer>
                </Col>
            </Row>
        </>
    )
}