import React, {useCallback, useEffect, useState} from 'react'
import {Tree} from '@sht-dev/fancy-tree';
import {
    getSyrxControllerTreeElementChildren
} from './treeBuildingUtilityFunctions';

export interface SyrxControllerBACnetPointSelectionTreeProps {
    syrxControllerId: string;
    selectedSyrxControllerBACnetPointId: string | null;
    onSyrxControllerBACnetPointSelected: (syrxControllerBACnetPointId: string) => void;
}

export const SyrxControllerBACnetPointSelectionTree: React.FunctionComponent<SyrxControllerBACnetPointSelectionTreeProps> = props => {
    const {
        syrxControllerId,
        onSyrxControllerBACnetPointSelected
    } = props;

    const [showTree, setShowTree] = useState(false);
    const [selectedTreeItemId, setSelectedTreeItemId] = useState(undefined as string | undefined);

    useEffect(() => {
        // this is a hack to force the tree to reload when syrxControllerId changes
        setShowTree(false);
        const t = setTimeout(() => setShowTree(true), 100);
        return () => {
            clearTimeout(t);
        }
    }, [syrxControllerId]);

    const onSelect = useCallback((treeItemId: string) => {
        const match = treeItemId.match("^/entries/([^/]*)/entries/([^/]*)/entries/([^/]*)$");
        if (match?.length !== 4) {
            return;
        }

        setSelectedTreeItemId(treeItemId);

        const [,,, syrxControllerBacnetPointId] = match;

        onSyrxControllerBACnetPointSelected(syrxControllerBacnetPointId);
    }, [setSelectedTreeItemId, onSyrxControllerBACnetPointSelected])

    const getTreeItems = useCallback(async (parentItemId: string | null) => {
        parentItemId = parentItemId ?? `/entries/${syrxControllerId}`;
        return await getSyrxControllerTreeElementChildren("", parentItemId);
    }, [syrxControllerId]);

    return showTree ? (
        <Tree
            getChildren={getTreeItems}
            selected={selectedTreeItemId}
            onSelect={onSelect}
        />
    ) : null;
};