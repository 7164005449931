import React, {useEffect, useState} from 'react'
import {Button, Modal} from "react-bootstrap";
import {SyrxControllerServiceVersion} from '../../../models/syrxControllerServiceVersion';
import {SyrxControllerServiceVersionsService} from '../../../services/syrxControllerServiceVersionsService';
import {LoadingSpinner} from '../../loadingSpinner/LoadingSpinner';

export interface SyrxControllerServiceVersionLockedModalProps {
    serviceId: string;
    versionName: string;
    onClose: () => void;
}

export const SyrxControllerServiceVersionLockedModal: React.FunctionComponent<SyrxControllerServiceVersionLockedModalProps> = (props) => {
    const {
        serviceId,
        versionName,
        onClose
    } = props;

    const [isLoaded, setIsLoaded] = useState(false);
    const [syrxControllerServiceVersion, setSyrxControllerServiceVersion] = useState(null as SyrxControllerServiceVersion | null);

    useEffect(() => {
        async function doTheThing() {
            setIsLoaded(false);
            setSyrxControllerServiceVersion(null);
            const _syrxControllerServiceVersion = await SyrxControllerServiceVersionsService.getServiceVersionForServiceTypeAndVersionName(serviceId, versionName);
            if (_syrxControllerServiceVersion == null) {
                onClose();
                return;
            }
            setSyrxControllerServiceVersion(_syrxControllerServiceVersion);
            setIsLoaded(true);
        }

        void doTheThing();
    }, [serviceId, versionName, onClose]);

    return (
        <Modal show={true} onHide={onClose} size="xl">
            {!isLoaded ? <LoadingSpinner /> : null}
            <Modal.Header closeButton>
                <Modal.Title>Service version locked</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {syrxControllerServiceVersion != null ? (
                    <>
                        <p>This service version is locked because it has been used by a syrx controller in the field.</p>
                        <table className="table">
                            <tbody>
                                <tr>
                                    <th>Service</th>
                                    <td>{syrxControllerServiceVersion.service_id}</td>
                                </tr>
                                <tr>
                                    <th>Version</th>
                                    <td>{syrxControllerServiceVersion.version_name}</td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                ) : null}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};