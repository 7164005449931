import { Dispatch } from "redux";
import { NewApiServices } from '../newApiServices';
import { purchasingActions } from "../features/purchasing/purchasingSlice";
import { SyrxControllerNodeTypesDict, Product, Cart, SyrxControllerNodeType } from '../features/purchasing/types';

export const purchasingActionCreators = {
    changePage: (direction: number) => async (dispatch: Dispatch<any>) => {
        dispatch(purchasingActions.setCurrentPage(direction));
    },
    getSyrxControllerSizes: () => async (dispatch: Dispatch<any>) => {
        const res = await NewApiServices.purchasingService.getSyrxControllerSizes();
        dispatch(purchasingActions.setSyrxControllerSizes(res.data))
    },
    getSyrxControllerNodeTypes: () => async (dispatch: Dispatch<any>) => {
        const res = await NewApiServices.purchasingService.getSyrxControllerNodeTypes();
        let syrxControllerNodeTypes: SyrxControllerNodeTypesDict = {};
        res.data.map((nodeType: SyrxControllerNodeType) => {
            syrxControllerNodeTypes[nodeType.syrx_controller_node_category_id] = [...syrxControllerNodeTypes[nodeType.syrx_controller_node_category_id] ?? [], nodeType];
        });
        dispatch(purchasingActions.setNodeTypes(syrxControllerNodeTypes));
    },
    getSyrxControllerNodeCategories: () => async (dispatch: Dispatch<any>) => {
        const res = await NewApiServices.purchasingService.getSyrxControllerNodeCategories();
        dispatch(purchasingActions.setSyrxControllerNodeCategories(res.data));
        dispatch(purchasingActions.setPages(res.data));
    },
    updateCartSyrxController: (products: Product) => async (dispatch: Dispatch<any>) => {
        dispatch(purchasingActions.setCartSyrxController(products));
    },
    updateCartProduct: (productId: string, product: Product) => async (dispatch: Dispatch<any>) => {
        dispatch(purchasingActions.setCartProduct({productId, product}));
    },
    initiatePurchase: (groupId: number, userId: string, syrxControllerSizeCart: Product, syrxControllerNodeTypeCart: Cart) => async (dispatch: Dispatch<any>) => {
        const syrxControllerSizeQuantities = Object.entries(syrxControllerSizeCart).map(([key, value]) => ({
            syrx_controller_size_id: key,
            quantity: value
        }));
        const syrxControllerNodeTypeQuantities = Object.values(syrxControllerNodeTypeCart).map((syrxControllerNodeType: Product) =>
            Object.entries(syrxControllerNodeType).map(([key, value]) => ({
                syrx_controller_node_type_id: key,
                quantity: value
            }))
        ).flat(1);
        const body = {
            group_id: groupId,
            user_id: userId,
            syrx_controller_size_quantities: syrxControllerSizeQuantities,
            syrx_controller_node_type_quantities: syrxControllerNodeTypeQuantities
        }
        const res = await NewApiServices.purchasingService.initiatePurchase(body);
        dispatch(purchasingActions.setSyrxControllerOrder(res.data));
    },
    updateStripeErrorMessage: (stripeErrorMessage: string | undefined) => (dispatch: Dispatch<any>) => {
        dispatch(purchasingActions.setStripeErrorMessage(stripeErrorMessage));
    },
    updateIsProcessingPayment: (isProcessingPayment: boolean) => (dispatch: Dispatch<any>) => {
        dispatch(purchasingActions.setIsProcessingPayment(isProcessingPayment));
    },
    completePurchase: (id: string) => async (dispatch: Dispatch<any>) => {
        const res = await NewApiServices.purchasingService.updatePurchaseStatus(id);
        dispatch(purchasingActions.setSyrxControllerOrder(res.data));
    }
}