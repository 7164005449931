import { BaseApiService } from "./baseApiService";

export class EquipmentsService extends BaseApiService {
    async submitEquipment(body: any) {
        const res = await this.axios.post(`/equipments`, body);
        return res.data;
    }
    async deleteEquipment(equipmentId: number) {
        return await this.axios.delete(`/equipments/${equipmentId}`)
    }
    async updateEquipment(equipmentId: number, body: any) {
        const res = await this.axios.put(`/equipments/${equipmentId}`, body);
        return res.data;
    }
}
