import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { buildSubStateSelector } from '../../store/utilities/useAppStateSelector';

import { Project, ComponentScheduleTree, Equipment } from './types';


const initialState = {
    isLoading: true,
    project: {} as Project,
    componentScheduleTree: [] as ComponentScheduleTree[],
    equipmentEditor: false,
    selectedComponent: {} as ComponentScheduleTree,
    projectSchedule: [] as Equipment[],
    selectedEquipment: {} as Equipment,
    showSubcomponentModal: false,
    modalSelectedSubcomponentId: '',
    selectedSubcomponents: {} as {[key: string]: ComponentScheduleTree[]},
    pointEditor: false
};

export type AppStateProjectSchedule = typeof initialState;

export const slice = createSlice<AppStateProjectSchedule, {
    setProject: CaseReducer<AppStateProjectSchedule, PayloadAction<Project>>;
    setComponentScheduleTree: CaseReducer<AppStateProjectSchedule, PayloadAction<ComponentScheduleTree[]>>;
    setEquipmentEditor: CaseReducer<AppStateProjectSchedule, PayloadAction<{componentId: string, equipmentId: number | undefined}>>;
    resetEquipmentEditor: CaseReducer<AppStateProjectSchedule>;
    setProjectSchedule: CaseReducer<AppStateProjectSchedule, PayloadAction<Equipment[]>>;
    addEquipment: CaseReducer<AppStateProjectSchedule, PayloadAction<Equipment>>;
    removeEquipment: CaseReducer<AppStateProjectSchedule, PayloadAction<number>>;
    updateEquipment: CaseReducer<AppStateProjectSchedule, PayloadAction<Equipment>>;
    showSubcomponentModal: CaseReducer<AppStateProjectSchedule, PayloadAction<string>>;
    hideSubcomponentModal: CaseReducer<AppStateProjectSchedule>;
    addSelectedSubcomponent: CaseReducer<AppStateProjectSchedule, PayloadAction<ComponentScheduleTree>>;
    popSelectedSubcomponent: CaseReducer<AppStateProjectSchedule>;
    setPointEditor: CaseReducer<AppStateProjectSchedule, PayloadAction<number>>;
    resetPointEditor: CaseReducer<AppStateProjectSchedule>;
}>({
    name: "projectSchedule",
    initialState,
    reducers: {
        setProject(state, action) {
            state.isLoading = false;
            state.project = action.payload;
        },
        setComponentScheduleTree(state, action) {
            state.componentScheduleTree = action.payload;
        },
        setEquipmentEditor(state, action) {
            state.selectedComponent = state.componentScheduleTree.find(({ id }) => id === action.payload.componentId)!;
            state.selectedEquipment = state.projectSchedule.find(({id}) => id === action.payload.equipmentId) ?? {} as Equipment;
            state.equipmentEditor = true;
        },
        resetEquipmentEditor(state) {
            state.equipmentEditor = false;
            state.selectedComponent = {} as ComponentScheduleTree;
            state.selectedEquipment = {} as Equipment;
            state.selectedSubcomponents = initialState.selectedSubcomponents;
        },
        setProjectSchedule(state, action) {
            state.projectSchedule = action.payload;
        },
        addEquipment(state, action) {
            state.projectSchedule = [
                ...state.projectSchedule,
                action.payload
            ]
        },
        removeEquipment(state, action) {
            state.projectSchedule = state.projectSchedule.filter(({id}) => id !== action.payload);
        },
        updateEquipment(state, action) {
            const index = state.projectSchedule.findIndex(({id}) => id === action.payload.id);
            state.projectSchedule = [
                ...state.projectSchedule.slice(0, index),
                action.payload,
                ...state.projectSchedule.slice(index+1, state.projectSchedule.length)
            ]
        },
        showSubcomponentModal(state, action) {
            state.showSubcomponentModal = true;
            state.modalSelectedSubcomponentId = action.payload;
        },
        hideSubcomponentModal(state) {
            state.showSubcomponentModal = false;
            state.modalSelectedSubcomponentId = initialState.modalSelectedSubcomponentId;
        },
        addSelectedSubcomponent(state, action) {
            if (state.selectedSubcomponents.hasOwnProperty(state.modalSelectedSubcomponentId)) {
                state.selectedSubcomponents[state.modalSelectedSubcomponentId] = [...state.selectedSubcomponents[state.modalSelectedSubcomponentId], action.payload];
            } else {
                state.selectedSubcomponents[state.modalSelectedSubcomponentId] = [action.payload];
            }
            if (!action.payload.children) {
                state.showSubcomponentModal = false;
            }
        },
        popSelectedSubcomponent(state) {
            state.selectedSubcomponents[state.modalSelectedSubcomponentId] = [...state.selectedSubcomponents[state.modalSelectedSubcomponentId].slice(0, state.selectedSubcomponents[state.modalSelectedSubcomponentId].length - 1)]
        },
        setPointEditor(state, action) {
            state.selectedEquipment = state.projectSchedule.find(({id}) => id === action.payload) ?? {} as Equipment;
            state.pointEditor = true;
        },
        resetPointEditor(state) {
            state.pointEditor = false;
            state.selectedEquipment = initialState.selectedEquipment;
        }
    }
});

export const useProjectScheduleSelector = buildSubStateSelector(state => state.projectSchedule);
export const projectScheduleActions = slice.actions;
export const projectScheduleReducer = slice.reducer;