import React, { useEffect } from 'react'
import { Form, FormGroup, Row, Col, Button } from 'react-bootstrap';
import { useParams, Redirect } from 'react-router';
import {Form as ReactFinalForm} from 'react-final-form';
import arrayMutators from 'final-form-arrays'
import { LinkContainer } from 'react-router-bootstrap';
import { useDispatch } from 'react-redux';

import { SyrxControllersEditorGeneralConfigurationFieldset } from './syrxControllersEditorGeneralConfigurationFieldset';
import { SyrxControllersEditorBacnetConfigurationFieldset } from './syrxControllersEditorBacnetConfigurationFieldset';
import { loadSyrxControllerEditor, submitSyrxControllerEditor } from '../../../actionCreators/syrxControllerEditorActionCreators';
import {LoadingSpinner} from '../../loadingSpinner/LoadingSpinner';
import { SyrxControllerEditorForm } from './syrxControllerEditorForm';
import {syrxControllerEditorActions, useSyrxControllerEditorSelector} from './syrxControllerEditorSlice';

export const SyrxControllersEditor: React.FunctionComponent<{}> = () => {
    const {syrxControllerId} = useParams() as {syrxControllerId: string};
    const dispatch = useDispatch();

    const isLoading = useSyrxControllerEditorSelector(state => state.isLoading);
    const isDoneLoading = useSyrxControllerEditorSelector(state => state.isDoneLoading);
    const isSubmitting = useSyrxControllerEditorSelector(state => state.isSubmitting);
    const isDoneSubmitting = useSyrxControllerEditorSelector(state => state.isDoneSubmitting);
    const formInitialValues = useSyrxControllerEditorSelector(state => state.formInitialValues);

    useEffect(() => {
        dispatch(loadSyrxControllerEditor(syrxControllerId));

        return () => {
            dispatch(syrxControllerEditorActions.editorUnload());
        }
    }, [dispatch, syrxControllerId]);

    const submit = (values: SyrxControllerEditorForm) => {
        dispatch(submitSyrxControllerEditor(values));
    }

    if (isLoading) {
        return <LoadingSpinner />
    }

    return isDoneLoading && formInitialValues != null ? (
        <ReactFinalForm onSubmit={submit} mutators={{...arrayMutators}} initialValues={formInitialValues}>
            {({ 
                handleSubmit, 
                values,
                form: {
                    mutators: { push }
                }
            }) => (
                <>
                    {isSubmitting ? <LoadingSpinner /> : null}
                    {isDoneSubmitting ? <Redirect to={`/admin/syrx-controllers/${formInitialValues.syrxController?.id}`} /> : null}
                    <Form onSubmit={handleSubmit}>
                        <SyrxControllersEditorGeneralConfigurationFieldset
                            syrxControllerId={formInitialValues.syrxController?.id}
                            groupName={formInitialValues.syrxController?.group_name}
                        />

                        {values.provideBacnetConfiguration === "yes" ? (
                            <SyrxControllersEditorBacnetConfigurationFieldset
                                values={values}
                                push={push}
                            />
                        ) : null}

                        <FormGroup as={Row}>
                            <Form.Label column md={4} />
                            <Col md={4}>
                                <Button type="submit" variant="primary">Submit</Button>
                                <LinkContainer to={`/admin/syrx-controllers/${formInitialValues.syrxController?.id}`}><Button type="submit" variant="secondary">Cancel</Button></LinkContainer>
                            </Col>
                        </FormGroup>
                    </Form>
                </>
            )}
        </ReactFinalForm>
    ) : null
}