import React, { useEffect } from 'react'
import { Row, Col, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';

import {
    loadSyrxControllerEnabledServiceDetails,
    unloadSyrxControllerEnabledServiceDetails
} from '../../../actionCreators/syrxControllerEnabledServiceDetailsActionCreators';
import {SyrxControllerEnabledServiceDetails} from './syrxControllerEnabledServiceDetails';


export const SyrxControllerEnabledService: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const {syrxControllerId, serviceId} = useParams() as {syrxControllerId: string, serviceId: string};

    useEffect(() => {
        dispatch(loadSyrxControllerEnabledServiceDetails(syrxControllerId, serviceId));
        return () => {
            dispatch(unloadSyrxControllerEnabledServiceDetails());
        };
    }, [dispatch, syrxControllerId, serviceId]);

    return (
        <>
            <SyrxControllerEnabledServiceDetails syrxControllerId={syrxControllerId} serviceId={serviceId} />
            <Row>
                <Col md={12}>
                    <LinkContainer to={`/admin/syrx-controllers/${syrxControllerId}`}>
                        <Button variant="outline-info">Back</Button>
                    </LinkContainer>
                </Col>
            </Row>
        </>
    )
}