declare const LEGACY_APP_URL: string;
declare const LEGACY_API_URL: string;
declare const NEW_API_URL: string;
declare const SYRX_SOCKETIO_ENDPOINT: string;
declare const STRIPE_API_KEY: string;

export const legacyAppUrl = LEGACY_APP_URL;
export const legacyApiUrl = LEGACY_API_URL;
export const newApiUrl = NEW_API_URL;
export const syrxSocketioEndpoint = SYRX_SOCKETIO_ENDPOINT;
export const stripeApiKey = STRIPE_API_KEY;
