import {axios} from "./axiosService";
import qs from 'qs';
import {VendorPointRecordMoveJob} from '../models/vendorPointRecordMoveJob';

export const VendorPointsService = {
    async getVendors() {
        const res = await axios.get<string[]>(`/vendor_points/vendors`);
        return res.data;
    },

    async getIdField1s(vendor: string, skip: number, take: number) {
        const queryString = qs.stringify({
            skip,
            take,
        });
        const res = await axios.get<string[]>(`/vendor_points/vendors/${vendor}/id_field_1?${queryString}`);
        return res.data;
    },

    async getIdField1sCount(vendor: string) {
        const {data: {count}} = await axios.get<{count: number}>(`/vendor_points/vendors/${vendor}/id_field_1/count`);
        return count;
    },

    async getIdField2s(vendor: string, idField1: string, skip: number, take: number) {
        const queryString = qs.stringify({
            skip,
            take,
        });
        const res = await axios.get<string[]>(`/vendor_points/vendors/${vendor}/id_field_1/${idField1}/id_field_2?${queryString}`);
        return res.data;
    },

    async getIdField2sCount(vendor: string, idField1: string) {
        const {data: {count}} = await axios.get<{count: number}>(`/vendor_points/vendors/${vendor}/id_field_1/${idField1}/id_field_2/count`);
        return count;
    },

    async getIdField3s(vendor: string, idField1: string, idField2: string, skip: number, take: number) {
        const queryString = qs.stringify({
            skip,
            take,
        });
        const res = await axios.get<string[]>(`/vendor_points/vendors/${vendor}/id_field_1/${idField1}/id_field_2/${idField2}/id_field_3?${queryString}`);
        return res.data;
    },

    async getIdField3sCount(vendor: string, idField1: string, idField2: string) {
        const {data: {count}} = await axios.get<{count: number}>(`/vendor_points/vendors/${vendor}/id_field_1/${idField1}/id_field_2/${idField2}/id_field_3/count`);
        return count;
    },

    async getPcsSites(skip: number, take: number) {
        const queryString = qs.stringify({
            skip,
            take,
            sort: [{
                field: "site_name",
                dir: "asc"
            }]
        });
        const {data} = await axios.get<{data: {id: string, site_name: string}[], total: number}>(`/PcsSites?${queryString}`); // obviously there is more than these fields, but these are all we care about at the moment
        return data;
    },

    async getPcsSitesCount() {
        const {data: {count}} = await axios.get<{count: number}>(`/PcsSites/count`);
        return count;
    },

    async getPcsDevicesForPcsSite(pcsSiteId: string, skip: number, take: number) {
        const queryString = qs.stringify({
            skip,
            take,
            sort: [{
                field: "name",
                dir: "asc"
            }]
        });
        const res = await axios.get<{data: {id: string, name: string}[], total: number}>(`/PcsSites/${pcsSiteId}/devices?${queryString}`); // obviously there is more than these fields, but these are all we care about at the moment
        return res.data;
    },

    async getPcsDevicesForPcsSiteCount(pcsSiteId: string) {
        const {data: {count}} = await axios.get<{count: number}>(`/PcsSites/${pcsSiteId}/devices/count`);
        return count;
    },

    async getPcsPointsForPcsDevice(pcsDeviceId: string, skip: number, take: number) {
        const queryString = qs.stringify({
            skip,
            take,
            sort: [{
                field: "name",
                dir: "asc"
            }]
        });
        const res = await axios.get<{data: {id: string, name: string}[], total: number}>(`/PcsDevices/${pcsDeviceId}/points?${queryString}`); // obviously there is more than these fields, but these are all we care about at the moment
        return res.data;
    },

    async getPcsPointsForPcsDeviceCount(pcsDeviceId: string) {
        const {data: {count}} = await axios.get<{count: number}>(`/PcsDevices/${pcsDeviceId}/points/count`);
        return count;
    },

    async getPcsPointById(pcsPointId: string) {
        const {data: pcsPoint} = await axios.get<{
            name: string;
            pcs_device: {
                name: string;
                pcs_site: {
                    site_name: string;
                }
            }
        }>(`/PcsPoints/${pcsPointId}`);
        return pcsPoint;
    },

    async submitVendorPointRecordMoveJobs(vendorPointRecordMoveJobs: VendorPointRecordMoveJob[]) {
        await axios.post(`/vendor_point_record_move_jobs`, vendorPointRecordMoveJobs);
    }
}