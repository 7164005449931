import React, {useEffect, useMemo} from 'react'
import {Row, Col, Button, Form, FormGroup} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import {Redirect, useParams} from 'react-router';

import {LoadingSpinner} from '../../loadingSpinner/LoadingSpinner';
import {Field, Form as ReactFinalForm} from 'react-final-form';
import {SyrxControllerBacnetDeviceEditorForm} from './syrxControllerBacnetDeviceEditorForm';
import {useDispatch} from 'react-redux';
import {submitSyrxControllerBacnetDeviceEditor} from '../../../actionCreators/syrxControllerBacnetDeviceEditorActionCreators';
import {
    syrxControllerBacnetDeviceEditorActions,
    useSyrxControllerBacnetDeviceEditorSelector
} from './syrxControllerBacnetDeviceEditorSlice';


export interface SyrxControllerBacnetDeviceEditorProps {
    syrxControllerId?: string;
    redirectRoute?: string;
}

export const SyrxControllerBacnetDeviceEditor: React.FunctionComponent<SyrxControllerBacnetDeviceEditorProps> = props => {
    const dispatch = useDispatch();
    const syrxControllerId = props.syrxControllerId ?? (useParams() as {syrxControllerId: string}).syrxControllerId;
    const redirectRoute = props.redirectRoute ?? `/admin/syrx-controllers/${syrxControllerId}/services/Pathian.BACnetNetworkQueryService`;

    useEffect(() => {
        dispatch(syrxControllerBacnetDeviceEditorActions.editorUnload());
        return () => {
            dispatch(syrxControllerBacnetDeviceEditorActions.editorUnload());
        }
    }, [dispatch]);

    const submit = (values: SyrxControllerBacnetDeviceEditorForm) => {
        if (values.provideDestinationAddressing === "no") {
            values.syrxControllerBacnetDevice.destination_mac_address = "1.0.0.0:0";
            values.syrxControllerBacnetDevice.destination_network = 0;
        }
        dispatch(submitSyrxControllerBacnetDeviceEditor(values));
    };

    const isSubmitting = useSyrxControllerBacnetDeviceEditorSelector(x => x.isSubmitting);
    const isDoneSubmitting = useSyrxControllerBacnetDeviceEditorSelector(x => x.isDoneSubmitting);

    const formInitialValues = useMemo(
        () => ({
            provideDestinationAddressing: "no",
            syrxControllerBacnetDevice: {
                syrx_controller_id: syrxControllerId,
                network: 0
            }
        } as SyrxControllerBacnetDeviceEditorForm),
        [syrxControllerId]);

    return (
        <ReactFinalForm onSubmit={submit} initialValues={formInitialValues}>
            {({
                handleSubmit,
                values
            }) => (
                <>
                    {isSubmitting ? <LoadingSpinner /> : null}
                    {isDoneSubmitting ? <Redirect to={redirectRoute} /> : null}
                    <Form onSubmit={handleSubmit}>
                        <fieldset>
                            <legend>Add BACnet device</legend>

                            <FormGroup as={Row}>
                                <Form.Label column md={6} lg={4}>Controller ID</Form.Label>
                                <Col md={6}>
                                    <p className="form-control-static">{syrxControllerId}</p>
                                </Col>
                            </FormGroup>

                            <FormGroup as={Row}>
                                <Form.Label column md={6} lg={4}>MAC address</Form.Label>
                                <Col md={6}>
                                    <Field
                                        name="syrxControllerBacnetDevice.mac_address"
                                        component="input"
                                        type="text"
                                        className="form-control"
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup as={Row}>
                                <Form.Label column md={6} lg={4}>Instance number (BACnet Object ID)</Form.Label>
                                <Col md={6}>
                                    <Field
                                        name="syrxControllerBacnetDevice.instance_number"
                                        component="input"
                                        type="number"
                                        className="form-control"
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup as={Row}>
                                <Form.Label column md={6} lg={4}>BACnet Network</Form.Label>
                                <Col md={6}>
                                    <Field
                                        name="syrxControllerBacnetDevice.network"
                                        component="input"
                                        type="number"
                                        className="form-control"
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup as={Row}>
                                <Form.Label column md={6} lg={4}>Provide destination addressing</Form.Label>
                                <Col md={6}>
                                    <Field
                                        name="provideDestinationAddressing"
                                        component="select"
                                        className="form-control"
                                    >
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </Field>
                                </Col>
                            </FormGroup>

                            {values.provideDestinationAddressing === "yes" ? (
                                <>
                                    <FormGroup as={Row}>
                                        <Form.Label column md={6} lg={4}>Destination MAC address</Form.Label>
                                        <Col md={6}>
                                            <Field
                                                name="syrxControllerBacnetDevice.destination_mac_address"
                                                component="input"
                                                type="text"
                                                className="form-control"
                                            />
                                        </Col>
                                    </FormGroup>

                                    <FormGroup as={Row}>
                                        <Form.Label column md={6} lg={4}>Destination network</Form.Label>
                                        <Col md={6}>
                                            <Field
                                                name="syrxControllerBacnetDevice.destination_network"
                                                component="input"
                                                type="number"
                                                className="form-control"
                                            />
                                        </Col>
                                    </FormGroup>
                                </>
                            ) : null}
                        </fieldset>

                        <FormGroup as={Row}>
                            <Form.Label column md={4} lg={4} />
                            <Col md={6}>
                                <Button type="submit" variant="primary">Submit</Button>
                                <LinkContainer to={redirectRoute}><Button type="submit" variant="secondary">Cancel</Button></LinkContainer>
                            </Col>
                        </FormGroup>
                    </Form>
                </>
            )}
        </ReactFinalForm>
    );
}