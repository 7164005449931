import React, {useCallback, useEffect, useState} from 'react'
import {Tree, TreeElement} from '@sht-dev/fancy-tree';
import {SyrxController} from '../../../models/syrxController';
import {
    getSyrxControllerBacnetDevices,
    getSyrxControllerBacnetPoints,
    getSyrxControllerBacnetPointsByDevice,
    getSyrxControllerEnabledServices,
    getSyrxControllers
} from '../../../services/syrxControllersService';
import {useParams} from 'react-router';
import {SyrxControllerEnabledService} from '../../../models/syrxControllerEnabledService';
import {SyrxControllerBacnetDevice} from '../../../models/syrxControllerBacnetDevice';
import {SyrxControllerBacnetPoint} from '../../../models/syrxControllerBacnetPoint';
import {SyrxControllerStatusIndicator} from './syrxControllerStatusIndicator';
import {SyrxControllerSystemdServiceStatusIndicator} from './syrxControllerSystemdServiceStatusIndicator';



const renderTreeElementFunction = (treeElement: TreeElement) => {
    const treeElementIdSplit = treeElement.id.split("/");
    if (treeElementIdSplit.length === 2 && treeElementIdSplit[0] === "syrx-controllers") {
        const syrxControllerId = treeElementIdSplit[1];
        return (
            <>
                <SyrxControllerStatusIndicator syrxControllerId={syrxControllerId} />
                {treeElement.name}
            </>
        );
    } else if (treeElementIdSplit.length === 4 && treeElementIdSplit[0] === "syrx-controllers" && treeElementIdSplit[2] === "services") {
        const syrxControllerId = treeElementIdSplit[1];
        const serviceId = treeElementIdSplit[3];
        return (
            <>
                <SyrxControllerSystemdServiceStatusIndicator
                    syrxControllerId={syrxControllerId}
                    serviceId={serviceId}
                />
                {treeElement.name}
            </>
        );
    }

    return <>{treeElement.name}</>
}

const getTreeItemForSyrxController = (syrxController: SyrxController) => ({
    id: `syrx-controllers/${syrxController.id}`,
    name: `${syrxController.group_name} - ${syrxController.name}`
});

const getTreeItemForSyrxControllerEnabledService = (syrxControllerEnabledService: SyrxControllerEnabledService) => ({
    id: `syrx-controllers/${syrxControllerEnabledService.syrx_controller_id}/services/${syrxControllerEnabledService.service_id}`,
    name: syrxControllerEnabledService.service_id
});

const getTreeItemForSyrxControllerBacnetDevice = (syrxControllerBacnetDevice: SyrxControllerBacnetDevice) => ({
    id: `syrx-controllers/${syrxControllerBacnetDevice.syrx_controller_id}/devices/${syrxControllerBacnetDevice.id}`,
    name: syrxControllerBacnetDevice.name ?? `${syrxControllerBacnetDevice.mac_address} [${syrxControllerBacnetDevice.network}] - ${syrxControllerBacnetDevice.instance_number}`
});

const getTreeItemForSyrxControllerBacnetPoint = (syrxControllerBacnetPoint: SyrxControllerBacnetPoint) => ({
    id: `syrx-controllers/${syrxControllerBacnetPoint.syrx_controller_id}/devices/${syrxControllerBacnetPoint.syrx_controller_bacnet_device_id}/points/${syrxControllerBacnetPoint.id}`,
    name: syrxControllerBacnetPoint.name ?? "",
    hideExpandArrow: true
})

export interface SyrxControllerManagerTreeProps {
    rootGroupId: number;
    onSyrxControllerSelected: (syrxControllerId: string) => void;
    onSyrxControllerLogsSelected: (syrxControllerId: string) => void;
    onSyrxControllerServicesSelected: (syrxControllerId: string) => void;
    onSyrxControllerEnabledServiceSelected: (syrxControllerId: string, serviceId: string) => void;
    onSyrxControllerEnabledServiceLogsSelected: (syrxControllerId: string, serviceId: string) => void;
    onSyrxControllerDevicesSelected: (syrxControllerId: string) => void;
    onSyrxControllerDeviceSelected: (syrxControllerId: string, syrxControllerBacnetDeviceId: string) => void;
    onSyrxControllerDevicePointsSelected: (syrxControllerId: string, syrxControllerBacnetDeviceId: string) => void;
    onSyrxControllerPointsSelected: (syrxControllerId: string) => void;
}

export const SyrxControllerManagerTree: React.FunctionComponent<SyrxControllerManagerTreeProps> = props => {
    const {
        rootGroupId,
        onSyrxControllerSelected,
        onSyrxControllerLogsSelected,
        onSyrxControllerServicesSelected,
        onSyrxControllerEnabledServiceSelected,
        onSyrxControllerEnabledServiceLogsSelected,
        onSyrxControllerDevicesSelected,
        onSyrxControllerDeviceSelected,
        onSyrxControllerDevicePointsSelected,
        onSyrxControllerPointsSelected
    } = props;
    const [showTree, setShowTree] = useState(false);
    const {syrxControllerId} = useParams();
    const [selectedTreeItemId, setSelectedTreeItemId] = useState(syrxControllerId != null ? `syrx-controllers/${syrxControllerId}` : undefined);

    useEffect(() => {
        // this is a hack to force the tree to reload when rootGroupId changes
        
        setShowTree(false);
        const t = setTimeout(() => setShowTree(true), 100);
        return () => {
            clearTimeout(t);
        }
    }, [rootGroupId]);

    const getTreeItems = useCallback(async (parentItemId: string | null) => {
        if (parentItemId == null) {
            const syrxControllers = await getSyrxControllers(rootGroupId);
            const treeItems = syrxControllers.map(getTreeItemForSyrxController);
            return treeItems;
        } else {
            const parentItemIdSplit = parentItemId.split("/");
            if (parentItemIdSplit.length === 2 && parentItemIdSplit[0] === "syrx-controllers") {
                const syrxControllerId = parentItemIdSplit[1];
                return [
                    {
                        id: `syrx-controllers/${syrxControllerId}/devices`,
                        name: "Devices"
                    },
                    {
                        id: `syrx-controllers/${syrxControllerId}/points`,
                        name: "Points",
                        hideExpandArrow: true
                    },
                    {
                        id: `syrx-controllers/${syrxControllerId}/services`,
                        name: "Services"
                    },
                    {
                        id: `syrx-controllers/${syrxControllerId}/logs`,
                        name: "Logs",
                        hideExpandArrow: true
                    }
                ]
            } else if (parentItemIdSplit.length === 3 && parentItemIdSplit[0] === "syrx-controllers" && parentItemIdSplit[2] === "services") {
                const syrxControllerId = parentItemIdSplit[1];
                const enabledServices = await getSyrxControllerEnabledServices(syrxControllerId);
                const treeItems = [
                    ...enabledServices.map(getTreeItemForSyrxControllerEnabledService),
                    {
                        id: `syrx-controllers/${syrxControllerId}/services/rabbitmq-server`,
                        name: "rabbitmq-server"
                    }
                ];
                return treeItems;
            } else if (parentItemIdSplit.length === 4 && parentItemIdSplit[0] === "syrx-controllers" && parentItemIdSplit[2] === "services") {
                const syrxControllerId = parentItemIdSplit[1];
                const serviceId = parentItemIdSplit[3];
                return [
                    {
                        id: `syrx-controllers/${syrxControllerId}/services/${serviceId}/logs`,
                        name: "Logs",
                        hideExpandArrow: true
                    }
                ]
            } else if (parentItemIdSplit.length === 3 && parentItemIdSplit[0] === "syrx-controllers" && parentItemIdSplit[2] === "devices") {
                const syrxControllerId = parentItemIdSplit[1];
                const bacnetDevices = await getSyrxControllerBacnetDevices(syrxControllerId, false);
                const treeItems = bacnetDevices.map(getTreeItemForSyrxControllerBacnetDevice);
                return treeItems;
            } else if (parentItemIdSplit.length === 3 && parentItemIdSplit[0] === "syrx-controllers" && parentItemIdSplit[2] === "points") {
                const syrxControllerId = parentItemIdSplit[1];
                const bacnetPoints = await getSyrxControllerBacnetPoints(syrxControllerId, false);
                const treeItems = bacnetPoints.map(getTreeItemForSyrxControllerBacnetPoint);
                return treeItems;
            } else if (parentItemIdSplit.length === 4 && parentItemIdSplit[0] === "syrx-controllers" && parentItemIdSplit[2] === "devices") {
                const syrxControllerId = parentItemIdSplit[1];
                const syrxControllerBacnetDeviceId = parentItemIdSplit[3];
                return [
                    {
                        id: `syrx-controllers/${syrxControllerId}/devices/${syrxControllerBacnetDeviceId}/points`,
                        name: "Points"
                    }
                ]
            } else if (parentItemIdSplit.length === 5 && parentItemIdSplit[0] === "syrx-controllers" && parentItemIdSplit[2] === "devices" && parentItemIdSplit[4] === "points") {
                const syrxControllerId = parentItemIdSplit[1];
                const syrxControllerBacnetDeviceId = parentItemIdSplit[3];
                const bacnetPoints = await getSyrxControllerBacnetPointsByDevice(syrxControllerId, syrxControllerBacnetDeviceId, false);
                const treeItems = bacnetPoints.map(getTreeItemForSyrxControllerBacnetPoint);
                return treeItems;
            }
        }

        return [];
    }, [rootGroupId]);

    const treeItemSelected = useCallback(itemId => {
        setSelectedTreeItemId(itemId);
        const itemIdSplit = itemId.split("/");
        if (itemIdSplit.length === 2 && itemIdSplit[0] === "syrx-controllers") {
            const syrxControllerId = itemIdSplit[1];
            onSyrxControllerSelected(syrxControllerId);
        } else if (itemIdSplit.length === 3 && itemIdSplit[0] === "syrx-controllers" && itemIdSplit[2] === "logs") {
            const syrxControllerId = itemIdSplit[1];
            onSyrxControllerLogsSelected(syrxControllerId);
        } else if (itemIdSplit.length === 3 && itemIdSplit[0] === "syrx-controllers" && itemIdSplit[2] === "devices") {
            const syrxControllerId = itemIdSplit[1];
            onSyrxControllerDevicesSelected(syrxControllerId);
        } else if (itemIdSplit.length === 4 && itemIdSplit[0] === "syrx-controllers" && itemIdSplit[2] === "devices") {
            const syrxControllerId = itemIdSplit[1];
            const syrxControllerBacnetDeviceId = itemIdSplit[3];
            onSyrxControllerDeviceSelected(syrxControllerId, syrxControllerBacnetDeviceId);
        } else if (itemIdSplit.length === 5 && itemIdSplit[0] === "syrx-controllers" && itemIdSplit[2] === "devices" && itemIdSplit[4] === "points") {
            const syrxControllerId = itemIdSplit[1];
            const syrxControllerBacnetDeviceId = itemIdSplit[3];
            onSyrxControllerDevicePointsSelected(syrxControllerId, syrxControllerBacnetDeviceId);
        } else if (itemIdSplit.length === 3 && itemIdSplit[0] === "syrx-controllers" && itemIdSplit[2] === "points") {
            const syrxControllerId = itemIdSplit[1];
            onSyrxControllerPointsSelected(syrxControllerId);
        } else if (itemIdSplit.length === 4 && itemIdSplit[0] === "syrx-controllers" && itemIdSplit[2] === "services") {
            const syrxControllerId = itemIdSplit[1];
            const serviceId = itemIdSplit[3];
            onSyrxControllerEnabledServiceSelected(syrxControllerId, serviceId);
        } else if (itemIdSplit.length === 3 && itemIdSplit[0] === "syrx-controllers" && itemIdSplit[2] === "services") {
            const syrxControllerId = itemIdSplit[1];
            onSyrxControllerServicesSelected(syrxControllerId);
        } else if (itemIdSplit.length === 5 && itemIdSplit[0] === "syrx-controllers" && itemIdSplit[2] === "services" && itemIdSplit[4] === "logs") {
            const syrxControllerId = itemIdSplit[1];
            const serviceId = itemIdSplit[3];
            onSyrxControllerEnabledServiceLogsSelected(syrxControllerId, serviceId);
        }
    }, [
        onSyrxControllerSelected,
        onSyrxControllerLogsSelected,
        onSyrxControllerServicesSelected,
        onSyrxControllerEnabledServiceSelected,
        onSyrxControllerDevicesSelected,
        onSyrxControllerDeviceSelected,
        onSyrxControllerDevicePointsSelected,
        onSyrxControllerPointsSelected,
        onSyrxControllerEnabledServiceLogsSelected
    ]);

    return showTree ? (
        <Tree
            getChildren={getTreeItems}
            onSelect={treeItemSelected}
            selected={selectedTreeItemId}
            renderTreeElement={renderTreeElementFunction}
        />
    ) : null;
};