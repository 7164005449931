import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { purchasingActionCreators } from '../../actionCreators/purchasingActionCreators';
import { usePurchasingSelector } from '../purchasing/purchasingSlice';

import { PurchasingNavBar } from './purchasingNavBar';
import { PurchasingProductsTable } from './purchasingProductsTable';
import { PurchasingSummary } from './purchasingSummary';
import { SyrxControllerNodeCategory, Product } from './types';

export const PurchasingSyrxControllerTable: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const cart = usePurchasingSelector(x => x.syrxControllerSizeCart);
    const syrxControllerSizes = usePurchasingSelector(x => x.syrxControllerSizes);

    const onSubmit = useCallback((product: Product) => {
        dispatch(purchasingActionCreators.updateCartSyrxController(product));
    }, [])

    const changePage = useCallback(async (direction) => {
        dispatch(purchasingActionCreators.changePage(direction));
    }, [dispatch]);

    return (
        <PurchasingProductsTable
            products={syrxControllerSizes}
            initialValues={cart}
            onSubmit={onSubmit}
            showPreviousPageButton={false}
            showNextPageButton={true}
            onChangePage={changePage}
        />
    )
};

export const PurchasingSyrxControllerNodeCategoryProductsTable: React.FunctionComponent<{syrxControllerNodeCategory: SyrxControllerNodeCategory}> = ({syrxControllerNodeCategory}) => {
    const dispatch = useDispatch();
    const cart = usePurchasingSelector(x => x.syrxControllerNodeTypeCart);
    const syrxControllerNodeTypes = usePurchasingSelector(x => x.syrxControllerNodeTypes);
    const products = syrxControllerNodeTypes[syrxControllerNodeCategory.id];

    const onSubmit = useCallback((product: Product) => {
        dispatch(purchasingActionCreators.updateCartProduct(syrxControllerNodeCategory.id, product));
    }, []);

    const changePage = useCallback(async (direction) => {
        dispatch(purchasingActionCreators.changePage(direction));
    }, [dispatch]);

    return (
        <PurchasingProductsTable
            products={products}
            initialValues={cart[syrxControllerNodeCategory.id]}
            onSubmit={onSubmit}
            showPreviousPageButton={true}
            showNextPageButton={true}
            onChangePage={changePage}
        />
    )
};

export const Purchasing: React.FunctionComponent = () => {
    const { currentPage, syrxControllerNodeCategories } = usePurchasingSelector(x => x);
    const dispatch = useDispatch();

    useEffect(() => {
        async function fetchData() {
            dispatch(purchasingActionCreators.getSyrxControllerSizes());
            dispatch(purchasingActionCreators.getSyrxControllerNodeTypes());
        }

        fetchData();
    }, [dispatch]);

    return (
        <>
            <PurchasingNavBar />
            {currentPage.id === 'syrxController' && <PurchasingSyrxControllerTable />}
            {syrxControllerNodeCategories.map(syrxControllerNodeCategory => (
                currentPage.id === syrxControllerNodeCategory.id && <PurchasingSyrxControllerNodeCategoryProductsTable key={syrxControllerNodeCategory.id} syrxControllerNodeCategory={syrxControllerNodeCategory}/>
            ))}
            {currentPage.id === 'costSummary' && <PurchasingSummary />}
        </>
    )
}
