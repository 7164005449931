import React, { CSSProperties } from 'react'
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { useAuthSessionSelector } from '../authSession/authSessionSlice';

import { BuildingMeterAccountsGrid } from './buildingMeterAccountsGrid';
import { BuildingMeterAccountsDetailsModal } from './buildingMeterAccountsDetailsModal';

const addButtonContainer: CSSProperties = {
    display: "flex",
    justifyContent: "flex-end",
    padding: 15
};

export const BuildingMeterAccountsList: React.FunctionComponent = () => {
    const currentGroup = useAuthSessionSelector(state => state.currentGroup);

    return (
        <>
            <div style={addButtonContainer}>
                <Link to="/admin/groups/accounts/editor">
                    <Button variant="outline-secondary">Add Account</Button>
                </Link>
            </div>
            <BuildingMeterAccountsGrid rootGroupId={currentGroup.id} />
            <BuildingMeterAccountsDetailsModal rootGroupId={currentGroup.id} />
        </>
    )
}