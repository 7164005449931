import { Dispatch } from "redux";
import { registerSyrxController } from '../services/syrxRegistrationService';
import {syrxControllerRegistrationActions} from '../features/syrxControllerRegistration/syrxControllerRegistrationSlice';


export const submitSyrxRegistrationActionCreator = (syrxControllerId: string, password: string) => async (dispatch: Dispatch<any>) => {
    dispatch(syrxControllerRegistrationActions.submit({syrxControllerId}));

    try {
        await registerSyrxController(syrxControllerId, password);
        dispatch(syrxControllerRegistrationActions.success());
    } catch (e) {
        dispatch(syrxControllerRegistrationActions.error(e));
    }
};