import React, {useCallback, useMemo} from 'react'
import {Button, Col, Row} from 'react-bootstrap';
import {moveVendorPointDataNewJobActions, QueueEntry} from './moveVendorPointDataNewJobSlice';
import {useDispatch} from 'react-redux';

export const MoveVendorPointDataNewJobSummaryTableRow: React.FunctionComponent<{queueEntry: QueueEntry}> = ({queueEntry}) => {
    const dispatch = useDispatch();


    const removeEntry = useCallback(() => {
        dispatch(moveVendorPointDataNewJobActions.removeFromQueue({id: queueEntry.id}));
    }, [dispatch, queueEntry]);


    const fullTimeRangeInputValue = useMemo(() => queueEntry.fullTimeRange ? "true" : "false", [queueEntry.fullTimeRange]);
    const remapEquipmentPointsInputValue = useMemo(() => queueEntry.remapEquipmentPoints ? "true" : "false", [queueEntry.remapEquipmentPoints]);

    const updateFullTimeRangeOnChangeCallback = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
        const fullTimeRange = e.target.value === "true";
        dispatch(moveVendorPointDataNewJobActions.updateQueueEntryFullTimeRange({id: queueEntry.id, fullTimeRange}));
    }, [dispatch, queueEntry.id]);

    const updateStartDateOnChangeCallback = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const startDate = e.target.value;
        dispatch(moveVendorPointDataNewJobActions.updateQueueEntryStartDate({id: queueEntry.id, startDate}));
    }, [dispatch, queueEntry.id]);

    const updateEndDateOnChangeCallback = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const endDate = e.target.value;
        dispatch(moveVendorPointDataNewJobActions.updateQueueEntryEndDate({id: queueEntry.id, endDate}));
    }, [dispatch, queueEntry.id]);

    const updateSourceRecordHandlingOnChangeCallback = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
        const sourceRecordHandling = e.target.value as "copy" | "move";
        dispatch(moveVendorPointDataNewJobActions.updateQueueEntrySourceRecordHandling({id: queueEntry.id, sourceRecordHandling}));
    }, [dispatch, queueEntry.id]);

    const updateRemapEquipmentPointsOnChangeCallback = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
        const remapEquipmentPoints = e.target.value === "true";
        dispatch(moveVendorPointDataNewJobActions.updateQueueEntryRemapEquipmentPoints({id: queueEntry.id, remapEquipmentPoints}));
    }, [dispatch, queueEntry.id]);

    return (
        <tr>
            <td><div className="form-control-plaintext">{queueEntry.sourcePointName}</div></td>
            <td><div className="form-control-plaintext">{queueEntry.destinationPointName}</div></td>
            <td>
                <Row>
                    <Col md={12}>
                        <select className="form-control" value={fullTimeRangeInputValue} onChange={updateFullTimeRangeOnChangeCallback}>
                            <option value="true">All records</option>
                            <option value="false">Date range</option>
                        </select>
                    </Col>
                </Row>
                {!queueEntry.fullTimeRange ? (
                    <Row>
                        <Col md={6}>
                            <input type="date" className="form-control" value={queueEntry.startDate} onChange={updateStartDateOnChangeCallback} />
                        </Col>
                        <Col md={6}>
                            <input type="date" className="form-control" value={queueEntry.endDate} onChange={updateEndDateOnChangeCallback} />
                        </Col>
                    </Row>
                ) : null}
            </td>
            <td>
                <select className="form-control" value={queueEntry.sourceRecordHandling} onChange={updateSourceRecordHandlingOnChangeCallback}>
                    <option value="copy">Preserve source records</option>
                    <option value="move">Delete source records</option>
                </select>
            </td>
            <td>
                <select className="form-control" value={remapEquipmentPointsInputValue} onChange={updateRemapEquipmentPointsOnChangeCallback}>
                    <option value="true">Remap</option>
                    <option value="false">Do not remap</option>
                </select>
            </td>
            <td>
                <Button variant="danger" onClick={removeEntry}>Remove</Button>
            </td>
        </tr>
    );
};