import {CaseReducer, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {buildSubStateSelector} from '../../../../store/utilities/useAppStateSelector';
import {Role} from '../../../../models/role';

const initialState = {
    isLoading: false,
    isDoneLoading: false,
    isSubmitting: false,
    isDoneSubmitting: false,
    formInitialValues: null as Role | null
};

export type AppStateRoleEditor = typeof initialState;

export const slice = createSlice<AppStateRoleEditor, {
    startLoading: CaseReducer<AppStateRoleEditor>;
    setInitialFormValues: CaseReducer<AppStateRoleEditor, PayloadAction<{formInitialValues: Role | null}>>;
    finishLoading: CaseReducer<AppStateRoleEditor>;
    startSubmitting: CaseReducer<AppStateRoleEditor>;
    finishSubmitting: CaseReducer<AppStateRoleEditor>;
    editorUnload: CaseReducer<AppStateRoleEditor>;
}>({
    name: "roleEditor",
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
            state.isDoneLoading = false;
            state.isSubmitting = false;
            state.isDoneSubmitting = false;
        },
        setInitialFormValues(state, {payload}) {
            state.formInitialValues = payload.formInitialValues;
        },
        finishLoading(state) {
            state.isLoading = false;
            state.isDoneLoading = true;
            state.isSubmitting = false;
            state.isDoneSubmitting = false;
        },
        startSubmitting(state) {
            state.isSubmitting = true;
            state.isDoneSubmitting = false;
        },
        finishSubmitting(state) {
            state.isSubmitting = false;
            state.isDoneSubmitting = true;
        },
        editorUnload(state) {
            state.isLoading = false;
            state.isDoneLoading = false;
            state.isSubmitting = false;
            state.isDoneSubmitting = false;
            state.formInitialValues = null;
        }
    }
});

export const useRoleEditorSelector = buildSubStateSelector(state => state.roleEditor);
export const roleEditorActions = slice.actions;
export const roleEditorReducer = slice.reducer;