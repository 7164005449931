import { Dispatch } from "redux";
import { NewApiServices } from "../newApiServices";
import { buildingMeterAccountsEditorActions } from '../features/buildingMeterAccounts/buildingMeterAccountsEditorSlice';
import { BuildingMeterAccount, Account } from '../features/buildingMeterAccounts/types';

export const buildingMeterAccountsEditorActionCreators = {
    submitNewBuilingMeterAccountActionCreator: (groupId: number, buildingMeterAccount: BuildingMeterAccount) => async (dispatch: Dispatch<any>) => {
        dispatch(buildingMeterAccountsEditorActions.submit(buildingMeterAccount));

        try {
            await NewApiServices.buildingMeterAccountsService.createNewBuildingMeterAccount(groupId, buildingMeterAccount);

            dispatch(buildingMeterAccountsEditorActions.success());
        } catch (e) {
            dispatch(buildingMeterAccountsEditorActions.error({ exception: e }));
            return;
        }
    },
    getBuildingMeterAccountByIdActionCreator: (accountId: string) => async (dispatch: Dispatch<any>) => {
        const buildingMeterAccount: Account = await NewApiServices.buildingMeterAccountsService.getBuildingMeterAccountById(accountId);
        dispatch(buildingMeterAccountsEditorActions.setBuildingMeterAccount(buildingMeterAccount));
    },
    editBuilingMeterAccountActionCreator: (accountId: string, groupId: number, buildingMeterAccount: BuildingMeterAccount) => async (dispatch: Dispatch<any>) => {
        dispatch(buildingMeterAccountsEditorActions.submit(buildingMeterAccount));

        try {
            await NewApiServices.buildingMeterAccountsService.editBuildingMeterAccount(accountId, groupId, buildingMeterAccount);

            dispatch(buildingMeterAccountsEditorActions.success());
        } catch (e) {
            dispatch(buildingMeterAccountsEditorActions.error({ exception: e }));
            return;
        }
    },
    getWeatherStationsActionCreator: () => async (dispatch: Dispatch<any>) => {
        const weatherStations = await NewApiServices.buildingMeterAccountsService.getWeatherStations();
        dispatch(buildingMeterAccountsEditorActions.setWeatherStations(weatherStations));
    },
    getTimeZonesActionCreator: () => async (dispatch: Dispatch<any>) => {
        const timeZones = await NewApiServices.buildingMeterAccountsService.getTimeZones();
        dispatch(buildingMeterAccountsEditorActions.setTimeZones(timeZones));
    }
}
