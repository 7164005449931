import React, {useCallback, useEffect, useRef, useState} from 'react';
import {EventEmitter} from 'events';
import {Terminal} from './terminal';

export interface SocketTerminalProps {
    socket: SocketIOClient.Socket;
    agentId: string;
}

export const SocketTerminal: React.FunctionComponent<SocketTerminalProps> = ({agentId, socket}) => {
    const {current: writeEmitter} = useRef(new EventEmitter());
    const terminalIdRef = useRef(null as string | null);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        function onDataHandler(message: { terminalId: string, data: any }) {

            const {data} = message;
            writeEmitter.emit("data", data);
        }

        socket
            .on("server/client/data", onDataHandler)
            .emit("client/server/connect", {agentId}, (message: { terminalId?: string, error?: any }) => {
                if (message.error != null) {
                    throw new Error(message.error);
                }

                if (message.terminalId == null) {
                    throw new Error("Terminal id not returned");
                }

                const {terminalId} = message;
                terminalIdRef.current = terminalId;
                setIsLoaded(true);
            });

        return () => {
            socket
                .off("server/client/data", onDataHandler)
                .emit("client/server/disconnect", {terminalId: terminalIdRef.current });

            terminalIdRef.current = null;
            setIsLoaded(false);
        }
    }, [socket, writeEmitter, agentId]);

    const onKey = useCallback(e => {
        socket.emit("client/server/data", {terminalId: terminalIdRef.current, data: e.key});
    }, [socket]);

    const onResize = useCallback((dimensions: {cols: number, rows: number}) => {
        socket.emit("client/server/resize", {terminalId: terminalIdRef.current, dimensions});
    }, [socket]);

    return isLoaded ? (
        <Terminal
            writeEmitter={writeEmitter}
            onKey={onKey}
            onResize={onResize}
        />
    ) : null;
}