import {CaseReducer, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {buildSubStateSelector} from '../../../../store/utilities/useAppStateSelector';

const initialState = {
    isLoading: false,
    isSelectedPointUpdating: false,
    isDoneLoading: false,
    isSubmitted: false,
    oatSensorSyrxControllerBacnetPointId: null as string | null
};

export type AppStateSyrxControllerBuildingPressureControlServiceOatSensorEditor = typeof initialState;

export const slice = createSlice<AppStateSyrxControllerBuildingPressureControlServiceOatSensorEditor, {
    startLoading: CaseReducer<AppStateSyrxControllerBuildingPressureControlServiceOatSensorEditor>;
    setOatSensorSyrxControllerBacnetPointId: CaseReducer<AppStateSyrxControllerBuildingPressureControlServiceOatSensorEditor, PayloadAction<string | null>>;
    finishLoading: CaseReducer<AppStateSyrxControllerBuildingPressureControlServiceOatSensorEditor>;
    startSubmitting: CaseReducer<AppStateSyrxControllerBuildingPressureControlServiceOatSensorEditor>;
    finishSubmitting: CaseReducer<AppStateSyrxControllerBuildingPressureControlServiceOatSensorEditor>;
    resetState: CaseReducer<AppStateSyrxControllerBuildingPressureControlServiceOatSensorEditor>;
    startSelectedPointUpdating: CaseReducer<AppStateSyrxControllerBuildingPressureControlServiceOatSensorEditor>;
    finishSelectedPointUpdating: CaseReducer<AppStateSyrxControllerBuildingPressureControlServiceOatSensorEditor>;
}>({
    name: "syrxControllerBuildingPressureControlServiceOatSensorEditor",
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
            state.isDoneLoading = false;
        },
        setOatSensorSyrxControllerBacnetPointId(state, {payload: oatSensorSyrxControllerBacnetPointId}) {
            state.oatSensorSyrxControllerBacnetPointId = oatSensorSyrxControllerBacnetPointId;
        },
        finishLoading(state) {
            state.isLoading = false;
            state.isDoneLoading = true;
        },
        startSubmitting(state) {
            state.isLoading = true;
        },
        finishSubmitting(state) {
            state.isLoading = false;
            state.isSubmitted = true;
        },
        resetState(state) {
            state.oatSensorSyrxControllerBacnetPointId = null;
            state.isLoading = false;
            state.isSelectedPointUpdating = false;
            state.isDoneLoading = false;
            state.isSubmitted = false;
        },
        startSelectedPointUpdating(state) {
            state.isSelectedPointUpdating = true;
        },
        finishSelectedPointUpdating(state) {
            state.isSelectedPointUpdating = false;
        }
    }
});

export const useSyrxControllerBuildingPressureControlServiceOatSensorEditorSelector = buildSubStateSelector(state => state.syrxControllerBuildingPressureControlServiceOatSensorEditor);
export const syrxControllerBuildingPressureControlServiceOatSensorEditorActions = slice.actions;
export const syrxControllerBuildingPressureControlServiceOatSensorEditorReducer = slice.reducer;