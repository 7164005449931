import {CaseReducer, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {SyrxControllerServiceType} from '../../../models/syrxControllerServiceType';
import {buildSubStateSelector} from '../../../store/utilities/useAppStateSelector';

const initialState = {
    isLoading: false,
    isDoneLoading: false,
    syrxControllerServiceTypes: null as SyrxControllerServiceType[] | null
};

export type AppStateSyrxControllerServicesManagerList = typeof initialState;

export const slice = createSlice<AppStateSyrxControllerServicesManagerList, {
    startLoading: CaseReducer<AppStateSyrxControllerServicesManagerList>;
    setSyrxControllerServiceTypes: CaseReducer<AppStateSyrxControllerServicesManagerList, PayloadAction<SyrxControllerServiceType[] | null>>;
    finishLoading: CaseReducer<AppStateSyrxControllerServicesManagerList>;
    resetState: CaseReducer<AppStateSyrxControllerServicesManagerList>;
}>({
    name: "syrxControllerServicesManagerList",
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
            state.isDoneLoading = false;
        },
        setSyrxControllerServiceTypes(state, {payload: syrxControllerServiceTypes}) {
            state.syrxControllerServiceTypes = syrxControllerServiceTypes;
        },
        finishLoading(state) {
            state.isLoading = false;
            state.isDoneLoading = true;
        },
        resetState(state) {
            state.isLoading = false;
            state.isDoneLoading = false;
            state.syrxControllerServiceTypes = null;
        }
    }
});

export const useSyrxControllerServicesManagerListSelector = buildSubStateSelector(state => state.syrxControllerServicesManagerList);
export const syrxControllerServicesManagerListActions = slice.actions;
export const syrxControllerServicesManagerListReducer = slice.reducer;