import { BaseApiService } from "./baseApiService";
import { BuildingMeterAccount } from '../features/buildingMeterAccounts/types';

export class BuildingMeterAccountsService extends BaseApiService {
    async getBuildingMeterAccountsByGroupId(groupId: number | null) {
        const res = await this.axios.get(`/accounts/for-group/${groupId}`);
        return res.data;
    }
    async createNewBuildingMeterAccount(groupId: number, buildingMeterAccount: BuildingMeterAccount) {
        const body = {
            group_id: groupId,
            name: buildingMeterAccount.name,
            num: buildingMeterAccount.accountNumber,
            type: buildingMeterAccount.type,
            weatherstation_id: buildingMeterAccount.weatherStationId,
            timezone: buildingMeterAccount.timezone,
            initial_size_normalization: buildingMeterAccount.buildingSquareFootage,
            initial_price_normalization: buildingMeterAccount.utilityCostPerUnit
        }
        return await this.axios.post('/accounts', body);
    }
    async getBuildingMeterAccountById(accountId: string) {
        const res = await this.axios.get(`/accounts/${accountId}`);
        const buildingMeterAccount = {
            id: res.data.id,
            name: res.data.name,
            accountNumber: res.data.num,
            type: res.data.type,
            weatherStationId: res.data.weatherstation_id,
            timezone: res.data.timezone
        }
        return buildingMeterAccount;
    }
    async editBuildingMeterAccount(accountId: string, groupId: number, buildingMeterAccount: BuildingMeterAccount) {
        const body = {
            id: accountId,
            group_id: groupId,
            name: buildingMeterAccount.name,
            num: buildingMeterAccount.accountNumber,
            type: buildingMeterAccount.type,
            weatherstation_id: buildingMeterAccount.weatherStationId,
            timezone: buildingMeterAccount.timezone
        }
        return await this.axios.put(`/accounts/${accountId}`, body);
    }
    async getWeatherStations() {
        const res = await this.axios.get('/weatherstations');
        return res.data;
    }
    async getTimeZones() {
        const res = await this.axios.get('/timezones');
        return res.data;
    }
    async createNewBuildingMeterAccountSizeEntry(accountId: number, size: number, effectiveDate: Date) {
        const res = await this.axios.post(`/account-size-normalizations`, {
            account_id: accountId,
            value: size,
            effective_date: effectiveDate,
            note: ''
        });
        return res.data;
    }
    async createNewBuildingMeterAccountUtilityCostEntry(accountId: number, cost: number, effectiveDate: Date) {
        const res = await this.axios.post(`/account-price-normalizations`, {
            account_id: accountId,
            value: cost,
            effective_date: effectiveDate,
            note: ''
        });
        return res.data;
    }
    async deleteBuildingMeterAccount(accountId: number) {
        const res = await this.axios.delete(`/accounts/${accountId}`);
        return res.data;
    }
}
