import {CaseReducer, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {buildSubStateSelector} from '../../store/utilities/useAppStateSelector';

const initialState = {
    isAttemptingLogin: false,
    isSuccessful: false,
    isInvalidCredentials: false,
    isUnknownError: false,
    errorMessage: ''
};

export type AppStateLogin = typeof initialState;

export const slice = createSlice<AppStateLogin, {
    submitLogin: CaseReducer<AppStateLogin, PayloadAction<{username: string}>>;
    loginSuccessful: CaseReducer<AppStateLogin>;
    invalidCredentials: CaseReducer<AppStateLogin, PayloadAction<{errorMessage: string}>>;
    unknownError: CaseReducer<AppStateLogin, PayloadAction<{exception: any}>>;
}>({
    name: "login",
    initialState,
    reducers: {
        submitLogin(state) {
            state.isAttemptingLogin = true;
            state.isSuccessful = false;
            state.isInvalidCredentials = false;
            state.isUnknownError = false;
            state.errorMessage = '';
        },
        loginSuccessful(state) {
            state.isAttemptingLogin = false;
            state.isSuccessful = true;
            state.isInvalidCredentials = false;
            state.isUnknownError = false;
            state.errorMessage = '';
        },
        invalidCredentials(state, {payload}) {
            state.isAttemptingLogin = false;
            state.isSuccessful = false;
            state.isInvalidCredentials = true;
            state.isUnknownError = false;
            state.errorMessage = payload.errorMessage;
        },
        unknownError(state) {
            state.isAttemptingLogin = false;
            state.isSuccessful = false;
            state.isInvalidCredentials = false;
            state.isUnknownError = true;
            state.errorMessage = '';
        }
    }
});

export const useLoginSelector = buildSubStateSelector(state => state.login);
export const loginActions = slice.actions;
export const loginReducer = slice.reducer;