import { Dispatch } from "redux";
import {groupRoleEditorActions} from './groupRoleEditorSlice';
import {NewApiServices} from '../../../../newApiServices';
import {GroupRoleEditorForm} from './groupRoleEditorForm';

export const groupRoleEditorActionCreators = {
    loadGroupRoleEditor: (groupRoleId: string | null) => async (dispatch: Dispatch<any>) => {
        dispatch(groupRoleEditorActions.startLoading());

        if (groupRoleId != null) {
            const groupRole = await NewApiServices.groupRolesService.getGroupRoleById(groupRoleId);
            const groupPermissions = await NewApiServices.groupRolesService.getGroupPermissionsForGroupRoleById(groupRoleId);

            dispatch(groupRoleEditorActions.setInitialFormValues({
                formInitialValues: {
                    groupRole,
                    permissions: groupPermissions
                } as GroupRoleEditorForm
            }));
        } else {
            dispatch(groupRoleEditorActions.setInitialFormValues({
                formInitialValues: {
                    groupRole: {},
                    permissions: [] as string[]
                } as GroupRoleEditorForm
            }));
        }

        dispatch(groupRoleEditorActions.finishLoading());
    },

    submitGroupRoleEditor: (values: GroupRoleEditorForm) => async (dispatch: Dispatch<any>) => {
        dispatch(groupRoleEditorActions.startSubmitting());
        const savedGroupRole = await NewApiServices.groupRolesService.saveGroupRole(values.groupRole);
        await NewApiServices.groupRolesService.saveGroupPermissionsForGroupRole(savedGroupRole.id, values.permissions);

        dispatch(groupRoleEditorActions.finishSubmitting());
    }
}