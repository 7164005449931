import React, {useCallback, useEffect, useMemo} from 'react'
import {useDispatch} from 'react-redux';
import semver from 'semver';
import {LoadingSpinner} from '../../loadingSpinner/LoadingSpinner';
import {Redirect} from 'react-router';
import {Field, Form as ReactFinalForm} from 'react-final-form';
import {Button, Col, Form, FormGroup, Row} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import {syrxControllerServiceVersionEditorActionCreators} from './syrxControllerServiceVersionEditorActionCreators';
import {
    syrxControllerServiceVersionEditorActions,
    useSyrxControllerServiceVersionEditorSelector
} from './syrxControllerServiceVersionEditorSlice';
import {SyrxControllerServiceArtifactAdapter} from './syrxControllerServiceArtifactAdapter';
import {toast} from 'react-toastify';
import {SyrxControllerServiceTypeAdapter} from './syrxControllerServiceTypeAdapter';

interface FormValues {
    service_id: string | null;
    version_name: string;
    syrx_controller_service_artifact_id: string | null;
}

export const SyrxControllerServiceVersionEditor: React.FunctionComponent<{ serviceId: string | null, versionName: string | null }> = ({serviceId, versionName}) => {
    const dispatch = useDispatch();
    const isLoading = useSyrxControllerServiceVersionEditorSelector(x => x.isLoading);
    const isDoneLoading = useSyrxControllerServiceVersionEditorSelector(x => x.isDoneLoading);
    const isSubmitted = useSyrxControllerServiceVersionEditorSelector(x => x.isSubmitted);
    const loadedSyrxControllerServiceVersion = useSyrxControllerServiceVersionEditorSelector(x => x.syrxControllerServiceVersion);

    useEffect(() => {
        dispatch(syrxControllerServiceVersionEditorActionCreators.load(serviceId, versionName));
        return () => {
            dispatch(syrxControllerServiceVersionEditorActions.resetState());
        }
    }, [dispatch, serviceId, versionName]);

    const submit = useCallback((values: FormValues) => {
        let hasErrors = false;

        if (values.service_id == null) {
            hasErrors = true;
            toast.error("A value must be supplied for Service");
        }

        if (values.version_name == null || values.version_name.length === 0) {
            hasErrors = true;
            toast.error("A value must be supplied for Version");
        }

        const semverVersion = semver.valid(values.version_name);
        if (semverVersion == null) {
            hasErrors = true;
            toast.error("Provided Version is not a valid semver version");
        }

        if (values.syrx_controller_service_artifact_id == null) {
            hasErrors = true;
            toast.error("A value must be supplied for Artifact");
        }

        if (hasErrors) {
            return;
        }

        const model = {
            service_id: values.service_id!,
            version_name: semverVersion!,
            syrx_controller_service_artifact_id: values.syrx_controller_service_artifact_id!
        }

        if (loadedSyrxControllerServiceVersion == null) {
            dispatch(syrxControllerServiceVersionEditorActionCreators.submitCreate(model));
        } else {
            dispatch(syrxControllerServiceVersionEditorActionCreators.submitUpdate(model));
        }
    }, [dispatch, loadedSyrxControllerServiceVersion]);

    const initialValues = useMemo<FormValues | undefined>(() => ({
        service_id: loadedSyrxControllerServiceVersion?.service_id ?? serviceId,
        version_name: loadedSyrxControllerServiceVersion?.version_name ?? versionName ?? "",
        syrx_controller_service_artifact_id: loadedSyrxControllerServiceVersion?.syrx_controller_service_artifact_id ?? null
    }), [loadedSyrxControllerServiceVersion, serviceId, versionName]);

    return (
        <>
            {isLoading ? <LoadingSpinner /> : null}
            {isDoneLoading ? (
                <ReactFinalForm onSubmit={submit} initialValues={initialValues}>
                    {({handleSubmit, values}) => (
                        <Form onSubmit={handleSubmit}>
                            <h4>Edit version</h4>
                            <fieldset>
                                <FormGroup as={Row}>
                                    <Form.Label column md={6} lg={4}>Service</Form.Label>
                                    <Col md={6}>
                                        {loadedSyrxControllerServiceVersion != null ? (
                                            <div className="form-text">
                                                {loadedSyrxControllerServiceVersion.service_id}
                                            </div>
                                        ) : null}
                                        {loadedSyrxControllerServiceVersion == null ? (
                                            <SyrxControllerServiceTypeAdapter name="service_id" />
                                        ) : null}
                                    </Col>
                                </FormGroup>
                                <FormGroup as={Row}>
                                    <Form.Label column md={6} lg={4}>Version</Form.Label>
                                    <Col md={6}>
                                        {loadedSyrxControllerServiceVersion != null ? (
                                            <div className="form-text">
                                                {loadedSyrxControllerServiceVersion.version_name}
                                            </div>
                                        ) : null}
                                        {loadedSyrxControllerServiceVersion == null ? (
                                            <Field
                                                name="version_name"
                                                component="input"
                                                className="form-control"
                                            />
                                        ) : null}
                                    </Col>
                                </FormGroup>
                                <FormGroup as={Row}>
                                    <Form.Label column md={6} lg={4}>Artifact</Form.Label>
                                    <Col md={6}>
                                        <SyrxControllerServiceArtifactAdapter serviceId={values.service_id} name="syrx_controller_service_artifact_id" />
                                    </Col>
                                </FormGroup>
                            </fieldset>
                            <Button variant="primary" type="submit">Submit</Button>
                            <LinkContainer to={`/admin/syrx-controller-services`} exact={true}><Button variant="outline-secondary">Cancel</Button></LinkContainer>
                        </Form>
                    )}
                </ReactFinalForm>
            ) : null}
            {isSubmitted ? <Redirect to={`/admin/syrx-controller-services`} /> : null}
        </>
    )
};