import React, {useCallback, useMemo} from 'react'
import {useField} from 'react-final-form';

export const PermissionCheckboxRow: React.FunctionComponent<{name: string, label: string, selectedPermissions: string[], onChange: (name: string, isChecked: boolean) => any}> = React.memo(({name, label, selectedPermissions, onChange}) => {
    const isChecked = useMemo(() => selectedPermissions.includes(name), [selectedPermissions, name]);
    return (
        <li>
            <label style={{fontWeight: "normal"}}><input type="checkbox" checked={isChecked} onChange={e => onChange(name, e.target.checked)} /> {label}</label>
        </li>
    )
});

export const RolePermissionSelectorAdapter: React.FunctionComponent<{name: string}> = ({name}) => {
    const field = useField<string[]>(name);
    const {value: selectedPermissions, onChange} = field.input;

    const onCheckboxChange = useCallback((name: string, isSelected: boolean) => {
        const newValue = [
            ...selectedPermissions.filter(x => x !== name),
            ...(isSelected ? [name] : [])
        ];
        onChange(newValue);
    }, [selectedPermissions, onChange])

    return (
        <table className="table">
            <thead>
            <tr>
                <th>Admin</th>
                <th>Report data</th>
                <th>Commissioning</th>
                <th>Reporting</th>
            </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <ul style={{listStyleType: "none", paddingLeft: 0}}>
                            <PermissionCheckboxRow name="users_manage" label="Manage users" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="roles_manage" label="Manage roles" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="weatherstations_manage" label="Manage weatherstations" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="groups_manage" label="Manage groups" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="groups_allow_all" label="Allow access to all groups" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="accounts_manage" label="Manage accounts" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="components_manage" label="Manage components" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="meeting_types_manage" label="Manage meeting types" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="utility_companies_manage" label="Manage utility companies" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="action_item_field_options_manage" label="Manage action item field options" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="task_field_options_manage" label="Manage task field options" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="issue_field_options_manage" label="Manage issue field options" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="contracts_manage" label="Manage contracts" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="syrx_categories_manage" label="Manage SYRX categories" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="contact_categories_manage" label="Manage contact categories" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="reset_schedules_manage" label="Manage reset schedules" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="functions_manage" label="Manage functions" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="indexes_manage" label="Manage indexes" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="equipment_audit_config_manage" label="Manage equipment audit report templates" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="nightly_process_logs_view" label="View nightly process logs" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="binary_point_values_manage" label="Manage binary point values" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="audit_log_view" label="View audit log" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="view_disabled_groups" label="View disabled groups" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="pcs_admin" label="PCS admin" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                        </ul>
                    </td>
                    <td>
                        <ul style={{listStyleType: "none", paddingLeft: 0}}>
                            <PermissionCheckboxRow name="group_data_view" label="View group data" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="group_data_upload" label="Upload group data" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="vendor_data_view" label="View vendor data" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="move_vendor_point_records" label="Move vendor point records" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                        </ul>
                    </td>
                    <td>
                        <ul style={{listStyleType: "none", paddingLeft: 0}}>
                            <PermissionCheckboxRow name="equipment_manage" label="Manage equipment" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="contacts_manage" label="Manage contacts" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="tasks_manage" label="Manage tasks" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="issues_manage" label="Manage issues" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="projects_manage" label="Manage projects" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="ecos_manage" label="Manage ECOs" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="committees_manage" label="Manage committees" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="meetings_manage" label="Manage meetings" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                        </ul>
                    </td>
                    <td>
                        <ul style={{listStyleType: "none", paddingLeft: 0}}>
                            <PermissionCheckboxRow name="reporting_main_dashboard" label="Main dashboard reporting" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="reporting_groups" label="Run group reports" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="reporting_naics" label="Run NAICS reports" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="reporting_sic" label="Run SIC reports" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="reporting_equipment_syrx" label="Run equipment SYRX reports" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="reporting_equipment_energy" label="Run equipment energy reports" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="reporting_equipment_audit" label="Run equipment audit reports" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="reporting_equipment_issues" label="Run equipment issues reports" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="reporting_equipment_tasks" label="Run equipment tasks reports" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                            <PermissionCheckboxRow name="reporting_indexes" label="Allow use of reporting indexes" selectedPermissions={selectedPermissions} onChange={onCheckboxChange} />
                        </ul>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}