import React, {useCallback, useMemo} from 'react'
import {
    SyrxControllerBuildingPressureControlServiceBasicConfiguration,
} from '../../../models/syrxControllerBuildingPressureControlServiceConfiguration';
import {Button, Col, Form, FormGroup, Row} from 'react-bootstrap';
import {Field, Form as ReactFinalForm} from 'react-final-form';
import {LinkContainer} from 'react-router-bootstrap';

export interface SyrxControllerBuildingPressureControlServiceBasicConfigurationTableProps {
    syrxControllerBuildingPressureControlServiceBasicConfiguration: SyrxControllerBuildingPressureControlServiceBasicConfiguration | null;
    editMode?: boolean;
    onSubmit?: (syrxControllerPressureBasicConfiguration: SyrxControllerBuildingPressureControlServiceBasicConfiguration) => void;
    cancelLinkTo?: string;
}

const EditMode: React.FunctionComponent<SyrxControllerBuildingPressureControlServiceBasicConfigurationTableProps> = props => {
    const {
        syrxControllerBuildingPressureControlServiceBasicConfiguration,
        onSubmit,
        cancelLinkTo
    } = props;

    const initialValues = useMemo(() => ({
        ...(syrxControllerBuildingPressureControlServiceBasicConfiguration ?? {}),
        neutral_plane_height: syrxControllerBuildingPressureControlServiceBasicConfiguration?.neutral_plane_height?.toString() ?? "",
        is_output_enabled: syrxControllerBuildingPressureControlServiceBasicConfiguration?.is_output_enabled ?? false
    }), [syrxControllerBuildingPressureControlServiceBasicConfiguration])

    const submit = useCallback((values: {neutral_plane_height: string, is_output_enabled: boolean}) => {
        if (onSubmit != null) {
            const neutralPlaneHeight = parseFloat(values?.neutral_plane_height?.toString() ?? ""); // this cast is necessary because
            const isNumeric = !isNaN(neutralPlaneHeight) && isFinite(neutralPlaneHeight);
            onSubmit({neutral_plane_height: isNumeric ? neutralPlaneHeight : null, is_output_enabled: values.is_output_enabled});
        }
    }, [onSubmit]);

    return (
        <ReactFinalForm onSubmit={submit} initialValues={initialValues}>
            {({handleSubmit}) => (
                <Form onSubmit={handleSubmit}>
                    <fieldset>
                        <FormGroup as={Row}>
                            <Form.Label column md={6} lg={4}>Neutral plane height (ft)</Form.Label>
                            <Col md={6}>
                                <Field
                                    name="neutral_plane_height"
                                    component="input"
                                    type="number"
                                    className="form-control"
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup as={Row}>
                            <Form.Label column md={6} lg={4}>Enable output</Form.Label>
                            <Col md={6}>
                                <Field
                                    name="is_output_enabled"
                                    component="input"
                                    type="checkbox"
                                    className="form-control"
                                />
                            </Col>
                        </FormGroup>
                    </fieldset>
                    <Button variant="primary" type="submit">Submit</Button>
                    {cancelLinkTo != null ? (<LinkContainer to={cancelLinkTo} exact={true}><Button
                        variant="outline-secondary">Cancel</Button></LinkContainer>) : null}
                </Form>
            )}
        </ReactFinalForm>
    );
};

const DisplayMode: React.FunctionComponent<SyrxControllerBuildingPressureControlServiceBasicConfigurationTableProps> = props => {
    const {
        syrxControllerBuildingPressureControlServiceBasicConfiguration
    } = props;

    return (
        <table className="table">
            <tbody>
            <tr>
                <th>Neutral plane height (ft)</th>
                <td>{syrxControllerBuildingPressureControlServiceBasicConfiguration?.neutral_plane_height ?? "n/a"}</td>
            </tr>
            <tr>
                <th>Enable output</th>
                <td>{syrxControllerBuildingPressureControlServiceBasicConfiguration == null ? "n/a" : (syrxControllerBuildingPressureControlServiceBasicConfiguration.is_output_enabled ? "Yes" : "No")}</td>
            </tr>
            </tbody>
        </table>
    );
};

export const SyrxControllerBuildingPressureControlServiceBasicConfigurationTable: React.FunctionComponent<SyrxControllerBuildingPressureControlServiceBasicConfigurationTableProps> = props => {
    const {
        editMode
    } = props;

    return editMode ? (
        <EditMode {...props} />
    ) : (
        <DisplayMode {...props} />
    )
};