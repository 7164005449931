import React, { useEffect, CSSProperties, useCallback } from 'react'
import { useDispatch } from 'react-redux';
import { useBuildingMeterAccountsGridSelector } from './buildingMeterAccountsGridSlice';
import { buildingMeterAccountsActionCreators } from '../../actionCreators/buildingMeterAccountsActionCreators';

const flexContainer: CSSProperties = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between"
};

const accountContainer: CSSProperties = {
    borderStyle: "solid",
    borderRadius: "7px",
    borderWidth: "1px",
    borderColor: "#707070",
    flex: "0 49%",
    marginBottom: "1em"
};

const headerContainer: CSSProperties = {
    display: "flex",
    justifyContent: "space-between",
    background: "linear-gradient(180deg, #7C7C7C 0%, #7B7B7B 51%, #A0A0A0 100%)",
    borderRadius: "5px 5px 0px 0px"
};

const headerLeft: CSSProperties = {
    color: "#FFFFFF",
    fontSize: 20,
    padding: "5 0 5 10"
};

const headerRight: CSSProperties = {
    color: "#FFFFFF",
    fontSize: 20,
    padding: "5 10 5 0"
};

const detailsContainer: CSSProperties = {
    display: "flex",
    justifyContent: "space-between",
    margin: '5 10 5 10'
};

const detailsColumn: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: "space-between"
};

const detailsText: CSSProperties = {
    color: "#707070",
    fontSize: 14
};

const detailsTextBold: CSSProperties = {
    color: "#707070",
    fontSize: 14,
    fontWeight: 'bold'
};

export interface buildingMeterAccountsGridProps {
    rootGroupId: number;
}

export const BuildingMeterAccountsGrid: React.FunctionComponent<buildingMeterAccountsGridProps> = props => {
    const { buildingMeterAccounts } = useBuildingMeterAccountsGridSelector(x => x);
    const dispatch = useDispatch();
    const groupId = props.rootGroupId;

    useEffect(() => {
        async function fetchData() {
            dispatch(buildingMeterAccountsActionCreators.getBuildingMeterAccountsByGroupId(groupId));
        }

        fetchData();
    }, [dispatch, groupId]);

    const showDetailsModal = useCallback(async (key: number) => {
        const selectedAccount = buildingMeterAccounts.filter(x => x.id === key)[0];
        dispatch(buildingMeterAccountsActionCreators.showModal(selectedAccount));
    }, [dispatch, buildingMeterAccounts]);

    return (
        <div style={flexContainer}>
            {buildingMeterAccounts.map((buildingMeterAccount) => {
                return (
                    <div style={accountContainer} key={buildingMeterAccount.id} onClick={() => showDetailsModal(buildingMeterAccount.id)}>
                        <div style={headerContainer}>
                            <div style={headerLeft}>{buildingMeterAccount.name}</div>
                            <div style={headerRight}>{buildingMeterAccount.accountNumber}</div>
                        </div>
                        <div style={detailsContainer}>
                            <div style={detailsColumn}>
                                <div style={detailsText}>
                                    {buildingMeterAccount.type === 'electric' ? 'Electric Account' : 'Gas Account'}
                                </div>
                                <div style={detailsText}>
                                    {buildingMeterAccount.weatherStation?.name}
                                </div>
                                <div style={detailsTextBold}>
                                    {new Intl.NumberFormat().format((buildingMeterAccount.buildingSizeHistory ?? [])[0]?.value)} sqft
                                </div>
                            </div>
                            <div style={detailsColumn}>
                                <div style={detailsText}>
                                    {buildingMeterAccount.timezone}
                                </div>
                                <div style={detailsTextBold}>
                                    ${(buildingMeterAccount.utilityCostHistory ?? [])[0]?.value} / kWh
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}