import { CaseReducer, createSlice } from '@reduxjs/toolkit';
import { buildSubStateSelector } from '../../store/utilities/useAppStateSelector';

const initialState = {
    refreshTrigger: null as any
};

export type AppStateProjects = typeof initialState;

export const slice = createSlice<AppStateProjects, {
    triggerRefresh: CaseReducer<AppStateProjects>;
}>({
    name: "projects",
    initialState,
    reducers: {
        triggerRefresh(state) {
            state.refreshTrigger = {};
        }
    }
});

export const useProjectsSelector = buildSubStateSelector(state => state.projects);
export const projectsActions = slice.actions;
export const projectsReducer = slice.reducer;