import { Dispatch } from "redux";
import {
    reloadPropertiesForDevice,
    ignoreDevice,
    unignoreDevice,
    reloadPointsForDevice,
    createDiscoverDevicesSyrxControllerBacnetNetworkQueryServiceMessage
} from '../services/syrxControllersService';
import {syrxControllerBacnetDevicesGridActions} from '../features/syrxControllers/bacnetNetworkQueryService/syrxControllerBacnetDevicesGridSlice';

export const discoverDevicesActionCreator = (syrxControllerId: string) => async (dispatch: Dispatch<any>) => {
    dispatch(syrxControllerBacnetDevicesGridActions.setIsUpdating(true));
    await createDiscoverDevicesSyrxControllerBacnetNetworkQueryServiceMessage(syrxControllerId);
    dispatch(syrxControllerBacnetDevicesGridActions.triggerRefresh());
    dispatch(syrxControllerBacnetDevicesGridActions.setIsUpdating(false));
};

export const reloadPropertiesForDeviceActionCreator = (syrxControllerId: string, syrxControllerBacnetDeviceId: string) => async (dispatch: Dispatch<any>) => {
    dispatch(syrxControllerBacnetDevicesGridActions.setIsUpdating(true));
    await reloadPropertiesForDevice(syrxControllerId, syrxControllerBacnetDeviceId);
    dispatch(syrxControllerBacnetDevicesGridActions.triggerRefresh());
    dispatch(syrxControllerBacnetDevicesGridActions.setIsUpdating(false));
};

export const ignoreDeviceActionCreator = (syrxControllerId: string, syrxControllerBacnetDeviceId: string) => async (dispatch: Dispatch<any>) => {
    dispatch(syrxControllerBacnetDevicesGridActions.setIsUpdating(true));
    await ignoreDevice(syrxControllerId, syrxControllerBacnetDeviceId);
    dispatch(syrxControllerBacnetDevicesGridActions.triggerRefresh());
    dispatch(syrxControllerBacnetDevicesGridActions.setIsUpdating(false));
};

export const unignoreDeviceActionCreator = (syrxControllerId: string, syrxControllerBacnetDeviceId: string) => async (dispatch: Dispatch<any>) => {
    dispatch(syrxControllerBacnetDevicesGridActions.setIsUpdating(true));
    await unignoreDevice(syrxControllerId, syrxControllerBacnetDeviceId);
    dispatch(syrxControllerBacnetDevicesGridActions.triggerRefresh());
    dispatch(syrxControllerBacnetDevicesGridActions.setIsUpdating(false));
};

export const reloadPointsForDeviceActionCreator = (syrxControllerId: string, syrxControllerBacnetDeviceId: string) => async (dispatch: Dispatch<any>) => {
    dispatch(syrxControllerBacnetDevicesGridActions.setIsUpdating(true));
    await reloadPointsForDevice(syrxControllerId, syrxControllerBacnetDeviceId);
    dispatch(syrxControllerBacnetDevicesGridActions.triggerRefresh());
    dispatch(syrxControllerBacnetDevicesGridActions.setIsUpdating(false));
};