import React from 'react'
import {Nav} from "react-bootstrap";
import { logout } from '../../services/authService';
import {legacyAppUrl} from '../../config';


const performLogoutAndRedirect = async () => {
    await logout();
    window.location.href = legacyAppUrl;
}

export const LogoutLink = () => {
    return (
        <Nav.Link className="nav-link" onClick={performLogoutAndRedirect}>Logout</Nav.Link>
    );
};