import { Dispatch } from "redux";
import {roleEditorActions} from './roleEditorSlice';
import {Role} from '../../../../models/role';
import {NewApiServices} from '../../../../newApiServices';

export const roleEditorActionCreators = {
    loadRoleEditor: (roleId: number | null) => async (dispatch: Dispatch<any>) => {
        dispatch(roleEditorActions.startLoading());

        if (roleId != null) {
            const role = await NewApiServices.rolesService.getRoleById(roleId);

            dispatch(roleEditorActions.setInitialFormValues({
                formInitialValues: role
            }));
        } else {
            dispatch(roleEditorActions.setInitialFormValues({
                formInitialValues: {
                    name: "",
                    permissions: [] as string[]
                } as Role
            }));
        }

        dispatch(roleEditorActions.finishLoading());
    },

    submitRoleEditor: (values: Role) => async (dispatch: Dispatch<any>) => {
        dispatch(roleEditorActions.startSubmitting());
        await NewApiServices.rolesService.saveRole(values);

        dispatch(roleEditorActions.finishSubmitting());
    }
}