import React from 'react'
import {MoveVendorPointDataJobsListGrid} from './moveVendorPointDataJobsListGrid';
import {Button} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';

export const MoveVendorPointDataJobsList: React.FunctionComponent = () => {
    return (
        <>
            <h3>Move vendor point data jobs list</h3>
            <LinkContainer to={"/admin/move-vendor-point-data/new"}><Button>New</Button></LinkContainer>
            <MoveVendorPointDataJobsListGrid />
        </>
    );
};