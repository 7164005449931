import { Dispatch } from "redux";
import { NewApiServices } from "../newApiServices";
import { buildingMeterAccountsGridActions } from "../features/buildingMeterAccounts/buildingMeterAccountsGridSlice";
import { DatabaseAccount, Account } from '../features/buildingMeterAccounts/types';

export const buildingMeterAccountsActionCreators = {
    getBuildingMeterAccountsByGroupId: (groupId: number) => async (dispatch: Dispatch<any>) => {
        const res = await NewApiServices.buildingMeterAccountsService.getBuildingMeterAccountsByGroupId(groupId);
        const buildingMeterAccounts = res.map((buildingMeterAccount: DatabaseAccount) => {
            return {
                id: buildingMeterAccount.id,
                name: buildingMeterAccount.name,
                accountNumber: buildingMeterAccount.num,
                type: buildingMeterAccount.type,
                weatherStationId: buildingMeterAccount.weatherstation_id,
                timezone: buildingMeterAccount.timezone,
                weatherStation: buildingMeterAccount.weatherstation,
                buildingSizeHistory: buildingMeterAccount.account_size_normalizations,
                utilityCostHistory: buildingMeterAccount.account_price_normalizations
            }
        })
        dispatch(buildingMeterAccountsGridActions.setBuildingMeterAccounts(buildingMeterAccounts));
    },
    showModal: (selectedAccount: Account) => async (dispatch: Dispatch<any>) => {
        dispatch(buildingMeterAccountsGridActions.showModal(selectedAccount));
    },
    hideModal: () => async (dispatch: Dispatch<any>) => {
        dispatch(buildingMeterAccountsGridActions.hideModal());
    },
    createNewBuildingMeterAccountSizeEntry: (groupId: number, id: number, size: number, effectiveDate: Date) => async (dispatch: Dispatch<any>) => {
        await NewApiServices.buildingMeterAccountsService.createNewBuildingMeterAccountSizeEntry(id, size, effectiveDate);
        await dispatch(buildingMeterAccountsActionCreators.getBuildingMeterAccountsByGroupId(groupId));
        dispatch(buildingMeterAccountsGridActions.hideModal());
    },
    createNewBuildingMeterAccountUtilityCostEntry: (groupId: number, id: number, cost: number, effectiveDate: Date) => async (dispatch: Dispatch<any>) => {
        await NewApiServices.buildingMeterAccountsService.createNewBuildingMeterAccountUtilityCostEntry(id, cost, effectiveDate);
        await dispatch(buildingMeterAccountsActionCreators.getBuildingMeterAccountsByGroupId(groupId));
        dispatch(buildingMeterAccountsGridActions.hideModal());
    },
    deleteBuildingMeterAccount: (groupId: number, accountId: number) => async (dispatch: Dispatch<any>) => {
        await NewApiServices.buildingMeterAccountsService.deleteBuildingMeterAccount(accountId);
        await dispatch(buildingMeterAccountsActionCreators.getBuildingMeterAccountsByGroupId(groupId));
        dispatch(buildingMeterAccountsGridActions.hideModal());
    }
}
