import React from 'react'
import { Switch, Route, Redirect } from 'react-router';
import qs from 'qs';

import { AuthenticatedRedirect } from '../authenticatedRedirect/AuthenticatedRedirect';
import { Home } from '../home/Home';
import { Login } from '../login/Login';
import { UserRegistration } from '../userRegistration/UserRegistration';
import { SyrxControllerRegistration } from '../syrxControllerRegistration/SyrxControllerRegistration';
import { SyrxControllerRegistrationRedirect } from '../syrxControllerRegistration/SyrxControllerRegistrationRedirect';
import { SyrxControllersList } from '../syrxControllers/syrxControllersList';
import {SyrxControllersEditor} from '../syrxControllers/editor/syrxControllersEditor';
import {SyrxControllerEnabledService} from '../syrxControllers/enabledServices/syrxControllerEnabledService';
import {SyrxControllerServiceLogs} from '../syrxControllers/logs/syrxControllerServiceLogs';
import {useAuthSessionSelector} from '../authSession/authSessionSlice';
import {SyrxControllerBacnetDeviceEditor} from '../syrxControllers/bacnetNetworkQueryService/syrxControllerBacnetDeviceEditor';
import {SyrxControllerManager} from '../syrxControllers/manager/syrxControllerManager';
import {JobsList} from '../jobs/jobsList';
import {JobLogs} from '../jobs/jobLogs';
import {MoveVendorPointDataNewJob} from '../vendorPoints/moveVendorPointData/newJob/moveVendorPointDataNewJob';
import {MoveVendorPointDataJobsList} from '../vendorPoints/moveVendorPointData/jobsList/moveVendorPointDataJobsList';
import {SyrxControllerServicesManagerList} from '../syrxControllerServices/list/syrxControllerServicesManagerList';
import {SyrxControllerServiceVersionEditor} from '../syrxControllerServices/versionEditor/syrxControllerServiceVersionEditor';
import {RolesList} from '../admin/roles/rolesList/rolesList';
import {RoleEditor} from '../admin/roles/editor/roleEditor';
import {GroupRolesList} from '../admin/groupRoles/list/groupRolesList';
import {GroupRoleEditor} from '../admin/groupRoles/editor/groupRoleEditor';
import {BuildingMeterAccountsList} from '../buildingMeterAccounts/buildingMeterAccountsList';
import {BuildingMeterAccountsEditor} from '../buildingMeterAccounts/buildingMeterAccountsEditor';
import {Purchasing} from '../purchasing/purchasing';
import {ProjectsList} from '../projects/projectsList';
import {ProjectEditor} from '../projects/projectEditor';
import {ProjectSchedule} from '../projects/projectSchedule';
import {EquipmentEditor} from '../equipment/equipmentEditor';

export const Router = () => {
    const user = useAuthSessionSelector(state => state.user);
    const isAuthenticated = user != null;

    return (
        <div style={{padding: "0 15px"}}>
            <Switch>
                {/* authenticated routes */}
                {isAuthenticated ? <Route exact path="/home" render={() => <Home />} /> : null}
                {isAuthenticated ? <Route exact path="/register-syrx-controller" render={() => <SyrxControllerRegistration />} /> : null}
                {isAuthenticated ? <Route exact path="/admin/group-roles" render={() => <GroupRolesList />} /> : null}
                {isAuthenticated ? <Route exact path="/admin/group-roles/new" render={() => <GroupRoleEditor groupRoleId={null} />} /> : null}
                {isAuthenticated ? <Route exact path="/admin/group-roles/:groupRoleId" render={({match}) => <GroupRoleEditor groupRoleId={match.params.groupRoleId} />} /> : null}
                {isAuthenticated ? <Route exact path="/admin/roles" render={() => <RolesList />} /> : null}
                {isAuthenticated ? <Route exact path="/admin/roles/new" render={() => <RoleEditor roleId={null} />} /> : null}
                {isAuthenticated ? <Route exact path="/admin/roles/:roleId" render={({match}) => <RoleEditor roleId={match.params.roleId} />} /> : null}
                {isAuthenticated ? <Route path="/admin/syrx-controllers" render={() => <SyrxControllerManager />} /> : null}
                {isAuthenticated ? <Route exact path="/admin/syrx-controllers-old/:syrxControllerId?" render={() => <SyrxControllersList />} /> : null}
                {isAuthenticated ? <Route exact path="/admin/syrx-controllers-old/:syrxControllerId/edit" render={() => <SyrxControllersEditor />} /> : null}
                {isAuthenticated ? <Route exact path="/admin/syrx-controllers-old/:syrxControllerId/services/:serviceId" render={() => <SyrxControllerEnabledService />} /> : null}
                {isAuthenticated ? <Route exact path="/admin/syrx-controllers-old/:syrxControllerId/services/:serviceId/logs" render={() => <SyrxControllerServiceLogs />} /> : null}
                {isAuthenticated ? <Route exact path="/admin/syrx-controllers-old/:syrxControllerId/services/Pathian.BACnetNetworkQueryService/devices/add" render={() => <SyrxControllerBacnetDeviceEditor />} /> : null}
                {isAuthenticated ? <Route exact path="/admin/syrx-controller-services" render={() => <SyrxControllerServicesManagerList />} /> : null}
                {isAuthenticated ? <Route exact path="/admin/syrx-controller-service-versions/new" render={({location}) => {
                    const qsWithoutLeadingQuestionMark = location.search.substr(1);
                    const queryObject = qs.parse(qsWithoutLeadingQuestionMark);
                    return (
                        <SyrxControllerServiceVersionEditor serviceId={queryObject.serviceId} versionName={null} />
                    )
                }} /> : null}
                {isAuthenticated ? <Route exact path="/admin/syrx-controller-services/:serviceId/versions/:versionName" render={({match}) => {
                    const {serviceId, versionName} = match.params;
                    return (
                        <SyrxControllerServiceVersionEditor serviceId={serviceId} versionName={versionName} />
                    )
                }} /> : null}
                {isAuthenticated ? <Route exact path="/admin/jobs" render={() => <JobsList />} /> : null}
                {isAuthenticated ? <Route exact path="/admin/jobs/:jobId/logs" render={() => <JobLogs />} /> : null}
                {isAuthenticated ? <Route exact path="/admin/move-vendor-point-data" render={() => <MoveVendorPointDataJobsList />} /> : null}
                {isAuthenticated ? <Route exact path="/admin/move-vendor-point-data/new" render={() => <MoveVendorPointDataNewJob />} /> : null}
                {isAuthenticated ? <Route exact path="/admin/groups/accounts" render={() => <BuildingMeterAccountsList />} /> : null}
                {isAuthenticated ? <Route exact path="/admin/groups/accounts/editor" render={() => <BuildingMeterAccountsEditor />} /> : null}
                {isAuthenticated ? <Route exact path="/admin/groups/accounts/editor/:id" render={() => <BuildingMeterAccountsEditor />} /> : null}
                {isAuthenticated ? <Route exact path="/purchasing" render={() => <Purchasing />} /> : null}
                {isAuthenticated ? <Route exact path="/projects" render={() => <ProjectsList />} /> : null}
                {isAuthenticated ? <Route exact path="/project/editor" render={() => <ProjectEditor />} /> : null}
                {isAuthenticated ? <Route exact path="/project/editor/:id" render={() => <ProjectEditor />} /> : null}
                {isAuthenticated ? <Route exact path="/project/schedule/:id" render={() => <ProjectSchedule />} /> : null}
                {isAuthenticated ? <Route exact path="/commissioning/equipment/editor" render={() => <EquipmentEditor />} /> : null}

                {/* unauthenticated routes */}
                {!isAuthenticated ? <Route exact path="/login" render={() => <Login />} /> : null}
                {!isAuthenticated ? <Route exact path="/login/:token" render={() => <Login />} /> : null}
                {!isAuthenticated ? <Route exact path="/user-registration" render={() => <UserRegistration />} /> : null}
                {!isAuthenticated ? <Route exact path="/register-syrx-controller" render={() => <SyrxControllerRegistrationRedirect />} /> : null}
                
                {/* redirects */}
                {isAuthenticated ? <Route render={() => <AuthenticatedRedirect />} /> : null}
                {!isAuthenticated ? <Redirect to="/login" /> : null}
            </Switch>
        </div>
    );
}
