import React from 'react'
import {Col, Tabs, Tab} from 'react-bootstrap';

import {SyrxControllerDiagnosticServiceRabbitmqMetricsQueues} from './syrxControllerDiagnosticServiceRabbitmqMetricsQueues';
import {SyrxControllerDiagnosticServiceRabbitmqMetricsChannels} from './syrxControllerDiagnosticServiceRabbitmqMetricsChannels';
import {SyrxControllerDiagnosticServiceRabbitmqMetricsConnections} from './syrxControllerDiagnosticServiceRabbitmqMetricsConnections';

export const SyrxControllerDiagnosticServiceRabbitmqMetrics = () => {
    return (
        <>
            <Col md={12} style={{marginTop: "1rem"}}>
                <Tabs defaultActiveKey="queues" id="SyrxControllerDiagnosticServiceRabbitmqMetrics">
                    <Tab eventKey="queues" title="Queues">
                        <SyrxControllerDiagnosticServiceRabbitmqMetricsQueues />
                    </Tab>
                    <Tab eventKey="channels" title="Channels">
                        <SyrxControllerDiagnosticServiceRabbitmqMetricsChannels />
                    </Tab>
                    <Tab eventKey="connections" title="Connections">
                        <SyrxControllerDiagnosticServiceRabbitmqMetricsConnections />
                    </Tab>
                </Tabs>
            </Col>
        </>
    )
};