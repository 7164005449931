import { Dispatch } from "redux";
import { toast } from "react-toastify";

import { NewApiServices } from "../newApiServices";
import { projectEditorActions } from "../features/projects/projectEditorSlice";
import { Project } from "../features/projects/types";

export const projectEditorActionCreators = {
    get: (projectId: number) => async (dispatch: Dispatch<any>) => {
        const project = await NewApiServices.projectsService.getOne(projectId);
        dispatch(projectEditorActions.setProject(project));
    },
    create: (project: Partial<Project>) => async (dispatch: Dispatch<any>) => {
        try {
            await NewApiServices.projectsService.create(project);
            dispatch(projectEditorActions.success());
        } catch(err) {
            toast.error('Project creation failed. Please try again later.');
            dispatch(projectEditorActions.error(err));
        }
    },
    update: (project: Project) => async (dispatch: Dispatch<any>) => {
        try {
            await NewApiServices.projectsService.update(project);
            dispatch(projectEditorActions.success());
        } catch(err) {
            toast.error('Project update failed. Please try again later.');
            dispatch(projectEditorActions.error(err));
        }
    },
    getContacts: (groupId: number) => async (dispatch: Dispatch<any>) => {
        const contacts = await NewApiServices.contactsService.getAllByGroupId(groupId);
        dispatch(projectEditorActions.setContacts(contacts));
    },
    reset: () => async (dispatch: Dispatch<any>) => {
        dispatch(projectEditorActions.reset());
    }
}