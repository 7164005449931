import React, { useEffect } from 'react'
import { Form, FormGroup, Row, Col, Button } from 'react-bootstrap';
import { Redirect } from 'react-router';
import {Field, Form as ReactFinalForm} from 'react-final-form';
import { LinkContainer } from 'react-router-bootstrap';
import { useDispatch } from 'react-redux';

import {groupRoleEditorActions, useGroupRoleEditorSelector} from './groupRoleEditorSlice';
import {groupRoleEditorActionCreators} from './groupRoleEditorActionCreators';
import {LoadingSpinner} from '../../../loadingSpinner/LoadingSpinner';
import {GroupRolePermissionSelectorAdapter} from './groupRolePermissionSelectorAdapter';
import {GroupRoleEditorForm} from './groupRoleEditorForm';

export const GroupRoleEditor: React.FunctionComponent<{groupRoleId: string | null}> = ({groupRoleId}) => {
    const dispatch = useDispatch();

    const isLoading = useGroupRoleEditorSelector(state => state.isLoading);
    const isDoneLoading = useGroupRoleEditorSelector(state => state.isDoneLoading);
    const isSubmitting = useGroupRoleEditorSelector(state => state.isSubmitting);
    const isDoneSubmitting = useGroupRoleEditorSelector(state => state.isDoneSubmitting);
    const formInitialValues = useGroupRoleEditorSelector(state => state.formInitialValues);

    useEffect(() => {
        dispatch(groupRoleEditorActionCreators.loadGroupRoleEditor(groupRoleId));

        return () => {
            dispatch(groupRoleEditorActions.editorUnload());
        }
    }, [dispatch, groupRoleId]);

    const submit = (values: GroupRoleEditorForm) => {
        dispatch(groupRoleEditorActionCreators.submitGroupRoleEditor(values));
    }

    if (isLoading) {
        return <LoadingSpinner />
    }

    return isDoneLoading && formInitialValues != null ? (
        <ReactFinalForm onSubmit={submit} initialValues={formInitialValues}>
            {({handleSubmit}) => (
                <>
                    {isSubmitting ? <LoadingSpinner /> : null}
                    {isDoneSubmitting ? <Redirect to={`/admin/group-roles`} /> : null}
                    <Form onSubmit={handleSubmit}>
                        <fieldset>
                            <legend>{groupRoleId != null ? "Edit" : "Create"} group role</legend>
                            <FormGroup as={Row}>
                                <Form.Label column lg={2}>Name</Form.Label>
                                <Col lg={6}>
                                    <Field
                                        name="groupRole.name"
                                        component="input"
                                        type="text"
                                        className="form-control"
                                        required
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup as={Row}>
                                <Form.Label column lg={2}>Description</Form.Label>
                                <Col lg={6}>
                                    <Field
                                        name="groupRole.description"
                                        component="input"
                                        type="text"
                                        className="form-control"
                                        required
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup as={Row}>
                                <Form.Label column lg={2}>Permissions</Form.Label>
                                <Col lg={10}>
                                    <GroupRolePermissionSelectorAdapter name="permissions" />
                                </Col>
                            </FormGroup>
                        </fieldset>
                        <FormGroup as={Row}>
                            <Form.Label column lg={2} />
                            <Col lg={4}>
                                <Button type="submit" variant="primary">Submit</Button>
                                <LinkContainer to={`/admin/group-roles`}><Button type="submit" variant="secondary">Cancel</Button></LinkContainer>
                            </Col>
                        </FormGroup>
                    </Form>
                </>
            )}
        </ReactFinalForm>
    ) : null
}