import {CaseReducer, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {buildSubStateSelector} from '../../../store/utilities/useAppStateSelector';
import {MetricsAggregatesWithStringDate} from '../../../models/metricsAggregates';

export type ChartData = {
    metrics: MetricsAggregatesWithStringDate,
    labels: string[]
} | null;

export type GroupType = "overall" | "process" | "systemd_service";

const initialState = {
    isUpdating: false,
    chartData: null as ChartData,
    startDate: null as string | null,
    endDate: null as string | null,
    availableGroups: [] as string[],
    selectedGroups: [] as string[],
    groupType: "overall" as GroupType,
    resolution: "15s",
    metric: "mem_percent",
    aggregation: "avg"
};
export type AppStateSyrxControllerDiagnosticServiceMetrics = typeof initialState;

export const slice = createSlice<AppStateSyrxControllerDiagnosticServiceMetrics, {
    setIsUpdating: CaseReducer<AppStateSyrxControllerDiagnosticServiceMetrics, PayloadAction<boolean>>;
    setChartData: CaseReducer<AppStateSyrxControllerDiagnosticServiceMetrics, PayloadAction<ChartData>>;
    setAvailableGroups: CaseReducer<AppStateSyrxControllerDiagnosticServiceMetrics, PayloadAction<string[]>>;
    setSelectedGroups: CaseReducer<AppStateSyrxControllerDiagnosticServiceMetrics, PayloadAction<string[]>>;
    setStartDate: CaseReducer<AppStateSyrxControllerDiagnosticServiceMetrics, PayloadAction<string>>;
    setEndDate: CaseReducer<AppStateSyrxControllerDiagnosticServiceMetrics, PayloadAction<string>>;
    setGroupType: CaseReducer<AppStateSyrxControllerDiagnosticServiceMetrics, PayloadAction<GroupType>>;
    setResolution: CaseReducer<AppStateSyrxControllerDiagnosticServiceMetrics, PayloadAction<string>>;
    setMetric: CaseReducer<AppStateSyrxControllerDiagnosticServiceMetrics, PayloadAction<string>>;
    setAggregation: CaseReducer<AppStateSyrxControllerDiagnosticServiceMetrics, PayloadAction<string>>;
}>({
    name: "syrxControllerDiagnosticServiceMetrics",
    initialState,
    reducers: {
        setIsUpdating(state, {payload: isUpdating}) {
            state.isUpdating = isUpdating;
        },
        setChartData(state, {payload}) {
            state.chartData = payload;
        },
        setAvailableGroups(state, {payload}) {
            state.availableGroups = payload;
        },
        setSelectedGroups(state, {payload}) {
            state.selectedGroups = payload;
        },
        setStartDate(state, {payload}) {
            state.startDate = payload;
        },
        setEndDate(state, {payload}) {
            state.endDate = payload;
        },
        setGroupType(state, {payload}) {
            state.groupType = payload;
        },
        setResolution(state, {payload}) {
            state.resolution = payload;
        },
        setMetric(state, {payload}) {
            state.metric = payload;
        },
        setAggregation(state, {payload}) {
            state.aggregation = payload;
        }
    }
});

export const useSyrxControllerDiagnosticServiceMetricsSelector = buildSubStateSelector(state => state.syrxControllerDiagnosticServiceMetrics);
export const syrxControllerDiagnosticServiceMetricsActions = slice.actions;
export const syrxControllerDiagnosticServiceMetricsReducer = slice.reducer;