import React from 'react'
import {
    SyrxControllerBuildingPressureControlServiceConfiguration,
} from '../../../models/syrxControllerBuildingPressureControlServiceConfiguration';
import dayjs from 'dayjs';

const formatDate = (date: string) => dayjs(date).format("MM/DD/YYYY hh:mm:ss a");

interface SyrxControllerBuildingPressureControlServiceStatusErrorTableProps {
    exceptionContainer: {
        exception_date: string | null;
        exception_type: string | null;
        exception_message: string | null;
        exception_stack_trace: string | null;
    }
}

const SyrxControllerBuildingPressureControlServiceStatusErrorTable: React.FunctionComponent<SyrxControllerBuildingPressureControlServiceStatusErrorTableProps> = ({exceptionContainer}) => {
    return (
        <table className="table">
            <tbody>
            <tr>
                <th>Date</th>
                <td>{exceptionContainer.exception_date != null ? formatDate(exceptionContainer.exception_date) : "n/a"}</td>
            </tr>
            <tr>
                <th>Type</th>
                <td>{exceptionContainer.exception_type ?? "n/a"}</td>
            </tr>
            <tr>
                <th>Message</th>
                <td>{exceptionContainer.exception_message ?? "n/a"}</td>
            </tr>
            <tr>
                <th>Stack trace</th>
                <td><pre>{exceptionContainer.exception_stack_trace ?? "n/a"}</pre></td>
            </tr>
            </tbody>
        </table>
    )
}

export interface SyrxControllerBuildingPressureControlServiceStatusTableProps {
    syrxControllerBuildingPressureControlServiceConfiguration: SyrxControllerBuildingPressureControlServiceConfiguration | null;
}

export const SyrxControllerBuildingPressureControlServiceStatusTable: React.FunctionComponent<SyrxControllerBuildingPressureControlServiceStatusTableProps> = props => {
    const {
        syrxControllerBuildingPressureControlServiceConfiguration
    } = props;

    return (
        <table className="table">
            <tbody>
            <tr>
                <th>Last OAT value</th>
                <td>
                    {syrxControllerBuildingPressureControlServiceConfiguration?.last_oat_date != null && syrxControllerBuildingPressureControlServiceConfiguration.last_oat_value != null ? (
                        `${syrxControllerBuildingPressureControlServiceConfiguration.last_oat_value} (at ${formatDate(syrxControllerBuildingPressureControlServiceConfiguration.last_oat_date)})`
                    ) : "n/a"}
                </td>
            </tr>
            <tr>
                <th>Last calculated P_norm</th>
                <td>
                    {syrxControllerBuildingPressureControlServiceConfiguration?.last_pnorm_output_date != null && syrxControllerBuildingPressureControlServiceConfiguration.last_pnorm_output_value != null ? (
                        `${syrxControllerBuildingPressureControlServiceConfiguration.last_pnorm_output_value} (at ${formatDate(syrxControllerBuildingPressureControlServiceConfiguration.last_pnorm_output_date)})`
                    ) : "n/a"}
                </td>
            </tr>
            <tr>
                <th>P_norm output write enabled</th>
                <td>
                    {syrxControllerBuildingPressureControlServiceConfiguration?.is_output_enabled ? "Yes" : "No"}
                </td>
            </tr>
            <tr>
                <th>Last P_norm output write</th>
                <td>
                    {syrxControllerBuildingPressureControlServiceConfiguration?.last_pnorm_output_write_date != null ? (
                        formatDate(syrxControllerBuildingPressureControlServiceConfiguration.last_pnorm_output_write_date)
                    ) : "n/a"}
                </td>
            </tr>
            <tr>
                <th>Error</th>
                <td>
                    {syrxControllerBuildingPressureControlServiceConfiguration?.exception_date != null ? (
                        <div style={{maxWidth: "800px", overflowX: "auto"}}>
                            <SyrxControllerBuildingPressureControlServiceStatusErrorTable exceptionContainer={syrxControllerBuildingPressureControlServiceConfiguration} />
                        </div>
                    ) : "n/a"}
                </td>
            </tr>
            </tbody>
        </table>
    );
};

