import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { buildSubStateSelector } from '../../store/utilities/useAppStateSelector';
import { BuildingMeterAccount, Account, WeatherStation, TimeZone } from './types';

const initialState = {
    name: '',
    accountNumber: '',
    type: '',
    weatherStationId: null as number | null,
    timezone: '',
    buildingSquareFootage: null as number | null,
    utilityCostPerUnit: null as number | null,
    isSuccessful: false,
    errorMessage: '',
    weatherStations: [] as WeatherStation[],
    timeZones: [] as TimeZone[]
};

export type AppStateBuildingMeterAccoutsEditor = typeof initialState;

export const slice = createSlice<AppStateBuildingMeterAccoutsEditor, {
    submit: CaseReducer<AppStateBuildingMeterAccoutsEditor, PayloadAction<BuildingMeterAccount>>;
    success: CaseReducer<AppStateBuildingMeterAccoutsEditor>;
    error: CaseReducer<AppStateBuildingMeterAccoutsEditor, PayloadAction<{exception: any}>>;
    setBuildingMeterAccount: CaseReducer<AppStateBuildingMeterAccoutsEditor, PayloadAction<Account>>;
    setWeatherStations: CaseReducer<AppStateBuildingMeterAccoutsEditor, PayloadAction<WeatherStation[]>>;
    setTimeZones: CaseReducer<AppStateBuildingMeterAccoutsEditor, PayloadAction<TimeZone[]>>;
}>({
    name: "buildingMeterAccountsEditor",
    initialState,
    reducers: {
        submit(state, action) {
            state.name = action.payload.name;
            state.accountNumber = action.payload.accountNumber;
            state.type = action.payload.type;
            state.weatherStationId = action.payload.weatherStationId;
            state.timezone = action.payload.timezone;
            state.buildingSquareFootage = action.payload.buildingSquareFootage;
            state.utilityCostPerUnit = action.payload.utilityCostPerUnit;
        },
        success(state) {
            state.isSuccessful = true;
        },
        error(state, action) {
            state.isSuccessful = false;
            state.errorMessage = action.payload.exception;
        },
        setBuildingMeterAccount(state, action) {
            state.name = action.payload.name;
            state.accountNumber = action.payload.accountNumber;
            state.type = action.payload.type;
            state.weatherStationId = action.payload.weatherStationId;
            state.timezone = action.payload.timezone;
        },
        setWeatherStations(state, action) {
            state.weatherStations = action.payload;
        },
        setTimeZones(state, action) {
            state.timeZones = action.payload;
        }
    }
});

export const useBuildingMeterAccountsEditorSelector = buildSubStateSelector(state => state.buildingMeterAccountsEditor);
export const buildingMeterAccountsEditorActions = slice.actions;
export const buildingMeterAccountsEditorReducer = slice.reducer;