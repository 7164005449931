import React, { useEffect, useState } from 'react'
import { Row, Col, Table, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import { getSyrxControllerById, getSyrxControllerBacnetConnectionConfiguration } from '../../services/syrxControllersService';
import { SyrxController } from '../../models/syrxController';
import { SyrxControllerBacnetConnectionConfiguration } from '../../models/syrxControllerBacnetConnectionConfiguration';
import {SyrxControllerEnabledServicesGrid} from './enabledServices/syrxControllerEnabledServicesGrid';


export interface SyrxControllerDetailProps {
    syrxControllerId: string;
}

export const SyrxControllersDetail: React.FunctionComponent<SyrxControllerDetailProps> = props => {
    const { syrxControllerId } = props;
    const [syrxController, setSyrxController] = useState(null as SyrxController | null);
    const [bacnetConnectionConfiguration, setBacnetConnectionConfiguration] = useState(null as SyrxControllerBacnetConnectionConfiguration | null);

    useEffect(() => {
        async function fetchData() {
            setSyrxController(null);

            const newSyrxController = await getSyrxControllerById(syrxControllerId);
            const newBacnetConnectionConfiguration = await getSyrxControllerBacnetConnectionConfiguration(syrxControllerId);
            setSyrxController(newSyrxController);
            setBacnetConnectionConfiguration(newBacnetConnectionConfiguration);
        }

        fetchData();
    }, [syrxControllerId]);

    return syrxController != null ? (
        <>
            <h3>{syrxController.name}</h3>

            <Row>
                <Col md={6}>
                    <h4>General configuration</h4>
                    <Table>
                        <tbody>
                            <tr>
                                <td>Syrx Controller ID</td>
                                <td>{syrxController.id}</td>
                            </tr>
                            <tr>
                                <td>Group ID</td>
                                <td>{syrxController.group_id}</td>
                            </tr>
                            <tr>
                                <td>Group name</td>
                                <td>{syrxController.group_name}</td>
                            </tr>
                            <tr>
                                <td>Description</td>
                                <td>{syrxController.description}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
                <Col md={6}>
                    <h4>BACnet connection configuration</h4>
                    <Table>
                        <tbody>
                            <tr>
                                <td>Local IP address</td>
                                <td>
                                    {bacnetConnectionConfiguration?.ip_address != null ? (
                                        <span>
                                            {bacnetConnectionConfiguration.ip_address}:{bacnetConnectionConfiguration.port_num}
                                        </span>
                                    ) : null}
                                    {bacnetConnectionConfiguration?.ip_address == null ? (
                                        <span>
                                            Unconfigured
                                        </span>
                                    ) : null}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
                <Col md={6}>
                    <h4>Actions</h4>
                    <Table>
                        <tbody>
                            <tr>
                                <td>
                                    <LinkContainer to={`/admin/syrx-controllers/${syrxController.id}/edit`}><Button variant="outline-info">Edit</Button></LinkContainer>
                                    <LinkContainer to={`/admin/syrx-controllers/${syrxController.id}/delete`}><Button variant="outline-danger">Delete</Button></LinkContainer>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <h4>Enabled services</h4>
            <p>Click the manage button to view details and manage configuration.</p>
            <LinkContainer to={`/admin/syrx-controllers/${syrxController.id}/services`}><Button variant="outline-info">Manage enabled services</Button></LinkContainer>
            <SyrxControllerEnabledServicesGrid
                syrxControllerId={syrxControllerId}
            ></SyrxControllerEnabledServicesGrid>
        </>
    ) : null
}