import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { buildSubStateSelector } from '../../store/utilities/useAppStateSelector';

import { Component } from './types';

const initialState = {
    isLoading: false,
    isSuccessful: false,
    errorMessage: '',
    componentTypeaheadValues: [] as Component[],
    selectedComponentDescendents: [] as Component[]
};

export type AppStateEquipmentEditor = typeof initialState;

export const slice = createSlice<AppStateEquipmentEditor, {
    setComponentTypeaheadValues: CaseReducer<AppStateEquipmentEditor, PayloadAction<Component[]>>;
    setSelectedComponentDescendents: CaseReducer<AppStateEquipmentEditor, PayloadAction<Component[]>>;
    setSuccess: CaseReducer<AppStateEquipmentEditor>;
}>({
    name: "equipmentEditor",
    initialState,
    reducers: {
        setComponentTypeaheadValues(state, action) {
            state.componentTypeaheadValues = action.payload;
        },
        setSelectedComponentDescendents(state, action) {
            state.selectedComponentDescendents = action.payload;
        },
        setSuccess(state) {
            state.isSuccessful = true;
        }
    }
});

export const useEquipmentEditorSelector = buildSubStateSelector(state => state.equipmentEditor);
export const equipmentEditorActions = slice.actions;
export const equipmentEditorReducer = slice.reducer;