import React from 'react';
import {useDrop} from 'react-dnd';


export interface DropzoneProps {
    text: string;
    accept: string;
    id: string;
    onDrop: (dropped: any) => any;
}

export const Dropzone: React.FunctionComponent<DropzoneProps> = ({text, accept, id, onDrop}) => {
    const [{isOver, canDrop}, dropRef] = useDrop({
        accept,
        canDrop: (item: any) => item.id !== id,
        drop: (item: any) => onDrop(item),
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    });

    return (
        <div
            ref={dropRef}
            className={`${canDrop && isOver ? "item-selected" : ""}`}
            style={{
                 position: "relative"
            }}
        >
            {canDrop && !isOver ? (
                <div
                    className="item-selected"
                    style={{
                        position: "absolute",
                        height: "100%",
                        width: "100%",
                        top: 0,
                        opacity: 0.25
                    }}
                />
            ) : null}

            <div style={{position: "relative"}}>{text}</div>
        </div>
    );
}