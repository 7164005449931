import React, {useCallback, useEffect} from 'react'
import {SyrxControllerBuildingPressureControlServicePnormOutputTable} from './syrxControllerBuildingPressureControlServicePnormOutputTable';
import {useDispatch} from 'react-redux';
import {LoadingSpinner} from '../../../loadingSpinner/LoadingSpinner';
import {syrxControllerBuildingPressureControlServicePnormOutputEditorActionCreators} from './syrxControllerBuildingPressureControlServicePnormOutputEditorActionCreators';
import {Redirect} from 'react-router';
import {
    syrxControllerBuildingPressureControlServicePnormOutputEditorActions,
    useSyrxControllerBuildingPressureControlServicePnormOutputEditorSelector
} from './syrxControllerBuildingPressureControlServicePnormOutputEditorSlice';

export const SyrxControllerBuildingPressureControlServicePnormOutputEditor: React.FunctionComponent<{ syrxControllerId: string }> = ({syrxControllerId}) => {
    const dispatch = useDispatch();
    const isLoading = useSyrxControllerBuildingPressureControlServicePnormOutputEditorSelector(x => x.isLoading);
    const isDoneLoading = useSyrxControllerBuildingPressureControlServicePnormOutputEditorSelector(x => x.isDoneLoading);
    const isSubmitted = useSyrxControllerBuildingPressureControlServicePnormOutputEditorSelector(x => x.isSubmitted);
    const pnormOutputSyrxControllerBacnetPointId = useSyrxControllerBuildingPressureControlServicePnormOutputEditorSelector(x => x.pnormOutputSyrxControllerBacnetPointId);

    useEffect(() => {
        dispatch(syrxControllerBuildingPressureControlServicePnormOutputEditorActionCreators.loadConfiguration(syrxControllerId));
        return () => {
            dispatch(syrxControllerBuildingPressureControlServicePnormOutputEditorActions.resetState())
        };
    }, [dispatch, syrxControllerId]);

    const onSubmit = useCallback((pnormOutputSyrxControllerBacnetPointId: string | null) => {
        dispatch(syrxControllerBuildingPressureControlServicePnormOutputEditorActionCreators.submit(syrxControllerId, pnormOutputSyrxControllerBacnetPointId));
    }, [dispatch, syrxControllerId]);
    return (
        <>
            {isLoading ? <LoadingSpinner /> : null}
            {isDoneLoading ? (
                <>
                    <h4>Normalized building pressure output (P_norm)</h4>
                    <SyrxControllerBuildingPressureControlServicePnormOutputTable
                        syrxControllerId={syrxControllerId}
                        pnormOutputSyrxControllerBacnetPointId={pnormOutputSyrxControllerBacnetPointId}
                        editMode={true}
                        onSubmit={onSubmit}
                        cancelLinkTo={`/admin/syrx-controllers/${syrxControllerId}/services/Pathian.Services.BuildingPressureControlService`}
                    />
                </>
            ) : null}
            {isSubmitted ? <Redirect to={`/admin/syrx-controllers/${syrxControllerId}/services/Pathian.Services.BuildingPressureControlService`} /> : null}
        </>
    )
};