import React, {useEffect, useState} from 'react';
import {useField} from 'react-final-form';
import {LoadingSpinner} from '../../loadingSpinner/LoadingSpinner';
import {SyrxControllerServiceTypesService} from '../../../services/syrxControllerServiceTypesService';
import {SyrxControllerServiceType} from '../../../models/syrxControllerServiceType';

export const SyrxControllerServiceTypeAdapter: React.FunctionComponent<{name: string}> = ({name}) => {
    const field = useField<string | null>(name);
    const {value: selectedServiceId} = field.input;

    const [isLoading, setIsLoading] = useState(false);
    const [syrxControllerServiceTypes, setSyrxControllerServiceTypes] = useState([] as SyrxControllerServiceType[]);

    useEffect(() => {
        async function doTheThing() {
            setIsLoading(true);
            try {
                const newSyrxControllerServiceTypes = await SyrxControllerServiceTypesService.getServiceTypes();
                setSyrxControllerServiceTypes(newSyrxControllerServiceTypes ?? []);
            } catch (e) {
                throw e;
            } finally {
                setIsLoading(false);
            }
        }

        void doTheThing();
    }, [setIsLoading, setSyrxControllerServiceTypes])

    return (
        <>
            {isLoading ? <LoadingSpinner /> : null}
            <select
                onChange={field.input.onChange}
                value={selectedServiceId ?? undefined}
                className="form-control"
                required
            >
                {syrxControllerServiceTypes.length === 0 ? (
                    <option>(no services available)</option>
                ) : null}
                {syrxControllerServiceTypes.length > 0 && selectedServiceId != null ? (
                    <option>(select one)</option>
                ) : null}
                {syrxControllerServiceTypes.map(syrxControllerServiceType => (
                    <option key={syrxControllerServiceType.id}>
                        {syrxControllerServiceType.id}
                    </option>
                ))}
            </select>
        </>
    );
}