import React, {useCallback, useEffect, useState} from 'react'
import {Button, Modal} from "react-bootstrap";
import {SyrxControllerBACnetPointInfoTable} from '../../bacnetPoints/syrxControllerBACnetPointInfoTable';
import {LoadingSpinner} from '../../../loadingSpinner/LoadingSpinner';
import {
    deleteBuildingPressureTransmitter,
    getBuildingPressureTransmitter
} from '../../../../services/syrxControllersService';
import {SyrxControllerBuildingPressureTransmitter} from '../../../../models/syrxControllerBuildingPressureTransmitter';

export interface DeleteSyrxControllerBuildingPressureTransmitterModalProps {
    syrxControllerId: string;
    syrxControllerBuildingPressureTransmitterId: string;
    onClose: (deleteSuccessful: boolean) => void;
}

export const DeleteSyrxControllerBuildingPressureTransmitterModal: React.FunctionComponent<DeleteSyrxControllerBuildingPressureTransmitterModalProps> = (props) => {
    const {
        syrxControllerId,
        syrxControllerBuildingPressureTransmitterId,
        onClose
    } = props;

    const [isLoaded, setIsLoaded] = useState(false);
    const [syrxControllerBuildingPressureTransmitter, setSyrxControllerBuildingPressureTransmitter] = useState(null as SyrxControllerBuildingPressureTransmitter | null);

    const doDismiss = useCallback(() => onClose(false), [onClose]);

    const doSubmit = useCallback(() => {
        async function doTheThing() {
            setIsLoaded(false);
            await deleteBuildingPressureTransmitter(syrxControllerId, syrxControllerBuildingPressureTransmitterId);
            onClose(true);
        }

        void doTheThing();
    }, [syrxControllerId, syrxControllerBuildingPressureTransmitterId, onClose]);

    useEffect(() => {
        async function doTheThing() {
            setIsLoaded(false);
            setSyrxControllerBuildingPressureTransmitter(null);
            const _syrxControllerBuildingPressureTransmitter = await getBuildingPressureTransmitter(syrxControllerId, syrxControllerBuildingPressureTransmitterId);
            if (_syrxControllerBuildingPressureTransmitter == null) {
                doDismiss();
                return;
            }
            setSyrxControllerBuildingPressureTransmitter(_syrxControllerBuildingPressureTransmitter);
            setIsLoaded(true);
        }

        void doTheThing();
    }, [syrxControllerId, syrxControllerBuildingPressureTransmitterId, doDismiss]);

    return (
        <Modal show={true} onHide={doDismiss} size="xl">
            {!isLoaded ? <LoadingSpinner /> : null}
            <Modal.Header closeButton>
                <Modal.Title>Delete Building Pressure Transmitter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {syrxControllerBuildingPressureTransmitter != null ? (
                    <>
                        <p>Delete this Building Pressure Transmitter?</p>
                        <table className="table">
                            <tbody>
                                <tr>
                                    <th>Num</th>
                                    <td>BPT-{syrxControllerBuildingPressureTransmitter.num}</td>
                                </tr>
                                <tr>
                                    <th>Name</th>
                                    <td>{syrxControllerBuildingPressureTransmitter.name}</td>
                                </tr>
                                <tr>
                                    <th>Transmitter height (ft)</th>
                                    <td>{syrxControllerBuildingPressureTransmitter.transmitter_height}</td>
                                </tr>
                                <tr>
                                    <th>BACnet point</th>
                                    <td>
                                        <SyrxControllerBACnetPointInfoTable
                                            syrxControllerId={syrxControllerId}
                                            syrxControllerBacnetPointId={syrxControllerBuildingPressureTransmitter.syrx_controller_bacnet_point_id}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                ) : null}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={doSubmit}>
                    Delete
                </Button>
                <Button variant="secondary" onClick={doDismiss}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};