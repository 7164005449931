import React, {useEffect, useState} from 'react'
import { Tree } from '@sht-dev/fancy-tree';
import {Job} from '../../models/job';
import {getChildJobByParentJobId, getJobById, getLogsForJob} from '../../services/jobsService';
import {Redirect} from 'react-router';
import {JobLog} from '../../models/jobLog';

export interface JobLogsTreeProps {
    jobId: string;
}

export const JobLogsTree: React.FunctionComponent<JobLogsTreeProps> = props => {
    const {jobId} = props;

    const [rootJob, setRootJob] = useState(undefined as Job | null | undefined);

    useEffect(() => {
        const f = async () => {
            const job = await getJobById(jobId);
            setRootJob(job);
        };

        void f();
    }, [jobId]);

    const getTreeItemForJobContainer = (job: Job) => ({
        id: `jobcontainer-${job.id}`,
        name: `${job.job_type} - ${job.status} [${job.id}]`
    });

    const getTreeItemForJobLog = (jobLog: JobLog) => ({
        id: `log-${jobLog.jobId}-${jobLog.id}`,
        name: `${jobLog.timestamp} ${jobLog.level} ${jobLog.message} ${jobLog.errorMessage != null ? "(" + jobLog.errorMessage + ")" : ""}`,
        hideExpandArrow: !jobLog.hasChildLogs
    });

    const getTreeItems = async (parentItemId: string | null) => {
        if (rootJob == null) {
            return [];
        }

        if (parentItemId == null) {
            return [getTreeItemForJobContainer(rootJob)];
        } else if (parentItemId.substr(0, 13) === "jobcontainer-") {
            const parentItemJobId = parentItemId.substr(13);

            return [
                {
                    id: `job-${parentItemJobId}`,
                    name: "Job details"
                },
                {
                    id: `logs-container-${parentItemJobId}`,
                    name: "Logs"
                },
                {
                    id: `child-jobs-container-${parentItemJobId}`,
                    name: "Child jobs"
                }
            ];
        } else if (parentItemId.substr(0, 4) === "job-") {
            const parentItemJobId = parentItemId.substr(4);
            const job = await getJobById(parentItemJobId);

            if (job == null) {
                return [];
            }

            return [
                {
                    id: `x-job-id-${parentItemJobId}`,
                    name: `id: ${job.id}`,
                    hideExpandArrow: true
                },
                {
                    id: `x-job-parent_job_id-${parentItemJobId}`,
                    name: `parent_job_id: ${job.parent_job_id}`,
                    hideExpandArrow: true
                },
                {
                    id: `x-job-sibling_job_execution_order_index-${parentItemJobId}`,
                    name: `sibling_job_execution_order_index: ${job.parent_job_id}`,
                    hideExpandArrow: true
                },
                {
                    id: `x-job-job_type-${parentItemJobId}`,
                    name: `job_type: ${job.job_type}`,
                    hideExpandArrow: true
                },
                {
                    id: `x-job-status-${parentItemJobId}`,
                    name: `status: ${job.status}`,
                    hideExpandArrow: true
                },
                {
                    id: `x-job-progress-${parentItemJobId}`,
                    name: `progress: ${job.progress}`,
                    hideExpandArrow: true
                },
                {
                    id: `x-job-priority-${parentItemJobId}`,
                    name: `priority: ${job.priority}`,
                    hideExpandArrow: true
                },
                {
                    id: `x-job-user_id-${parentItemJobId}`,
                    name: `user_id: ${job.user_id}`,
                    hideExpandArrow: true
                },
                {
                    id: `x-job-created_time-${parentItemJobId}`,
                    name: `created_time: ${job.created_time}`,
                    hideExpandArrow: true
                },
                {
                    id: `x-job-queued_time-${parentItemJobId}`,
                    name: `queued_time: ${job.queued_time}`,
                    hideExpandArrow: true
                },
                {
                    id: `x-job-run_started_time-${parentItemJobId}`,
                    name: `run_started_time: ${job.run_started_time}`,
                    hideExpandArrow: true
                },
                {
                    id: `x-job-run_finished_time-${parentItemJobId}`,
                    name: `run_finished_time: ${job.run_finished_time}`,
                    hideExpandArrow: true
                },
                {
                    id: `x-job-finished_time-${parentItemJobId}`,
                    name: `finished_time: ${job.finished_time}`,
                    hideExpandArrow: true
                },
                {
                    id: `x-job-params-${parentItemJobId}`,
                    name: `params: ${JSON.stringify(job.params)}`,
                    hideExpandArrow: true
                }
            ]
        } else if (parentItemId.substr(0, 15) === "logs-container-") {
            const parentItemJobId = parentItemId.substr(15);

            const logs = await getLogsForJob(parentItemJobId, null);
            const logTreeItems = logs.map(getTreeItemForJobLog);
            return logTreeItems;
        } else if (parentItemId.substr(0, 4) === "log-") {
            const parentItemJobId = parentItemId.substr(4, 36);
            const parentItemJobLogId = parentItemId.substr(41);

            const logs = await getLogsForJob(parentItemJobId, parentItemJobLogId);
            const logTreeItems = logs.map(getTreeItemForJobLog);
            return logTreeItems;
        } else if (parentItemId.substr(0, 21) === "child-jobs-container-") {
            const parentItemJobId = parentItemId.substr(21);
            const childJobs = await getChildJobByParentJobId(parentItemJobId);
            const childJobTreeItems = childJobs.map(getTreeItemForJobContainer);
            return childJobTreeItems;
        }

        return [];
    };

    console.log(rootJob);

    if (rootJob === undefined) {
        return <p>Loading...</p>
    }

    if (rootJob === null) {
        return <Redirect to="/admin/jobs" />
    }

    return (
        <Tree
            getChildren={getTreeItems}
        />
    );
};