import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useField} from 'react-final-form';
import {NewApiServices} from '../../../../newApiServices';
import {LoadingSpinner} from '../../../loadingSpinner/LoadingSpinner';
import {GroupPermission} from '../../../../models/groupPermission';

export const PermissionCheckboxRow: React.FunctionComponent<{name: string, label: string, selectedPermissions: string[], onChange: (name: string, isChecked: boolean) => any}> = React.memo(({name, label, selectedPermissions, onChange}) => {
    const isChecked = useMemo(() => selectedPermissions.includes(name), [selectedPermissions, name]);
    return (
        <li>
            <label style={{fontWeight: "normal"}}><input type="checkbox" checked={isChecked} onChange={e => onChange(name, e.target.checked)} /> {label}</label>
        </li>
    )
});

export const GroupRolePermissionSelectorAdapter: React.FunctionComponent<{name: string}> = ({name}) => {
    const field = useField<string[]>(name);
    const {value: selectedPermissions, onChange} = field.input;

    const [groupPermissions, setGroupPermissions] = useState(null as GroupPermission[] | null);

    useEffect(() => {
        async function loadGroupPermissions() {
            const _groupPermissions = await NewApiServices.groupPermissionsService.getAllGroupPermissions();
            setGroupPermissions(_groupPermissions);
        }

        void loadGroupPermissions();
    }, []);

    const onCheckboxChange = useCallback((name: string, isSelected: boolean) => {
        const newValue = [
            ...selectedPermissions.filter(x => x !== name),
            ...(isSelected ? [name] : [])
        ];
        onChange(newValue);
    }, [selectedPermissions, onChange]);

    return (
        <>
            {groupPermissions == null ? <LoadingSpinner /> : null}
            <ul style={{listStyleType: "none", paddingLeft: 0}}>
                {groupPermissions != null ? groupPermissions.map(groupPermission => (
                    <PermissionCheckboxRow
                        key={groupPermission.id}
                        name={groupPermission.id}
                        label={groupPermission.description}
                        selectedPermissions={selectedPermissions}
                        onChange={onCheckboxChange}
                    />
                )) : null}
            </ul>
        </>
    )
}