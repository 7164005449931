import React from 'react'
import { Redirect, RouteProps, useLocation } from 'react-router';
import qs from 'qs';

export const AuthenticatedRedirect: React.FunctionComponent<RouteProps> = () => {
    const location = useLocation();
    let next = '/home';

    const locationSearchWithoutLeadingQuestionMarkMatch = location.search.match(/\??(.*)/);
    const locationSearchWithoutLeadingQuestionMark = locationSearchWithoutLeadingQuestionMarkMatch != null && locationSearchWithoutLeadingQuestionMarkMatch.length >= 2 ? locationSearchWithoutLeadingQuestionMarkMatch[1] : null;
    const queryString = locationSearchWithoutLeadingQuestionMark != null ? qs.parse(locationSearchWithoutLeadingQuestionMark) : null;
    if (queryString?.next) {
        next = queryString.next as string;
    }

    return location.pathname.includes('/login') ? <Redirect to={next} /> : <Redirect to="/home" />;
}