import {CaseReducer, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {buildSubStateSelector} from '../../../store/utilities/useAppStateSelector';

export type AppStateSyrxControllerBacnetNetworkQueryServiceMessagesFilterCriteria = 'hide_acknowledged_hide_completed' | 'show_acknowledged_hide_completed' | 'show_acknowledged_show_completed';

const initialState = {
    filterCriteria: 'show_acknowledged_hide_completed' as AppStateSyrxControllerBacnetNetworkQueryServiceMessagesFilterCriteria,
    refreshTrigger: null as any
};

export type AppStateSyrxControllerBacnetNetworkQueryServiceMessagesGrid = typeof initialState;

export const slice = createSlice<AppStateSyrxControllerBacnetNetworkQueryServiceMessagesGrid, {
    setFilterCriteria: CaseReducer<AppStateSyrxControllerBacnetNetworkQueryServiceMessagesGrid, PayloadAction<AppStateSyrxControllerBacnetNetworkQueryServiceMessagesFilterCriteria>>;
    triggerRefresh: CaseReducer<AppStateSyrxControllerBacnetNetworkQueryServiceMessagesGrid>;
}>({
    name: "syrxControllerBacnetNetworkQueryServiceMessagesGrid",
    initialState,
    reducers: {
        setFilterCriteria(state, {payload: filterCriteria}) {
            state.filterCriteria = filterCriteria;
        },
        triggerRefresh(state) {
            state.refreshTrigger = {};
        }
    }
});

export const useSyrxControllerBacnetNetworkQueryServiceMessagesGridSelector = buildSubStateSelector(state => state.syrxControllerBacnetNetworkQueryServiceMessagesGrid);
export const syrxControllerBacnetNetworkQueryServiceMessagesGridActions = slice.actions;
export const syrxControllerBacnetNetworkQueryServiceMessagesGridReducer = slice.reducer;