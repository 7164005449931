import React, {useCallback, useEffect} from 'react'
import {
    SyrxControllerBuildingPressureControlServiceBasicConfiguration,
} from '../../../models/syrxControllerBuildingPressureControlServiceConfiguration';
import {SyrxControllerBuildingPressureControlServiceBasicConfigurationTable} from './syrxControllerBuildingPressureControlServiceBasicConfigurationTable';
import {useDispatch} from 'react-redux';
import {LoadingSpinner} from '../../loadingSpinner/LoadingSpinner';
import {syrxControllerBuildingPressureControlServiceBasicConfigurationEditorActionCreators} from './syrxControllerBuildingPressureControlServiceBasicConfigurationEditorActionCreators';
import {Redirect} from 'react-router';
import {
    syrxControllerBuildingPressureControlServiceBasicConfigurationEditorActions,
    useSyrxControllerBuildingPressureControlServiceBasicConfigurationEditorSelector
} from './syrxControllerBuildingPressureControlServiceBasicConfigurationEditorSlice';

export const SyrxControllerBuildingPressureControlServiceBasicConfigurationEditor: React.FunctionComponent<{ syrxControllerId: string }> = ({syrxControllerId}) => {
    const dispatch = useDispatch();
    const isLoading = useSyrxControllerBuildingPressureControlServiceBasicConfigurationEditorSelector(x => x.isLoading);
    const isDoneLoading = useSyrxControllerBuildingPressureControlServiceBasicConfigurationEditorSelector(x => x.isDoneLoading);
    const isSubmitted = useSyrxControllerBuildingPressureControlServiceBasicConfigurationEditorSelector(x => x.isSubmitted);
    const syrxControllerBuildingPressureControlServiceBasicConfiguration = useSyrxControllerBuildingPressureControlServiceBasicConfigurationEditorSelector(x => x.syrxControllerPressureBasicConfiguration);

    useEffect(() => {
        dispatch(syrxControllerBuildingPressureControlServiceBasicConfigurationEditorActionCreators.loadConfiguration(syrxControllerId));
        return () => {
            dispatch(syrxControllerBuildingPressureControlServiceBasicConfigurationEditorActions.resetState())
        };
    }, [dispatch, syrxControllerId]);

    const onSubmit = useCallback((syrxControllerBuildingPressureControlServiceBasicConfiguration: SyrxControllerBuildingPressureControlServiceBasicConfiguration) => {
        dispatch(syrxControllerBuildingPressureControlServiceBasicConfigurationEditorActionCreators.submit(syrxControllerId, syrxControllerBuildingPressureControlServiceBasicConfiguration));
    }, [dispatch, syrxControllerId]);
    return (
        <>
            {isLoading ? <LoadingSpinner /> : null}
            {isDoneLoading ? (
                <>
                    <h4>Service configuration</h4>
                    <SyrxControllerBuildingPressureControlServiceBasicConfigurationTable
                        syrxControllerBuildingPressureControlServiceBasicConfiguration={syrxControllerBuildingPressureControlServiceBasicConfiguration}
                        editMode={true}
                        onSubmit={onSubmit}
                        cancelLinkTo={`/admin/syrx-controllers/${syrxControllerId}/services/Pathian.Services.BuildingPressureControlService`}
                    />
                </>
            ) : null}
            {isSubmitted ? <Redirect to={`/admin/syrx-controllers/${syrxControllerId}/services/Pathian.Services.BuildingPressureControlService`} /> : null}
        </>
    )
};