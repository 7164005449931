import { Dispatch } from "redux";
import * as syrxControllersService from "../services/syrxControllersService";
import {SyrxControllerBacnetDeviceEditorForm} from '../features/syrxControllers/bacnetNetworkQueryService/syrxControllerBacnetDeviceEditorForm';
import {syrxControllerBacnetDeviceEditorActions} from '../features/syrxControllers/bacnetNetworkQueryService/syrxControllerBacnetDeviceEditorSlice';
import {SyrxControllerBacnetDevice} from '../models/syrxControllerBacnetDevice';
import {toast} from 'react-toastify';

export const submitSyrxControllerBacnetDeviceEditor = (values: SyrxControllerBacnetDeviceEditorForm) => async (dispatch: Dispatch<any>) => {
    dispatch(syrxControllerBacnetDeviceEditorActions.startSubmitting());

    const device = {
        ...values.syrxControllerBacnetDevice,
        destination_mac_address: values.provideDestinationAddressing ? values.syrxControllerBacnetDevice.destination_mac_address : "1.0.0.0:0",
        destination_network: values.provideDestinationAddressing ? values.syrxControllerBacnetDevice.destination_network : 0
    } as SyrxControllerBacnetDevice;

    try {
        await syrxControllersService.createSyrxControllerBacnetDevice(values.syrxControllerBacnetDevice.syrx_controller_id, device);
        dispatch(syrxControllerBacnetDeviceEditorActions.finishSubmitting());
    } catch (e) {
        if (e.response?.status === 409) {
            toast.error("The provided BACnet device already exists, it can't be re-added");
        } else {
            toast.error("An unknown error occurred");
            console.error(e);
        }
        dispatch(syrxControllerBacnetDeviceEditorActions.cancelSubmitting());
    }

};