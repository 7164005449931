import React, { FunctionComponent } from 'react'
import FancyGrid, { SortCollection } from '@sht-dev/fancy-grid';
import {getJobRunnerVendorPointRecordMoveJobs} from '../../../../services/jobsService';
import dayjs from 'dayjs';


export const MoveVendorPointDataJobsListGrid: FunctionComponent = () => {
    const dataRetrievalFunction = (pageNumber: number, pageSize: number, sort: SortCollection) => getJobRunnerVendorPointRecordMoveJobs(pageNumber, pageSize, sort);

    return (
        <FancyGrid.ReduxGrid 
            gridName={"MoveVendorPointDataJobsListGrid"}
            dataRetrievalFunction={dataRetrievalFunction}
        >
            <FancyGrid.ColumnList>
                <FancyGrid.Column
                    name="job_id"
                    title="Job id"
                />
                <FancyGrid.Column
                    name="source_data_point_name"
                    title="Source point"
                >
                </FancyGrid.Column>
                <FancyGrid.Column
                    name="destination_data_point_name"
                    title="Destination point"
                />
                <FancyGrid.Column
                    name="start_date"
                    title="From date"
                >
                    <FancyGrid.CellRenderer>
                        {(startDate, job) => <>{job.full_time_range ? "(all)" : (startDate != null ? dayjs.utc(startDate).format("MM/DD/YYYY") : null)}</>}
                    </FancyGrid.CellRenderer>
                </FancyGrid.Column>
                <FancyGrid.Column
                    name="end_date"
                    title="To date"
                >
                    <FancyGrid.CellRenderer>
                        {(endDate, job) => <>{job.full_time_range ? "(all)" : (endDate != null ? dayjs.utc(endDate).add(-1, "day").format("MM/DD/YYYY") : null)}</>}
                    </FancyGrid.CellRenderer>
                </FancyGrid.Column>
                <FancyGrid.Column
                    name="source_record_handling"
                    title="Operation"
                />
                <FancyGrid.Column
                    name="status"
                    title="Status"
                />
                <FancyGrid.Column
                    name="job_queued_date"
                    title="Creation date"
                >
                    <FancyGrid.CellRenderer>
                        {jobQueuedDate => <>{dayjs(jobQueuedDate).local().format("MM/DD/YYYY hh:mm:ss a")}</>}
                    </FancyGrid.CellRenderer>
                </FancyGrid.Column>
                <FancyGrid.Column
                    name="job_started_date"
                    title="Started date"
                >
                    <FancyGrid.CellRenderer>
                        {jobStartedDate => <>{jobStartedDate != null ? dayjs(jobStartedDate).local().format("MM/DD/YYYY hh:mm:ss a") : null}</>}
                    </FancyGrid.CellRenderer>
                </FancyGrid.Column>
                <FancyGrid.Column
                    name="job_completed_date"
                    title="Completed date"
                >
                    <FancyGrid.CellRenderer>
                        {jobCompletedDate => <>{jobCompletedDate != null ? dayjs(jobCompletedDate).local().format("MM/DD/YYYY hh:mm:ss a") : null}</>}
                    </FancyGrid.CellRenderer>
                </FancyGrid.Column>
            </FancyGrid.ColumnList>
            <FancyGrid.ReduxSortable defaultSort={[{fieldName: "job_queued_date", dir: "desc"}]} />
            <FancyGrid.ReduxPager />
        </FancyGrid.ReduxGrid>
    )
}