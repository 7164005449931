import React from 'react'
import dayjs from 'dayjs';
import {
    useSyrxControllerDiagnosticServiceDetailsSelector
} from './syrxControllerDiagnosticServiceDetailsSlice';

export interface SyrxControllerDiagnosticServiceNetworkInterfaceInformationProps {
    syrxControllerId: string;
}


export const SyrxControllerDiagnosticServiceNetworkInterfaceInformation: React.FunctionComponent<SyrxControllerDiagnosticServiceNetworkInterfaceInformationProps> = () => {
    const networkInterfaceInformationRecord = useSyrxControllerDiagnosticServiceDetailsSelector(state => state.latestNetworkInterfaceInformationRecord);

    return (
        <>
            <p>Last updated: {networkInterfaceInformationRecord?.timestamp != null ? dayjs(networkInterfaceInformationRecord.timestamp).format() : null}</p>
            <pre>{networkInterfaceInformationRecord?.networkInterfaceInformation}</pre>
        </>
    )
};