import { Dispatch } from "redux";
import {
    getBuildingPressureControlServiceConfigurationForSyrxController,
    patchBuildingPressureControlServiceConfigurationForSyrxController,
} from '../../../../services/syrxControllersService';
import {syrxControllerBuildingPressureControlServiceOatSensorEditorActions} from './syrxControllerBuildingPressureControlServiceOatSensorEditorSlice';

export const syrxControllerBuildingPressureControlServiceOatSensorEditorActionCreators = {
    loadConfiguration: (syrxControllerId: string) => async (dispatch: Dispatch<any>) => {
        dispatch(syrxControllerBuildingPressureControlServiceOatSensorEditorActions.startLoading());

        try {

            const syrxControllerPressureConfiguration = await getBuildingPressureControlServiceConfigurationForSyrxController(syrxControllerId);
            dispatch(syrxControllerBuildingPressureControlServiceOatSensorEditorActions.setOatSensorSyrxControllerBacnetPointId(syrxControllerPressureConfiguration?.oat_syrx_controller_bacnet_point_id ?? null));
        } finally {
            dispatch(syrxControllerBuildingPressureControlServiceOatSensorEditorActions.finishLoading());
        }
    },

    submit: (syrxControllerId: string, oatSensorSyrxControllerBacnetPointId: string | null) => async (dispatch: Dispatch) => {
        dispatch(syrxControllerBuildingPressureControlServiceOatSensorEditorActions.startSubmitting());
        await patchBuildingPressureControlServiceConfigurationForSyrxController(syrxControllerId, {oat_syrx_controller_bacnet_point_id: oatSensorSyrxControllerBacnetPointId});
        dispatch(syrxControllerBuildingPressureControlServiceOatSensorEditorActions.finishSubmitting());
    }
}
