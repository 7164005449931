import React from 'react'
import { useDispatch } from 'react-redux'

import { Router } from '../features/router/Router';
import { MainNavBar } from '../features/navBars/mainNavBar';

import imageSrc from '../assets/PathianLogoSmall.jpg';
import { loadLoggedInSession } from '../actionCreators/authActionCreators';
import { CurrentGroupNavBar } from '../features/navBars/currentGroupNavBar';
import { useAppStateSelector } from '../store/utilities/useAppStateSelector';

export const App = () => {
    const dispatch = useDispatch();
    const {initializationState, user: currentUser} = useAppStateSelector(state => state.authSession);

    if (initializationState === "not_initialized") {
        dispatch(loadLoggedInSession());
    }

    return initializationState === "initialized" ? (
        <div className="container-fluid">
            <div>
                <img src={imageSrc} width="180" height="70" />
            </div>
            <MainNavBar />
            {currentUser != null ? <CurrentGroupNavBar /> : null}

            <div className="content-container">
                <Router />
            </div>
        </div>
    ) : null;
};