import {CaseReducer, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {buildSubStateSelector} from '../../../../store/utilities/useAppStateSelector';
import {GroupRoleEditorForm} from './groupRoleEditorForm';

const initialState = {
    isLoading: false,
    isDoneLoading: false,
    isSubmitting: false,
    isDoneSubmitting: false,
    formInitialValues: null as GroupRoleEditorForm | null
};

export type AppStateGroupRoleEditor = typeof initialState;

export const slice = createSlice<AppStateGroupRoleEditor, {
    startLoading: CaseReducer<AppStateGroupRoleEditor>;
    setInitialFormValues: CaseReducer<AppStateGroupRoleEditor, PayloadAction<{formInitialValues: GroupRoleEditorForm | null}>>;
    finishLoading: CaseReducer<AppStateGroupRoleEditor>;
    startSubmitting: CaseReducer<AppStateGroupRoleEditor>;
    finishSubmitting: CaseReducer<AppStateGroupRoleEditor>;
    editorUnload: CaseReducer<AppStateGroupRoleEditor>;
}>({
    name: "groupRoleEditor",
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
            state.isDoneLoading = false;
            state.isSubmitting = false;
            state.isDoneSubmitting = false;
        },
        setInitialFormValues(state, {payload}) {
            state.formInitialValues = payload.formInitialValues;
        },
        finishLoading(state) {
            state.isLoading = false;
            state.isDoneLoading = true;
            state.isSubmitting = false;
            state.isDoneSubmitting = false;
        },
        startSubmitting(state) {
            state.isSubmitting = true;
            state.isDoneSubmitting = false;
        },
        finishSubmitting(state) {
            state.isSubmitting = false;
            state.isDoneSubmitting = true;
        },
        editorUnload(state) {
            state.isLoading = false;
            state.isDoneLoading = false;
            state.isSubmitting = false;
            state.isDoneSubmitting = false;
            state.formInitialValues = null;
        }
    }
});

export const useGroupRoleEditorSelector = buildSubStateSelector(state => state.groupRoleEditor);
export const groupRoleEditorActions = slice.actions;
export const groupRoleEditorReducer = slice.reducer;