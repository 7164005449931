import { Dispatch } from "redux";
import {syrxControllerServiceVersionEditorActions} from './syrxControllerServiceVersionEditorSlice';
import {SyrxControllerServiceVersionsService} from '../../../services/syrxControllerServiceVersionsService';
import {
    SyrxControllerServiceVersionSubmittable
} from '../../../models/syrxControllerServiceVersion';
import {toast} from 'react-toastify';

export const syrxControllerServiceVersionEditorActionCreators = {
    load: (serviceId: string | null, versionName: string | null) => async (dispatch: Dispatch<any>) => {
        dispatch(syrxControllerServiceVersionEditorActions.startLoading());

        try {

            const syrxControllerServiceVersion = serviceId != null && versionName != null ?
                (await SyrxControllerServiceVersionsService.getServiceVersionForServiceTypeAndVersionName(serviceId, versionName)) :
                null;

            dispatch(syrxControllerServiceVersionEditorActions.setSyrxControllerServiceVersion(syrxControllerServiceVersion));
        } finally {
            dispatch(syrxControllerServiceVersionEditorActions.finishLoading());
        }
    },

    submitCreate: (syrxControllerServiceVersion: SyrxControllerServiceVersionSubmittable) => async (dispatch: Dispatch) => {
        dispatch(syrxControllerServiceVersionEditorActions.startSubmitting());

        try {
            await SyrxControllerServiceVersionsService.createServiceVersion(syrxControllerServiceVersion);

            dispatch(syrxControllerServiceVersionEditorActions.finishSubmitting());
        } catch (e) {
            if (e.response.status === 409) {
                toast.error("The specified version already exists for this service");
                dispatch(syrxControllerServiceVersionEditorActions.cancelSubmitting());
            } else {
                toast.error("An error occurred trying to save the syrx controller service version");
                dispatch(syrxControllerServiceVersionEditorActions.cancelSubmitting());
                throw e;
            }
        }
    },

    submitUpdate: (syrxControllerServiceVersion: SyrxControllerServiceVersionSubmittable) => async (dispatch: Dispatch) => {
        dispatch(syrxControllerServiceVersionEditorActions.startSubmitting());

        try {
            await SyrxControllerServiceVersionsService.updateServiceVersion(syrxControllerServiceVersion);

            dispatch(syrxControllerServiceVersionEditorActions.finishSubmitting());
        } catch (e) {
            dispatch(syrxControllerServiceVersionEditorActions.cancelSubmitting());
            toast.error("An error occurred trying to save the syrx controller service version");
            throw e;
        }
    }
}
