import React from 'react'
import { Col } from 'react-bootstrap';
import {SyrxControllerReportingPointsGrid} from './syrxControllerReportingPointsGrid';

export interface SyrxControllerReportingServiceDetailsProps {
    syrxControllerId: string;
}

export const SyrxControllerReportingServiceDetails: React.FunctionComponent<SyrxControllerReportingServiceDetailsProps> = ({syrxControllerId}) => {
    return (
        <>
            <Col md={12} style={{marginTop: "1rem"}}>
                <h4>Points</h4>
                <SyrxControllerReportingPointsGrid syrxControllerId={syrxControllerId} />
            </Col>
        </>
    )
};