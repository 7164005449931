import {CaseReducer, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {SyrxControllerServiceVersion} from '../../../models/syrxControllerServiceVersion';
import {buildSubStateSelector} from '../../../store/utilities/useAppStateSelector';

const initialState = {
    isLoading: false,
    isDoneLoading: false,
    isSubmitted: false,
    syrxControllerServiceVersion: null as SyrxControllerServiceVersion | null
};

export type AppStateSyrxControllerServiceVersionEditor = typeof initialState;

export const slice = createSlice<AppStateSyrxControllerServiceVersionEditor, {
    startLoading: CaseReducer<AppStateSyrxControllerServiceVersionEditor>;
    setSyrxControllerServiceVersion: CaseReducer<AppStateSyrxControllerServiceVersionEditor, PayloadAction<SyrxControllerServiceVersion | null>>;
    finishLoading: CaseReducer<AppStateSyrxControllerServiceVersionEditor>;
    startSubmitting: CaseReducer<AppStateSyrxControllerServiceVersionEditor>;
    cancelSubmitting: CaseReducer<AppStateSyrxControllerServiceVersionEditor>;
    finishSubmitting: CaseReducer<AppStateSyrxControllerServiceVersionEditor>;
    resetState: CaseReducer<AppStateSyrxControllerServiceVersionEditor>;
}>({
    name: "syrxControllerServiceVersionEditorSlice",
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
            state.isDoneLoading = false;
        },
        setSyrxControllerServiceVersion(state, {payload: syrxControllerServiceVersion}) {
            state.syrxControllerServiceVersion = syrxControllerServiceVersion;
        },
        finishLoading(state) {
            state.isLoading = false;
            state.isDoneLoading = true;
        },
        startSubmitting(state) {
            state.isLoading = true;
        },
        cancelSubmitting(state) {
            state.isLoading = false;
            state.isSubmitted = false;
        },
        finishSubmitting(state) {
            state.isLoading = false;
            state.isSubmitted = true;
        },
        resetState(state) {
            state.syrxControllerServiceVersion = null;
            state.isLoading = false;
            state.isDoneLoading = false;
            state.isSubmitted = false;
        }
    }
});

export const useSyrxControllerServiceVersionEditorSelector = buildSubStateSelector(state => state.syrxControllerServiceVersionEditor);
export const syrxControllerServiceVersionEditorActions = slice.actions;
export const syrxControllerServiceVersionEditorReducer = slice.reducer;