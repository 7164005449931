import React, { ChangeEvent } from 'react'
import {useDispatch} from 'react-redux';
import { Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap';
import { useParams } from 'react-router';

import {SyrxControllersGrid} from './syrxControllersGrid'
import {SyrxControllersDetail} from './syrxControllersDetail';
import {useAuthSessionSelector} from '../authSession/authSessionSlice';
import {syrxControllersListActions, useSyrxControllersListSelector} from './syrxControllersListSlice';

export const SyrxControllersList: React.FunctionComponent = () => {
    const {syrxControllerId} = useParams();
    const dispatch = useDispatch();
    const currentGroup = useAuthSessionSelector(state => state.currentGroup);
    const syrxControllerGroupScope = useSyrxControllersListSelector(state => state.syrxControllerGroupScope);


    const onSyrxControllerGroupScopeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        dispatch(syrxControllersListActions.setGroupScope(e.target.value as 'current_group' | 'all_groups'));
    };

    return <>
        <h3>Syrx controllers</h3>
        <p>Click a controller to view details and manage configuration.</p>
        <div style={{marginBottom: '10px'}}>
            <LinkContainer to={`admin/syrx-controllers/new`}><Button variant="primary">Setup new controller</Button></LinkContainer>
            <select className="form-control" style={{display: "inline-block", width: "unset"}} value={syrxControllerGroupScope} onChange={onSyrxControllerGroupScopeChange}>
                <option value='current_group'>Show Syrx controllers from current group</option>
                <option value='all_groups'>Show Syrx controllers from all groups</option>
            </select>
        </div>

        <SyrxControllersGrid rootGroupId={syrxControllerGroupScope === 'current_group' ? currentGroup.id : null} />
        {syrxControllerId != null ? (
            <div style={{marginTop: "15px"}}>
                <SyrxControllersDetail syrxControllerId={syrxControllerId} />
            </div>
        ) : null}
    </>
}