import React, { CSSProperties, useCallback } from 'react'
import { Form } from 'react-final-form';
import { Field } from 'react-final-form-html5-validation';
import { Modal, Table, Button, FormGroup, Col } from 'react-bootstrap';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import { useBuildingMeterAccountsGridSelector } from './buildingMeterAccountsGridSlice';
import { buildingMeterAccountsActionCreators } from '../../actionCreators/buildingMeterAccountsActionCreators';

const headerContainer: CSSProperties = {
    display: "flex",
    justifyContent: "space-between",
    background: "linear-gradient(180deg, #7C7C7C 0%, #7B7B7B 51%, #A0A0A0 100%)",
    borderRadius: "5px 5px 0px 0px"
};

const headerLeft: CSSProperties = {
    color: "#FFFFFF",
    fontSize: 20,
    padding: "5 0 5 10"
};

const headerRight: CSSProperties = {
    color: "#FFFFFF",
    fontSize: 20,
    padding: "5 10 5 0"
};

const detailsContainer: CSSProperties = {
    display: "flex",
    justifyContent: "space-between",
    margin: '5 10 5 10'
};

const detailsColumn: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: "space-between"
};

const detailsText: CSSProperties = {
    color: "#707070",
    fontSize: 14
};

const detailsTextBold: CSSProperties = {
    color: "#707070",
    fontSize: 14,
    fontWeight: 'bold'
};

const tableAndHeading: CSSProperties = {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column"
};

const row: CSSProperties = {
    display: "flex",
    flexDirection: "row"
};

const inputPadding: CSSProperties = {
    paddingLeft: 5,
    paddingRight: 5
};

const fullHeightButton: CSSProperties = {
    height: "100%"
};

const footer: CSSProperties = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
};

export interface buildingMeterAccountsGridProps {
    rootGroupId: number;
}

export const BuildingMeterAccountsDetailsModal: React.FunctionComponent<buildingMeterAccountsGridProps> = props => {
    const { selectedBuildingMeterAccount, showModal } = useBuildingMeterAccountsGridSelector(x => x);
    const dispatch = useDispatch();
    const groupId = props.rootGroupId;

    const hideDetailsModal = useCallback(async () => {
        dispatch(buildingMeterAccountsActionCreators.hideModal());
    }, [dispatch]);

    const submitBuildingSize = useCallback(async (values: { size: number, effectiveDate: Date }) => {
        dispatch(buildingMeterAccountsActionCreators.createNewBuildingMeterAccountSizeEntry(groupId, selectedBuildingMeterAccount.id, values.size, values.effectiveDate))
    }, [dispatch, selectedBuildingMeterAccount]);

    const submitUtilityCost = useCallback(async (values: { cost: number, effectiveDate: Date }) => {
        dispatch(buildingMeterAccountsActionCreators.createNewBuildingMeterAccountUtilityCostEntry(groupId, selectedBuildingMeterAccount.id, values.cost, values.effectiveDate))
    }, [dispatch, selectedBuildingMeterAccount]);

    const deleteBuildingMeterAccount = useCallback(async () => {
        dispatch(buildingMeterAccountsActionCreators.deleteBuildingMeterAccount(groupId, selectedBuildingMeterAccount.id));
    }, [dispatch, selectedBuildingMeterAccount]);

    return (
        <Modal show={showModal} onHide={hideDetailsModal} centered size="lg">
            <Modal.Header style={headerContainer}>
                <div style={headerLeft}>{selectedBuildingMeterAccount.name}</div>
                <div style={headerRight}>{selectedBuildingMeterAccount.accountNumber}</div>
            </Modal.Header>
            <Modal.Body>
                <div style={detailsContainer}>
                    <div style={detailsColumn}>
                        <div style={detailsText}>
                            {selectedBuildingMeterAccount.type === 'electric' ? 'Electric Account' : 'Gas Account'}
                        </div>
                        <div style={detailsText}>
                            {selectedBuildingMeterAccount.weatherStation?.name}
                        </div>
                        <div style={detailsTextBold}>
                            {new Intl.NumberFormat().format((selectedBuildingMeterAccount.buildingSizeHistory ?? [])[0]?.value)} sqft
                        </div>
                    </div>
                    <div style={detailsColumn}>
                        <div style={detailsText}>
                            {selectedBuildingMeterAccount.timezone}
                        </div>
                        <div style={detailsTextBold}>
                            ${(selectedBuildingMeterAccount.utilityCostHistory ?? [])[0]?.value} / kWh
                        </div>
                    </div>
                </div>
                <div style={detailsContainer}>
                    <div style={detailsColumn}>
                        <div style={tableAndHeading}>
                            <div style={detailsTextBold}>
                                Building Size History
                            </div>
                            <Table bordered size="sm">
                                <thead style={detailsText}>
                                    <tr>
                                        <th>Effective Date</th>
                                        <th>Building Size</th>
                                    </tr>
                                </thead>
                                <tbody style={detailsText}>
                                    {selectedBuildingMeterAccount.buildingSizeHistory?.map(buildingSizeHistoryItem => {
                                        return (
                                            <tr key={buildingSizeHistoryItem.id}>
                                                <td>
                                                    {moment(buildingSizeHistoryItem.effective_date).utc().format("MM/DD/YYYY")}
                                                </td>
                                                <td>
                                                    {new Intl.NumberFormat().format(buildingSizeHistoryItem.value)} sqft
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </div>
                        <Form onSubmit={submitBuildingSize}>
                            {({ handleSubmit }) => (
                                <form onSubmit={handleSubmit} style={row}>
                                    <FormGroup>
                                        <Col style={inputPadding}>
                                            <Field
                                                name="effectiveDate"
                                                component="input"
                                                type="date"
                                                placeholder="Date"
                                                className="form-control"
                                                required
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup>
                                        <Col style={inputPadding}>
                                            <Field
                                                name="size"
                                                component="input"
                                                type="number"
                                                placeholder="Size (sqft)"
                                                className="form-control"
                                                required
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup>
                                        <Col style={inputPadding}>
                                            <Button variant="primary" type="submit" style={fullHeightButton}>
                                                <i className="fas fa-plus" />
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                </form>
                            )}
                        </Form>
                    </div>
                    <div style={detailsColumn}>
                        <div style={tableAndHeading}>
                            <div style={detailsTextBold}>
                                Utility Cost History
                            </div>
                            <Table bordered size="sm">
                                <thead style={detailsText}>
                                    <tr>
                                        <th>Effective Date</th>
                                        <th>Utility Cost</th>
                                    </tr>
                                </thead>
                                <tbody style={detailsText}>
                                    {selectedBuildingMeterAccount.utilityCostHistory?.map(utilityCostHistoryItem => {
                                        return (
                                            <tr key={utilityCostHistoryItem.id}>
                                                <td>
                                                    {moment(utilityCostHistoryItem.effective_date).utc().format("MM/DD/YYYY")}
                                                </td>
                                                <td>
                                                    ${utilityCostHistoryItem.value} / kWh
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </div>
                        <Form onSubmit={submitUtilityCost}>
                            {({ handleSubmit }) => (
                                <form onSubmit={handleSubmit} style={row}>
                                    <FormGroup>
                                        <Col style={inputPadding}>
                                            <Field
                                                name="effectiveDate"
                                                component="input"
                                                type="date"
                                                placeholder="Date"
                                                className="form-control"
                                                required
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup>
                                        <Col style={inputPadding}>
                                            <Field
                                                name="cost"
                                                component="input"
                                                type="number"
                                                step="0.01"
                                                placeholder="Cost"
                                                className="form-control"
                                                required
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup>
                                        <Col style={inputPadding}>
                                            <Button variant="primary" type="submit" style={fullHeightButton}>
                                                <i className="fas fa-plus" />
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                </form>
                            )}
                        </Form>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer style={footer}>
                <Button variant="danger" onClick={deleteBuildingMeterAccount}>
                    Delete
                </Button>
                <Button variant="secondary" onClick={hideDetailsModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}