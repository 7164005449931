import React, {useCallback, useEffect, useRef, useState} from 'react'
import io from "socket.io-client"
import {SocketTerminal} from './socketTerminal';
import {Col} from 'react-bootstrap';
import {syrxSocketioEndpoint} from '../../../config';

export interface SyrxControllerBacnetNetworkQueryServiceDetailsProps {
    syrxControllerId: string;
}

export const SyrxControllerControlServiceDetails: React.FunctionComponent<SyrxControllerBacnetNetworkQueryServiceDetailsProps> = ({syrxControllerId}) => {
    const [isTerminalActive, setIsTerminalActive] = useState(false);
    const setForceRender = useState({})[1];
    const socketRef = useRef(null as SocketIOClient.Socket | null);

    const socket = socketRef.current;

    const forceRender = useCallback(() => {
        setForceRender({});
    }, [setForceRender]);

    useEffect(() => {
        socketRef.current = io(`${syrxSocketioEndpoint}/Pathian.ControlService/terminal`);
        forceRender();
        return () => {
            socketRef.current?.close();
            socketRef.current = null;
            forceRender();
        }
    }, [forceRender]);

    return (
        <Col md={12} style={{marginTop: "1rem"}}>
            {socket == null ? <p>Connecting to Socket.IO</p> : (
                <>
                <button onClick={() => setIsTerminalActive(true)} disabled={isTerminalActive}>Connect terminal</button>
                <button onClick={() => setIsTerminalActive(false)} disabled={!isTerminalActive}>Disconnect terminal</button>
                {isTerminalActive ? <SocketTerminal socket={socket} agentId={syrxControllerId} /> : null}
                </>
            )}
        </Col>
    )
};
