import React from 'react'
import { Row, Col, FormGroup, Form } from 'react-bootstrap';
import {Field} from 'react-final-form';

import { SyrxControllersEditorBbmdServersTable } from './syrxControllersEditorBbmdServersTable';

export const SyrxControllersEditorBacnetConfigurationFieldset: React.FunctionComponent<{values: any, push: (...args: any[]) => any}> = props => (
    <fieldset>
        <legend>BACnet connection configuration</legend>

        <FormGroup as={Row}>
            <Form.Label column md={4}>Local IP address</Form.Label>
            <Col md={4}>
                <Field 
                    name="bacnetConnectionConfiguration.ip_address"
                    component="input"
                    type="text"
                    className="form-control"
                />
            </Col>
        </FormGroup>

        <FormGroup as={Row}>
            <Form.Label column md={4}>BACnet UDP port</Form.Label>
            <Col md={4}>
                <Field 
                    name="bacnetConnectionConfiguration.port_num"
                    component="input"
                    type="text"
                    className="form-control"
                />
            </Col>
        </FormGroup>

        <FormGroup as={Row}>
            <Form.Label column md={4}>BBMD servers</Form.Label>
            <Col md={6}>
                <SyrxControllersEditorBbmdServersTable push={props.push} />
            </Col>
        </FormGroup>
    </fieldset>
);