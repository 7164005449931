import React, {useCallback, useEffect, useState} from 'react'
import {Button, Modal} from "react-bootstrap";
import {
    SyrxControllerBacnetNetworkSocketHelper
} from '../../../hooks/useSyrxControllerBacnetNetworkSocketHelper';
import {ScrollToBottom} from '../../../components/scrollToBottom';
import {generateDevicesEasyTable, generatePointsEasyTable} from './easyTableHelpers';
import {
    updateBacnetPoints
} from '../../../services/syrxControllersService';
import {SyrxControllerBacnetDevice} from '../../../models/syrxControllerBacnetDevice';
import {SyrxControllerBacnetPointUpdateModel} from '../../../models/syrxControllerBacnetPoint';
import {useStringAppenderState} from '../../../hooks/useStringAppenderState';

type ModalState = "running" | "ready_for_submit" | "submitting" | "done" | "error";

async function performReloadPoints(socketHelper: SyrxControllerBacnetNetworkSocketHelper, device: SyrxControllerBacnetDevice, setPoints: (points: SyrxControllerBacnetPointUpdateModel[]) => any, setModalState: (modalState: ModalState) => any, appendToDebugMessages: (x: string) => any) {
    let pointIndex = 1;

    const newPoints: SyrxControllerBacnetPointUpdateModel[] = [];

    try {
        try {
            for (; pointIndex < 2000; ++pointIndex) {
                appendToDebugMessages(`Attempting retrieval of point ${pointIndex}...\n`)
                const {instance, type, typeName} = await socketHelper.retrieveObjectListItem(device, pointIndex, () => appendToDebugMessages("Request acknowledged\n"));
                const objectId = `${typeName}:${instance}`;
                appendToDebugMessages(`Found point ${objectId}\n\n`);

                appendToDebugMessages(`Sent request for point name\nWaiting for acknowledgment...\n`);
                const name = await socketHelper.retrieveObjectName(device, type, instance, () => appendToDebugMessages(`Request acknowledged by syrx controller\nWaiting for response...\n`));
                appendToDebugMessages(`Name: ${name}\n\n`);

                appendToDebugMessages(`Sent request for point description\nWaiting for acknowledgment...\n`);
                const description = await socketHelper.retrieveObjectDescription(device, type, instance, () => appendToDebugMessages(`Request acknowledged by syrx controller\nWaiting for response...\n`));
                appendToDebugMessages(`Description: ${description}\n\n`);

                newPoints.push({
                    syrx_controller_bacnet_device_id: device.id,
                    object_type: type,
                    instance_number: instance,
                    name,
                    description
                });
            }

            appendToDebugMessages(`Exceeded maximum of 2000 points\n\n`);
        } catch (e) {
            if (e.message !== "System.IO.BACnet.BacnetErrorException: Error from device: ERROR_CLASS_PROPERTY - ERROR_CODE_INVALID_ARRAY_INDEX") {
                throw e;
            }
            appendToDebugMessages(`Attempted retrieval of point ${pointIndex} resulted in ERROR_CODE_INVALID_ARRAY_INDEX signifying end of the list of points (this error is expected)\n\n`);
        }

        setPoints(newPoints);

        const devicesTable = generateDevicesEasyTable([device], true);
        appendToDebugMessages(`Reloaded points for the following device:\n\n${devicesTable.toString()}\n`);

        const pointsTable = generatePointsEasyTable(newPoints);
        appendToDebugMessages(`Found ${newPoints.length} points:\n\n${pointsTable.toString()}\n`);

        appendToDebugMessages(`Ready for submit.`);

        setModalState("ready_for_submit");
    } catch (e) {
        appendToDebugMessages(`An error occurred retrieving points for device: ${JSON.stringify(e, null, 4)}\n`);
        setModalState("error");
        return;
    }
}

async function performSubmitPoints(syrxControllerId: string, device: SyrxControllerBacnetDevice, points: SyrxControllerBacnetPointUpdateModel[], setModalState: (modalState: ModalState) => any, appendToDebugMessages: (x: string) => any) {
    setModalState("submitting");
    appendToDebugMessages(`\n\nSubmitting...\n`);
    try {
        await updateBacnetPoints(syrxControllerId, device.id, points, progress => appendToDebugMessages(`Submitted ${progress}/${points.length}\n`));
        appendToDebugMessages(`Submitting points complete.\n`);
        setModalState("done");
    } catch (e) {
        appendToDebugMessages(`An error occurred submitting points for device: ${JSON.stringify(e, null, 4)}\n`);
        setModalState("error");
    }
}

export interface SyrxControllerBacnetDeviceReloadPointsModalProps {
    syrxControllerId: string;
    device: SyrxControllerBacnetDevice;
    socketHelper: SyrxControllerBacnetNetworkSocketHelper;
    onClose: (deleteSuccessful: boolean) => void;
}

export const SyrxControllerBacnetDeviceReloadPointsModal: React.FunctionComponent<SyrxControllerBacnetDeviceReloadPointsModalProps> = (props) => {
    const {
        syrxControllerId,
        device,
        socketHelper,
        onClose
    } = props;

    const [debugMessages, appendToDebugMessages] = useStringAppenderState("Initiating device reload points\n\n");
    const [points, setPoints] = useState(null as SyrxControllerBacnetPointUpdateModel[] | null);
    const [modalState, setModalState] = useState("running" as "running" | "ready_for_submit" | "submitting" | "done" | "error");

    useEffect(() => {
        void performReloadPoints(socketHelper, device, setPoints, setModalState, appendToDebugMessages);
    }, [socketHelper, device, appendToDebugMessages]);

    const doDismiss = useCallback(() => onClose(false), [onClose]);

    const doSubmit = useCallback(async () => {
        if (points == null) {
            return;
        }

        await performSubmitPoints(syrxControllerId, device, points, setModalState, appendToDebugMessages);
    }, [syrxControllerId, device, points, appendToDebugMessages]);

    return (
        <Modal show={true} onHide={doDismiss} size="xl" backdrop="static" keyboard={false}>
            <Modal.Header>
                <Modal.Title>Reload points for device</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <pre>
                    <ScrollToBottom style={{maxHeight: "600px"}}>
                        {debugMessages}
                    </ScrollToBottom>
                </pre>
            </Modal.Body>
            <Modal.Footer>
                {modalState === "ready_for_submit" && (
                    <Button variant="primary" onClick={doSubmit}>
                        Submit points
                    </Button>
                )}
                <Button variant="outline-secondary" onClick={doDismiss}>
                    {modalState === "done" || modalState === "error" ? "Close" : "Cancel"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

