import React from 'react'
import {Table} from 'react-bootstrap';
import {useMoveVendorPointDataNewJobSelector} from './moveVendorPointDataNewJobSlice';
import {MoveVendorPointDataNewJobSummaryTableRow} from './moveVendorPointDataNewJobSummaryTableRow';

export const MoveVendorPointDataNewJobSummaryTable: React.FunctionComponent = () => {
    const queue = useMoveVendorPointDataNewJobSelector(x => x.queue);

    return (
        <Table>
            <thead>
                <tr>
                    <th>Source point</th>
                    <th>Destination point</th>
                    <th>Date range</th>
                    <th>Source record handling</th>
                    <th>Mapped equipment points</th>
                    <th />
                </tr>
            </thead>
            <tbody>
                {queue.length === 0 ? (
                    <tr><th colSpan={4}>There are no points queued</th></tr>
                ) : queue.map(queueEntry => (
                    <MoveVendorPointDataNewJobSummaryTableRow queueEntry={queueEntry} key={queueEntry.id} />
                ))}
            </tbody>
        </Table>
    );
};