import React, { /*useEffect,*/ useCallback, CSSProperties } from 'react'
import { Link } from 'react-router-dom';
import { useParams/*, Redirect*/ } from 'react-router';
import { Form } from 'react-final-form';
import { Field } from 'react-final-form-html5-validation'
import { Button, FormGroup, Col } from 'react-bootstrap';
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { useDispatch } from 'react-redux';
import { LoadingSpinner } from '../loadingSpinner/LoadingSpinner';
import dayjs from 'dayjs';

import { equipmentEditorActionCreators } from '../../actionCreators/equipmentEditorActionCreators';
import { useEquipmentEditorSelector } from './equipmentEditorSlice';
import { useAuthSessionSelector } from '../authSession/authSessionSlice';
import { Equipment } from './types';

const container: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
}

const column: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: '0 15px'
}

export const EquipmentEditor: React.FunctionComponent = () => {
    const { isLoading, componentTypeaheadValues, selectedComponentDescendents } = useEquipmentEditorSelector(x => x);
    const currentGroup = useAuthSessionSelector(state => state.currentGroup);
    const dispatch = useDispatch();

    const params = useParams<{ id: string | undefined }>();
    const id = (params.id) ? parseInt(params.id) : null;

    const handleTypeahead = useCallback(async (value: any) => {
        dispatch(equipmentEditorActionCreators.typeaheadSearch(value));
    }, [dispatch]);

    const typeaheadOnSelect = useCallback(async (props: any, selected: any) => {
        if (selected[0]) {
            props.input.onChange(selected[0].id);
            dispatch(equipmentEditorActionCreators.getDescendents(selected[0].id))
        } else {
            props.input.onChange(null);
            // console.log('cleared')
        }
    }, [dispatch]);

    const onSubmit = useCallback(async (values: any) => {
        const {subComponents, install_date, demo_date, ...rest} = values;
        const equipment: Equipment = {
            ...rest,
            install_date: dayjs.utc(install_date).format(),
            demo_date: dayjs.utc(demo_date).format(),
            group_id: currentGroup.id
        }
        dispatch(equipmentEditorActionCreators.submitEquipment(equipment, Object.values(subComponents)));
    }, [dispatch]);

    return (
        <>
            {isLoading ? <LoadingSpinner /> : null}
            <Form
                onSubmit={onSubmit}
            >
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <FormGroup>
                            <Col md={4}>
                                <h2>{id ? "Edit" : "Add"} Equipment</h2>
                            </Col>
                        </FormGroup>
                        <div style={container}>
                            <div style={column}>
                                <FormGroup>
                                    <label>Name</label>
                                    <Field
                                        name="name"
                                        component="input"
                                        type="text"
                                        placeholder="Name"
                                        className="form-control"
                                        required
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label>Description</label>
                                    <Field
                                        name="description"
                                        component="input"
                                        type="text"
                                        placeholder="Description"
                                        className="form-control"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label>Department</label>
                                    <Field
                                        name="department"
                                        component="input"
                                        type="text"
                                        placeholder="Department"
                                        className="form-control"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label>Model</label>
                                    <Field
                                        name="model"
                                        component="input"
                                        type="text"
                                        placeholder="Model"
                                        className="form-control"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label>Manufacturer</label>
                                    <Field
                                        name="manufacturer"
                                        component="input"
                                        type="text"
                                        placeholder="Manufacturer"
                                        className="form-control"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label>Serial</label>
                                    <Field
                                        name="serial"
                                        component="input"
                                        type="text"
                                        className="form-control"
                                        placeholder="Serial"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label>Install Date</label>
                                    <Field
                                        name="install_date"
                                        component="input"
                                        type="date"
                                        placeholder="Install Date"
                                        className="form-control"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label>Demo Date</label>
                                    <Field
                                        name="demo_date"
                                        component="input"
                                        type="date"
                                        placeholder="Demo Date"
                                        className="form-control"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label>Location</label>
                                    <Field
                                        name="location"
                                        component="input"
                                        type="text"
                                        placeholder="Location"
                                        className="form-control"
                                    />
                                </FormGroup>
                            </div>
                            <div style={column}>
                                <FormGroup>
                                    <label>Select a component</label>
                                    <Field
                                        name="component_id"
                                        allowNull={true}
                                        required
                                        render={(props: any) => {
                                            return <AsyncTypeahead
                                                id="component-typeahead"
                                                isLoading={isLoading}
                                                onSearch={handleTypeahead}
                                                options={componentTypeaheadValues}
                                                labelKey={option => `${option.description}`}
                                                onChange={(selected) => typeaheadOnSelect(props, selected)}
                                                maxResults={5}
                                                maxHeight="300px"
                                            />
                                        }}
                                    />
                                </FormGroup>
                                {
                                    selectedComponentDescendents.length > 0 && selectedComponentDescendents.map((child) => {
                                        return (
                                            <FormGroup key={child.id}>
                                                <label>Select Type of {child.description}</label>
                                                <Field
                                                    name={`subComponents.${child.id}`}
                                                    component="select"
                                                    className="form-control"
                                                >
                                                    <option>None</option>
                                                    {
                                                        child.children!.map((grandchild) => {
                                                            return (
                                                                <option key={grandchild.id} value={grandchild.id}>{grandchild.description}</option>
                                                            )
                                                        })
                                                    }
                                                </Field>
                                            </FormGroup>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <FormGroup>
                            <Col md={4}>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div>
                                        <Button variant="primary" type="submit">
                                            Submit
                                        </Button>
                                    </div>
                                    <div>
                                        <Link to="/">
                                            <Button variant="secondary">
                                                Cancel
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </FormGroup>
                    </form>
                )}
            </Form>
        </>
    );
}