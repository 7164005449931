import {CaseReducer, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {buildSubStateSelector} from '../../../../store/utilities/useAppStateSelector';
import {SyrxControllerBuildingPressureTransmitter} from '../../../../models/syrxControllerBuildingPressureTransmitter';

const initialState = {
    isLoading: false,
    isDoneLoading: false,
    isSubmitted: false,
    syrxControllerBuildingPressureTransmitter: null as SyrxControllerBuildingPressureTransmitter | null
};

export type AppStateSyrxControllerBuildingPressureTransmitterEditor = typeof initialState;

export const slice = createSlice<AppStateSyrxControllerBuildingPressureTransmitterEditor, {
    startLoading: CaseReducer<AppStateSyrxControllerBuildingPressureTransmitterEditor>;
    setSyrxControllerBuildingPressureTransmitter: CaseReducer<AppStateSyrxControllerBuildingPressureTransmitterEditor, PayloadAction<SyrxControllerBuildingPressureTransmitter | null>>;
    finishLoading: CaseReducer<AppStateSyrxControllerBuildingPressureTransmitterEditor>;
    startSubmitting: CaseReducer<AppStateSyrxControllerBuildingPressureTransmitterEditor>;
    cancelSubmitting: CaseReducer<AppStateSyrxControllerBuildingPressureTransmitterEditor>;
    finishSubmitting: CaseReducer<AppStateSyrxControllerBuildingPressureTransmitterEditor>;
    resetState: CaseReducer<AppStateSyrxControllerBuildingPressureTransmitterEditor>;
}>({
    name: "syrxControllerBuildingPressureTransmitterEditor",
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
            state.isDoneLoading = false;
        },
        setSyrxControllerBuildingPressureTransmitter(state, {payload: syrxControllerBuildingPressureTransmitter}) {
            state.syrxControllerBuildingPressureTransmitter = syrxControllerBuildingPressureTransmitter;
        },
        finishLoading(state) {
            state.isLoading = false;
            state.isDoneLoading = true;
        },
        startSubmitting(state) {
            state.isLoading = true;
        },
        cancelSubmitting(state) {
            state.isLoading = false;
            state.isSubmitted = false;
        },
        finishSubmitting(state) {
            state.isLoading = false;
            state.isSubmitted = true;
        },
        resetState(state) {
            state.syrxControllerBuildingPressureTransmitter = null;
            state.isLoading = false;
            state.isDoneLoading = false;
            state.isSubmitted = false;
        }
    }
});

export const useSyrxControllerBuildingPressureTransmitterEditorSelector = buildSubStateSelector(state => state.syrxControllerBuildingPressureTransmitterEditor);
export const syrxControllerBuildingPressureTransmitterEditorActions = slice.actions;
export const syrxControllerBuildingPressureTransmitterEditorReducer = slice.reducer;