import React from 'react'
import { Container, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import revPi from '../../assets/revPi.jpg';


export const SyrxControllerRegistrationRedirect: React.FunctionComponent = () => {
    return (
        <Container>
            <Col>
                <div style={{textAlign: "center"}}>
                    <h2>Register your Pathian Syrx Controller</h2>
                    <p>Please find the controller ID located on the side of the Syrx controller device. Reference the image below to assist in locating the ID.</p>
                    <img alt="Revolution Pi" src={revPi} width={320} height={320} />
                    <p>You must be logged in to complete registration.</p>
                    <p><Link to="/login?next=register-syrx-controller">Click Here</Link> to login, or to create your account.</p>
                </div>
            </Col>
        </Container>
    );
}