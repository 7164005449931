import {CaseReducer, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {buildSubStateSelector} from '../../../store/utilities/useAppStateSelector';

const initialState = {
    showExtraColumns: false,
    showUnavailableDevices: false,
    isUpdating: false,
    refreshTrigger: null as any
};

export type AppStateSyrxControllerBacnetDevicesGrid = typeof initialState;

export const slice = createSlice<AppStateSyrxControllerBacnetDevicesGrid, {
    setShowExtraColumns: CaseReducer<AppStateSyrxControllerBacnetDevicesGrid, PayloadAction<boolean>>;
    setShowUnavailableDevices: CaseReducer<AppStateSyrxControllerBacnetDevicesGrid, PayloadAction<boolean>>;
    setIsUpdating: CaseReducer<AppStateSyrxControllerBacnetDevicesGrid, PayloadAction<boolean>>;
    triggerRefresh: CaseReducer<AppStateSyrxControllerBacnetDevicesGrid>;
}>({
    name: "syrxControllerBacnetDevicesGrid",
    initialState,
    reducers: {
        setShowExtraColumns(state, {payload: showExtraColumns}) {
            state.showExtraColumns = showExtraColumns;
        },
        setShowUnavailableDevices(state, {payload: showUnavailableDevices}) {
            state.showUnavailableDevices = showUnavailableDevices;
        },
        setIsUpdating(state, {payload: isUpdating}) {
            state.isUpdating = isUpdating;
        },
        triggerRefresh(state) {
            state.refreshTrigger = {};
        }
    }
});

export const useSyrxControllerBacnetDevicesGridSelector = buildSubStateSelector(state => state.syrxControllerBacnetDevicesGrid);
export const syrxControllerBacnetDevicesGridActions = slice.actions;
export const syrxControllerBacnetDevicesGridReducer = slice.reducer;