import React from 'react'
import {
    useSyrxControllerDiagnosticServiceDetailsSelector
} from './syrxControllerDiagnosticServiceDetailsSlice';

export const SyrxControllerDiagnosticServiceRabbitmqMetricsQueues: React.FunctionComponent = () => {
    const queues = useSyrxControllerDiagnosticServiceDetailsSelector(state => state.latestRabbitmqMetricsCollectionRecord?.queues);

    return (
        <>
            <table className="table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Policy</th>
                        <th>Ready</th>
                        <th>Unack</th>
                        <th>Total</th>
                        <th>First seen</th>
                        <th>Last updated</th>
                    </tr>
                </thead>
                <tbody>
                    {queues?.map(x => (
                        <tr key={x.name}>
                            <td>{x.name}</td>
                            <td>{x.policy}</td>
                            <td>{x.numMessagesReady}</td>
                            <td>{x.numMessagesUnacknowledged}</td>
                            <td>{x.numMessagesTotal}</td>
                            <td>{x.firstSeenTimestamp}</td>
                            <td>{x["@timestamp"]}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
};