import React, {FunctionComponent, useCallback, useEffect, useState} from 'react'
import dayjs from 'dayjs';

import FancyGrid, { SortCollection } from '@sht-dev/fancy-grid';

import {
    getSyrxControllerBacnetDevicesForGrid
} from '../../../services/syrxControllersService';
import {SyrxControllerBacnetDevice} from '../../../models/syrxControllerBacnetDevice';
import {useAppStateSelector} from '../../../store/utilities/useAppStateSelector';
import {
    ignoreDeviceActionCreator,
    unignoreDeviceActionCreator
} from '../../../actionCreators/syrxControllerBacnetDevicesGridActionCreators';
import {useDispatch} from 'react-redux';
import {LoadingSpinner} from '../../loadingSpinner/LoadingSpinner';
import {syrxControllerBacnetDevicesGridActions} from './syrxControllerBacnetDevicesGridSlice';
import {Button} from 'react-bootstrap';
import {useSyrxControllerBacnetNetworkSocketHelper} from '../../../hooks/useSyrxControllerBacnetNetworkSocketHelper';
import {LinkContainer} from 'react-router-bootstrap';
import {SyrxControllerBacnetDevicesDiscoverModal} from './syrxControllerBacnetDevicesDiscoverModal';
import {SyrxControllerBacnetDeviceReloadModal} from './syrxControllerBacnetDeviceReloadModal';
import {SyrxControllerBacnetDeviceIdentification} from '../../../models/syrxControllerBacnetDeviceIdentification';
import {SyrxControllerBacnetDeviceReloadPointsModal} from './syrxControllerBacnetDeviceReloadPointsModal';

const ReloadDevicePropertiesButton: FunctionComponent<{disabled: boolean, syrxControllerBacnetDevice: SyrxControllerBacnetDevice, reloadPropertiesForDevice: (syrxControllerBacnetDeviceIdentification: SyrxControllerBacnetDeviceIdentification) => void}> = props => {
    const title = props.disabled ? "Inactive while disconnected from agent" : "Reload device";
    return <Button disabled={props.disabled} title={title} variant="outline-secondary" onClick={() => props.reloadPropertiesForDevice(props.syrxControllerBacnetDevice)}><i className="fas fa-redo-alt" /></Button>
}

const IgnoreDeviceButton: FunctionComponent<{disabled: boolean, syrxControllerBacnetDeviceId: string, ignoreDevice: (syrxControllerBacnetDeviceId: string) => void}> = props => {
    const title = props.disabled ? "Inactive while disconnected from agent" : "Ignore device";
    return <Button disabled={props.disabled} title={title} variant="outline-secondary" onClick={() => props.ignoreDevice(props.syrxControllerBacnetDeviceId)}><i className="fas fa-trash" /></Button>
}

const UnignoreDeviceButton: FunctionComponent<{disabled: boolean, syrxControllerBacnetDeviceId: string, unignoreDevice: (syrxControllerBacnetDeviceId: string) => void}> = props => {
    const title = props.disabled ? "Inactive while disconnected from agent" : "Unignore device";
    return <Button disabled={props.disabled} title={title} variant="outline-secondary" onClick={() => props.unignoreDevice(props.syrxControllerBacnetDeviceId)}><i className="fas fa-trash-restore" /></Button>
}

const ReloadPointsButton: FunctionComponent<{disabled: boolean, syrxControllerBacnetDevice: SyrxControllerBacnetDevice, reloadPoints: (device: SyrxControllerBacnetDevice) => void}> = props => {
    const title = props.disabled ? "Inactive while disconnected from agent" : "Reload points for device";
    return <Button disabled={props.disabled} title={title} variant="outline-secondary" onClick={() => props.reloadPoints(props.syrxControllerBacnetDevice)}><i className="fas fa-sitemap" /></Button>
}

const GridOptionsContainer: FunctionComponent<{isSocketAgentConnected: boolean, isSocketServerConnected: boolean}> = ({isSocketAgentConnected, isSocketServerConnected}) => {
    const dispatch = useDispatch();
    const showExtraColumns = useAppStateSelector(state => state.syrxControllerBacnetDevicesGrid.showExtraColumns);
    const showUnavailableDevices = useAppStateSelector(state => state.syrxControllerBacnetDevicesGrid.showUnavailableDevices);

    const setShowExtraColumns = (newShowDateColumns: string) => {
        dispatch(syrxControllerBacnetDevicesGridActions.setShowExtraColumns(newShowDateColumns === 'Yes'));
    };

    const setShowUnavailableDevices = (newShowUnavailableDevices: string) => {
        dispatch(syrxControllerBacnetDevicesGridActions.setShowUnavailableDevices(newShowUnavailableDevices === 'Yes'));
    };

    return (
        <table>
            <tbody>
            <tr>
                <td>
                    <div className="form-control-plaintext">Server status:</div>
                </td>
                <td style={{textAlign: "center"}}>
                    {isSocketServerConnected && <span style={{color: "rgb(0, 204, 0)", opacity: 1, fontSize: "16pt"}}><i className="fas fa-wifi"/></span>}
                    {!isSocketServerConnected && <span style={{color: "rgb(204, 0, 0)", opacity: 1, fontSize: "16pt"}}><i className="fas fa-times-circle"/></span>}
                </td>
            </tr>
            <tr>
                <td>
                    <div className="form-control-plaintext">Agent status:</div>
                </td>
                <td style={{textAlign: "center"}}>
                    {isSocketAgentConnected && <span style={{color: "rgb(0, 204, 0)", opacity: 1, fontSize: "16pt"}}><i className="fas fa-wifi"/></span>}
                    {!isSocketAgentConnected && <span style={{color: "rgb(204, 0, 0)", opacity: 1, fontSize: "16pt"}}><i className="fas fa-times-circle"/></span>}
                </td>
            </tr>
            <tr>
                <td>
                    Show extra columns:
                </td>
                <td>
                    <select value={showExtraColumns ? 'Yes' : 'No'} onChange={e => setShowExtraColumns(e.target.value)} className="form-control">
                        <option>Yes</option>
                        <option>No</option>
                    </select>
                </td>
            </tr>
            <tr>
                <td>
                    Show unavailable devices:
                </td>
                <td>
                    <select value={showUnavailableDevices ? 'Yes' : 'No'} onChange={e => setShowUnavailableDevices(e.target.value)} className="form-control">
                        <option>Yes</option>
                        <option>No</option>
                    </select>
                </td>
            </tr>
            </tbody>
        </table>
    );
};

export interface SyrxControllerBacnetDevicesGridProps {
    syrxControllerId: string;
    isSocketServerConnected: boolean;
    isSocketAgentConnected: boolean;
    onReloadDevice: (deviceIdentification: SyrxControllerBacnetDeviceIdentification) => any;
    onReloadDevicePoints: (device: SyrxControllerBacnetDevice) => any;
}

export const SyrxControllerBacnetDevicesGrid: FunctionComponent<SyrxControllerBacnetDevicesGridProps> = props => {
    const {
        syrxControllerId,
        isSocketServerConnected,
        isSocketAgentConnected,
        onReloadDevice,
        onReloadDevicePoints
    } = props;

    const dispatch = useDispatch();

    const showExtraColumns = useAppStateSelector(state => state.syrxControllerBacnetDevicesGrid.showExtraColumns);
    const showUnavailableDevices = useAppStateSelector(state => state.syrxControllerBacnetDevicesGrid.showUnavailableDevices);
    const isUpdating = useAppStateSelector(state => state.syrxControllerBacnetDevicesGrid.isUpdating);
    const refreshTrigger = useAppStateSelector(state => state.syrxControllerBacnetDevicesGrid.refreshTrigger);

    const dataRetrievalFunction = (pageNumber: number, pageSize: number, sort: SortCollection) => getSyrxControllerBacnetDevicesForGrid(syrxControllerId, showUnavailableDevices, pageNumber, pageSize, sort);

    const ignoreDevice = useCallback((syrxControllerBacnetDeviceId: string) => {
        dispatch(ignoreDeviceActionCreator(syrxControllerId, syrxControllerBacnetDeviceId));
    }, [dispatch, syrxControllerId]);

    const unignoreDevice = useCallback((syrxControllerBacnetDeviceId: string) => {
        dispatch(unignoreDeviceActionCreator(syrxControllerId, syrxControllerBacnetDeviceId));
    }, [dispatch, syrxControllerId]);

    return (
        <>
            <GridOptionsContainer
                isSocketAgentConnected={isSocketAgentConnected}
                isSocketServerConnected={isSocketServerConnected}
            />
            {isUpdating ? <LoadingSpinner /> : null}
            <FancyGrid.ReduxGrid
                gridName={"SyrxControllerBacnetDevicesGrid"}
                dataRetrievalFunction={dataRetrievalFunction}
                updateTriggers={[
                    syrxControllerId,
                    showUnavailableDevices,
                    refreshTrigger
                ]}
            >
                <FancyGrid.ColumnList>
                    <FancyGrid.Column
                        name="mac_address"
                        title="MAC address"
                    >
                        <FancyGrid.CellRenderer>
                            {(_, device: SyrxControllerBacnetDevice) => <>
                                <span>{device.mac_address} [{device.network}]</span>
                            </>}
                        </FancyGrid.CellRenderer>
                    </FancyGrid.Column>
                    {showExtraColumns ? (
                        <FancyGrid.Column
                            name="destination_mac_address"
                            title="Destination address"
                        >
                            <FancyGrid.CellRenderer>
                                {(_, device: SyrxControllerBacnetDevice) => <>
                                    {device.destination_mac_address !== '1.0.0.0:0' || device.destination_network !== 0 ? (<span>{device.destination_mac_address} [{device.destination_network}]</span>) : null}
                                </>}
                            </FancyGrid.CellRenderer>
                        </FancyGrid.Column>
                    ) : null}
                    <FancyGrid.Column
                        name="instance_number"
                        title="Object ID"
                    >
                        <FancyGrid.CellRenderer>
                            {(instanceNumber) => <>
                                OBJECT_DEVICE:{instanceNumber}
                            </>}
                        </FancyGrid.CellRenderer>
                    </FancyGrid.Column>
                    <FancyGrid.Column
                        name="name"
                        title="Device name"
                    />
                    <FancyGrid.Column
                        name="description"
                        title="Description"
                    />
                    <FancyGrid.Column
                        name="vendor_name"
                        title="Vendor name"
                    />
                    {showExtraColumns ? (
                        <FancyGrid.Column
                            name="first_available_on"
                            title="First available"
                        >
                            <FancyGrid.CellRenderer>
                                {(firstAvailableOn: string | null) => <>{firstAvailableOn != null ? dayjs.utc(firstAvailableOn).format("YYYY-MM-DD hh:mm a") : null}</>}
                            </FancyGrid.CellRenderer>
                        </FancyGrid.Column>
                    ) : null}
                    {showExtraColumns ? (
                        <FancyGrid.Column
                            name="last_available_on"
                            title="Last available"
                        >
                            <FancyGrid.CellRenderer>
                                {(lastAvailableOn: string | null) => <>{lastAvailableOn != null ? dayjs.utc(lastAvailableOn).format("YYYY-MM-DD hh:mm a") : null}</>}
                            </FancyGrid.CellRenderer>
                        </FancyGrid.Column>
                    ) : null}
                    {showExtraColumns ? (
                        <FancyGrid.Column
                            name="last_unavailable_on"
                            title="Last unavailable"
                        >
                            <FancyGrid.CellRenderer>
                                {(lastUnavailableOn: string | null) => <>{lastUnavailableOn != null ? dayjs.utc(lastUnavailableOn).format("YYYY-MM-DD hh:mm a") : null}</>}
                            </FancyGrid.CellRenderer>
                        </FancyGrid.Column>
                    ) : null}
                    {showExtraColumns ? (
                        <FancyGrid.Column
                            name="last_point_discovery"
                            title="Last point discovery"
                        >
                            <FancyGrid.CellRenderer>
                                {(lastPointDiscovery: string | null) => <>{lastPointDiscovery != null ? dayjs.utc(lastPointDiscovery).format("YYYY-MM-DD hh:mm a") : null}</>}
                            </FancyGrid.CellRenderer>
                        </FancyGrid.Column>
                    ) : null}

                    <FancyGrid.Column
                        name="status"
                        title="Availability"
                    />

                    <FancyGrid.Column
                        sortable={false}
                        filterable={false}
                        title="Actions"
                    >
                        <FancyGrid.CellRenderer>
                            {(_, device: SyrxControllerBacnetDevice) => (
                                <>
                                    {['unknown', 'available', 'unavailable', 'pending'].includes(device.status) ? <ReloadDevicePropertiesButton disabled={!isSocketServerConnected || !isSocketAgentConnected} syrxControllerBacnetDevice={device} reloadPropertiesForDevice={onReloadDevice} /> : null}
                                    {['unknown', 'available', 'unavailable', 'pending'].includes(device.status) ? <IgnoreDeviceButton disabled={!isSocketServerConnected || !isSocketAgentConnected} syrxControllerBacnetDeviceId={device.id} ignoreDevice={ignoreDevice} /> : null}
                                    {['ignored'].includes(device.status) ? <UnignoreDeviceButton disabled={!isSocketServerConnected || !isSocketAgentConnected} syrxControllerBacnetDeviceId={device.id} unignoreDevice={unignoreDevice} /> : null}
                                    {['available'].includes(device.status) ? <ReloadPointsButton disabled={!isSocketServerConnected || !isSocketAgentConnected} syrxControllerBacnetDevice={device} reloadPoints={onReloadDevicePoints} /> : null}
                                </>
                            )}
                        </FancyGrid.CellRenderer>
                    </FancyGrid.Column>
                </FancyGrid.ColumnList>
                <FancyGrid.ReduxSortable />
                <FancyGrid.ReduxPager />
            </FancyGrid.ReduxGrid>
        </>
    )
};

export const SyrxControllerBacnetDevicesManager: React.FunctionComponent<{syrxControllerId: string}> = ({syrxControllerId}) => {
    const dispatch = useDispatch();
    const socketHelper = useSyrxControllerBacnetNetworkSocketHelper(syrxControllerId);
    const [showDiscoverDevicesModal, setShowDiscoverDevicesModal] = useState(false);
    const [reloadDeviceModalDeviceIdentification, setReloadDeviceModalDeviceIdentification] = useState(null as SyrxControllerBacnetDeviceIdentification | null);
    const [reloadDevicePointsModalDevice, setReloadDevicePointsModalDevice] = useState(null as SyrxControllerBacnetDevice | null);
    const [isSocketServerConnected, setIsSocketServerConnected] = useState(false);
    const [isSocketAgentConnected, setIsSocketAgentConnected] = useState(false);

    useEffect(() => {
        if (socketHelper == null) {
            return;
        }

        socketHelper
            .on("connect", () => {
                setIsSocketServerConnected(true);
            })
            .on("disconnect", () => {
                setIsSocketServerConnected(false);
                setIsSocketAgentConnected(false);
            })
            .on("agentStatus", ({connected}: {connected: boolean}) => {
                setIsSocketAgentConnected(connected);
            });

        socketHelper.initialize();
    }, [socketHelper]);

    const doDiscoverDevices = useCallback(() => {
        setShowDiscoverDevicesModal(true);
    }, []);

    const doReloadDevice = useCallback((deviceIdentification: SyrxControllerBacnetDeviceIdentification) => {
        setReloadDeviceModalDeviceIdentification(deviceIdentification);
    }, []);

    const doReloadDevicePoints = useCallback((device: SyrxControllerBacnetDevice) => {
        setReloadDevicePointsModalDevice(device);
    }, []);

    const onDiscoverDevicesModalClose = useCallback(() => {
        setShowDiscoverDevicesModal(false);
        dispatch(syrxControllerBacnetDevicesGridActions.triggerRefresh());
    }, [dispatch]);

    const onReloadDeviceModalClose = useCallback(() => {
        setReloadDeviceModalDeviceIdentification(null);
        dispatch(syrxControllerBacnetDevicesGridActions.triggerRefresh());
    }, [dispatch]);

    const onReloadDevicePointsModalClose = useCallback(() => {
        setReloadDevicePointsModalDevice(null);
        dispatch(syrxControllerBacnetDevicesGridActions.triggerRefresh());
    }, [dispatch]);

    return (
        <>
            {socketHelper != null && showDiscoverDevicesModal && (
                <SyrxControllerBacnetDevicesDiscoverModal
                    syrxControllerId={syrxControllerId}
                    socketHelper={socketHelper}
                    onClose={onDiscoverDevicesModalClose}
                />
            )}
            {socketHelper != null && reloadDeviceModalDeviceIdentification != null && (
                <SyrxControllerBacnetDeviceReloadModal
                    syrxControllerId={syrxControllerId}
                    deviceIdentification={reloadDeviceModalDeviceIdentification}
                    socketHelper={socketHelper}
                    onClose={onReloadDeviceModalClose}
                />
            )}
            {socketHelper != null && reloadDevicePointsModalDevice != null && (
                <SyrxControllerBacnetDeviceReloadPointsModal
                    syrxControllerId={syrxControllerId}
                    device={reloadDevicePointsModalDevice}
                    socketHelper={socketHelper}
                    onClose={onReloadDevicePointsModalClose}
                />
            )}
            <h4>Devices</h4>
            <SyrxControllerBacnetDevicesGrid
                syrxControllerId={syrxControllerId}
                isSocketServerConnected={isSocketServerConnected}
                isSocketAgentConnected={isSocketAgentConnected}
                onReloadDevice={doReloadDevice}
                onReloadDevicePoints={doReloadDevicePoints}
            />
            <LinkContainer to={`/admin/syrx-controllers/${syrxControllerId}/devices/add`}>
                <Button variant="outline-info">Manually add device</Button>
            </LinkContainer>
            <Button
                title={(!isSocketServerConnected || !isSocketAgentConnected) ? "Inactive while disconnected from agent": ""}
                disabled={!isSocketServerConnected || !isSocketAgentConnected}
                variant="outline-info"
                onClick={doDiscoverDevices}
            >
                Discover devices
            </Button>
        </>
    )
};