import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { buildSubStateSelector } from '../../store/utilities/useAppStateSelector';
import { SyrxControllerSize, SyrxControllerNodeTypesDict, Cart, Product, SyrxControllerOrder, SyrxControllerNodeCategory } from './types';

const initialState = {
    purchasingPages: [
        "Syrx Controller",
        "Central Utility Plant Systems",
        "HVAC Equipment",
        "Specialty Building Systems/Compliance",
        "Building Level Metering",
        "Syrx Energy Modeling",
        "Cost Summary"
    ] as string[],
    currentPage: {} as SyrxControllerNodeCategory,
    pages: [] as SyrxControllerNodeCategory[],
    syrxControllerSizes: [] as SyrxControllerSize[],
    syrxControllerNodeTypes: {} as SyrxControllerNodeTypesDict,
    syrxControllerNodeCategories: [] as SyrxControllerNodeCategory[],
    syrxControllerSizeCart: {} as Product,
    syrxControllerNodeTypeCart: {} as Cart,
    syrxControllerNodeCount: 0,
    otherNodeCount: 0,
    syrxControllerOrder: {} as SyrxControllerOrder,
    stripeErrorMessage: undefined as string | undefined,
    isProcessingPayment: false
};

export type AppStatePurchasing = typeof initialState;

export const slice = createSlice<AppStatePurchasing, {
    setPages: CaseReducer<AppStatePurchasing, PayloadAction<SyrxControllerNodeCategory[]>>;
    setCurrentPage: CaseReducer<AppStatePurchasing, PayloadAction<number>>;
    setSyrxControllerSizes: CaseReducer<AppStatePurchasing, PayloadAction<SyrxControllerSize[]>>;
    setNodeTypes: CaseReducer<AppStatePurchasing, PayloadAction<SyrxControllerNodeTypesDict>>;
    setSyrxControllerNodeCategories: CaseReducer<AppStatePurchasing, PayloadAction<SyrxControllerNodeCategory[]>>;
    setCartSyrxController: CaseReducer<AppStatePurchasing, PayloadAction<Product>>;
    setCartProduct: CaseReducer<AppStatePurchasing, PayloadAction<{productId: string, product: Product}>>;
    setSyrxControllerOrder: CaseReducer<AppStatePurchasing, PayloadAction<SyrxControllerOrder>>;
    setStripeErrorMessage: CaseReducer<AppStatePurchasing, PayloadAction<string | undefined>>;
    setIsProcessingPayment: CaseReducer<AppStatePurchasing, PayloadAction<boolean>>;
}>({
    name: "purchasing",
    initialState,
    reducers: {
        setPages(state, action) {
            const syrxControllerSizePage = { id: 'syrxController', name: 'Syrx Controller' };
            const costSummaryPage = { id: 'costSummary', name: 'Cost Summary' }
            state.pages = [syrxControllerSizePage, ...action.payload, costSummaryPage];
            state.currentPage = state.pages[0];
        },
        setCurrentPage(state, action) {
            const currentPageIndex = state.pages.findIndex(page => page.id === state.currentPage.id);
            state.currentPage = state.pages[currentPageIndex + action.payload];
        },
        setSyrxControllerSizes(state, action) {
            state.syrxControllerSizes = action.payload;
        },
        setNodeTypes(state, action) {
            state.syrxControllerNodeTypes = action.payload;
        },
        setSyrxControllerNodeCategories(state, action) {
            state.syrxControllerNodeCategories = action.payload;
        },
        setCartSyrxController(state, action) {
            state.syrxControllerSizeCart = action.payload;
            state.syrxControllerNodeCount = Object.keys(state.syrxControllerSizeCart).reduce((acc, key) => acc + (state.syrxControllerSizeCart[key] * state.syrxControllerSizes.find((size) => size.id === key)?.number_of_nodes!), 0);
        },
        setCartProduct(state, action) {
            state.syrxControllerNodeTypeCart[action.payload.productId] = action.payload.product;
            state.otherNodeCount = Object.values(Object.values(state.syrxControllerNodeTypeCart).reduce((acc, item) => ({...acc, ...item}), {})).reduce((acc, quantity) => acc + parseInt(quantity), 0);
        },
        setSyrxControllerOrder(state, action) {
            state.syrxControllerOrder = action.payload;
            state.isProcessingPayment = false;
        },
        setStripeErrorMessage(state, action) {
            state.stripeErrorMessage = action.payload;
            state.isProcessingPayment = false;
        },
        setIsProcessingPayment(state, action) {
            state.isProcessingPayment = action.payload;
        }
    }
});

export const usePurchasingSelector = buildSubStateSelector(state => state.purchasing);
export const purchasingActions = slice.actions;
export const purchasingReducer = slice.reducer;