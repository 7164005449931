import React from 'react'
import { Tree } from '@sht-dev/fancy-tree';
import {getChildrenOf as getChildGroupsOf} from '../../services/groupsService';

export interface GroupSelectionTreeProps {
    onSelect: (groupId: string) => void;
    selectedGroupId: string | undefined;
}

export const GroupSelectionTree = (props: GroupSelectionTreeProps) => {

    const getChildGroups = async (parentGroupId: string | null) => {
        const {data: groups} = await getChildGroupsOf(parentGroupId != null ? parseInt(parentGroupId) : null);
        return groups.map((group: any) => ({
            id: group.id.toString(),
            name: group.name
        }))
    }

    return (
        <Tree 
            getChildren={getChildGroups}
            onSelect={props.onSelect}
            selected={props.selectedGroupId}
        />
    );
}