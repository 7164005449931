import {CaseReducer, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {SyrxControllerEnabledService} from '../../../../models/syrxControllerEnabledService';
import {SyrxControllerServiceType} from '../../../../models/syrxControllerServiceType';
import {buildSubStateSelector} from '../../../../store/utilities/useAppStateSelector';
import {SyrxController} from '../../../../models/syrxController';

const initialState = {
    isLoading: false,
    isDoneLoading: false,
    isSubmitting: false,
    isDoneSubmitting: false,
    syrxController: null as SyrxController | null,
    syrxControllerServiceTypes: null as SyrxControllerServiceType[] | null,
    syrxControllerEnabledServices: null as SyrxControllerEnabledService[] | null,
};

export type AppStateSyrxControllerEnabledServicesManager = typeof initialState;

export const slice = createSlice<AppStateSyrxControllerEnabledServicesManager, {
    startLoading: CaseReducer<AppStateSyrxControllerEnabledServicesManager>;
    setSyrxController: CaseReducer<AppStateSyrxControllerEnabledServicesManager, PayloadAction<SyrxController | null>>;
    setSyrxControllerServiceTypes: CaseReducer<AppStateSyrxControllerEnabledServicesManager, PayloadAction<SyrxControllerServiceType[] | null>>;
    setSyrxControllerEnabledServices: CaseReducer<AppStateSyrxControllerEnabledServicesManager, PayloadAction<SyrxControllerEnabledService[] | null>>;
    finishLoading: CaseReducer<AppStateSyrxControllerEnabledServicesManager>;
    startSubmitting: CaseReducer<AppStateSyrxControllerEnabledServicesManager>;
    cancelSubmitting: CaseReducer<AppStateSyrxControllerEnabledServicesManager>;
    finishSubmitting: CaseReducer<AppStateSyrxControllerEnabledServicesManager>;
    resetState: CaseReducer<AppStateSyrxControllerEnabledServicesManager>;
}>({
    name: "syrxControllerEnabledServicesManager",
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
            state.isDoneLoading = false;
        },
        setSyrxController(state, {payload: syrxController}) {
            state.syrxController = syrxController;
        },
        setSyrxControllerServiceTypes(state, {payload: syrxControllerServiceTypes}) {
            state.syrxControllerServiceTypes = syrxControllerServiceTypes;
        },
        setSyrxControllerEnabledServices(state, {payload: syrxControllerEnabledServices}) {
            state.syrxControllerEnabledServices = syrxControllerEnabledServices;
        },
        finishLoading(state) {
            state.isLoading = false;
            state.isDoneLoading = true;
        },
        startSubmitting(state) {
            state.isSubmitting = true;
            state.isDoneSubmitting = false;
        },
        cancelSubmitting(state) {
            state.isSubmitting = false;
        },
        finishSubmitting(state) {
            state.isSubmitting = false;
            state.isDoneSubmitting = true;
        },
        resetState(state) {
            state.isLoading = false;
            state.isDoneLoading = false;
            state.isSubmitting = false;
            state.isDoneSubmitting = false;
            state.syrxController = null;
            state.syrxControllerServiceTypes = null;
            state.syrxControllerEnabledServices = null;
        }
    }
});

export const useSyrxControllerEnabledServicesManagerSelector = buildSubStateSelector(state => state.syrxControllerEnabledServicesManager);
export const syrxControllerEnabledServicesManagerActions = slice.actions;
export const syrxControllerEnabledServicesManagerReducer = slice.reducer;