import { Dispatch } from "redux";
import {
    getBuildingPressureControlServiceConfigurationForSyrxController, getSyrxControllerBacnetDevice, getSyrxControllerBacnetPoint
} from '../../../services/syrxControllersService';
import {syrxControllerBuildingPressureControlServiceDetailsActions} from './syrxControllerBuildingPressureControlServiceDetailsSlice';

export const syrxControllerBuildingPressureControlServiceDetailsActionCreators = {
    loadConfiguration: (syrxControllerId: string) => async (dispatch: Dispatch<any>) => {
        dispatch(syrxControllerBuildingPressureControlServiceDetailsActions.startLoading());

        try {

            const syrxControllerBuildingPressureControlServiceConfiguration = await getBuildingPressureControlServiceConfigurationForSyrxController(syrxControllerId);
            const oatSyrxControllerBacnetPoint = syrxControllerBuildingPressureControlServiceConfiguration?.oat_syrx_controller_bacnet_point_id != null ?
                (await getSyrxControllerBacnetPoint(syrxControllerId, syrxControllerBuildingPressureControlServiceConfiguration.oat_syrx_controller_bacnet_point_id)) :
                null;
            const oatSyrxControllerBacnetDevice = oatSyrxControllerBacnetPoint?.syrx_controller_bacnet_device_id != null ?
                (await getSyrxControllerBacnetDevice(syrxControllerId, oatSyrxControllerBacnetPoint.syrx_controller_bacnet_device_id)) :
                null;
            dispatch(syrxControllerBuildingPressureControlServiceDetailsActions.setSyrxControllerPressureConfiguration({syrxControllerBuildingPressureControlServiceConfiguration}));
            dispatch(syrxControllerBuildingPressureControlServiceDetailsActions.setOatSyrxControllerBacnetPoint({oatSyrxControllerBacnetPoint}));
            dispatch(syrxControllerBuildingPressureControlServiceDetailsActions.setOatSyrxControllerBacnetDevice({oatSyrxControllerBacnetDevice}));
        } finally {
            dispatch(syrxControllerBuildingPressureControlServiceDetailsActions.finishLoading());
        }
    }
}
