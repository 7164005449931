import React from 'react'
import { Form } from 'react-final-form';
import { Field } from 'react-final-form-html5-validation';
import { Alert, Button, FormGroup, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { submitNewUserActionCreator } from '../../actionCreators/userRegistrationActionCreators';
import {NewUserFormValues} from './types';
import {useUserRegistrationSelector} from './userRegistrationSlice';




export const UserRegistration: React.FunctionComponent = () => {
    const newUserState = useUserRegistrationSelector(x => x);
    const dispatch = useDispatch();
    let passwordDiff = false;

    const onSubmit = async (values: NewUserFormValues) => {
        if (values.password !== values.passwordConfirmation) {
            passwordDiff = true;
            return;
        }
        passwordDiff = false;

        dispatch(submitNewUserActionCreator(values));
    }

    return (
        <Form
            onSubmit={onSubmit}
        >
            {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    {newUserState.isSuccessful ? (
                        <FormGroup>
                            <Col md={4}>
                                <h2>Success, user account created!</h2>
                                <p>Please check your email for the link to verify your email address and complete user registration.</p>
                            </Col>
                        </FormGroup>
                    ) : null}
                    {!newUserState.isSuccessful ? (
                        <FormGroup>
                            <Col md={4}>
                                <h2>Create a new user account</h2>
                            </Col>
                        </FormGroup>
                    ) : null}
                    {passwordDiff ? (
                        <FormGroup>
                            <Col md={12}>
                                <Alert variant="danger">Invalid email and/or password</Alert>
                            </Col>
                        </FormGroup>
                    ) : null}
                    {!newUserState.isSuccessful ? (
                        <>
                            <FormGroup>
                                <Col md={4}>
                                    <Field
                                        name="email"
                                        component="input"
                                        type="email"
                                        placeholder="Email"
                                        className="form-control"
                                        required
                                        patternMismatch="Invalid email address entered"
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col md={4}>
                                    <Field
                                        name="password"
                                        component="input"
                                        type="password"
                                        placeholder="Password"
                                        className="form-control"
                                        required
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col md={4}>
                                    <Field
                                        name="passwordConfirmation"
                                        component="input"
                                        type="password"
                                        placeholder="Retype Password"
                                        className="form-control"
                                        required
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col md={4}>
                                    <Field
                                        name="firstName"
                                        component="input"
                                        type="text"
                                        placeholder="First Name"
                                        className="form-control"
                                        required
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col md={4}>
                                    <Field
                                        name="lastName"
                                        component="input"
                                        type="text"
                                        placeholder="Last Name"
                                        className="form-control"
                                        required
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col md={4}>
                                    <Field
                                        name="address"
                                        component="input"
                                        type="text"
                                        placeholder="Address"
                                        className="form-control"
                                        required
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col md={4}>
                                    <Field
                                        name="city"
                                        component="input"
                                        type="text"
                                        placeholder="City"
                                        className="form-control"
                                        required
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col md={4}>
                                    <Field
                                        name="state"
                                        component="input"
                                        type="text"
                                        placeholder="State"
                                        className="form-control"
                                        required
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col md={4}>
                                    <Field
                                        name="zip"
                                        component="input"
                                        type="text"
                                        placeholder="Zip"
                                        className="form-control"
                                        required
                                        minLength={5}
                                        maxLength={10}
                                        tooShort="Zip code should be at least 5 digits"
                                        tooLong="Zip code should be no more than 10 digits"
                                        pattern="^[0-9]{5}(?:-[0-9]{4})?$"
                                        patternMismatch="Zip code should be of pattern ##### or #####-####"
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col md={4}>
                                    <Field
                                        name="jobTitle"
                                        component="input"
                                        type="text"
                                        placeholder="Job Title"
                                        className="form-control"
                                        required
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col md={4}>
                                    <Field
                                        name="groupName"
                                        component="input"
                                        type="text"
                                        placeholder="Organization Name"
                                        className="form-control"
                                        required
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup>
                                <Col md={4}>
                                    <Button variant="primary" type="submit">
                                        Register
                                    </Button>
                                </Col>
                            </FormGroup>
                        </>
                    ) : null}
                </form>
            )}
        </Form>
    );
}