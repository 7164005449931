import axiosStatic, {AxiosError, AxiosInstance} from 'axios';
import {newApiUrl} from '../config';
import {history} from '../store/history';

const axios = axiosStatic.create({
    baseURL: newApiUrl,
    withCredentials: true,
    xsrfHeaderName: "X-CSRF-TOKEN",
    xsrfCookieName: "csrf_access_token",
});

axios.interceptors.response.use(undefined, async (error: AxiosError) => {
    if (error.response?.status === 401 && error.response?.config.url !== '/auth') {
        try {
            await refreshAuthCookie();
            return await axios(error.config);
        } catch (refreshAuthCookieError) {
            history.push("/");
        }
    }

    throw error;
})

const refreshAxios = axiosStatic.create({
    baseURL: newApiUrl,
    withCredentials: true,
    xsrfHeaderName: "X-CSRF-TOKEN",
    xsrfCookieName: "csrf_refresh_token"
});

const refreshAuthCookie = async () => {
    await refreshAxios.post("/auth/refresh");
}

export class BaseApiService {
    protected axios: AxiosInstance;

    constructor() {
        this.axios = axios;
    }

    async refreshAuthCookie() {
        await refreshAuthCookie();
    }
}