import React, {FunctionComponent, useEffect, useRef, useState} from 'react';

export const ScrollToBottom: FunctionComponent<React.HTMLAttributes<HTMLDivElement>> = ({style, children}) => {
    const scrollerRef = useRef(null as HTMLDivElement | null);
    const scrollStickyRef = useRef(null as HTMLDivElement | null);
    const isInitializedRef = useRef(false);
    const [doSticky, setDoSticky] = useState(true);

    useEffect(() => {
        const timeout = setInterval(() => {
            if (!isInitializedRef.current && scrollerRef.current != null && scrollStickyRef.current != null) {
                isInitializedRef.current = true;

                scrollerRef.current.addEventListener("scroll", function event(this: HTMLDivElement) {
                    const scrollHeight = this.scrollHeight;
                    const scrollTop = this.scrollTop;
                    const clientHeight = this.clientHeight;
                    const scrollBottom = scrollTop + clientHeight;

                    const newDoSticky = scrollBottom === scrollHeight;

                    setDoSticky(newDoSticky);
                });
            }

            if (isInitializedRef.current) {
                clearInterval(timeout);
            }
        }, 100);
    }, []);

    useEffect(() => {
        if (doSticky && scrollStickyRef.current != null) {
            scrollStickyRef.current.scrollIntoView();
        }
    }, [children, doSticky]);

    return (
        <div style={{...style, overflowY: "auto"}} ref={scrollerRef}>
            {children}
            <div ref={scrollStickyRef} />
        </div>
    )
}