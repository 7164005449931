import React, {FunctionComponent, useCallback} from 'react'
import dayjs from 'dayjs';

import FancyGrid, { SortCollection } from '@sht-dev/fancy-grid';

import { getSyrxControllerBacnetDevicesForGrid } from '../../../services/syrxControllersService';
import {SyrxControllerBacnetDevice} from '../../../models/syrxControllerBacnetDevice';
import {useAppStateSelector} from '../../../store/utilities/useAppStateSelector';
import {
    reloadPropertiesForDeviceActionCreator,
    ignoreDeviceActionCreator,
    unignoreDeviceActionCreator,
    reloadPointsForDeviceActionCreator
} from '../../../actionCreators/syrxControllerBacnetDevicesGridActionCreators';
import {useDispatch} from 'react-redux';
import {LoadingSpinner} from '../../loadingSpinner/LoadingSpinner';
import {syrxControllerBacnetDevicesGridActions} from './syrxControllerBacnetDevicesGridSlice';
import {Button} from 'react-bootstrap';

const ReloadDevicePropertiesButton: FunctionComponent<{syrxControllerBacnetDeviceId: string, reloadPropertiesForDevice: (syrxControllerBacnetDeviceId: string) => void}> = props => {
    return <Button title="Reload device" variant="outline-secondary" onClick={() => props.reloadPropertiesForDevice(props.syrxControllerBacnetDeviceId)}><i className="fas fa-redo-alt" /></Button>
}

const IgnoreDeviceButton: FunctionComponent<{syrxControllerBacnetDeviceId: string, ignoreDevice: (syrxControllerBacnetDeviceId: string) => void}> = props => {
    return <Button title="Ignore device" variant="outline-secondary" onClick={() => props.ignoreDevice(props.syrxControllerBacnetDeviceId)}><i className="fas fa-trash" /></Button>
}

const UnignoreDeviceButton: FunctionComponent<{syrxControllerBacnetDeviceId: string, unignoreDevice: (syrxControllerBacnetDeviceId: string) => void}> = props => {
    return <Button title="Unignore device" variant="outline-secondary" onClick={() => props.unignoreDevice(props.syrxControllerBacnetDeviceId)}><i className="fas fa-trash-restore" /></Button>
}

const ReloadPointsButton: FunctionComponent<{syrxControllerBacnetDeviceId: string, reloadPoints: (syrxControllerBacnetDeviceId: string) => void}> = props => {
    return <Button title="Reload points for device" variant="outline-secondary" onClick={() => props.reloadPoints(props.syrxControllerBacnetDeviceId)}><i className="fas fa-sitemap" /></Button>
}

const GridOptionsContainer: FunctionComponent<{}> = () => {
    const dispatch = useDispatch();
    const showExtraColumns = useAppStateSelector(state => state.syrxControllerBacnetDevicesGrid.showExtraColumns);
    const showUnavailableDevices = useAppStateSelector(state => state.syrxControllerBacnetDevicesGrid.showUnavailableDevices);

    const setShowExtraColumns = (newShowDateColumns: string) => {
        dispatch(syrxControllerBacnetDevicesGridActions.setShowExtraColumns(newShowDateColumns === 'Yes'));
    };

    const setShowUnavailableDevices = (newShowUnavailableDevices: string) => {
        dispatch(syrxControllerBacnetDevicesGridActions.setShowUnavailableDevices(newShowUnavailableDevices === 'Yes'));
    };

    return (
        <table>
            <tbody>
            <tr>
                <td>
                    Show extra columns:
                </td>
                <td>
                    <select value={showExtraColumns ? 'Yes' : 'No'} onChange={e => setShowExtraColumns(e.target.value)} className="form-control">
                        <option>Yes</option>
                        <option>No</option>
                    </select>
                </td>
            </tr>
            <tr>
                <td>
                    Show unavailable devices:
                </td>
                <td>
                    <select value={showUnavailableDevices ? 'Yes' : 'No'} onChange={e => setShowUnavailableDevices(e.target.value)} className="form-control">
                        <option>Yes</option>
                        <option>No</option>
                    </select>
                </td>
            </tr>
            </tbody>
        </table>
    );
};

export interface SyrxControllerBacnetDevicesGridProps {
    syrxControllerId: string;
}

export const SyrxControllerBacnetDevicesGrid: FunctionComponent<SyrxControllerBacnetDevicesGridProps> = ({syrxControllerId}) => {
    const dispatch = useDispatch();

    const showExtraColumns = useAppStateSelector(state => state.syrxControllerBacnetDevicesGrid.showExtraColumns);
    const showUnavailableDevices = useAppStateSelector(state => state.syrxControllerBacnetDevicesGrid.showUnavailableDevices);
    const isUpdating = useAppStateSelector(state => state.syrxControllerBacnetDevicesGrid.isUpdating);
    const refreshTrigger = useAppStateSelector(state => state.syrxControllerBacnetDevicesGrid.refreshTrigger);

    const dataRetrievalFunction = (pageNumber: number, pageSize: number, sort: SortCollection) => getSyrxControllerBacnetDevicesForGrid(syrxControllerId, showUnavailableDevices, pageNumber, pageSize, sort);

    const reloadPropertiesForDevice = useCallback((syrxControllerBacnetDeviceId: string) => {
        dispatch(reloadPropertiesForDeviceActionCreator(syrxControllerId, syrxControllerBacnetDeviceId));
    }, [dispatch, syrxControllerId]);

    const ignoreDevice = useCallback((syrxControllerBacnetDeviceId: string) => {
        dispatch(ignoreDeviceActionCreator(syrxControllerId, syrxControllerBacnetDeviceId));
    }, [dispatch, syrxControllerId]);

    const unignoreDevice = useCallback((syrxControllerBacnetDeviceId: string) => {
        dispatch(unignoreDeviceActionCreator(syrxControllerId, syrxControllerBacnetDeviceId));
    }, [dispatch, syrxControllerId]);

    const reloadPointsForDevice = useCallback((syrxControllerBacnetDeviceId: string) => {
        dispatch(reloadPointsForDeviceActionCreator(syrxControllerId, syrxControllerBacnetDeviceId));
    }, [dispatch, syrxControllerId])

    return (
        <>
            <GridOptionsContainer />
            {isUpdating ? <LoadingSpinner /> : null}
            <FancyGrid.ReduxGrid
                gridName={"SyrxControllerBacnetDevicesGrid"}
                dataRetrievalFunction={dataRetrievalFunction}
                updateTriggers={[
                    syrxControllerId,
                    showUnavailableDevices,
                    refreshTrigger
                ]}
            >
                <FancyGrid.ColumnList>
                    <FancyGrid.Column
                        name="mac_address"
                        title="MAC address"
                    >
                        <FancyGrid.CellRenderer>
                            {(_, device: SyrxControllerBacnetDevice) => <>
                                <span>{device.mac_address} [{device.network}]</span>
                            </>}
                        </FancyGrid.CellRenderer>
                    </FancyGrid.Column>
                    {showExtraColumns ? (
                        <FancyGrid.Column
                            name="destination_mac_address"
                            title="Destination address"
                        >
                            <FancyGrid.CellRenderer>
                                {(_, device: SyrxControllerBacnetDevice) => <>
                                    {device.destination_mac_address !== '1.0.0.0:0' || device.destination_network !== 0 ? (<span>{device.destination_mac_address} [{device.destination_network}]</span>) : null}
                                </>}
                            </FancyGrid.CellRenderer>
                        </FancyGrid.Column>
                    ) : null}
                    <FancyGrid.Column
                        name="instance_number"
                        title="Object ID"
                    >
                        <FancyGrid.CellRenderer>
                            {(instanceNumber) => <>
                                OBJECT_DEVICE:{instanceNumber}
                            </>}
                        </FancyGrid.CellRenderer>
                    </FancyGrid.Column>
                    <FancyGrid.Column
                        name="name"
                        title="Device name"
                    />
                    <FancyGrid.Column
                        name="description"
                        title="Description"
                    />
                    <FancyGrid.Column
                        name="vendor_name"
                        title="Vendor name"
                    />
                    {showExtraColumns ? (
                        <FancyGrid.Column
                            name="first_available_on"
                            title="First available"
                        >
                            <FancyGrid.CellRenderer>
                                {(firstAvailableOn: string | null) => <>{firstAvailableOn != null ? dayjs.utc(firstAvailableOn).format("YYYY-MM-DD hh:mm a") : null}</>}
                            </FancyGrid.CellRenderer>
                        </FancyGrid.Column>
                    ) : null}
                    {showExtraColumns ? (
                        <FancyGrid.Column
                            name="last_available_on"
                            title="Last available"
                        >
                            <FancyGrid.CellRenderer>
                                {(lastAvailableOn: string | null) => <>{lastAvailableOn != null ? dayjs.utc(lastAvailableOn).format("YYYY-MM-DD hh:mm a") : null}</>}
                            </FancyGrid.CellRenderer>
                        </FancyGrid.Column>
                    ) : null}
                    {showExtraColumns ? (
                        <FancyGrid.Column
                            name="last_unavailable_on"
                            title="Last unavailable"
                        >
                            <FancyGrid.CellRenderer>
                                {(lastUnavailableOn: string | null) => <>{lastUnavailableOn != null ? dayjs.utc(lastUnavailableOn).format("YYYY-MM-DD hh:mm a") : null}</>}
                            </FancyGrid.CellRenderer>
                        </FancyGrid.Column>
                    ) : null}
                    {showExtraColumns ? (
                        <FancyGrid.Column
                            name="last_point_discovery"
                            title="Last point discovery"
                        >
                            <FancyGrid.CellRenderer>
                                {(lastPointDiscovery: string | null) => <>{lastPointDiscovery != null ? dayjs.utc(lastPointDiscovery).format("YYYY-MM-DD hh:mm a") : null}</>}
                            </FancyGrid.CellRenderer>
                        </FancyGrid.Column>
                    ) : null}

                    <FancyGrid.Column
                        name="status"
                        title="Availability"
                    />

                    <FancyGrid.Column
                        sortable={false}
                        filterable={false}
                        title="Actions"
                    >
                        <FancyGrid.CellRenderer>
                            {(_, device: SyrxControllerBacnetDevice) => (
                                <>
                                    {['unknown', 'available', 'unavailable'].includes(device.status) ? <ReloadDevicePropertiesButton syrxControllerBacnetDeviceId={device.id} reloadPropertiesForDevice={reloadPropertiesForDevice} /> : null}
                                    {['unknown', 'available', 'unavailable'].includes(device.status) ? <IgnoreDeviceButton syrxControllerBacnetDeviceId={device.id} ignoreDevice={ignoreDevice} /> : null}
                                    {['ignored'].includes(device.status) ? <UnignoreDeviceButton syrxControllerBacnetDeviceId={device.id} unignoreDevice={unignoreDevice} /> : null}
                                    {['available'].includes(device.status) ? <ReloadPointsButton syrxControllerBacnetDeviceId={device.id} reloadPoints={reloadPointsForDevice} /> : null}
                                </>
                            )}
                        </FancyGrid.CellRenderer>
                    </FancyGrid.Column>
                </FancyGrid.ColumnList>
                <FancyGrid.ReduxSortable />
                <FancyGrid.ReduxPager />
            </FancyGrid.ReduxGrid>
        </>
    )
};