import EventEmitter from "events";
import {v4 as uuid} from "uuid";
import {useEffect, useState} from 'react';
import {useSocket} from './useSocket';
import {syrxSocketioEndpoint} from '../config';
import {bacnetObjectTypeNames, bacnetObjectTypesByName} from '../features/bacnet/bacnetObjectTypeSelectField';
import {bacnetPropertyTypesByProperty} from '../features/bacnet/bacnetPropertyTypeSelectField';
import {SyrxControllerBacnetDeviceIdentification} from '../models/syrxControllerBacnetDeviceIdentification';


export interface ReadPropertyDescription {
    macAddress: string;
    network: number;
    destinationMacAddress: string | null;
    destinationNetwork: number | null;
    objectType: number;
    instanceNumber: number;
    propertyId: number;
    propertyIndex: number | null;
}

export const useSyrxControllerBacnetNetworkSocketHelper = (syrxControllerId: string) => {
    const socket = useSocket(`${syrxSocketioEndpoint}/Pathian.Services.BACnetNetworkQueryService`);
    const [socketHelper, setSocketHelper] = useState(null as SyrxControllerBacnetNetworkSocketHelper | null);

    useEffect(() => {
        if (socket == null) {
            setSocketHelper(null);
            return;
        }

        const newSocketHelper = new SyrxControllerBacnetNetworkSocketHelper(syrxControllerId, socket);
        setSocketHelper(newSocketHelper);
    }, [socket, syrxControllerId]);

    return socketHelper;
}

export class SyrxControllerBacnetNetworkSocketHelper extends EventEmitter {
    constructor(private syrxControllerId: string, private socket: SocketIOClient.Socket) {
        super();
    }

    initialize() {
        this.socket
            .on("connect", () => {
                this.socket.emit("client/server/connect", {syrxControllerId: this.syrxControllerId}, () => {
                    this.emit("connect");
                });
            })
            .on("disconnect", () => {
                this.emit("disconnect");
            })
            .on("server/client/agentStatus", (incomingMessage: {connected: boolean}) => {
                const {connected} = incomingMessage;
                this.emit("agentStatus", {connected});
            });
    }

    async discoverDevices(acknowledgementHandler?: () => any) {
        const outgoingRequest = {
            requestType: "DiscoverDevices",
            syrxControllerId: this.syrxControllerId,
            requestId: uuid()
        };

        interface SocketDevice {
            MacAddress: string;
            Network: number;
            DestinationMacAddress: string | null;
            DestinationNetwork: number | null;
            InstanceNumber: number;
        }

        const socketDevices = await this.sendRequest<SocketDevice[]>(outgoingRequest, acknowledgementHandler);

        const syrxControllerBacnetDeviceIdentification = socketDevices.map(socketDevice => ({
            mac_address: socketDevice.MacAddress,
            network: socketDevice.Network,
            destination_mac_address: socketDevice.DestinationMacAddress,
            destination_network: socketDevice.DestinationNetwork,
            instance_number: socketDevice.InstanceNumber
        } as SyrxControllerBacnetDeviceIdentification));

        return syrxControllerBacnetDeviceIdentification;
    }

    async retrieveObjectName(syrxControllerBacnetDeviceIdentification: SyrxControllerBacnetDeviceIdentification, objectType: number, instanceNumber: number, acknowledgementHandler?: () => any) {
        const readPropertyDescription: ReadPropertyDescription = {
            macAddress: syrxControllerBacnetDeviceIdentification.mac_address,
            network: syrxControllerBacnetDeviceIdentification.network,
            destinationMacAddress: syrxControllerBacnetDeviceIdentification.destination_mac_address,
            destinationNetwork: syrxControllerBacnetDeviceIdentification.destination_network,
            instanceNumber,
            objectType,
            propertyId: bacnetPropertyTypesByProperty.PROP_OBJECT_NAME,
            propertyIndex: null
        };
        const name = await this.readProperty(readPropertyDescription, acknowledgementHandler).then(x => x[0].Value);

        return name;
    }

    async retrieveObjectVendorName(syrxControllerBacnetDeviceIdentification: SyrxControllerBacnetDeviceIdentification, objectType: number, instanceNumber: number, acknowledgementHandler?: () => any) {
        const readPropertyDescription: ReadPropertyDescription = {
            macAddress: syrxControllerBacnetDeviceIdentification.mac_address,
            network: syrxControllerBacnetDeviceIdentification.network,
            destinationMacAddress: syrxControllerBacnetDeviceIdentification.destination_mac_address,
            destinationNetwork: syrxControllerBacnetDeviceIdentification.destination_network,
            instanceNumber,
            objectType,
            propertyId: bacnetPropertyTypesByProperty.PROP_VENDOR_NAME,
            propertyIndex: null
        };
        const vendorName = await this.readProperty(readPropertyDescription, acknowledgementHandler).then(x => x[0].Value);

        return vendorName;
    }

    async retrieveObjectDescription(syrxControllerBacnetDeviceIdentification: SyrxControllerBacnetDeviceIdentification, objectType: number, instanceNumber: number, acknowledgementHandler?: () => any) {
        const readPropertyDescription: ReadPropertyDescription = {
            macAddress: syrxControllerBacnetDeviceIdentification.mac_address,
            network: syrxControllerBacnetDeviceIdentification.network,
            destinationMacAddress: syrxControllerBacnetDeviceIdentification.destination_mac_address,
            destinationNetwork: syrxControllerBacnetDeviceIdentification.destination_network,
            instanceNumber,
            objectType,
            propertyId: bacnetPropertyTypesByProperty.PROP_DESCRIPTION,
            propertyIndex: null
        };
        const description = await this.readProperty(readPropertyDescription, acknowledgementHandler).then(x => x[0].Value);

        return description;
    }
    
    async retrieveObjectListItem(syrxControllerBacnetDeviceIdentification: SyrxControllerBacnetDeviceIdentification, pointIndex: number, acknowledgementHandler?: () => any) {
        const getPointObjectIdReadPropertyDescription = {
            macAddress: syrxControllerBacnetDeviceIdentification.mac_address,
            network: syrxControllerBacnetDeviceIdentification.network,
            destinationMacAddress: syrxControllerBacnetDeviceIdentification.mac_address,
            destinationNetwork: syrxControllerBacnetDeviceIdentification.destination_network,
            objectType: bacnetObjectTypesByName.OBJECT_DEVICE,
            instanceNumber: syrxControllerBacnetDeviceIdentification.instance_number,
            propertyId: bacnetPropertyTypesByProperty.PROP_OBJECT_LIST,
            propertyIndex: pointIndex
        };

        const {Instance: pointInstance, Type: pointType} = (await this.readProperty(getPointObjectIdReadPropertyDescription, acknowledgementHandler))[0].Value;
        const pointTypeName = bacnetObjectTypeNames.get(pointType);

        return {
            instance: pointInstance,
            type: pointType,
            typeName: pointTypeName
        }
    }

    readProperty(propertyDescription: ReadPropertyDescription, acknowledgementHandler?: () => any) {
        const outgoingRequest = {
            requestType: "ReadProperty",
            syrxControllerId: this.syrxControllerId,
            requestId: uuid(),
            ...propertyDescription
        };

        interface SocketPropertyValue {
            Tag: number;
            ValueType: string;
            Value: any;
        }

        return this.sendRequest<SocketPropertyValue[]>(outgoingRequest, acknowledgementHandler);
    }

    private sendRequest<TData>(outgoingRequest: {syrxControllerId: string, requestId: string}, acknowledgementHandler?: () => any) {
        return new Promise<TData>((resolve, reject) => {
            const handler = (incomingMessage: {requestId: string, data: {IsAcknowledgement: boolean, IsSuccessful: boolean, IsException: boolean, Data: any, ExceptionType: string | null, ExceptionMessage: string | null}, exception: any}) => {
                if (incomingMessage.requestId !== outgoingRequest.requestId) {
                    return;
                }

                try {
                    if (incomingMessage.exception != null) {
                        const e = new Error(incomingMessage.exception);
                        reject(e);
                        this.socket.emit("client/server/stop-request", {requestId: outgoingRequest.requestId});
                        this.socket.off("server/client/request-response", handler);
                    } else if (incomingMessage.data?.IsAcknowledgement) {
                        acknowledgementHandler != null && acknowledgementHandler();
                    } else if (incomingMessage.data?.IsSuccessful) {
                        resolve(incomingMessage.data?.Data);
                        this.socket.emit("client/server/stop-request", {requestId: outgoingRequest.requestId});
                        this.socket.off("server/client/request-response", handler);
                    } else if (incomingMessage.data?.IsException) {
                        const e = new Error(`${incomingMessage.data.ExceptionType}: ${incomingMessage.data.ExceptionMessage}`);
                        reject(e);
                        this.socket.emit("client/server/stop-request", {requestId: outgoingRequest.requestId});
                        this.socket.off("server/client/request-response", handler);
                    }
                } catch (e) {
                    reject(e);
                    this.socket.emit("client/server/stop-request", {requestId: outgoingRequest.requestId});
                    this.socket.off("server/client/request-response", handler);
                }
            };

            this.socket.on("server/client/request-response", handler);

            this.socket.emit("client/server/start-request", outgoingRequest, (incomingMessage: any) => {
                if (incomingMessage.error != null) {
                    reject(incomingMessage.error);
                    this.socket.emit("client/server/stop-request", {requestId: outgoingRequest.requestId});
                    this.socket.off("server/client/request-response", handler);
                }
            });
        });
    }
}
