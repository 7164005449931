import {useEffect, useRef, useState} from "react";
import io from "socket.io-client";

export const useSocket = (socketEndpoint: string) => {
    const socketRef = useRef(null as SocketIOClient.Socket | null);
    const [socket, setSocket] = useState(null as SocketIOClient.Socket | null);

    useEffect(() => {
        socketRef.current = io(socketEndpoint);
        setSocket(socketRef.current);
        return () => {
            socketRef.current?.close();
            socketRef.current = null;
            setSocket(socketRef.current);
        }
    }, [socketEndpoint]);

    return socket;
}