import React from 'react';
import {useDrag} from 'react-dnd';

export const Draggable: React.FunctionComponent<{text: string, type: string, id: string, source?: any}> = ({text, type, id, source}) => {
    const [{isDragging}, dragRef] = useDrag({
        item: { type, text, id, source },
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })
    });

    const opacity = isDragging ? 0.5 : 1;

    return (
        <div ref={dragRef} style={{ opacity }}>
            {text}
        </div>
    );
}