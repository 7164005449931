import React, {useCallback, useEffect, useState} from 'react'
import {Button, Modal} from "react-bootstrap";
import {toast} from 'react-toastify';
import {LoadingSpinner} from '../features/loadingSpinner/LoadingSpinner';

export interface DeleteModalProps {
    title: string;
    message: string;
    loadItem: () => Promise<any>;
    performDeletion: () => Promise<any>;
    onClose: (deleteSuccessful: boolean) => void;
    children: (item: any) => JSX.Element;
}

export const DeleteModal: React.FunctionComponent<DeleteModalProps> = (props) => {
    const {
        title,
        message,
        loadItem,
        performDeletion,
        onClose,
        children
    } = props;

    const [isLoaded, setIsLoaded] = useState(false);
    const [item, setItem] = useState(null as any);

    const doDismiss = useCallback(() => onClose(false), [onClose]);

    const doSubmit = useCallback(() => {
        async function doTheThing() {
            setIsLoaded(false);
            try {
                await performDeletion();
                onClose(true);
            } catch (e) {
                toast.error(`An error occurred while trying to perform the deletion`);
                setIsLoaded(true);
            }
        }

        void doTheThing();
    }, [performDeletion, onClose]);

    useEffect(() => {
        async function doTheThing() {
            setIsLoaded(false);
            setItem(null);
            const _item = await loadItem();
            if (_item == null) {
                toast.error(`Item could not be found`);
                doDismiss();
                return;
            }
            setItem(_item);
            setIsLoaded(true);
        }

        void doTheThing();
    }, [loadItem, doDismiss]);

    return (
        <Modal show={true} onHide={doDismiss} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!isLoaded ? <LoadingSpinner /> : null}
                {isLoaded ? (
                    <>
                        <p>{message}</p>
                        {children(item)}
                    </>
                ) : null}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={doDismiss}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={doSubmit}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
};