import React from 'react'
import { Row, Col, FormGroup, Form } from 'react-bootstrap';
import {Field} from 'react-final-form';

export const SyrxControllersEditorGeneralConfigurationFieldset: React.FunctionComponent<{syrxControllerId: string | null, groupName: string | null}> = props => (
    <fieldset>
        <legend>Syrx controller configuration</legend>

        {props.syrxControllerId != null ? (
            <FormGroup as={Row}>
                <Form.Label column md={4}>Controller ID</Form.Label>
                <Col md={4}>
                    <p className="form-control-static">{props.syrxControllerId}</p>
                </Col>
            </FormGroup>
        ) : null}

        <FormGroup as={Row}>
            <Form.Label column md={4}>Group name</Form.Label>
            <Col md={4}>
                <p className="form-control-static">{props.groupName}</p>
            </Col>
        </FormGroup>

        <FormGroup as={Row}>
            <Form.Label column md={4}>Name</Form.Label>
            <Col md={4}>
                <Field
                    name="syrxController.name"
                    component="input"
                    type="text"
                    className="form-control"
                />
            </Col>
        </FormGroup>

        <FormGroup as={Row}>
            <Form.Label column md={4}>Description</Form.Label>
            <Col md={4}>
                <Field
                    name="syrxController.description"
                    component="input"
                    type="text"
                    className="form-control"
                />
            </Col>
        </FormGroup>

        <FormGroup as={Row}>
            <Form.Label column md={4}>Provide BACnet configuration</Form.Label>
            <Col md={4}>
                <Field 
                    name="provideBacnetConfiguration" 
                    component="select"
                    className="form-control"
                >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </Field>
            </Col>
        </FormGroup>
    </fieldset>
);