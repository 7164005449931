import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { buildSubStateSelector } from '../../store/utilities/useAppStateSelector';

import { Project, Contact } from './types';

const initialState = {
    project: {} as Project,
    contacts: [] as Contact[],
    isLoading: true,
    isSuccessful: false,
    errorMessage: ''
};

export type AppStateProjectEditor = typeof initialState;

export const slice = createSlice<AppStateProjectEditor, {
    setProject: CaseReducer<AppStateProjectEditor, PayloadAction<Project>>;
    setContacts: CaseReducer<AppStateProjectEditor, PayloadAction<Contact[]>>;
    success: CaseReducer<AppStateProjectEditor>;
    error: CaseReducer<AppStateProjectEditor, PayloadAction<{exceptionMessage: string}>>;
    reset: CaseReducer<AppStateProjectEditor>;
}>({
    name: "projectEditor",
    initialState,
    reducers: {
        setProject(state, action) {
            state.isLoading = false;
            state.project = action.payload;
        },
        setContacts(state, action) {
            state.isLoading = false;
            state.contacts = action.payload;
        },
        success(state) {
            state.isSuccessful = true;
        },
        error(state, action) {
            state.isSuccessful = false;
            state.errorMessage = action.payload.exceptionMessage;
        },
        reset(state) {
            state.isLoading = true;
            state.isSuccessful = false;
            state.errorMessage = '';
        }
    }
});

export const useProjectEditorSelector = buildSubStateSelector(state => state.projectEditor);
export const projectEditorActions = slice.actions;
export const projectEditorReducer = slice.reducer;