import React, {FunctionComponent, useMemo} from 'react'
import dayjs from 'dayjs';

import FancyGrid, {FilterCollection, SortCollection} from '@sht-dev/fancy-grid';

import {
    getSyrxControllerBacnetPointsByDeviceForGrid,
    getSyrxControllerBacnetPointsForGrid
} from '../../../services/syrxControllersService';
import {useDispatch} from 'react-redux';
import {
    syrxControllerBacnetPointsGridActions,
    useSyrxControllerBacnetPointsGridSelector
} from './syrxControllerBacnetPointsGridSlice';

const GridOptionsContainer: FunctionComponent<{}> = () => {
    const dispatch = useDispatch();
    const showExtraColumns = useSyrxControllerBacnetPointsGridSelector(state => state.showExtraColumns);
    const showUnavailablePoints = useSyrxControllerBacnetPointsGridSelector(state => state.showUnavailablePoints);

    const setShowExtraColumns = (newShowDateColumns: string) => {
        dispatch(syrxControllerBacnetPointsGridActions.setShowExtraColumns(newShowDateColumns === 'Yes'));
    };

    const setShowUnavailablePoints = (newShowUnavailablePoints: string) => {
        dispatch(syrxControllerBacnetPointsGridActions.setShowUnavailablePoints(newShowUnavailablePoints === 'Yes'));
    };

    return (
        <table>
            <tbody>
            <tr>
                <td>
                    Show extra columns:
                </td>
                <td>
                    <select value={showExtraColumns ? 'Yes' : 'No'} onChange={e => setShowExtraColumns(e.target.value)} className="form-control">
                        <option>Yes</option>
                        <option>No</option>
                    </select>
                </td>
            </tr>
            <tr>
                <td>
                    Show unavailable points:
                </td>
                <td>
                    <select value={showUnavailablePoints ? 'Yes' : 'No'} onChange={e => setShowUnavailablePoints(e.target.value)} className="form-control">
                        <option>Yes</option>
                        <option>No</option>
                    </select>
                </td>
            </tr>
            </tbody>
        </table>
    );
};

export interface SyrxControllerBacnetPointsGridProps {
    syrxControllerId: string;
    syrxControllerBacnetDeviceId?: string | undefined;
}

export const SyrxControllerBacnetPointsGrid: FunctionComponent<SyrxControllerBacnetPointsGridProps> = ({syrxControllerId, syrxControllerBacnetDeviceId}) => {
    const showExtraColumns = useSyrxControllerBacnetPointsGridSelector(state => state.showExtraColumns);
    const showUnavailablePoints = useSyrxControllerBacnetPointsGridSelector(state => state.showUnavailablePoints);

    const dataRetrievalFunction = useMemo(() => {
        if (syrxControllerBacnetDeviceId == null) {
            return (pageNumber: number, pageSize: number, sort: SortCollection, filter: FilterCollection) => getSyrxControllerBacnetPointsForGrid(syrxControllerId, showUnavailablePoints, pageNumber, pageSize, sort, filter);
        } else {
            return (pageNumber: number, pageSize: number, sort: SortCollection, filter: FilterCollection) => getSyrxControllerBacnetPointsByDeviceForGrid(syrxControllerId, syrxControllerBacnetDeviceId, showUnavailablePoints, pageNumber, pageSize, sort, filter);
        }
    }, [syrxControllerId, syrxControllerBacnetDeviceId, showUnavailablePoints]);

    return (
        <>
            <GridOptionsContainer />
            <FancyGrid.ReduxGrid
                gridName={"SyrxControllerBacnetPointsGrid"}
                dataRetrievalFunction={dataRetrievalFunction}
                updateTriggers={[showUnavailablePoints, syrxControllerId, syrxControllerBacnetDeviceId]}
            >
                <FancyGrid.ColumnList>
                    {syrxControllerBacnetDeviceId == null ? (
                        <FancyGrid.Column
                            name="syrx_controller_bacnet_device_name"
                            title="Device name"
                        />
                    ) : null}
                    <FancyGrid.Column
                        name="object_id"
                        title="Object ID"
                    />
                    <FancyGrid.Column
                        name="name"
                        title="Point name"
                    />
                    <FancyGrid.Column
                        name="description"
                        title="Description"
                    />
                    {showExtraColumns ? (
                        <FancyGrid.Column
                            name="first_seen_on"
                            title="First seen"
                            fieldType="date"
                        >
                            <FancyGrid.CellRenderer>
                                {(firstSeenOn: string) => <>{dayjs.utc(firstSeenOn).format("YYYY-MM-DD hh:mm a")}</>}
                            </FancyGrid.CellRenderer>
                        </FancyGrid.Column>
                    ) : null}
                    {showExtraColumns ? (
                        <FancyGrid.Column
                            name="last_seen_on"
                            title="Last seen"
                            fieldType="date"
                        >
                            <FancyGrid.CellRenderer>
                                {(lastSeenOn: string) => <>{dayjs.utc(lastSeenOn).format("YYYY-MM-DD hh:mm a")}</>}
                            </FancyGrid.CellRenderer>
                        </FancyGrid.Column>
                    ) : null}
                    {showExtraColumns ? (
                        <FancyGrid.Column
                            name="last_missed_on"
                            title="Last missed"
                            fieldType="date"
                        >
                            <FancyGrid.CellRenderer>
                                {(lastMissedOn: string | null) => <>{lastMissedOn != null ? dayjs.utc(lastMissedOn).format("YYYY-MM-DD hh:mm a") : null}</>}
                            </FancyGrid.CellRenderer>
                        </FancyGrid.Column>
                    ) : null}
                    {showExtraColumns ? (
                        <FancyGrid.Column
                            name="last_point_discovery"
                            title="Last discovery attempt"
                            fieldType="date"
                        >
                            <FancyGrid.CellRenderer>
                                {(lastPointDiscovery: string) => <>{dayjs.utc(lastPointDiscovery).format("YYYY-MM-DD hh:mm a")}</>}
                            </FancyGrid.CellRenderer>
                        </FancyGrid.Column>
                    ) : null}
                    <FancyGrid.Column
                        name="reporting_latest_record_date"
                        title="Latest record date"
                        fieldType="date"
                    >
                        <FancyGrid.CellRenderer>
                            {(reportingLatestRecordDate: string | null) => <>{reportingLatestRecordDate != null ? dayjs.utc(reportingLatestRecordDate).format("YYYY-MM-DD hh:mm a") : null}</>}
                        </FancyGrid.CellRenderer>
                    </FancyGrid.Column>
                    <FancyGrid.Column
                        name="reporting_latest_value"
                        title="Latest value"
                        fieldType="number"
                    />
                    <FancyGrid.Column
                        name="is_available"
                        title="Is available"
                        fieldType="yesNo"
                    >
                        <FancyGrid.CellRenderer>
                            {(isAvailable: boolean) => <>{isAvailable ? 'Yes' : 'No'}</>}
                        </FancyGrid.CellRenderer>
                    </FancyGrid.Column>
                </FancyGrid.ColumnList>
                <FancyGrid.ReduxSortable />
                <FancyGrid.ReduxFilterable
                    filterStyle="popup"
                />
                <FancyGrid.ReduxPager />
            </FancyGrid.ReduxGrid>
        </>
    )
};