import {CaseReducer, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {buildSubStateSelector} from '../../../store/utilities/useAppStateSelector';
import {
    SyrxControllerBuildingPressureControlServiceBasicConfiguration
} from '../../../models/syrxControllerBuildingPressureControlServiceConfiguration';

const initialState = {
    isLoading: false,
    isDoneLoading: false,
    isSubmitted: false,
    syrxControllerPressureBasicConfiguration: null as SyrxControllerBuildingPressureControlServiceBasicConfiguration | null
};

export type AppStateSyrxControllerBuildingPressureControlServiceBasicConfigurationEditor = typeof initialState;

export const slice = createSlice<AppStateSyrxControllerBuildingPressureControlServiceBasicConfigurationEditor, {
    startLoading: CaseReducer<AppStateSyrxControllerBuildingPressureControlServiceBasicConfigurationEditor>;
    setSyrxControllerPressureBasicConfiguration: CaseReducer<AppStateSyrxControllerBuildingPressureControlServiceBasicConfigurationEditor, PayloadAction<{syrxControllerPressureBasicConfiguration: SyrxControllerBuildingPressureControlServiceBasicConfiguration | null}>>;
    finishLoading: CaseReducer<AppStateSyrxControllerBuildingPressureControlServiceBasicConfigurationEditor>;
    startSubmitting: CaseReducer<AppStateSyrxControllerBuildingPressureControlServiceBasicConfigurationEditor>;
    finishSubmitting: CaseReducer<AppStateSyrxControllerBuildingPressureControlServiceBasicConfigurationEditor>;
    resetState: CaseReducer<AppStateSyrxControllerBuildingPressureControlServiceBasicConfigurationEditor>;
}>({
    name: "syrxControllerBuildingPressureServiceBasicConfigurationEditor",
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
            state.isDoneLoading = false;
        },
        setSyrxControllerPressureBasicConfiguration(state, {payload: {syrxControllerPressureBasicConfiguration}}) {
            state.syrxControllerPressureBasicConfiguration = syrxControllerPressureBasicConfiguration;
        },
        finishLoading(state) {
            state.isLoading = false;
            state.isDoneLoading = true;
        },
        startSubmitting(state) {
            state.isLoading = true;
        },
        finishSubmitting(state) {
            state.isLoading = false;
            state.isSubmitted = true;
        },
        resetState(state) {
            state.syrxControllerPressureBasicConfiguration = null;
            state.isLoading = false;
            state.isDoneLoading = false;
            state.isSubmitted = false;
        }
    }
});

export const useSyrxControllerBuildingPressureControlServiceBasicConfigurationEditorSelector = buildSubStateSelector(state => state.syrxControllerBuildingPressureControlServiceBasicConfigurationEditor);
export const syrxControllerBuildingPressureControlServiceBasicConfigurationEditorActions = slice.actions;
export const syrxControllerBuildingPressureControlServiceBasicConfigurationEditorReducer = slice.reducer;