import React from 'react'
import { Col } from 'react-bootstrap';
import {SyrxControllerBacnetNetworkQueryConsole} from './syrxControllerBacnetNetworkQueryConsole';

export interface SyrxControllerBacnetNetworkQueryServiceDetailsProps {
    syrxControllerId: string;
}

export const SyrxControllerBacnetNetworkQueryServiceDetails: React.FunctionComponent<SyrxControllerBacnetNetworkQueryServiceDetailsProps> = ({syrxControllerId}) => {
    return (
        <>
            <Col md={12}>
                <h4>Query console</h4>
                <SyrxControllerBacnetNetworkQueryConsole syrxControllerId={syrxControllerId} />
            </Col>
        </>
    )
};