import {CaseReducer, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {buildSubStateSelector} from '../../../store/utilities/useAppStateSelector';

const initialState = {
    showExtraColumns: false,
    showUnavailablePoints: false
};

export type AppStateSyrxControllerBacnetPointsGrid = typeof initialState;

export const slice = createSlice<AppStateSyrxControllerBacnetPointsGrid, {
    setShowExtraColumns: CaseReducer<AppStateSyrxControllerBacnetPointsGrid, PayloadAction<boolean>>;
    setShowUnavailablePoints: CaseReducer<AppStateSyrxControllerBacnetPointsGrid, PayloadAction<boolean>>;
}>({
    name: "syrxControllerBacnetPointsGrid",
    initialState,
    reducers: {
        setShowExtraColumns(state, {payload: showExtraColumns}) {
            state.showExtraColumns = showExtraColumns;
        },
        setShowUnavailablePoints(state, {payload: showUnavailablePoints}) {
            state.showUnavailablePoints = showUnavailablePoints;
        }
    }
});

export const useSyrxControllerBacnetPointsGridSelector = buildSubStateSelector(state => state.syrxControllerBacnetPointsGrid);
export const syrxControllerBacnetPointsGridActions = slice.actions;
export const syrxControllerBacnetPointsGridReducer = slice.reducer;