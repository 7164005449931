import React, { useEffect } from 'react'
import { Row, Col, Table, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useDispatch } from 'react-redux';

import {
    loadSyrxControllerEnabledServiceDetails,
    unloadSyrxControllerEnabledServiceDetails
} from '../../../actionCreators/syrxControllerEnabledServiceDetailsActionCreators';
import {LoadingSpinner} from '../../loadingSpinner/LoadingSpinner';
import {SyrxControllerBacnetNetworkQueryServiceDetails} from '../bacnetNetworkQueryService/syrxControllerBacnetNetworkQueryServiceDetails';
import {SyrxControllerReportingServiceDetails} from '../reportingService/syrxControllerReportingServiceDetails';
import {SyrxControllerDiagnosticServiceDetails} from '../diagnosticService/syrxControllerDiagnosticServiceDetails';
import {useSyrxControllerEnabledServiceDetailsSelector} from './syrxControllerEnabledServiceDetailsSlice';
import {SyrxControllerControlServiceDetails} from '../controlService/syrxControllerControlServiceDetails';
import {SyrxControllerBuildingPressureControlServiceDetails} from '../buildingPressureControlService/syrxControllerBuildingPressureControlServiceDetails';


export interface SyrxControllerInstalledServiceDetailsProps {
    syrxControllerId: string;
    serviceId: string;
}

export const SyrxControllerEnabledServiceDetails: React.FunctionComponent<SyrxControllerInstalledServiceDetailsProps> = props => {
    const dispatch = useDispatch();
    const {syrxControllerId, serviceId} = props;

    const syrxController = useSyrxControllerEnabledServiceDetailsSelector(state => state.syrxController);
    const syrxControllerInstalledService = useSyrxControllerEnabledServiceDetailsSelector(state => state.syrxControllerEnabledService);

    useEffect(() => {
        dispatch(loadSyrxControllerEnabledServiceDetails(syrxControllerId, serviceId));
        return () => {
            dispatch(unloadSyrxControllerEnabledServiceDetails());
        };
    }, [dispatch, syrxControllerId, serviceId]);

    return (syrxController != null && syrxControllerInstalledService != null) ? (
        <>
            <h3>{syrxControllerInstalledService.service_id}</h3>

            <Row>
                <Col md={6}>
                    <h4>General configuration</h4>
                    <Table>
                        <tbody>
                            <tr>
                                <td>Syrx Controller ID</td>
                                <td>{syrxControllerInstalledService.syrx_controller_id}</td>
                            </tr>
                            <tr>
                                <td>Service ID</td>
                                <td>{syrxControllerInstalledService.service_id}</td>
                            </tr>
                            <tr>
                                <td>Assigned version</td>
                                <td>{syrxControllerInstalledService.assigned_version}</td>
                            </tr>
                            <tr>
                                <td>Reported version</td>
                                <td>{syrxControllerInstalledService.reported_version}</td>
                            </tr>
                            <tr>
                                <td>Last contact</td>
                                <td>{syrxControllerInstalledService.last_contact ?? "never contacted"}</td>
                            </tr>
                            <tr>
                                <td>Logs</td>
                                <td>
                                    <LinkContainer to={`/admin/syrx-controllers/${syrxControllerInstalledService.syrx_controller_id}/services/${syrxControllerInstalledService.service_id}/logs`}>
                                        <Button variant="outline-info">Logs</Button>
                                    </LinkContainer>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
                {serviceId === 'Pathian.BACnetNetworkQueryService' || serviceId === 'Pathian.Services.BACnetNetworkQueryService' ? (
                    <SyrxControllerBacnetNetworkQueryServiceDetails syrxControllerId={syrxControllerId} />
                ) : null}
                {serviceId === 'Pathian.DiagnosticService' || serviceId === 'Pathian.Services.DiagnosticService' ? (
                    <SyrxControllerDiagnosticServiceDetails syrxControllerId={syrxControllerId} />
                ) : null}
                {serviceId === 'Pathian.ReportingService' || serviceId === 'Pathian.Services.ReportingService' ? (
                    <SyrxControllerReportingServiceDetails syrxControllerId={syrxControllerId} />
                ) : null}
                {serviceId === 'Pathian.ControlService' ? (
                    <SyrxControllerControlServiceDetails syrxControllerId={syrxControllerId} />
                ) : null}
                {serviceId === 'Pathian.Services.BuildingPressureControlService' ? (
                    <SyrxControllerBuildingPressureControlServiceDetails syrxControllerId={syrxControllerId} />
                ) : null}
            </Row>
        </>
    ) : <LoadingSpinner/>
}