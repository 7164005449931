import React, {useEffect, useState} from 'react';
import {getSyrxControllerCriticalSystemdServiceStatuses} from '../../../services/syrxControllersService';

export const SyrxControllerStatusIndicator: React.FunctionComponent<{syrxControllerId: string}> = ({syrxControllerId}) => {
    const [controllerStatus, setControllerStatus] = useState(null as string | null);

    useEffect(() => {
        async function load() {
            const serviceStatuses = await getSyrxControllerCriticalSystemdServiceStatuses(syrxControllerId);
            if (serviceStatuses == null) {
                setControllerStatus("down");
            } else if (Object.values(serviceStatuses).some(x => x !== "active")) {
                setControllerStatus("partially-up");
            } else {
                setControllerStatus("up");
            }
        }

        void load();
    }, [syrxControllerId]);

    let style;

    if (controllerStatus == null) {
        style = {color: "#999", opacity: 0.25};
    } else if (controllerStatus === "up") {
        style = {color: "#0C0", opacity: 1};
    } else if (controllerStatus === "partially-up") {
        style = {color: "#CC0", opacity: 1};
    } else if (controllerStatus === "down") {
        style = {color: "#C00", opacity: 1};
    }

    return (
        <span style={style}>⬤</span>
    )
}