import {BaseApiService} from "./baseApiService";
import {GroupPermission} from '../models/groupPermission';

export class GroupPermissionsService extends BaseApiService {
    async getAllGroupPermissions() {
        const res = await this.axios.get<GroupPermission[]>(`/group-permissions?`);
        return res.data;
    }
}

