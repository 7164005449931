import React, { useCallback, CSSProperties } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import dayjs from 'dayjs';
import FancyGrid, { SortCollection } from '@sht-dev/fancy-grid';
import { LinkContainer } from 'react-router-bootstrap';

import { projectsActionCreators } from '../../actionCreators/projectsActionCreators';

import { NewApiServices } from "../../newApiServices";
import { useAuthSessionSelector } from '../authSession/authSessionSlice';
import { useProjectsSelector } from './projectsSlice';
import { Project } from './types';

const buttonContainer: CSSProperties = {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "10px"
};

export const ProjectsList: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const currentGroup = useAuthSessionSelector(state => state.currentGroup);
    const { refreshTrigger } = useProjectsSelector(x => x);

    const deleteProject = useCallback(async (projectId) => {
        dispatch(projectsActionCreators.delete(projectId));
    }, [dispatch]);
    
    const dataRetrievalFunction = (pageNumber: number, pageSize: number, sort: SortCollection) => NewApiServices.projectsService.getAllByGroupId(currentGroup.id, pageNumber, pageSize, sort);
    return (
        <>
            <div style={buttonContainer}>
                <LinkContainer to={`/project/editor`}><Button variant="primary">New Project</Button></LinkContainer>
            </div>
            <FancyGrid.ReduxGrid
                gridName={"ProjectsGrid"}
                dataRetrievalFunction={dataRetrievalFunction}
                updateTriggers={[
                    currentGroup.id,
                    refreshTrigger
                ]}
            >
                <FancyGrid.ColumnList>
                    <FancyGrid.Column
                        name="name"
                        title="Name"
                    />
                    <FancyGrid.Column
                        name="customer_project_id"
                        title="Customer Project Id"
                    />
                    <FancyGrid.Column
                        name="architect_project_id"
                        title="Architect Project Id"
                    />
                    <FancyGrid.Column
                        name="start_date"
                        title="Start Date"
                    >
                        <FancyGrid.CellRenderer>
                            {(startDate: Date) => <>{startDate ? dayjs.utc(startDate).format('MM/DD/YYYY') : ''}</>}
                        </FancyGrid.CellRenderer>
                    </FancyGrid.Column>
                    <FancyGrid.Column
                        name="complete_date"
                        title="Completed Date"
                    >
                        <FancyGrid.CellRenderer>
                            {(completeDate: Date) => <>{completeDate ? dayjs.utc(completeDate).format('MM/DD/YYYY') : ''}</>}
                        </FancyGrid.CellRenderer>
                    </FancyGrid.Column>
                    <FancyGrid.Column
                        name="estimated_cost"
                        title="Estimated Cost"
                    >
                        <FancyGrid.CellRenderer>
                            {(estimatedCost: number) => <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(estimatedCost)}</>}
                        </FancyGrid.CellRenderer>
                    </FancyGrid.Column>
                    <FancyGrid.Column>
                            <FancyGrid.CellRenderer>
                                {(_, project: Project) => (
                                    <>
                                        <LinkContainer to={`/project/editor/${project.id}`}><Button variant="primary">Edit</Button></LinkContainer>
                                        <LinkContainer to={`/project/schedule/${project.id}`}><Button variant="primary">Schedule</Button></LinkContainer>
                                        <Button type="button" variant="danger" onClick={() => deleteProject(project.id)}>Delete</Button>
                                    </>
                                )}
                            </FancyGrid.CellRenderer>
                        </FancyGrid.Column>
                </FancyGrid.ColumnList>
                <FancyGrid.ReduxSortable />
                <FancyGrid.ReduxPager />
            </FancyGrid.ReduxGrid>
        </>
    )
}