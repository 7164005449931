import React, {useCallback, useEffect, useMemo} from 'react'
import semver from 'semver';

import {
    syrxControllerEnabledServicesManagerActions,
    useSyrxControllerEnabledServicesManagerSelector
} from './syrxControllerEnabledServicesManagerSlice';
import {LoadingSpinner} from '../../../loadingSpinner/LoadingSpinner';
import {useDispatch} from 'react-redux';
import {
    SyrxControllerEnabledServiceModel,
    syrxControllerEnabledServicesManagerActionCreators
} from './syrxControllerEnabledServicesManagerActionCreators';
import {Redirect} from 'react-router';
import {Button, Form} from 'react-bootstrap';
import {Field, Form as ReactFinalForm} from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import {FieldArray} from 'react-final-form-arrays';
import {LinkContainer} from 'react-router-bootstrap';


export interface SyrxControllerDetailProps {
    syrxControllerId: string;
}

export const SyrxControllerEnabledServicesManager: React.FunctionComponent<SyrxControllerDetailProps> = props => {
    const { syrxControllerId } = props;
    const dispatch = useDispatch();
    const isLoading = useSyrxControllerEnabledServicesManagerSelector(x => x.isLoading);
    const isDoneLoading = useSyrxControllerEnabledServicesManagerSelector(x => x.isDoneLoading);
    const isSubmitting = useSyrxControllerEnabledServicesManagerSelector(x => x.isSubmitting);
    const isDoneSubmitting = useSyrxControllerEnabledServicesManagerSelector(x => x.isDoneSubmitting);
    const syrxController = useSyrxControllerEnabledServicesManagerSelector(x => x.syrxController);
    const syrxControllerServiceTypes = useSyrxControllerEnabledServicesManagerSelector(x => x.syrxControllerServiceTypes);
    const syrxControllerEnabledServices = useSyrxControllerEnabledServicesManagerSelector(x => x.syrxControllerEnabledServices);

    const syrxControllerEnabledServicesByServiceId = useMemo(() => {
        if (syrxControllerEnabledServices == null) {
            return null;
        }

        return new Map(syrxControllerEnabledServices.map(syrxControllerEnabledService => [syrxControllerEnabledService.service_id, syrxControllerEnabledService]));
    }, [syrxControllerEnabledServices]);

    useEffect(() => {
        dispatch(syrxControllerEnabledServicesManagerActionCreators.load(syrxControllerId));

        return () => {
            dispatch(syrxControllerEnabledServicesManagerActions.resetState());
        }
    }, [dispatch, syrxControllerId]);

    const submit = useCallback(({services: enabledServiceModels}: {services: (SyrxControllerEnabledServiceModel & {is_enabled: boolean})[]}) => {
        const enabledServiceModelsToSubmit = enabledServiceModels
            .filter(enabledServiceModel => enabledServiceModel.is_enabled)
            .map(enabledServiceModel => ({
                service_id: enabledServiceModel.service_id,
                assigned_version: semver.validRange(enabledServiceModel.assigned_version)
            }))

        dispatch(syrxControllerEnabledServicesManagerActionCreators.submit(syrxControllerId, enabledServiceModelsToSubmit));
    }, [dispatch, syrxControllerId]);

    const initialValues = useMemo(() => {
        if (syrxController == null || syrxControllerServiceTypes == null || syrxControllerEnabledServicesByServiceId == null) {
            return null;
        }

        return {
            services: syrxControllerServiceTypes.map(syrxControllerServiceType => ({
                service_id: syrxControllerServiceType.id,
                is_enabled: syrxControllerEnabledServicesByServiceId.has(syrxControllerServiceType.id),
                assigned_version: syrxControllerEnabledServicesByServiceId.get(syrxControllerServiceType.id)?.assigned_version ?? ""
            }))
        }
    }, [syrxController, syrxControllerServiceTypes, syrxControllerEnabledServicesByServiceId]);

    const assignedVersionValidation = (value: string) => {
        if (value == null) {
            return "Required";
        } else if (semver.validRange(value) == null) {
            return "Not a valid semver range";
        } else {
            return undefined;
        }
    };

    return (
        <>
            {isLoading || isSubmitting ? <LoadingSpinner /> : null}
            {isDoneLoading ? (
                !isDoneSubmitting && syrxController != null && initialValues != null && syrxControllerEnabledServicesByServiceId != null ? (
                    <ReactFinalForm
                        onSubmit={submit}
                        initialValues={initialValues}
                        mutators={{
                            ...arrayMutators
                        }}
                    >
                        {({handleSubmit, valid}) => (
                            <Form onSubmit={handleSubmit}>
                                <h3>{syrxController.name} enabled services</h3>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Service name</th>
                                            <th>Enabled</th>
                                            <th>Assigned version</th>
                                            <th>Reported version</th>
                                            <th>Last contact</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <FieldArray name="services">
                                            {({fields}) => fields.value.map((row, index) => (
                                                <tr key={row.service_id}>
                                                    <td>{row.service_id}</td>
                                                    <td>
                                                        <Field
                                                            name={`services[${index}].is_enabled`}
                                                            component="input"
                                                            type="checkbox"
                                                            className="form-control"
                                                            disabled={row.service_id === "Pathian.UpdaterService"}
                                                            title={row.service_id === "Pathian.UpdaterService" ? "Pathian.UpdaterService cannot be disabled." : undefined}
                                                        />
                                                    </td>

                                                    <td>
                                                        {row.is_enabled ? (
                                                            <Field
                                                                name={`services[${index}].assigned_version`}
                                                                validate={assignedVersionValidation}
                                                                format={value => semver.validRange(value)}
                                                                formatOnBlur={true}
                                                            >
                                                                {({ input, meta }) => (
                                                                    <div>
                                                                        <input {...input} type="text" className="form-control" />
                                                                        {meta.error && meta.touched && <span style={{color: "#cc0000"}}><i className="fas fa-exclamation-triangle" /> {meta.error}</span>}
                                                                    </div>
                                                                )}
                                                            </Field>
                                                        ) : (
                                                            <span className="form-text disabled">{row.assigned_version}</span>
                                                        )}
                                                    </td>
                                                    <td><span className={`form-text ${!row.is_enabled ? "disabled" : ""}`}>{syrxControllerEnabledServicesByServiceId.get(row.service_id)?.reported_version}</span></td>
                                                    <td><span className={`form-text ${!row.is_enabled ? "disabled" : ""}`}>{syrxControllerEnabledServicesByServiceId.get(row.service_id)?.last_contact}</span></td>
                                                </tr>
                                            ))}
                                        </FieldArray>
                                    </tbody>
                                </table>
                                <div>
                                    <Button disabled={!valid} type="submit" variant="primary">Submit</Button>
                                    <LinkContainer to={`/admin/syrx-controllers/${syrxControllerId}`}><Button type="submit" variant="secondary">Cancel</Button></LinkContainer>
                                </div>
                            </Form>
                        )}
                    </ReactFinalForm>
                ) : (
                    <Redirect to={`/admin/syrx-controllers/${syrxControllerId}`} />
                )
            ) : null}
        </>
    )
}