import {axios} from "./axiosService";
import {
    SyrxControllerServiceVersion,
    SyrxControllerServiceVersionSubmittable
} from '../models/syrxControllerServiceVersion';

export const SyrxControllerServiceVersionsService = {
    getServiceVersionsForServiceType: async (serviceId: string) => {
        const {data: syrxControllerServiceVersions} = await axios.get<SyrxControllerServiceVersion[]>(`/syrx-controller-service-types/${serviceId}/versions`);
        return syrxControllerServiceVersions;
    },

    getServiceVersionForServiceTypeAndVersionName: async (serviceId: string, versionName: string) => {
        const {data: syrxControllerServiceVersion} = await axios.get<SyrxControllerServiceVersion>(`/syrx-controller-service-types/${serviceId}/versions/${versionName}`);
        return syrxControllerServiceVersion;
    },

    createServiceVersion: async (syrxControllerServiceVersion: SyrxControllerServiceVersionSubmittable) => {
        const {data: apiSyrxControllerServiceVersion} = await axios.post<SyrxControllerServiceVersion>(`/syrx-controller-service-types/${syrxControllerServiceVersion.service_id}/versions`, syrxControllerServiceVersion);
        return apiSyrxControllerServiceVersion;
    },

    updateServiceVersion: async (syrxControllerServiceVersion: SyrxControllerServiceVersionSubmittable) => {
        const {data: apiSyrxControllerServiceVersion} = await axios.put<SyrxControllerServiceVersion>(`/syrx-controller-service-types/${syrxControllerServiceVersion.service_id}/versions/${syrxControllerServiceVersion.version_name}`, syrxControllerServiceVersion);
        return apiSyrxControllerServiceVersion;
    },

    deleteServiceVersion: async (serviceId: string, versionName: string) => {
        await axios.delete(`/syrx-controller-service-types/${serviceId}/versions/${versionName}`);
    }
}