import { Dispatch } from "redux";
import {
    getBuildingPressureControlServiceConfigurationForSyrxController,
    patchBuildingPressureControlServiceConfigurationForSyrxController,
} from '../../../../services/syrxControllersService';
import {syrxControllerBuildingPressureControlServicePnormOutputEditorActions} from './syrxControllerBuildingPressureControlServicePnormOutputEditorSlice';

export const syrxControllerBuildingPressureControlServicePnormOutputEditorActionCreators = {
    loadConfiguration: (syrxControllerId: string) => async (dispatch: Dispatch<any>) => {
        dispatch(syrxControllerBuildingPressureControlServicePnormOutputEditorActions.startLoading());

        try {

            const syrxControllerPressureConfiguration = await getBuildingPressureControlServiceConfigurationForSyrxController(syrxControllerId);
            dispatch(syrxControllerBuildingPressureControlServicePnormOutputEditorActions.setPnormOutputSyrxControllerBacnetPointId(syrxControllerPressureConfiguration?.pnorm_output_syrx_controller_bacnet_point_id ?? null));
        } finally {
            dispatch(syrxControllerBuildingPressureControlServicePnormOutputEditorActions.finishLoading());
        }
    },

    submit: (syrxControllerId: string, pnormOutputSyrxControllerBacnetPointId: string | null) => async (dispatch: Dispatch) => {
        dispatch(syrxControllerBuildingPressureControlServicePnormOutputEditorActions.startSubmitting());
        await patchBuildingPressureControlServiceConfigurationForSyrxController(syrxControllerId, {pnorm_output_syrx_controller_bacnet_point_id: pnormOutputSyrxControllerBacnetPointId});
        dispatch(syrxControllerBuildingPressureControlServicePnormOutputEditorActions.finishSubmitting());
    }
}
