import React, {useEffect, useState} from 'react';
import {useField} from 'react-final-form';
import {SyrxControllerBACnetPointSelectionTree} from './syrxControllerBACnetPointSelectionTree';
import {getSyrxControllerBacnetDevice, getSyrxControllerBacnetPoint} from '../../../services/syrxControllersService';
import {SyrxControllerBacnetPoint} from '../../../models/syrxControllerBacnetPoint';
import {SyrxControllerBacnetDevice} from '../../../models/syrxControllerBacnetDevice';
import {LoadingSpinner} from '../../loadingSpinner/LoadingSpinner';

export const SyrxControllerBACnetPointSelectionTreeFieldAdapter: React.FunctionComponent<{syrxControllerId: string, name: string}> = ({syrxControllerId, name}) => {
    const field = useField<string | null>(name);
    const {value: syrxControllerBacnetPointId} = field.input;

    const [isLoading, setIsLoading] = useState(false);
    const [bacnetPoint, setBacnetPoint] = useState(null as SyrxControllerBacnetPoint | null);
    const [bacnetDevice, setBacnetDevice] = useState(null as SyrxControllerBacnetDevice | null);

    useEffect(() => {
        async function doTheThing() {
            setIsLoading(true);
            try {
                if (syrxControllerBacnetPointId == null) {
                    setBacnetPoint(null);
                    setBacnetDevice(null);
                    return;
                }

                const newBacnetPoint = await getSyrxControllerBacnetPoint(syrxControllerId, syrxControllerBacnetPointId);

                if (newBacnetPoint == null) {
                    setBacnetPoint(null);
                    setBacnetDevice(null);
                    return;
                }

                const newBacnetDevice = await getSyrxControllerBacnetDevice(syrxControllerId, newBacnetPoint.syrx_controller_bacnet_device_id);

                if (newBacnetDevice == null) {
                    setBacnetPoint(null);
                    setBacnetDevice(null);
                    return;
                }

                setBacnetPoint(newBacnetPoint);
                setBacnetDevice(newBacnetDevice);

            } finally {
                setIsLoading(false);
            }
        }

        void doTheThing();
    }, [syrxControllerId, syrxControllerBacnetPointId])

    return (
        <>
            {isLoading ? <LoadingSpinner /> : null}
            <table className="table">
                <tbody>
                <tr>
                    <td>MAC address</td>
                    <td>{bacnetDevice != null ? `${bacnetDevice.mac_address} [${bacnetDevice.network}]` : "n/a"}</td>
                </tr>
                <tr>
                    <td>Object ID</td>
                    <td>{bacnetPoint?.object_id ?? "n/a"}</td>
                </tr>
                <tr>
                    <td>Device name</td>
                    <td>{bacnetDevice?.name ?? "n/a"}</td>
                </tr>
                <tr>
                    <td>Point name</td>
                    <td>{bacnetPoint?.name ?? "n/a"}</td>
                </tr>
                </tbody>
            </table>
            <SyrxControllerBACnetPointSelectionTree
                syrxControllerId={syrxControllerId}
                onSyrxControllerBACnetPointSelected={field.input.onChange}
                selectedSyrxControllerBACnetPointId={field.input.value}
            />
        </>
    );
}