import { Dispatch } from "redux";
import { toast } from "react-toastify";

import { NewApiServices } from "../newApiServices";
import { projectsActions } from "../features/projects/projectsSlice";

export const projectsActionCreators = {
    delete: (projectId: number) => async (dispatch: Dispatch<any>) => {
        const deleteSuccessful = await NewApiServices.projectsService.delete(projectId);
        if (deleteSuccessful) {
            dispatch(projectsActions.triggerRefresh());
        } else {
            toast.error('Delete Failed. Please try again later.');
        }
    }
}