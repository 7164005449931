import { Dispatch } from "redux";
import {
    getLatestDiagnosticsForSyrxController,
} from '../services/syrxControllersService';
import {syrxControllerDiagnosticServiceDetailsActions} from '../features/syrxControllers/diagnosticService/syrxControllerDiagnosticServiceDetailsSlice';
import dayjs from 'dayjs';

export const loadLatestDiagnosticServiceProcessStatusRecord = (syrxControllerId: string) => async (dispatch: Dispatch<any>) => {
    dispatch(syrxControllerDiagnosticServiceDetailsActions.setIsUpdating(true));

    const lastRetrievalDate = dayjs.utc().format();
    const {
        latestNetworkInterfaceInformationRecord,
        latestRabbitmqMetricsCollectionRecord
    } = await getLatestDiagnosticsForSyrxController(syrxControllerId);

    dispatch(syrxControllerDiagnosticServiceDetailsActions.setControllerDiagnostics({
        lastRetrievalDate,
        latestNetworkInterfaceInformationRecord: latestNetworkInterfaceInformationRecord == null ? null : {
            ...latestNetworkInterfaceInformationRecord,
            timestamp: dayjs.utc(latestNetworkInterfaceInformationRecord.timestamp).format()
        },
        latestRabbitmqMetricsCollectionRecord: latestRabbitmqMetricsCollectionRecord
    }));
    dispatch(syrxControllerDiagnosticServiceDetailsActions.setIsUpdating(false));
};