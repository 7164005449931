import {BaseApiService} from "./baseApiService";

export class AuthService extends BaseApiService {
    async login(username: string, password: string, token?: string) {
        const body = {username, password, token};
        return await this.axios.post("/auth", body);
    }

    async logout() {
        await this.axios.delete("/auth");
    }

    async createNewUser(newUser: {email: string, password: string, firstName: string, lastName: string, address: string, city: string, state: string, zip: string, jobTitle: string, groupName: string}) {
        return await this.axios.post(`/users/register`, {
            email: newUser.email,
            password: newUser.password,
            first_name: newUser.firstName,
            last_name: newUser.lastName,
            address: newUser.address,
            city: newUser.city,
            state: newUser.state,
            zip: newUser.zip,
            job_title: newUser.jobTitle,
            group_name: newUser.groupName
        });
    }
}

