import React from 'react'

import {useParams} from 'react-router';
import {JobLogsTree} from './jobLogsTree';

export const JobLogs: React.FunctionComponent = () => {
    const {jobId} = useParams() as {jobId: string};

    return <>
        <h3>Job logs</h3>
        <JobLogsTree jobId={jobId} />
    </>
}