import {BaseApiService} from "./baseApiService";
import {FilterCollection, SortCollection} from '@sht-dev/fancy-grid';
import qs from 'qs';
import {GroupRole} from '../models/groupRole';
import {AxiosError} from 'axios';

export class GroupRolesService extends BaseApiService {
    async getGroupRolesForGrid(pageNum: number, pageSize: number, sort: SortCollection = [], filter: FilterCollection = []) {
        const queryString = qs.stringify({
            skip: pageNum * pageSize,
            take: pageSize,
            page: pageNum,
            pageSize,
            sort: sort.map(x => ({
                field: x.fieldName,
                dir: x.dir
            })),
            filter: {
                filters: filter.map(x => ({
                    field: x.fieldName,
                    field_type: x.fieldType,
                    operator: x.operator,
                    value: x.value
                }))
            }
        });
        const res = await this.axios.get<{data: GroupRole[], total: number}>(`/group-roles?${queryString}`);
        return res.data;
    }

    async getGroupRoleById(groupRoleId: string) {
        try {
            const res = await this.axios.get<GroupRole>(`group-roles/${groupRoleId}`);
            return res.data;
        } catch (e) {
            if (e.isAxiosError && (e as AxiosError).response?.status === 404) {
                return null;
            }
            throw e;
        }
    }

    async getGroupPermissionsForGroupRoleById(groupRoleId: string) {
        try {
            const res = await this.axios.get<string[]>(`group-roles/${groupRoleId}/group-permissions`);
            return res.data;
        } catch (e) {
            if (e.isAxiosError && (e as AxiosError).response?.status === 404) {
                return null;
            }
            throw e;
        }
    }

    async saveGroupRole(groupRole: GroupRole): Promise<GroupRole> {
        if (groupRole.id == null) {
            const res = await this.axios.post<GroupRole>(`/group-roles`, groupRole);
            return res.data;
        } else {
            const res = await this.axios.put<GroupRole>(`/group-roles/${groupRole.id}`, groupRole);
            return res.data
        }
    }

    async saveGroupPermissionsForGroupRole(groupRoleId: string, groupPermissions: string[]): Promise<string[]> {
        const res = await this.axios.put<string[]>(`/group-roles/${groupRoleId}/group-permissions`, groupPermissions);
        return res.data
    }

    async deleteGroupRoleById(groupRoleId: string): Promise<void> {
        await this.axios.delete(`/group-roles/${groupRoleId}`);
    }
}

