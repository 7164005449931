import React, {useCallback, useEffect, useState} from 'react'
import {Button, Modal} from "react-bootstrap";
import {
    SyrxControllerBacnetNetworkSocketHelper
} from '../../../hooks/useSyrxControllerBacnetNetworkSocketHelper';
import {
    patchBacnetDevices
} from '../../../services/syrxControllersService';
import {ScrollToBottom} from '../../../components/scrollToBottom';
import {SyrxControllerBacnetDeviceIdentification} from '../../../models/syrxControllerBacnetDeviceIdentification';
import {generateDevicesEasyTable} from './easyTableHelpers';
import {bacnetObjectTypesByName} from '../../bacnet/bacnetObjectTypeSelectField';
import {SyrxControllerBacnetDeviceUpdateModel} from '../../../models/syrxControllerBacnetDevice';
import {useStringAppenderState} from '../../../hooks/useStringAppenderState';

type ModalState = "running" | "ready_for_submit" | "submitting" | "done" | "error";

async function performDeviceReload(socketHelper: SyrxControllerBacnetNetworkSocketHelper, deviceIdentification: SyrxControllerBacnetDeviceIdentification, setDeviceUpdateModel: (devices: SyrxControllerBacnetDeviceUpdateModel) => any, setModalState: (modalState: ModalState) => any, appendToDebugMessages: (x: string) => any) {
    let newDeviceUpdateModel: SyrxControllerBacnetDeviceUpdateModel | null;

    try {
        appendToDebugMessages(`Sent request for device name\nWaiting for acknowledgment...\n`);
        const name = await socketHelper.retrieveObjectName(deviceIdentification, bacnetObjectTypesByName.OBJECT_DEVICE, deviceIdentification.instance_number, () => appendToDebugMessages(`Request acknowledged by syrx controller\nWaiting for response...\n`));
        appendToDebugMessages(`Name: ${name}\n\n`);

        appendToDebugMessages(`Sent request for device vendor name\nWaiting for acknowledgment...\n`);
        const vendorName = await socketHelper.retrieveObjectVendorName(deviceIdentification, bacnetObjectTypesByName.OBJECT_DEVICE, deviceIdentification.instance_number, () => appendToDebugMessages(`Request acknowledged by syrx controller\nWaiting for response...\n`));
        appendToDebugMessages(`Vendor name: ${vendorName}\n\n`);

        appendToDebugMessages(`Sent request for device description\nWaiting for acknowledgment...\n`);
        const description = await socketHelper.retrieveObjectDescription(deviceIdentification, bacnetObjectTypesByName.OBJECT_DEVICE, deviceIdentification.instance_number, () => appendToDebugMessages(`Request acknowledged by syrx controller\nWaiting for response...\n`));
        appendToDebugMessages(`Description: ${description}\n\n`);

        newDeviceUpdateModel = {
            ...deviceIdentification,
            name: name,
            vendor_name: vendorName,
            description: description,
            status: 'available'
        };

    } catch (e) {
        appendToDebugMessages(`An error occurred retrieving properties for device: ${JSON.stringify(e, null, 4)}\n`);

        newDeviceUpdateModel = {
            ...deviceIdentification,
            name: null,
            vendor_name: null,
            description: null,
            status: 'unavailable'
        };
    }

    setDeviceUpdateModel(newDeviceUpdateModel);

    const t = generateDevicesEasyTable([newDeviceUpdateModel]);
    appendToDebugMessages(`Reloaded devices:\n\n${t.toString()}\n`);

    appendToDebugMessages(`Ready for submit.`);

    setModalState("ready_for_submit");
}

async function performSubmitDevices(syrxControllerId: string, deviceUpdateModel: SyrxControllerBacnetDeviceUpdateModel, setModalState: (modalState: ModalState) => any, appendToDebugMessages: (x: string) => any) {
    setModalState("submitting");

    appendToDebugMessages(`\n\nSubmitting...\n`);
    try {
        await patchBacnetDevices(syrxControllerId, [deviceUpdateModel]);
        appendToDebugMessages(`Submitting device complete.\n`);
        setModalState("done");
    } catch (e) {
        appendToDebugMessages(`An error occurred submitting device: ${JSON.stringify(e, null, 4)}\n`);
        setModalState("error");
    }
}

export interface SyrxControllerBacnetDeviceReloadModalProps {
    syrxControllerId: string;
    deviceIdentification: SyrxControllerBacnetDeviceIdentification;
    socketHelper: SyrxControllerBacnetNetworkSocketHelper;
    onClose: (deleteSuccessful: boolean) => void;
}

export const SyrxControllerBacnetDeviceReloadModal: React.FunctionComponent<SyrxControllerBacnetDeviceReloadModalProps> = (props) => {
    const {
        syrxControllerId,
        deviceIdentification,
        socketHelper,
        onClose
    } = props;

    const [debugMessages, appendToDebugMessages] = useStringAppenderState("Initiating device reload\n\n");
    const [deviceUpdateModel, setDeviceUpdateModel] = useState(null as SyrxControllerBacnetDeviceUpdateModel | null);
    const [modalState, setModalState] = useState("running" as "running" | "ready_for_submit" | "submitting" | "done" | "error");

    useEffect(() => {
        void performDeviceReload(socketHelper, deviceIdentification, setDeviceUpdateModel, setModalState, appendToDebugMessages);
    }, [socketHelper, deviceIdentification, appendToDebugMessages]);

    const doDismiss = useCallback(() => onClose(false), [onClose]);

    const doSubmit = useCallback(async () => {
        if (deviceUpdateModel == null) {
            return;
        }

        await performSubmitDevices(syrxControllerId, deviceUpdateModel, setModalState, appendToDebugMessages);
    }, [syrxControllerId, deviceUpdateModel, appendToDebugMessages]);

    return (
        <Modal show={true} onHide={doDismiss} size="xl" backdrop="static" keyboard={false}>
            <Modal.Header>
                <Modal.Title>Reload device</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <pre>
                    <ScrollToBottom style={{maxHeight: "600px"}}>
                        {debugMessages}
                    </ScrollToBottom>
                </pre>
            </Modal.Body>
            <Modal.Footer>
                {modalState === "ready_for_submit" && (
                    <Button variant="primary" onClick={doSubmit}>
                        Submit reloaded device
                    </Button>
                )}
                <Button variant="outline-secondary" onClick={doDismiss}>
                    {modalState === "done" || modalState === "error" ? "Close" : "Cancel"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

