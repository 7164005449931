import React, {useCallback, useEffect, useState} from 'react'
import {Button, Modal} from "react-bootstrap";
import {SyrxControllerBacnetNetworkSocketHelper} from '../../../hooks/useSyrxControllerBacnetNetworkSocketHelper';
import {patchBacnetDevices} from '../../../services/syrxControllersService';
import {ScrollToBottom} from '../../../components/scrollToBottom';
import {generateDevicesEasyTable} from './easyTableHelpers';
import {bacnetObjectTypesByName} from '../../bacnet/bacnetObjectTypeSelectField';
import {SyrxControllerBacnetDeviceUpdateModel} from '../../../models/syrxControllerBacnetDevice';
import {useStringAppenderState} from '../../../hooks/useStringAppenderState';

type ModalState = "running" | "ready_for_submit" | "submitting" | "done" | "error";

async function performDiscoverDevices(socketHelper: SyrxControllerBacnetNetworkSocketHelper, setDiscoveredDevices: (devices: SyrxControllerBacnetDeviceUpdateModel[]) => any, setModalState: (modalState: ModalState) => any, appendToDebugMessages: (x: string) => any) {
    try {
        const devices = await socketHelper.discoverDevices(() => appendToDebugMessages(`Request acknowleged by syrx controller\nWaiting for response...\n\n`));

        appendToDebugMessages(`Discovered ${devices.length} devices\n\n`);

        const newDiscoveredDevices: SyrxControllerBacnetDeviceUpdateModel[] = [];

        for (const deviceIdentification of devices) {
            appendToDebugMessages(`Retrieving device properties for ${deviceIdentification.mac_address} OBJECT_DEVICE:${deviceIdentification.instance_number}\n\n`);

            try {
                appendToDebugMessages(`Sent request for device name\nWaiting for acknowledgment...\n`);
                const name = await socketHelper.retrieveObjectName(deviceIdentification, bacnetObjectTypesByName.OBJECT_DEVICE, deviceIdentification.instance_number, () => appendToDebugMessages(`Request acknowledged by syrx controller\nWaiting for response...\n`));
                appendToDebugMessages(`Name: ${name}\n\n`);

                appendToDebugMessages(`Sent request for device vendor name\nWaiting for acknowledgment...\n`);
                const vendorName = await socketHelper.retrieveObjectVendorName(deviceIdentification, bacnetObjectTypesByName.OBJECT_DEVICE, deviceIdentification.instance_number, () => appendToDebugMessages(`Request acknowledged by syrx controller\nWaiting for response...\n`));
                appendToDebugMessages(`Vendor name: ${vendorName}\n\n`);

                appendToDebugMessages(`Sent request for device description\nWaiting for acknowledgment...\n`);
                const description = await socketHelper.retrieveObjectDescription(deviceIdentification, bacnetObjectTypesByName.OBJECT_DEVICE, deviceIdentification.instance_number, () => appendToDebugMessages(`Request acknowledged by syrx controller\nWaiting for response...\n`));
                appendToDebugMessages(`Description: ${description}\n\n`);

                newDiscoveredDevices.push({
                    ...deviceIdentification,
                    name: name,
                    vendor_name: vendorName,
                    description: description,
                    status: 'available'
                });
            } catch (e) {
                appendToDebugMessages(`An error occurred retrieving properties for device: ${JSON.stringify(e, null, 4)}\n\n`);

                newDiscoveredDevices.push({
                    ...deviceIdentification,
                    name: null,
                    vendor_name: null,
                    description: null,
                    status: 'unavailable'
                });
            }
        }

        const t = generateDevicesEasyTable(newDiscoveredDevices);
        appendToDebugMessages(`Discovered devices:\n\n${t.toString()}\n`);

        appendToDebugMessages(`Ready for submit.`);

        setDiscoveredDevices(newDiscoveredDevices);

        setModalState("ready_for_submit");
    } catch (e) {
        appendToDebugMessages(`Received error: ${JSON.stringify(e, null, 4)}\n`);
        setModalState("error");
    }
}

async function performSubmitDevices(syrxControllerId: string, devices: SyrxControllerBacnetDeviceUpdateModel[], setModalState: (modalState: ModalState) => any, appendToDebugMessages: (x: string) => any) {
    setModalState("submitting");

    appendToDebugMessages(`\n\nSubmitting...\n`);
    try {
        await patchBacnetDevices(syrxControllerId, devices);
        appendToDebugMessages(`Submitting devices complete.\n`);
        setModalState("done");
    } catch (e) {
        appendToDebugMessages(`An error occurred submitting device: ${JSON.stringify(e, null, 4)}\n`);
        setModalState("error");
    }
}

export interface SyrxControllerBacnetDevicesDiscoverModalProps {
    syrxControllerId: string;
    socketHelper: SyrxControllerBacnetNetworkSocketHelper;
    onClose: (deleteSuccessful: boolean) => void;
}

export const SyrxControllerBacnetDevicesDiscoverModal: React.FunctionComponent<SyrxControllerBacnetDevicesDiscoverModalProps> = (props) => {
    const {syrxControllerId, socketHelper, onClose} = props;

    const [debugMessages, appendToDebugMessages] = useStringAppenderState("Initiating device discovery request\nWaiting for acknowledgement...\n");
    const [discoveredDevices, setDiscoveredDevices] = useState(null as SyrxControllerBacnetDeviceUpdateModel[] | null);
    const [modalState, setModalState] = useState("running" as ModalState);

    useEffect(() => {
        void performDiscoverDevices(socketHelper, setDiscoveredDevices, setModalState, appendToDebugMessages);
    }, [socketHelper, appendToDebugMessages]);

    const doSubmit = useCallback(async () => {
        if (discoveredDevices == null) {
            return;
        }
        await performSubmitDevices(syrxControllerId, discoveredDevices, setModalState, appendToDebugMessages);
    }, [syrxControllerId, appendToDebugMessages, discoveredDevices]);

    return (
        <Modal show={true} size="xl" backdrop="static" keyboard={false}>
            <Modal.Header>
                <Modal.Title>Discover devices</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <pre>
                    <ScrollToBottom style={{maxHeight: "600px"}}>
                        {debugMessages}
                    </ScrollToBottom>
                </pre>
            </Modal.Body>
            <Modal.Footer>
                {modalState === "ready_for_submit" && (
                    <Button variant="primary" onClick={doSubmit}>
                        Submit devices
                    </Button>
                )}
                <Button variant="outline-secondary" onClick={() => onClose(false)}>
                    {modalState === "done" || modalState === "error" ? "Close" : "Cancel"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

