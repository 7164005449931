import React, { FunctionComponent } from 'react'
import FancyGrid, { SortCollection } from '@sht-dev/fancy-grid';
import { Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import { getSyrxControllerEnabledServicesForGrid } from '../../../services/syrxControllersService';
import { SyrxControllerEnabledService } from '../../../models/syrxControllerEnabledService';

export interface SyrxControllerEnabledServicesGridProps {
    syrxControllerId: string;
}

export const SyrxControllerEnabledServicesGrid: FunctionComponent<SyrxControllerEnabledServicesGridProps> = ({syrxControllerId}) => {
    const dataRetrievalFunction = (pageNumber: number, pageSize: number, sort: SortCollection) => getSyrxControllerEnabledServicesForGrid(syrxControllerId, pageNumber, pageSize, sort);

    return (
        <FancyGrid.ReduxGrid 
            gridName={"SyrxControllerEnabledServicesGrid"} 
            dataRetrievalFunction={dataRetrievalFunction}
            updateTriggers={[syrxControllerId]}
        >
            <FancyGrid.ColumnList>
                <FancyGrid.Column
                    name="service_id"
                    title="Service ID"
                />
                <FancyGrid.Column
                    name="assigned_version"
                    title="Assigned version"
                />
                <FancyGrid.Column
                    name="reported_version"
                    title="Reported version"
                />
                <FancyGrid.Column
                    name="last_contact"
                    title="Last contact"
                >
                </FancyGrid.Column>
                <FancyGrid.Column 
                    sortable={false}
                    filterable={false}
                >
                    <FancyGrid.CellRenderer>
                        {(_, enabledService: SyrxControllerEnabledService) => (
                            <>
                                <LinkContainer to={`/admin/syrx-controllers/${syrxControllerId}/services/${enabledService.service_id}`}><Button variant="outline-secondary">Manage service</Button></LinkContainer>
                                <LinkContainer to={`/admin/syrx-controllers/${syrxControllerId}/services/${enabledService.service_id}/logs`}><Button variant="outline-secondary">Logs</Button></LinkContainer>
                            </>
                        )}
                    </FancyGrid.CellRenderer>
                </FancyGrid.Column>
            </FancyGrid.ColumnList>
            <FancyGrid.ReduxSortable />
            <FancyGrid.ReduxPager />
        </FancyGrid.ReduxGrid>
    )
}
