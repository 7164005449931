import React, { FunctionComponent } from 'react'
import FancyGrid, {FilterCollection, SortCollection} from '@sht-dev/fancy-grid';
import { Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import {getJobsForGrid} from '../../services/jobsService';

export const JobsGrid: FunctionComponent = () => {
    const dataRetrievalFunction = (pageNumber: number, pageSize: number, sort: SortCollection, filter: FilterCollection) => getJobsForGrid(pageNumber, pageSize, sort, filter);

    return (
        <FancyGrid.ReduxGrid 
            gridName={"JobsGrid"}
            dataRetrievalFunction={dataRetrievalFunction}
        >
            <FancyGrid.ColumnList>
                <FancyGrid.Column
                    name="id"
                    title="ID"
                />
                <FancyGrid.Column
                    name="parent_job_id"
                    title="Parent job ID"
                >
                </FancyGrid.Column>
                <FancyGrid.Column
                    name="job_type"
                    title="Type"
                />
                <FancyGrid.Column
                    name="status"
                    title="Status"
                >
                </FancyGrid.Column>
                <FancyGrid.Column
                    name="created_time"
                    title="Created time"
                >
                </FancyGrid.Column>
                <FancyGrid.Column
                    name="queued_time"
                    title="Queued time"
                >
                </FancyGrid.Column>
                <FancyGrid.Column
                    name="run_started_time"
                    title="Run started time"
                >
                </FancyGrid.Column>
                <FancyGrid.Column
                    name="run_finished_time"
                    title="Run finished time"
                >
                </FancyGrid.Column>
                <FancyGrid.Column
                    name="finished_time"
                    title="Finished time"
                >
                </FancyGrid.Column>
                <FancyGrid.Column 
                    tdProps={{style: {flex: "0 0 14%"}}}
                    sortable={false}
                    filterable={false}
                >
                    <FancyGrid.CellRenderer>
                        {(_, job) => <LinkContainer to={`/admin/jobs/${job.id}/logs`}><Button variant="outline-secondary">Logs</Button></LinkContainer>}
                    </FancyGrid.CellRenderer>
                </FancyGrid.Column>
            </FancyGrid.ColumnList>
            <FancyGrid.ReduxSortable />
            <FancyGrid.ReduxPager />
            <FancyGrid.ReduxFilterable filterStyle="popup" />
        </FancyGrid.ReduxGrid>
    )
}