import React from 'react'
import { Table, Button } from 'react-bootstrap';
import {Field} from 'react-final-form';
import {FieldArray} from 'react-final-form-arrays';

export const SyrxControllersEditorBbmdServersTable: React.FunctionComponent<{push: (...args: any[]) => any}> = props => {
    const addBbmdServer = () => {
        props.push('bacnetConnectionBbmdServers', {ip_address: "", ip_mask: "255.255.255.255", port_num: 47808});
    };

    return (
        <Table>
            <thead>
                <tr>
                    <th>IP address</th>
                    <th>IP mask</th>
                    <th>BACnet UDP port</th>
                    <th />
                </tr>
            </thead>
            <tbody>
                <FieldArray name="bacnetConnectionBbmdServers">
                    {({fields}) => fields.map((name, index) => (
                        <tr key={name}>
                            <td>
                                <Field 
                                    name={`${name}.ip_address`}
                                    component="input"
                                    type="text"
                                    className="form-control"
                                />
                            </td>
                            <td>
                                <Field 
                                    name={`${name}.ip_mask`}
                                    component="input"
                                    type="text"
                                    className="form-control"
                                />
                            </td>
                            <td>
                                <Field 
                                    name={`${name}.port_num`}
                                    component="input"
                                    type="text"
                                    className="form-control"
                                />
                            </td>
                            <td>
                                <Button type="button" variant="danger" onClick={() => fields.remove(index)}>X</Button>
                            </td>
                        </tr>
                    ))}
                </FieldArray>
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan={4}>
                        <Button type="button" onClick={addBbmdServer}>+</Button>
                    </td>
                </tr>
            </tfoot>
        </Table>
    );
};