import {CaseReducer, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {buildSubStateSelector} from '../../store/utilities/useAppStateSelector';
import {NewUserModel} from './types';

const initialState = {
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    jobTitle: '',
    isSuccessful: false,
    errorMessage: '',
    groupName: ''
};

export type AppStateUserRegistration = typeof initialState;

export const slice = createSlice<AppStateUserRegistration, {
    submit: CaseReducer<AppStateUserRegistration, PayloadAction<NewUserModel>>;
    success: CaseReducer<AppStateUserRegistration>;
    error: CaseReducer<AppStateUserRegistration, PayloadAction<{exception: any}>>;
}>({
    name: "userRegistration",
    initialState,
    reducers: {
        submit(state, action) {
            state.email = action.payload.email;
            state.password = action.payload.password;
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.address = action.payload.address;
            state.city = action.payload.city;
            state.state = action.payload.state;
            state.zip = action.payload.zip;
            state.jobTitle = action.payload.jobTitle;
            state.groupName = action.payload.groupName;
        },
        success(state) {
            state.isSuccessful = true;
        },
        error(state, action) {
            state.isSuccessful = false;
            state.errorMessage = action.payload.exception;
        }
    }
});

export const useUserRegistrationSelector = buildSubStateSelector(state => state.userRegistration);
export const userRegistrationActions = slice.actions;
export const userRegistrationReducer = slice.reducer;