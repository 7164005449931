import { BaseApiService } from "./baseApiService";

export class ComponentsService extends BaseApiService {
    async typeaheadSearch(searchValue: string) {
        const res = await this.axios.get(`/components/typeahead/${searchValue}`);
        return res.data;
    }
    async getDescendents(componentId: string) {
        const res = await this.axios.get(`/components/${componentId}/descendents`);
        return res.data;
    }
    async getComponentScheduleTree() {
        const res = await this.axios.get('/components/schedule-tree');
        return res.data;
    }
}
