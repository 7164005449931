import {FilterCollection, SortCollection} from '@sht-dev/fancy-grid';
import qs from "qs";
import {axios} from "./axiosService";
import {Job, JobRunnerVendorPointRecordMoveJob} from '../models/job';
import {AxiosError} from 'axios';
import {JobLog} from '../models/jobLog';

export const getJobsForGrid = async (pageNum: number, pageSize: number, sort: SortCollection, filter: FilterCollection) => {
    const queryString = qs.stringify({
        pageSize,
        pageNum,
        sort,
        filter: filter.filter(x => x.value.length > 0)
    });
    const res = await axios.get<{data: Job[], total: number}>(`/jobs?${queryString}`);
    return res.data;
};

export const getJobById = async (jobId: string) => {
    try {
        const res = await axios.get<Job>(`/jobs/${jobId}`);
        if (res.status === 404) {
            return null;
        }
        return res.data;
    } catch (e) {
        if (e.isAxiosError) {
            const axiosError = e as AxiosError;
            if (axiosError.response?.status === 404) {
                return null;
            }
        }

        throw e;
    }
};

export const getChildJobByParentJobId = async (parentJobId: string) => {
    const res = await axios.get<Job[]>(`/jobs/${parentJobId}/child-jobs`);
    return res.data;
};

export const getLogsForJob = async (jobId: string, parentLogId?: string | null) => {
    const parentLogIdStr = parentLogId === null ? "null" : parentLogId;
    const queryString = qs.stringify({
        ...(parentLogIdStr != null ? {parentLogId: parentLogIdStr} : {})
    });
    const res = await axios.get<JobLog[]>(`/jobs/${jobId}/logs?${queryString}`);
    return res.data;
};

export const getJobRunnerVendorPointRecordMoveJobs = async (pageNum: number, pageSize: number, sort: SortCollection) => {
    const queryString = qs.stringify({
        skip: pageNum * pageSize,
        take: pageSize,
        page: pageNum,
        pageSize,
        sort
    });
    const res = await axios.get<{data: JobRunnerVendorPointRecordMoveJob[], total: number}>(`/jobs/statuses?${queryString}`);
    return res.data;
};