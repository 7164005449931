import {CaseReducer, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {buildSubStateSelector} from '../../../store/utilities/useAppStateSelector';
import {SyrxController} from '../../../models/syrxController';
import {SyrxControllerEnabledService} from '../../../models/syrxControllerEnabledService';

const initialState = {
    isLoading: false,
    isDoneLoading: false,
    syrxController: null as SyrxController | null,
    syrxControllerEnabledService: null as SyrxControllerEnabledService | null
};

export type AppStateSyrxControllerEnabledServiceDetails = typeof initialState;

export const slice = createSlice<AppStateSyrxControllerEnabledServiceDetails, {
    startLoading: CaseReducer<AppStateSyrxControllerEnabledServiceDetails>;
    detailsLoad: CaseReducer<AppStateSyrxControllerEnabledServiceDetails, PayloadAction<{syrxController: SyrxController | null, syrxControllerEnabledService: SyrxControllerEnabledService | null}>>;
    finishLoading: CaseReducer<AppStateSyrxControllerEnabledServiceDetails>;
}>({
    name: "syrxControllerEnabledServiceDetails",
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
            state.isDoneLoading = false;
        },
        detailsLoad(state, {payload: {syrxController, syrxControllerEnabledService}}) {
            state.syrxController = syrxController;
            state.syrxControllerEnabledService = syrxControllerEnabledService;
        },
        finishLoading(state) {
            state.isLoading = false;
            state.isDoneLoading = true;
        }
    }
});

export const useSyrxControllerEnabledServiceDetailsSelector = buildSubStateSelector(state => state.syrxControllerEnabledServiceDetails);
export const syrxControllerEnabledServiceDetailsActions = slice.actions;
export const syrxControllerEnabledServiceDetailsReducer = slice.reducer;