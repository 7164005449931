import React from 'react'
import {NewApiServices} from '../../../../newApiServices';
import {DeleteModal} from '../../../../modals/deleteModal';

export interface GroupRoleDeleteModalProps {
    groupRoleId: string;
    onClose: (deleteSuccessful: boolean) => void;
}

export const GroupRoleDeleteModal: React.FunctionComponent<GroupRoleDeleteModalProps> = ({groupRoleId, onClose}) => {
    return (
        <DeleteModal
            title="Delete group role"
            message="Delete this group role?"
            loadItem={() => NewApiServices.groupRolesService.getGroupRoleById(groupRoleId)}
            performDeletion={() => NewApiServices.groupRolesService.deleteGroupRoleById(groupRoleId)}
            onClose={onClose}
        >
            {item => (
                <table className="table">
                    <tbody>
                        <tr>
                            <th>Name</th>
                            <td>{item.name}</td>
                        </tr>
                        <tr>
                            <th>Description</th>
                            <td>{item.description}</td>
                        </tr>
                    </tbody>
                </table>
            )}
        </DeleteModal>
    )
}