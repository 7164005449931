import { Dispatch } from "redux";
import {moveVendorPointDataNewJobActions, QueueEntry} from './moveVendorPointDataNewJobSlice';
import {VendorPointsService} from '../../../../services/vendorPointsService';
import {AppState} from '../../../../store/appState';
import dayjs from 'dayjs';

export const moveVendorPointDataNewJobActionCreators = {
    addToQueue: (queueEntry: QueueEntry) => async (dispatch: Dispatch<any>) => {
        dispatch(moveVendorPointDataNewJobActions.setShowLoadingSpinner(true));

        if (queueEntry.sourcePcsPointId != null) {
            // because of the way the api methods are structured, PCS points will not have pointName filled out
            const pcsPoint = await VendorPointsService.getPcsPointById(queueEntry.sourcePcsPointId);
            queueEntry.sourcePointName = `pcs|${pcsPoint.pcs_device.pcs_site.site_name}|${pcsPoint.pcs_device.name}|${pcsPoint.name}`;
        }

        if (queueEntry.destinationPcsPointId != null) {
            // because of the way the api methods are structured, PCS points will not have pointName filled out
            const pcsPoint = await VendorPointsService.getPcsPointById(queueEntry.destinationPcsPointId);
            queueEntry.destinationPointName = `pcs|${pcsPoint.pcs_device.pcs_site.site_name}|${pcsPoint.pcs_device.name}|${pcsPoint.name}`;
        }

        dispatch(moveVendorPointDataNewJobActions.addToQueue(queueEntry));
        dispatch(moveVendorPointDataNewJobActions.setShowLoadingSpinner(false));
    },

    submitVendorPointRecordMoveJobs: () => async (dispatch: Dispatch<any>, getState: () => AppState) => {
        dispatch(moveVendorPointDataNewJobActions.setShowLoadingSpinner(true));
        try {
            const vendorPointRecordMoveJobs = getState().moveVendorPointDataNewJob.queue.map(x => ({
                source_data_point_name: x.sourcePointName,
                source_vendor_point_id: x.sourceVendorPointId,
                source_pcs_point_id: x.sourcePcsPointId,
                source_syrx_controller_bacnet_point_id: x.sourceSyrxControllerBacnetPointId,
                destination_data_point_name: x.destinationPointName,
                destination_vendor_point_id: x.destinationVendorPointId,
                destination_pcs_point_id: x.destinationPcsPointId,
                destination_syrx_controller_bacnet_point_id: x.destinationSyrxControllerBacnetPointId,
                full_time_range: x.fullTimeRange,
                start_date: x.fullTimeRange ? null : dayjs.utc(x.startDate).hour(0).minute(0).second(0).millisecond(0).format(),
                end_date: x.fullTimeRange ? null : dayjs.utc(x.endDate).hour(0).minute(0).second(0).millisecond(0).add(1, "day").format(),
                source_record_handling: x.sourceRecordHandling,
                remap_equipment_points: x.remapEquipmentPoints
            }));

            await VendorPointsService.submitVendorPointRecordMoveJobs(vendorPointRecordMoveJobs);
            dispatch(moveVendorPointDataNewJobActions.setIsCompleted(true));
        } finally {
            dispatch(moveVendorPointDataNewJobActions.setShowLoadingSpinner(false));
        }
    },

    initialize: () => async (dispatch: Dispatch<any>) => {
        dispatch(moveVendorPointDataNewJobActions.setIsCompleted(false));
        dispatch(moveVendorPointDataNewJobActions.setShowLoadingSpinner(false));
        dispatch(moveVendorPointDataNewJobActions.clearQueue());
    }
}
