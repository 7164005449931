import { Dispatch } from "redux";
import {
    getBuildingPressureControlServiceConfigurationForSyrxController, patchBuildingPressureControlServiceConfigurationForSyrxController,
} from '../../../services/syrxControllersService';
import {syrxControllerBuildingPressureControlServiceBasicConfigurationEditorActions} from './syrxControllerBuildingPressureControlServiceBasicConfigurationEditorSlice';
import {SyrxControllerBuildingPressureControlServiceBasicConfiguration} from '../../../models/syrxControllerBuildingPressureControlServiceConfiguration';

export const syrxControllerBuildingPressureControlServiceBasicConfigurationEditorActionCreators = {
    loadConfiguration: (syrxControllerId: string) => async (dispatch: Dispatch<any>) => {
        dispatch(syrxControllerBuildingPressureControlServiceBasicConfigurationEditorActions.startLoading());

        try {

            const syrxControllerPressureBasicConfiguration = await getBuildingPressureControlServiceConfigurationForSyrxController(syrxControllerId) as SyrxControllerBuildingPressureControlServiceBasicConfiguration;
            dispatch(syrxControllerBuildingPressureControlServiceBasicConfigurationEditorActions.setSyrxControllerPressureBasicConfiguration({syrxControllerPressureBasicConfiguration}));
        } finally {
            dispatch(syrxControllerBuildingPressureControlServiceBasicConfigurationEditorActions.finishLoading());
        }
    },

    submit: (syrxControllerId: string, syrxControllerBuildingPressureServiceBasicConfiguration: SyrxControllerBuildingPressureControlServiceBasicConfiguration) => async (dispatch: Dispatch) => {
        dispatch(syrxControllerBuildingPressureControlServiceBasicConfigurationEditorActions.startSubmitting());
        await patchBuildingPressureControlServiceConfigurationForSyrxController(syrxControllerId, syrxControllerBuildingPressureServiceBasicConfiguration);
        console.log(syrxControllerBuildingPressureServiceBasicConfiguration);
        dispatch(syrxControllerBuildingPressureControlServiceBasicConfigurationEditorActions.finishSubmitting());
    }
}
