import {CaseReducer, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {buildSubStateSelector} from '../../../store/utilities/useAppStateSelector';
import {SyrxControllerDiagnosticServiceNetworkInterfaceInformationRecord} from '../../../models/syrxControllerDiagnosticServiceNetworkInterfaceInformationRecord';
import {SyrxControllerDiagnosticServiceRabbitmqMetricsCollectionRecord} from '../../../models/syrxControllerDiagnosticServiceRabbitmqMetricsCollectionRecord';

const initialState = {
    isUpdating: false,
    lastRetrievalDate: null as string | null,
    latestNetworkInterfaceInformationRecord: null as SyrxControllerDiagnosticServiceNetworkInterfaceInformationRecord | null,
    latestRabbitmqMetricsCollectionRecord: null as SyrxControllerDiagnosticServiceRabbitmqMetricsCollectionRecord | null
};
export type AppStateSyrxControllerDiagnosticServiceDetails = typeof initialState;

export const slice = createSlice<AppStateSyrxControllerDiagnosticServiceDetails, {
    setIsUpdating: CaseReducer<AppStateSyrxControllerDiagnosticServiceDetails, PayloadAction<boolean>>;
    setControllerDiagnostics: CaseReducer<AppStateSyrxControllerDiagnosticServiceDetails, PayloadAction<{
        lastRetrievalDate: string | null;
        latestNetworkInterfaceInformationRecord: SyrxControllerDiagnosticServiceNetworkInterfaceInformationRecord | null;
        latestRabbitmqMetricsCollectionRecord: SyrxControllerDiagnosticServiceRabbitmqMetricsCollectionRecord | null;
    }>>;
}>({
    name: "syrxControllerDiagnosticServiceDetails",
    initialState,
    reducers: {
        setIsUpdating(state, {payload: isUpdating}) {
            state.isUpdating = isUpdating;
        },
        setControllerDiagnostics(state, {payload}) {
            state.lastRetrievalDate = payload.lastRetrievalDate;
            state.latestNetworkInterfaceInformationRecord = payload.latestNetworkInterfaceInformationRecord;
            state.latestRabbitmqMetricsCollectionRecord = payload.latestRabbitmqMetricsCollectionRecord;
        },
    }
});

export const useSyrxControllerDiagnosticServiceDetailsSelector = buildSubStateSelector(state => state.syrxControllerDiagnosticServiceDetails);
export const syrxControllerDiagnosticServiceDetailsActions = slice.actions;
export const syrxControllerDiagnosticServiceDetailsReducer = slice.reducer;