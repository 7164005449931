import { Dispatch } from "redux";
import {
    createBuildingPressureTransmitter,
    getBuildingPressureTransmitter, updateBuildingPressureTransmitter
} from '../../../../services/syrxControllersService';
import {syrxControllerBuildingPressureTransmitterEditorActions} from './syrxControllerBuildingPressureTransmitterEditorSlice';
import {SyrxControllerBuildingPressureTransmitter} from '../../../../models/syrxControllerBuildingPressureTransmitter';

export const syrxControllerBuildingPressureTransmitterEditorActionCreators = {
    load: (syrxControllerId: string, syrxControllerBuildingPressureTransmitterId: string | null) => async (dispatch: Dispatch<any>) => {
        dispatch(syrxControllerBuildingPressureTransmitterEditorActions.startLoading());

        try {

            const syrxControllerBuildingPressureTransmitter = syrxControllerBuildingPressureTransmitterId != null ?
                (await getBuildingPressureTransmitter(syrxControllerId, syrxControllerBuildingPressureTransmitterId)) :
                null;

            dispatch(syrxControllerBuildingPressureTransmitterEditorActions.setSyrxControllerBuildingPressureTransmitter(syrxControllerBuildingPressureTransmitter));
        } finally {
            dispatch(syrxControllerBuildingPressureTransmitterEditorActions.finishLoading());
        }
    },

    submit: (syrxControllerId: string, syrxControllerBuildingPressureTransmitterId: string | null, syrxControllerBuildingPressureTransmitter: Partial<SyrxControllerBuildingPressureTransmitter>) => async (dispatch: Dispatch) => {
        dispatch(syrxControllerBuildingPressureTransmitterEditorActions.startSubmitting());

        try {
            if (syrxControllerBuildingPressureTransmitterId != null) {
                await updateBuildingPressureTransmitter(syrxControllerId, syrxControllerBuildingPressureTransmitterId, syrxControllerBuildingPressureTransmitter);
            } else {
                await createBuildingPressureTransmitter(syrxControllerId, syrxControllerBuildingPressureTransmitter);
            }

            dispatch(syrxControllerBuildingPressureTransmitterEditorActions.finishSubmitting());
        } catch (e) {
            dispatch(syrxControllerBuildingPressureTransmitterEditorActions.cancelSubmitting());
            throw e;
        }
    }
}
