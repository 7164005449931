import {axios} from "./axiosService";

export const getLoggedInSession = async () => {
    return await axios.get(`/Login`);
}

export const login = async (username: string, password: string, token?: string) => {
    const body = {username, password, token};
    return await axios.post(`/Login`, body);
}

export const logout = async () => {
    return await axios.delete(`/Login`);
}

export const setCurrentGroup = async (userId: number, groupId: number) => {
    return await axios.post(`/Users/${userId}/CurrentGroup`, {group_id: groupId});
}

export const createNewUser = async (newUser: {email: string, password: string, firstName: string, lastName: string, address: string, city: string, state: string, zip: string, jobTitle: string, groupName: string}) => {
    return await axios.post(`/Users/Register`, {
        email: newUser.email,
        password: newUser.password,
        first_name: newUser.firstName,
        last_name: newUser.lastName,
        address: newUser.address,
        city: newUser.city,
        state: newUser.state,
        zip: newUser.zip,
        job_title: newUser.jobTitle,
        group_name: newUser.groupName
    });
}