import { Dispatch } from "redux";

import { NewApiServices } from "../newApiServices";
import { equipmentEditorActions } from "../features/equipment/equipmentEditorSlice";
import { Equipment } from "../features/equipment/types";

export const equipmentEditorActionCreators = {
    typeaheadSearch: (searchValue: string) => async (dispatch: Dispatch<any>) => {
        const res = await NewApiServices.componentsService.typeaheadSearch(searchValue);
        dispatch(equipmentEditorActions.setComponentTypeaheadValues(res));
    },
    getDescendents: (componentId: string) => async (dispatch: Dispatch<any>) => {
        const res = await NewApiServices.componentsService.getDescendents(componentId);
        dispatch(equipmentEditorActions.setSelectedComponentDescendents(res));
    },
    submitEquipment: (equipment: Equipment, subComponents: string[]) => async (dispatch: Dispatch<any>) => {
        const body = {
            equipment,
            sub_components: subComponents
        }
        await NewApiServices.equipmentsService.submitEquipment(body);
        dispatch(equipmentEditorActions.setSuccess());
    }
}