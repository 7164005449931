import { Dispatch } from "redux";
import { login } from '../services/authService';
import { loginActions} from '../features/login/loginSlice';
import { loadLoggedInSession } from "./authActionCreators";
import {NewApiServices} from '../newApiServices';


export const submitLoginActionCreator = (username: string, password: string, token: string) => async (dispatch: Dispatch<any>) => {
    dispatch(loginActions.submitLogin({username, password}));

    try {
        await NewApiServices.authService.login(username, password, token);
        await login(username, password);
        await loadLoggedInSession()(dispatch);

        dispatch(loginActions.loginSuccessful());
    } catch (e) {
        if (e?.response?.status === 401) {
            dispatch(loginActions.invalidCredentials(e.response.data));
        } else {
            dispatch(loginActions.unknownError({exception: e}));
        }
    }
};