import { Dispatch } from "redux";
import * as syrxControllersService from "../services/syrxControllersService";
import { SyrxControllerEditorForm } from "../features/syrxControllers/editor/syrxControllerEditorForm";
import {syrxControllerEditorActions} from '../features/syrxControllers/editor/syrxControllerEditorSlice';
import {SyrxController} from '../models/syrxController';
import {SyrxControllerBacnetConnectionConfiguration} from '../models/syrxControllerBacnetConnectionConfiguration';


export const loadSyrxControllerEditor = (syrxControllerId: string) => async (dispatch: Dispatch<any>) => {
    dispatch(syrxControllerEditorActions.startLoading());

    if (syrxControllerId != null) {
        const syrxController = await syrxControllersService.getSyrxControllerById(syrxControllerId);
        const bacnetConnectionConfiguration = await syrxControllersService.getSyrxControllerBacnetConnectionConfiguration(syrxControllerId);
        const bacnetConnectionBbmdServers = await syrxControllersService.getSyrxControllerBacnetConnectionBbmdServers(syrxControllerId);

        dispatch(syrxControllerEditorActions.setInitialFormValues({
            formInitialValues: {
                provideBacnetConfiguration: bacnetConnectionConfiguration != null ? "yes" : "no",
                syrxController: syrxController,
                bacnetConnectionConfiguration: bacnetConnectionConfiguration ?? {ip_address: "", port_num: 47808},
                bacnetConnectionBbmdServers: bacnetConnectionBbmdServers
            }
        }));
    } else {
        dispatch(syrxControllerEditorActions.setInitialFormValues({
            formInitialValues: {
                provideBacnetConfiguration: "yes",
                syrxController: {} as SyrxController,
                bacnetConnectionConfiguration: {ip_address: "", port_num: 47808} as SyrxControllerBacnetConnectionConfiguration,
                bacnetConnectionBbmdServers: []
            }
        }));
    }

    dispatch(syrxControllerEditorActions.finishLoading());
};

export const submitSyrxControllerEditor = (values: SyrxControllerEditorForm) => async (dispatch: Dispatch<any>) => {
    dispatch(syrxControllerEditorActions.startSubmitting());

    const savedSyrxController = await syrxControllersService.saveSyrxController(values.syrxController);
    const syrxControllerId = savedSyrxController.id as string;

    values = {
        ...values,
        bacnetConnectionConfiguration: {
            ...values.bacnetConnectionConfiguration,
            syrx_controller_id: syrxControllerId
        },
        bacnetConnectionBbmdServers: values.bacnetConnectionBbmdServers.map(x => ({
            ...x,
            syrx_controller_id: syrxControllerId
        }))
    };
    
    if (values.provideBacnetConfiguration === "yes") {
        await syrxControllersService.saveSyrxControllerBacnetConnectionConfiguration(syrxControllerId, values.bacnetConnectionConfiguration);
        await syrxControllersService.saveSyrxControllerBacnetConnectionBbmdServers(syrxControllerId, values.bacnetConnectionBbmdServers);
    } else {
        await syrxControllersService.removeSyrxControllerBacnetConnectionConfiguration(syrxControllerId);
        await syrxControllersService.saveSyrxControllerBacnetConnectionBbmdServers(syrxControllerId, []);
    }

    dispatch(syrxControllerEditorActions.finishSubmitting());
};