import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux';
import {
    syrxControllerServicesManagerListActions,
    useSyrxControllerServicesManagerListSelector
} from './syrxControllerServicesManagerListSlice';
import {syrxControllerServicesManagerListActionCreators} from './syrxControllerServicesManagerListActionCreators';
import {LoadingSpinner} from '../../loadingSpinner/LoadingSpinner';
import dayjs from 'dayjs';
import {SyrxControllerServiceVersion} from '../../../models/syrxControllerServiceVersion';
import {LinkContainer} from 'react-router-bootstrap';
import {Button} from 'react-bootstrap';
import {SyrxControllerServiceVersionDeleteModal} from './syrxControllerServiceVersionDeleteModal';
import {SyrxControllerServiceVersionLockedModal} from './syrxControllerServiceVersionLockedModal';

const SyrxControllerServicesManagerListVersionItem: React.FunctionComponent<{syrxControllerServiceVersion: SyrxControllerServiceVersion, onUpdate: () => void}> = ({syrxControllerServiceVersion, onUpdate}) => {
    const serviceId = syrxControllerServiceVersion.service_id;
    const versionName = syrxControllerServiceVersion.version_name;
    const gitRevision = syrxControllerServiceVersion.syrx_controller_service_artifact?.git_revision;
    const createdDate = syrxControllerServiceVersion.syrx_controller_service_artifact?.created_date != null ? dayjs.utc(syrxControllerServiceVersion.syrx_controller_service_artifact.created_date).format() : null;

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showLockedModal, setShowLockedModal] = useState(false);

    const onDeleteModalClosed = useCallback((deleteSuccessful: boolean) => {
        if (deleteSuccessful) {
            onUpdate();
        }
        setShowDeleteModal(false);
    }, [setShowDeleteModal, onUpdate]);

    const launchDeleteModal = useCallback(() => {
        setShowDeleteModal(true);
    }, [setShowDeleteModal]);

    const onLockedModalClosed = useCallback(() => {
        setShowLockedModal(false);
    }, [setShowLockedModal]);

    const launchLockedModal = useCallback(() => {
        setShowLockedModal(true);
    }, [setShowLockedModal]);

    return (
        <>
            {showDeleteModal ? <SyrxControllerServiceVersionDeleteModal serviceId={serviceId} versionName={versionName} onClose={onDeleteModalClosed} /> : null}
            {showLockedModal ? <SyrxControllerServiceVersionLockedModal serviceId={serviceId} versionName={versionName} onClose={onLockedModalClosed} /> : null}
            {versionName} - {gitRevision} ({createdDate})
            <span style={{marginLeft: "10px"}}>
                {!syrxControllerServiceVersion.is_locked ? (
                    <LinkContainer to={`/admin/syrx-controller-services/${serviceId}/versions/${versionName}`}>
                        <Button variant="outline-primary">
                            <i className="far fa-edit" />
                        </Button>
                    </LinkContainer>
                ) : null}
                {!syrxControllerServiceVersion.is_locked ? (
                    <Button variant="outline-primary" onClick={launchDeleteModal}>
                        <i className="far fa-trash-alt" />
                    </Button>
                ) : null}
                {syrxControllerServiceVersion.is_locked ? (
                    <Button variant="outline-secondary" onClick={launchLockedModal}>
                        <i className="fas fa-lock" />
                    </Button>
                ) : null}
            </span>
        </>
    );
}

export const SyrxControllerServicesManagerList: React.FunctionComponent = () => {
    const dispatch = useDispatch();

    const isLoading = useSyrxControllerServicesManagerListSelector(x => x.isLoading);
    const isDoneLoading = useSyrxControllerServicesManagerListSelector(x => x.isDoneLoading);
    const syrxControllerServiceTypes = useSyrxControllerServicesManagerListSelector(x => x.syrxControllerServiceTypes);

    const [updateTrigger, setUpdateTrigger] = useState({});

    useEffect(() => {
        dispatch(syrxControllerServicesManagerListActionCreators.loadSyrxControllerServiceTypes());

        return () => {
            dispatch(syrxControllerServicesManagerListActions.resetState());
        }
    }, [dispatch, updateTrigger]);


    return (
        <>
            {isLoading ? <LoadingSpinner /> : null}
            <h3>Syrx controller services manager</h3>
            {isDoneLoading ? (
                <ul>
                    {(syrxControllerServiceTypes ?? []).map(syrxControllerServiceType => (
                        <li key={syrxControllerServiceType.id}>
                            {syrxControllerServiceType.id}
                            <LinkContainer style={{marginLeft: "10px"}} to={`/admin/syrx-controller-service-versions/new?serviceId=${syrxControllerServiceType.id}`}>
                                <Button variant="outline-primary">
                                    <i className="far fa-plus-square" />
                                </Button>
                            </LinkContainer>
                            <ul>
                                {(syrxControllerServiceType.syrx_controller_service_versions ?? []).map(syrxControllerServiceVersion => (
                                    <li key={syrxControllerServiceVersion.version_name}>
                                        <SyrxControllerServicesManagerListVersionItem
                                            syrxControllerServiceVersion={syrxControllerServiceVersion}
                                            onUpdate={() => setUpdateTrigger({})}
                                        />
                                    </li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ul>
            ) : null}
        </>
    )
}