import {CaseReducer, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {buildSubStateSelector} from '../../../store/utilities/useAppStateSelector';

const initialState = {
    minLogLevel: 'INFO'
};

export type AppStateSyrxControllerServiceLogsGrid = typeof initialState;

export const slice = createSlice<AppStateSyrxControllerServiceLogsGrid, {
    setMinLogLevel: CaseReducer<AppStateSyrxControllerServiceLogsGrid, PayloadAction<string>>;
}>({
    name: "syrxControllerServiceLogsGrid",
    initialState,
    reducers: {
        setMinLogLevel(state, {payload: minLogLevel}) {
            state.minLogLevel = minLogLevel;
        }
    }
});

export const useSyrxControllerServiceLogsGridSelector = buildSubStateSelector(state => state.syrxControllerServiceLogsGrid);
export const syrxControllerServiceLogsGridActions = slice.actions;
export const syrxControllerServiceLogsGridReducer = slice.reducer;