import React, {useEffect, useState} from 'react';
import {getSyrxControllerCriticalSystemdServiceStatuses} from '../../../services/syrxControllersService';

export const SyrxControllerSystemdServiceStatusIndicator: React.FunctionComponent<{syrxControllerId: string, serviceId: string}> = ({syrxControllerId, serviceId}) => {
    const [serviceStatus, setServiceStatus] = useState(null as string | null);

    useEffect(() => {
        const systemdServiceName = `${serviceId}.service`;
        async function load() {
            const serviceStatuses = await getSyrxControllerCriticalSystemdServiceStatuses(syrxControllerId);
            if (serviceStatuses == null || !serviceStatuses.hasOwnProperty(systemdServiceName)) {
                setServiceStatus("inactive");
            } else {
                setServiceStatus(serviceStatuses[systemdServiceName]);
            }
        }

        void load();
    }, [syrxControllerId, serviceId]);

    let style;

    if (serviceStatus == null) {
        style = {color: "#999", opacity: 0.25};
    } else if (serviceStatus === "active") {
        style = {color: "#0C0", opacity: 1};
    } else if (serviceStatus === "inactive" || serviceStatus === "failed") {
        style = {color: "#C00", opacity: 1};
    } else {
        style = {color: "#CC0", opacity: 1};
    }

    return (
        <span style={style}>⬤</span>
    )
}