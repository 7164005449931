import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'

import { getSyrxControllerById } from '../../../services/syrxControllersService';
import { SyrxController } from '../../../models/syrxController';
import io from "socket.io-client";
import {syrxSocketioEndpoint} from '../../../config';
import {Button, Col, Row} from 'react-bootstrap';
import dayjs from 'dayjs';
import Select from 'react-select';

export interface SyrxControllerLogViewerProps {
    syrxControllerId: string;
}


export const SyrxControllerLogViewer: React.FunctionComponent<SyrxControllerLogViewerProps> = props => {
    const { syrxControllerId } = props;
    const [syrxController, setSyrxController] = useState(null as SyrxController | null);
    const socketRef = useRef(null as SocketIOClient.Socket | null);
    const [logEntriesSortOrder, setLogEntriesSortOrder] = useState("asc" as "asc" | "desc")

    // incoming data from the socket connection gets pushed into incomingLogEntries

    // there is a watch on incomingLogEntries which takes that data and puts it into logEntries
    // before clearing incomingLogEntries

    // there is a watch on logEntries which filters and truncates logEntries and puts them in filteredLogEntries

    // there is a watch on filteredLogEntries which (if unpaused) puts filteredLogEntries into displayLogEntries

    // there is a watch on displayLogEntries which applies ordering


    const [incomingLogEntries, setIncomingLogEntries] = useState([] as any[]);
    const [logEntries, setLogEntries] = useState([] as any[]);
    const [filteredLogEntries, setFilteredLogEntries] = useState([] as any[]);
    const [displayLogEntries, setDisplayLogEntries] = useState([] as any[]);
    const [orderedDisplayLogEntries, setOrderedDisplayLogEntries] = useState([] as any[]);

    const [isPaused, setIsPaused] = useState(false);
    const [numLogsRetention, setNumLogsRetention] = useState(2000);
    const [lastLogsDate, setLastLogsDate] = useState(null as Date | null);

    const [availableServices, setAvailableServices] = useState([] as string[]);
    const [selectedServices, setSelectedServices] = useState([] as string[]);
    const availableServiceOptions = useMemo(() => availableServices.map(x => ({value: x, label: x})), [availableServices]);
    const selectedServiceOptions = selectedServices.map(x => availableServiceOptions.find(xx => xx.value === x));

    const [selectedLogLevels, setSelectedLogLevels] = useState(["DEBUG", "INFO", "WARNING", "ERR", "CRIT"]);
    const availableLogLevelOptions = ["DEBUG", "INFO", "WARNING", "ERR", "CRIT"].map(x => ({value: x, label: x}));
    const selectedLogLevelOptions = selectedLogLevels.map(x => availableLogLevelOptions.find(xx => xx.value === x));

    const setForceRender = useState({})[1];
    const forceRender = useCallback(() => {
        setForceRender({});
    }, [setForceRender]);

    const filterLogMessage = useCallback(logMessage => {
        if (selectedServices.length > 0 && !selectedServices.includes(logMessage.systemdUnit)) {
            return false;
        }

        if (!selectedLogLevels.includes(logMessage.logLevel)) {
            return false;
        }

        return true;
    }, [selectedLogLevels, selectedServices]);

    useEffect(() => {
        async function fetchData() {
            setSyrxController(null);

            const newSyrxController = await getSyrxControllerById(syrxControllerId);
            setSyrxController(newSyrxController);
        }

        fetchData();
    }, [syrxControllerId]);

    useEffect(() => {
        socketRef.current = io(`${syrxSocketioEndpoint}/Pathian.Services.LogTransportService`);
        socketRef.current
            .on("connect", () => {
                socketRef.current!.emit("client/server/connect", {syrxControllerId});
            })
            .on("server/client/data", ({logEntries: newIncomingLogEntries}: {logEntries: any[]}) => {
                setIncomingLogEntries(oldIncomingLogEntries => [...oldIncomingLogEntries, ...newIncomingLogEntries]);
                setLastLogsDate(new Date());
            });
        forceRender();
        return () => {
            socketRef.current?.close();
            socketRef.current = null;
            forceRender();
        }
    }, [forceRender, syrxControllerId]);

    useEffect(() => {
        if (incomingLogEntries.length > 0) {
            setLogEntries(oldLogEntries => [...oldLogEntries, ...incomingLogEntries].slice(numLogsRetention * -1));
            setAvailableServices(oldAvailableServices => [
                ...new Set([
                    ...oldAvailableServices,
                    ...incomingLogEntries.map(x => x.systemdUnit?.trim() ?? null).filter(x => x != null && x !== "")
                ])
            ]);
            setIncomingLogEntries([]);
        }

    }, [incomingLogEntries, filterLogMessage, numLogsRetention]);

    useEffect(() => {
        setFilteredLogEntries(logEntries.filter(filterLogMessage).slice(numLogsRetention * -1));
    }, [logEntries, numLogsRetention, filterLogMessage]);

    useEffect(() => {
        if (!isPaused) {
            setDisplayLogEntries(filteredLogEntries);
        }
    }, [isPaused, filteredLogEntries]);

    useEffect(() => {
        const newOrderedDisplayLogEntries = logEntriesSortOrder === "desc" ? [...displayLogEntries].reverse() : displayLogEntries;
        setOrderedDisplayLogEntries(newOrderedDisplayLogEntries);
    }, [logEntriesSortOrder, displayLogEntries]);

    const clearLogEntries = useCallback(() => {
        setLogEntries([]);
        setDisplayLogEntries([]);
        setIsPaused(false);
    }, []);

    return syrxController != null ? (
        <>
            <h3>{syrxController.name}</h3>
            <Row>
                <Col lg={9} xl={6}>
                    <table className="table">
                        <tbody>
                        <tr>
                            <th>View mode</th>
                            <td>
                                <select className="form-control" value={logEntriesSortOrder} onChange={e => setLogEntriesSortOrder(e.target.value as "asc" | "desc")}>
                                    <option value="asc">Oldest to newest</option>
                                    <option value="desc">Newest to oldest</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>Controls</th>
                            <td>
                                <Button variant="outline-danger" onClick={clearLogEntries}><i className="fas fa-dumpster-fire"/></Button>
                                <Button variant="outline-info" onClick={() => setIsPaused(true)} disabled={isPaused}><i className="fas fa-pause"/></Button>
                                <Button variant="outline-success" onClick={() => setIsPaused(false)} disabled={!isPaused}><i className="fas fa-play"/></Button>
                            </td>
                        </tr>
                        <tr>
                            <th>Services</th>
                            <td>
                                <Select
                                    value={selectedServiceOptions}
                                    onChange={selectedOptions => setSelectedServices((selectedOptions ?? []).map((selectedOption: any) => selectedOption.value))}
                                    options={availableServiceOptions}
                                    isMulti={true}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>Selected log levels</th>
                            <td>
                                <Select
                                    value={selectedLogLevelOptions}
                                    onChange={selectedOptions => setSelectedLogLevels((selectedOptions ?? []).map((selectedOption: any) => selectedOption.value))}
                                    options={availableLogLevelOptions}
                                    isMulti={true}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>Retention</th>
                            <td>
                                <select className="form-control" value={numLogsRetention} onChange={e => setNumLogsRetention(parseInt(e.target.value))}>
                                    <option>100</option>
                                    <option>500</option>
                                    <option>1000</option>
                                    <option>2000</option>
                                </select>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </Col>
            </Row>
            <div>Last received logs: {lastLogsDate != null ? dayjs.utc(lastLogsDate).format() : "never"}</div>
            <table className="table" style={{fontFamily: "monospace", fontSize: "12px"}}>
                <colgroup>
                    <col/>
                </colgroup>
                <thead>
                    <tr>
                        <th>Timestamp</th>
                        <th>Service</th>
                        <th>Level</th>
                        <th>Message</th>
                    </tr>
                </thead>
                <tbody>
                    {orderedDisplayLogEntries.map(logEntry => (
                        <tr key={logEntry.journalCursor}>
                            <td style={{whiteSpace: "nowrap"}}>{logEntry.timestamp}</td>
                            <td>{logEntry.systemdUnit}</td>
                            <td>{logEntry.logLevel}</td>
                            <td>{logEntry.message}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    ) : null
}