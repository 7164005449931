import React, {FunctionComponent, useCallback, useState} from 'react'

import FancyGrid, { SortCollection } from '@sht-dev/fancy-grid';

import {
    getBuildingPressureTransmittersForSyrxController
} from '../../../../services/syrxControllersService';
import {Button} from 'react-bootstrap';
import {DeleteSyrxControllerBuildingPressureTransmitterModal} from './deleteSyrxControllerBuildingPressureTransmitterModal';
import dayjs from 'dayjs';

export interface SyrxControllerBuildingPressureTransmittersGridProps {
    syrxControllerId: string;
}

export const SyrxControllerBuildingPressureTransmittersGrid: FunctionComponent<SyrxControllerBuildingPressureTransmittersGridProps> = ({syrxControllerId}) => {
    const [deleteModalBuildingPressureTransmitterId, setDeleteModalBuildingPressureTransmitterId] = useState(null as string | null);
    const [forcedUpdateTrigger, setForcedUpdateTrigger] = useState({});
    const [showLastValueColumns, setShowLastValueColumns] = useState(false);
    const dataRetrievalFunction = (pageNumber: number, pageSize: number, sort: SortCollection) => getBuildingPressureTransmittersForSyrxController(syrxControllerId, pageNumber, pageSize, sort);

    const onDeleteModalClosed = useCallback((deleteSuccessful: boolean) => {
        setDeleteModalBuildingPressureTransmitterId(null);

        if (deleteSuccessful) {
            setForcedUpdateTrigger({});
        }
    }, [])

    return (
        <>
            {deleteModalBuildingPressureTransmitterId != null ? (
                <DeleteSyrxControllerBuildingPressureTransmitterModal
                    syrxControllerId={syrxControllerId}
                    syrxControllerBuildingPressureTransmitterId={deleteModalBuildingPressureTransmitterId}
                    onClose={onDeleteModalClosed}
                />
            ) : null}
            <div>
                <span>Show last value columns:</span>
                <div style={{display: "inline-block"}}>
                    <select className="form-control" value={showLastValueColumns ? "Yes" : "No"} onChange={e => setShowLastValueColumns(e.target.value === "Yes")}>
                        <option>Yes</option>
                        <option>No</option>
                    </select>
                </div>
            </div>
            <FancyGrid.ReduxGrid
                gridName={"SyrxControllerBuildingPressureTransmittersGrid"}
                dataRetrievalFunction={dataRetrievalFunction}
                updateTriggers={[
                    syrxControllerId,
                    forcedUpdateTrigger
                ]}
            >
                <FancyGrid.ColumnList>
                    <FancyGrid.Column
                        name="num"
                        title="Num"
                    >
                        <FancyGrid.CellRenderer>
                            {(num: string) => <>BPT-{num}</>}
                        </FancyGrid.CellRenderer>
                    </FancyGrid.Column>
                    <FancyGrid.Column
                        name="name"
                        title="Name"
                    />
                    <FancyGrid.Column
                        name="transmitter_height"
                        title="Transmitter height (ft)"
                    />
                    <FancyGrid.Column
                        name="syrx_controller_bacnet_device_mac_address"
                        title="MAC address"
                    />
                    <FancyGrid.Column
                        name="syrx_controller_bacnet_point_object_id"
                        title="Object ID"
                    />
                    <FancyGrid.Column
                        name="syrx_controller_bacnet_device_name"
                        title="Device name"
                    />
                    <FancyGrid.Column
                        name="syrx_controller_bacnet_point_name"
                        title="Point name"
                    />

                    {showLastValueColumns ? (
                        <FancyGrid.Column
                            name="last_value_date"
                            title="Last value date"
                        >
                            <FancyGrid.CellRenderer>
                                {lastValueDate => (
                                    <>
                                        {lastValueDate != null ? dayjs(lastValueDate).format("MM/DD/YYYY hh:mm:ss a") : "n/a"}
                                    </>
                                )}

                            </FancyGrid.CellRenderer>
                        </FancyGrid.Column>
                    ) : null}

                    {showLastValueColumns ? (
                        <FancyGrid.Column
                            name="last_pressure"
                            title="Last pressure value"
                        />
                    ) : null}

                    {showLastValueColumns ? (
                        <FancyGrid.Column
                            name="last_normalized_pressure"
                            title="Last normalized pressure"
                        />
                    ) : null}

                    <FancyGrid.Column>
                        <FancyGrid.CellRenderer>
                            {(_, buildingPressureTransmitter) => (
                                <>
                                    <Button variant="outline-info" onClick={() => setDeleteModalBuildingPressureTransmitterId(buildingPressureTransmitter.id)}>Delete</Button>
                                </>
                            )}
                        </FancyGrid.CellRenderer>
                    </FancyGrid.Column>
                </FancyGrid.ColumnList>
                <FancyGrid.ReduxSortable />
                <FancyGrid.ReduxPager />
            </FancyGrid.ReduxGrid>
        </>
    )
};