import React, {useEffect, useState} from 'react'

import {LoadingSpinner} from '../../loadingSpinner/LoadingSpinner';
import {SyrxControllerBacnetDevice} from '../../../models/syrxControllerBacnetDevice';
import {getSyrxControllerBacnetDevice} from '../../../services/syrxControllersService';
import {SyrxControllerBacnetDeviceIdentification} from '../../../models/syrxControllerBacnetDeviceIdentification';

export interface SyrxControllerBacnetDeviceDetailsProps {
    syrxControllerId: string;
    syrxControllerBacnetDeviceId: string
}

const showDestinationAddress = (syrxControllerBacnetDevice: SyrxControllerBacnetDeviceIdentification) => {
    return syrxControllerBacnetDevice.destination_mac_address !== "1.0.0.0:0" || syrxControllerBacnetDevice.destination_network !== 0;
}

export const SyrxControllerBacnetDeviceDetails: React.FunctionComponent<SyrxControllerBacnetDeviceDetailsProps> = props => {
    const {syrxControllerId, syrxControllerBacnetDeviceId} = props;
    const [syrxControllerBacnetDevice, setSyrxControllerBacnetDevice] = useState(undefined as SyrxControllerBacnetDevice | undefined);

    useEffect(() => {
        const load = async () => {
            const newSyrxControllerBacnetDevice = await getSyrxControllerBacnetDevice(syrxControllerId, syrxControllerBacnetDeviceId);
            setSyrxControllerBacnetDevice(newSyrxControllerBacnetDevice);
        }

        load();
    }, [syrxControllerId, syrxControllerBacnetDeviceId]);

    return syrxControllerBacnetDevice != null ? (
        <>
            <h4>Device</h4>
            <table className="table">
                <tbody>
                    <tr>
                        <th>Id</th>
                        <td>{syrxControllerBacnetDevice.id}</td>
                    </tr>
                    <tr>
                        <th>MAC address</th>
                        <td>{syrxControllerBacnetDevice.mac_address} [{syrxControllerBacnetDevice.network}]</td>
                    </tr>
                    <tr>
                        <th>Destination address</th>
                        <td>{showDestinationAddress(syrxControllerBacnetDevice) ? `${syrxControllerBacnetDevice.mac_address} [${syrxControllerBacnetDevice.network}]` : "n/a"}</td>
                    </tr>
                    <tr>
                        <th>Instance (BACnet object id)</th>
                        <td>{syrxControllerBacnetDevice.instance_number}</td>
                    </tr>
                    <tr>
                        <th>Device name</th>
                        <td>{syrxControllerBacnetDevice.name}</td>
                    </tr>
                    <tr>
                        <th>Description</th>
                        <td>{syrxControllerBacnetDevice.description}</td>
                    </tr>
                    <tr>
                        <th>Vendor name</th>
                        <td>{syrxControllerBacnetDevice.vendor_name}</td>
                    </tr>
                    <tr>
                        <th>First available</th>
                        <td>{syrxControllerBacnetDevice.first_available_on}</td>
                    </tr>
                    <tr>
                        <th>Last available</th>
                        <td>{syrxControllerBacnetDevice.last_available_on}</td>
                    </tr>
                    <tr>
                        <th>Last unavailable</th>
                        <td>{syrxControllerBacnetDevice.last_unavailable_on}</td>
                    </tr>
                    <tr>
                        <th>Last discovery attempt</th>
                        <td>{syrxControllerBacnetDevice.last_point_discovery}</td>
                    </tr>
                    <tr>
                        <th>Status</th>
                        <td>{syrxControllerBacnetDevice.status}</td>
                    </tr>
                </tbody>
            </table>
        </>
    ) : <LoadingSpinner />;
}