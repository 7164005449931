import React, {useCallback, useEffect} from 'react'
import {Button, Col, Form, FormGroup, Row, Tab, Tabs} from 'react-bootstrap';
import {Field, Form as ReactFinalForm} from 'react-final-form';
import {BacnetPropertyTypeSelectField} from '../../bacnet/bacnetPropertyTypeSelectField';
import {BacnetObjectTypeSelectField} from '../../bacnet/bacnetObjectTypeSelectField';
import {
    ReadPropertyDescription,
    useSyrxControllerBacnetNetworkSocketHelper
} from '../../../hooks/useSyrxControllerBacnetNetworkSocketHelper';
import {useStringAppenderState} from '../../../hooks/useStringAppenderState';

export interface SyrxControllerBacnetNetworkQueryServiceDetailsProps {
    syrxControllerId: string;
}

interface SyrxControllerBacnetNetworkQueryConsoleReadPropertyFormValues {
    macAddress: string;
    network: string;
    destinationMacAddress: string;
    destinationNetwork: string;
    objectType: string;
    instanceNumber: string;
    propertyId: string;
    propertyIndex: string;
}

const SyrxControllerBacnetNetworkQueryConsoleReadPropertyForm: React.FunctionComponent<{submit: (formValues: ReadPropertyDescription) => void}> = props => {
    const {submit: propsSubmit} = props;

    const submit = useCallback((formValues: SyrxControllerBacnetNetworkQueryConsoleReadPropertyFormValues) => {
        const message: ReadPropertyDescription = {
            macAddress: formValues.macAddress,
            network: parseInt(formValues.network),
            destinationMacAddress: formValues.destinationMacAddress.length > 0 ? formValues.destinationMacAddress : null,
            destinationNetwork: formValues.destinationNetwork.length > 0 ? parseInt(formValues.destinationNetwork) : null,
            objectType: parseInt(formValues.objectType),
            instanceNumber: parseInt(formValues.instanceNumber),
            propertyId: parseInt(formValues.propertyId),
            propertyIndex: formValues.propertyIndex?.length > 0 ? parseInt(formValues.propertyIndex) : null
        }
        propsSubmit(message);
    }, [propsSubmit]);

    return (
        <ReactFinalForm onSubmit={submit} initialValues={{propertyId: 77, objectType: 8} as unknown as SyrxControllerBacnetNetworkQueryConsoleReadPropertyFormValues}>
            {({handleSubmit}) => (
                <Form onSubmit={handleSubmit}>
                    <fieldset>
                        <FormGroup as={Row}>
                            <Form.Label column md={6} lg={4}>MAC address</Form.Label>
                            <Col md={6}>
                                <Field
                                    name="macAddress"
                                    component="input"
                                    className="form-control"
                                    required
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup as={Row}>
                            <Form.Label column md={6} lg={4}>Network</Form.Label>
                            <Col md={6}>
                                <Field
                                    name="network"
                                    component="input"
                                    type="number"
                                    className="form-control"
                                    required
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup as={Row}>
                            <Form.Label column md={6} lg={4}>Destination MAC address</Form.Label>
                            <Col md={6}>
                                <Field
                                    name="destinationMacAddress"
                                    component="input"
                                    className="form-control"
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup as={Row}>
                            <Form.Label column md={6} lg={4}>Destination network</Form.Label>
                            <Col md={6}>
                                <Field
                                    name="destinationNetwork"
                                    component="input"
                                    type="number"
                                    className="form-control"
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup as={Row}>
                            <Form.Label column md={6} lg={4}>Object type</Form.Label>
                            <Col md={6}>
                                <BacnetObjectTypeSelectField
                                    name="objectType"
                                    className="form-control"
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup as={Row}>
                            <Form.Label column md={6} lg={4}>Instance number</Form.Label>
                            <Col md={6}>
                                <Field
                                    name="instanceNumber"
                                    component="input"
                                    type="number"
                                    className="form-control"
                                    required
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup as={Row}>
                            <Form.Label column md={6} lg={4}>Property</Form.Label>
                            <Col md={6}>
                                <BacnetPropertyTypeSelectField
                                    name="propertyId"
                                    className="form-control"
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup as={Row}>
                            <Form.Label column md={6} lg={4}>Property index</Form.Label>
                            <Col md={6}>
                                <Field
                                    name="propertyIndex"
                                    component="input"
                                    type="number"
                                    className="form-control"
                                />
                            </Col>
                        </FormGroup>
                    </fieldset>
                    <Button variant="primary" type="submit">Submit</Button>
                </Form>
            )}
        </ReactFinalForm>
    )
}

export const SyrxControllerBacnetNetworkQueryConsole: React.FunctionComponent<SyrxControllerBacnetNetworkQueryServiceDetailsProps> = ({syrxControllerId}) => {
    const socketHelper = useSyrxControllerBacnetNetworkSocketHelper(syrxControllerId);
    const [response, appendToResponse] = useStringAppenderState("Connecting...\n")

    useEffect(() => {
        if (socketHelper == null) {
            return;
        }

        socketHelper
            .on("connect", () => {
                appendToResponse(`Connection established\n`);
            })
            .on("disconnect", () => {
                appendToResponse(`Client connected: false\n`);
            })
            .on("agentStatus", ({connected}: {connected: boolean}) => {
                appendToResponse(`Agent connected: ${connected}\n`);
            });

        socketHelper.initialize();

    }, [socketHelper, appendToResponse]);

    const discoverDevices = useCallback(() => {
        const asyncHandler = async () => {
            if (socketHelper == null) {
                return;
            }

            appendToResponse(`Sending discover device request\n`);
            appendToResponse(`Waiting for request to be acknowledged...\n`);

            try {
                const devices = await socketHelper.discoverDevices(
                    () => {
                        appendToResponse(`Request acknowledged\n`);
                        appendToResponse(`Waiting for response...\n`);
                    }
                );

                appendToResponse(`${JSON.stringify(devices, null, 4)}\n`);
            } catch (e) {
                appendToResponse(`An error occurred\n${JSON.stringify(e, null, 4)}\n`);
            }
        };

        void asyncHandler();
    }, [socketHelper, appendToResponse]);

    const readProperty = useCallback((propertyDescription: ReadPropertyDescription) => {
        const asyncHandler = async (propertyDescription: ReadPropertyDescription) => {
            if (socketHelper == null) {
                return;
            }

            appendToResponse(`Sending read BACnet property value request\n`);
            appendToResponse(`Waiting for request to be acknowledged...\n`);

            try {
                const data = await socketHelper.readProperty(propertyDescription,
                    () => {
                        appendToResponse(`Request acknowledged\n`);
                        appendToResponse(`Waiting for response...\n`);
                    }
                );

                appendToResponse(`${JSON.stringify(data, null, 4)}\n`);
            } catch (e) {
                appendToResponse(`An error occurred\n${JSON.stringify(e, null, 4)}\n`);
            }
        }

        void asyncHandler(propertyDescription);
    }, [socketHelper, appendToResponse]);

    return (
        <>
            <pre style={{width: "100%", height: "600px", overflow: "auto"}}>{response}</pre>
            <Tabs defaultActiveKey="readProperty" id="abc">
                <Tab eventKey="readProperty" title="Read property">
                    <SyrxControllerBacnetNetworkQueryConsoleReadPropertyForm
                        submit={readProperty}
                    />
                </Tab>
                <Tab eventKey="discoverDevices" title="Discover devices">
                    <Button variant="primary" onClick={discoverDevices}>Discover devices</Button>
                </Tab>
            </Tabs>
        </>
    )
};