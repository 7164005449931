import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { buildSubStateSelector } from '../../store/utilities/useAppStateSelector';
import { Account } from './types';

const initialState = {
    buildingMeterAccounts: [] as Account[],
    selectedBuildingMeterAccount: {} as Account,
    showModal: false
};

export type AppStateBuildingMeterAccoutsGrid = typeof initialState;

export const slice = createSlice<AppStateBuildingMeterAccoutsGrid, {
    setBuildingMeterAccounts: CaseReducer<AppStateBuildingMeterAccoutsGrid, PayloadAction<Account[]>>;
    showModal: CaseReducer<AppStateBuildingMeterAccoutsGrid, PayloadAction<Account>>;
    hideModal: CaseReducer<AppStateBuildingMeterAccoutsGrid>;
}>({
    name: "buildingMeterAccountsGrid",
    initialState,
    reducers: {
        setBuildingMeterAccounts(state, action) {
            state.buildingMeterAccounts = action.payload;
        },
        showModal(state, action) {
            state.selectedBuildingMeterAccount = action.payload;
            state.showModal = true;
        },
        hideModal(state) {
            state.selectedBuildingMeterAccount = initialState.selectedBuildingMeterAccount;
            state.showModal = false;
        }
    }
});

export const useBuildingMeterAccountsGridSelector = buildSubStateSelector(state => state.buildingMeterAccountsGrid);
export const buildingMeterAccountsGridActions = slice.actions;
export const buildingMeterAccountsGridReducer = slice.reducer;