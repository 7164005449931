import React from 'react'
import { Form, Field } from 'react-final-form';
import { Alert, Button, FormGroup, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useLocation, useParams } from 'react-router';
import { submitLoginActionCreator } from '../../actionCreators/loginActionCreators';
import { LoadingSpinner } from '../loadingSpinner/LoadingSpinner';
import {useLoginSelector} from './loginSlice';


interface LoginFormValues {
    username: string;
    password: string;
}

export const Login: React.FunctionComponent = () => {
    const loginState = useLoginSelector(x => x);
    const dispatch = useDispatch();

    const location = useLocation();

    const params = useParams<{token: string | undefined}>();
    const token = params.token ?? '';

    const onSubmit = async (values: LoginFormValues) => {
        dispatch(submitLoginActionCreator(values.username, values.password, token));
    }

    return (
        <Form
            onSubmit={onSubmit}
        >
            {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    {loginState.isAttemptingLogin ? <LoadingSpinner /> : null}
                    {loginState.isInvalidCredentials ? (
                        <FormGroup>
                            <Row>
                                <Col md={12}>
                                    <Alert variant="danger">{loginState.errorMessage}</Alert>
                                </Col>
                            </Row>
                        </FormGroup>
                    ) : null}
                    {loginState.isUnknownError ? (
                        <FormGroup>
                            <Row>
                                <Col md={12}>
                                    <Alert variant="danger">An unknown error has occurred; please contact the system administrator.</Alert>
                                </Col>
                                </Row>
                        </FormGroup>
                    ) : null}
                    <FormGroup>
                        <Row>
                            <Col md={4}>
                                <h2>Please sign in</h2>
                                { token &&
                                    <p>Signing in will complete validation on your email address</p>
                                }
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col md={4}>
                                <Field
                                    name="username"
                                    component="input"
                                    type="text"
                                    placeholder="Username"
                                    className="form-control"
                                    required
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col md={4}>
                                <Field
                                    name="password"
                                    component="input"
                                    type="password"
                                    placeholder="Password"
                                    className="form-control"
                                    required
                                />
                            </Col>
                        </Row>
                    </FormGroup>

                    <FormGroup>
                        <Row>
                            <Col md={4}>
                                <Button variant="primary" type="submit">
                                    Login
                                </Button>
                            </Col>
                        </Row>
                    </FormGroup>

                    <FormGroup>
                        <Col md={4}>
                            <p><Link to={"/user-registration" + location.search}>Click Here</Link> to create an account.</p>
                        </Col>
                    </FormGroup>
                </form>
            )}
        </Form>
    );
}