import React, {FunctionComponent, useCallback, useState} from 'react'
import FancyGrid, {FilterCollection, SortCollection} from '@sht-dev/fancy-grid';
import {NewApiServices} from '../../../../newApiServices';
import {LinkContainer} from 'react-router-bootstrap';
import {Button} from 'react-bootstrap';
import {RoleDeleteModal} from './roleDeleteModal';
import {useUpdateTrigger} from '../../../../hooks/useUpdateTrigger';


export const RolesListGrid: FunctionComponent = () => {
    const dataRetrievalFunction = (pageNumber: number, pageSize: number, sort: SortCollection, filter: FilterCollection) => NewApiServices.rolesService.getRolesForGrid(pageNumber, pageSize, sort, filter);

    const [roleIdToDelete, setRoleIdToDelete] = useState(null as number | null);
    const [updateTrigger, triggerUpdate] = useUpdateTrigger();

    const onDeleteModalClose = useCallback(() => {
        setRoleIdToDelete(null);
        triggerUpdate();
    }, [triggerUpdate]);

    return (
        <>
            {roleIdToDelete != null ? <RoleDeleteModal roleId={roleIdToDelete} onClose={onDeleteModalClose} /> : null}
            <FancyGrid.ReduxGrid
                gridName={"RolesListGrid"}
                dataRetrievalFunction={dataRetrievalFunction}
                updateTriggers={[updateTrigger]}
            >
                <FancyGrid.ColumnList>
                    <FancyGrid.Column
                        name="name"
                        title="Name"
                    />
                    <FancyGrid.Column>
                        <FancyGrid.CellRenderer>
                            {(_, role) => (
                                <>
                                    <LinkContainer to={`/admin/roles/${role.id}`}><Button>Edit</Button></LinkContainer>
                                    <Button variant="danger" onClick={() => setRoleIdToDelete(role.id)}>Delete</Button>
                                </>
                            )}
                        </FancyGrid.CellRenderer>
                    </FancyGrid.Column>
                </FancyGrid.ColumnList>
                <FancyGrid.ReduxSortable />
                <FancyGrid.ReduxFilterable filterStyle="popup" />
                <FancyGrid.ReduxPager />
            </FancyGrid.ReduxGrid>
        </>
    )
}