import React, { FunctionComponent } from 'react'
import {Button} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import dayjs from 'dayjs';
import qs from 'qs';

import FancyGrid, { SortCollection } from '@sht-dev/fancy-grid';

import { getSyrxControllerBacnetPointsForGrid } from '../../../services/syrxControllersService';
import { setIsSelectedForReportingActionCreator } from '../../../actionCreators/syrxControllerReportingPointsGridActionCreators';
import {useDispatch} from 'react-redux';
import {SyrxControllerBacnetPoint} from '../../../models/syrxControllerBacnetPoint';
import {LoadingSpinner} from '../../loadingSpinner/LoadingSpinner';
import {legacyAppUrl} from '../../../config';
import {
    syrxControllerReportingPointsGridActions,
    useSyrxControllerReportingPointsGridSelector
} from './syrxControllerReportingPointsGridSlice';

const GridOptionsContainer: FunctionComponent<{}> = () => {
    const dispatch = useDispatch();
    const showDateColumns = useSyrxControllerReportingPointsGridSelector(state => state.showDateColumns);
    const showUnavailablePoints = useSyrxControllerReportingPointsGridSelector(state => state.showUnavailablePoints);

    const setShowDateColumns = (newShowDateColumns: string) => {
        dispatch(syrxControllerReportingPointsGridActions.setShowDateColumns(newShowDateColumns === 'Yes'));
    };

    const setShowUnavailablePoints = (newShowUnavailablePoints: string) => {
        dispatch(syrxControllerReportingPointsGridActions.setShowUnavailablePoints(newShowUnavailablePoints === 'Yes'));
    };

    return (
        <table>
            <tbody>
            <tr>
                <td>
                    Show date columns:
                </td>
                <td>
                    <select value={showDateColumns ? 'Yes' : 'No'} onChange={e => setShowDateColumns(e.target.value)} className="form-control">
                        <option>Yes</option>
                        <option>No</option>
                    </select>
                </td>
            </tr>
            <tr>
                <td>
                    Show unavailable points:
                </td>
                <td>
                    <select value={showUnavailablePoints ? 'Yes' : 'No'} onChange={e => setShowUnavailablePoints(e.target.value)} className="form-control">
                        <option>Yes</option>
                        <option>No</option>
                    </select>
                </td>
            </tr>
            </tbody>
        </table>
    );
};

export interface SyrxControllerReportingPointsGridProps {
    syrxControllerId: string;
}

function getVendorPointRecordBrowserUrl(point: SyrxControllerBacnetPoint) {
    const queryString = qs.stringify({
        syrxControllerBacnetPointIds: `${point.id},${point.syrx_controller_name},${point.syrx_controller_bacnet_device_name},${point.name}`,
        ...(point.reporting_earliest_record_date != null && point.reporting_latest_record_date != null
            ? {
                fromDate: dayjs(point.reporting_earliest_record_date).utc().format("MM-DD-YYYY"),
                toDate: dayjs(point.reporting_latest_record_date).utc().format("MM-DD-YYYY")
            }
            : {}
        )
    });
    return `${legacyAppUrl}/#/point_reporting/vendor_point_record_browser?${queryString}`;
}

export const SyrxControllerReportingPointsGrid: FunctionComponent<SyrxControllerReportingPointsGridProps> = ({syrxControllerId}) => {
    const dispatch = useDispatch();

    const showDateColumns = useSyrxControllerReportingPointsGridSelector(state => state.showDateColumns);
    const showUnavailablePoints = useSyrxControllerReportingPointsGridSelector(state => state.showUnavailablePoints);
    const isUpdating = useSyrxControllerReportingPointsGridSelector(state => state.isUpdating);
    const refreshTrigger = useSyrxControllerReportingPointsGridSelector(state => state.refreshTrigger);

    const dataRetrievalFunction = (pageNumber: number, pageSize: number, sort: SortCollection) => getSyrxControllerBacnetPointsForGrid(syrxControllerId, showUnavailablePoints, pageNumber, pageSize, sort);

    const setIsSelectedForReporting = (syrxControllerBacnetPointId: string, newIsSelectedForReporting: boolean) => {
        dispatch(setIsSelectedForReportingActionCreator(syrxControllerId, syrxControllerBacnetPointId, newIsSelectedForReporting));
    };

    return (
        <>
            <GridOptionsContainer />
            {isUpdating ? <LoadingSpinner /> : null}
            <FancyGrid.ReduxGrid
                gridName={"SyrxControllerReportingPointsGrid"}
                dataRetrievalFunction={dataRetrievalFunction}
                updateTriggers={[
                    showUnavailablePoints,
                    refreshTrigger
                ]}
            >
                <FancyGrid.ColumnList>
                    <FancyGrid.Column
                        name="syrx_controller_bacnet_device_name"
                        title="Device name"
                    />
                    <FancyGrid.Column
                        name="object_type_name"
                        title="Object type"
                    />
                    <FancyGrid.Column
                        name="instance_number"
                        title="Instance number"
                    />
                    <FancyGrid.Column
                        name="name"
                        title="Point name"
                    />
                    <FancyGrid.Column
                        name="description"
                        title="Description"
                    />
                    {showDateColumns ? (
                        <FancyGrid.Column
                            name="reporting_earliest_record_date"
                            title="Earliest record date"
                        >
                            <FancyGrid.CellRenderer>
                                {(reportingEarliestRecordDate: string | null) => <>{reportingEarliestRecordDate != null ? dayjs.utc(reportingEarliestRecordDate).format() : null}</>}
                            </FancyGrid.CellRenderer>
                        </FancyGrid.Column>
                    ) : null}
                    {showDateColumns ? (
                        <FancyGrid.Column
                            name="reporting_latest_record_date"
                            title="Latest record date"
                        >
                            <FancyGrid.CellRenderer>
                                {(reportingLatestRecordDate: string | null) => <>{reportingLatestRecordDate != null ? dayjs.utc(reportingLatestRecordDate).format() : null}</>}
                            </FancyGrid.CellRenderer>
                        </FancyGrid.Column>
                    ) : null}
                    <FancyGrid.Column
                        name="reporting_latest_value"
                        title="Latest value"
                    />
                    <FancyGrid.Column
                        name="is_available"
                        title="Is available"
                    >
                        <FancyGrid.CellRenderer>
                            {(isAvailable: boolean) => <>{isAvailable ? 'Yes' : 'No'}</>}
                        </FancyGrid.CellRenderer>
                    </FancyGrid.Column>

                    <FancyGrid.Column
                        name="reporting_is_selected_for_reporting"
                        title="Is selected"
                    >
                        <FancyGrid.CellRenderer>
                            {(isSelectedForReporting: boolean, point: SyrxControllerBacnetPoint) => (
                                <>
                                    <select
                                        className="form-control"
                                        value={isSelectedForReporting ? 'Yes' : 'No'}
                                        onChange={e => setIsSelectedForReporting(point.id, e.target.value === 'Yes')}
                                    >
                                        <option>Yes</option>
                                        <option>No</option>
                                    </select>
                                </>
                            )}
                        </FancyGrid.CellRenderer>
                    </FancyGrid.Column>

                    <FancyGrid.Column>
                        <FancyGrid.CellRenderer>
                            {(_, point: SyrxControllerBacnetPoint) => (
                                <>
                                    {point.reporting_is_selected_for_reporting ? (
                                        <LinkContainer to={getVendorPointRecordBrowserUrl(point)}><Button type="button" variant="secondary">Records</Button></LinkContainer>
                                    ) : null}
                                </>
                            )}
                        </FancyGrid.CellRenderer>
                    </FancyGrid.Column>

                </FancyGrid.ColumnList>
                <FancyGrid.ReduxSortable />
                <FancyGrid.ReduxPager />
            </FancyGrid.ReduxGrid>
        </>
    )
};