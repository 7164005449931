import React, { useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom';
import { useParams, Redirect } from 'react-router';
import { Form } from 'react-final-form';
import { Field } from 'react-final-form-html5-validation'
import { Button, FormGroup, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { LoadingSpinner } from '../loadingSpinner/LoadingSpinner';

import { projectEditorActionCreators } from '../../actionCreators/projectEditorActionCreators';
import { useProjectEditorSelector } from './projectEditorSlice';
import { useAuthSessionSelector } from '../authSession/authSessionSlice';
import { Project } from './types';

export const ProjectEditor: React.FunctionComponent = () => {
    const { project, contacts, isLoading, isSuccessful } = useProjectEditorSelector(x => x);
    const currentGroup = useAuthSessionSelector(state => state.currentGroup);
    const dispatch = useDispatch();

    const params = useParams<{ id: string | undefined }>();
    const id = (params.id) ? parseInt(params.id) : null;

    useEffect(() => {
        return () => {
            dispatch(projectEditorActionCreators.reset());
        }
    }, [dispatch])

    useEffect(() => {
        async function fetchData() {
            if (id) {
                dispatch(projectEditorActionCreators.get(id));
            }
            dispatch(projectEditorActionCreators.getContacts(currentGroup.id));
        }

        fetchData();
    }, [dispatch, id]);

    const onSubmit = useCallback(async (values: any) => {
        const {start_date_ymd, complete_date_ymd, ...valuesWithoutDates} = values;
        const formattedValues: Project = {
            ...valuesWithoutDates,
            start_date: dayjs.utc(start_date_ymd).format(),
            complete_date: dayjs.utc(complete_date_ymd).format(),
            owner_id: valuesWithoutDates.owner_id ?? null,
            comm_authority_id: valuesWithoutDates.comm_authority_id ?? null,
            engineer_id: valuesWithoutDates.engineer_id ?? null
        }
        if (id) {
            dispatch(projectEditorActionCreators.update({...formattedValues, id, group_id: currentGroup.id}));
        } else {
            dispatch(projectEditorActionCreators.create({...formattedValues, group_id: currentGroup.id}));
        }
    }, [dispatch]);

    return (
        <>
            {isLoading ? <LoadingSpinner /> : null}
            <Form
                onSubmit={onSubmit}
                initialValues={project}
            >
                {({ handleSubmit, values, initialValues }) => (
                    <form onSubmit={handleSubmit}>
                        {isSuccessful ? <Redirect to="/projects" /> : null}
                        <FormGroup>
                            <Col md={4}>
                                <h2>{id ? "Edit" : "Add"} Project</h2>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col md={4}>
                                <label>Name</label>
                                <Field
                                    name="name"
                                    component="input"
                                    type="text"
                                    placeholder="Name"
                                    className="form-control"
                                    required
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col md={4}>
                                <label>Address</label>
                                <Field
                                    name="address"
                                    component="input"
                                    type="text"
                                    placeholder="Address"
                                    className="form-control"
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col md={4}>
                                <label>Customer Project Id</label>
                                <Field
                                    name="customer_project_id"
                                    component="input"
                                    type="text"
                                    placeholder="Customer Project Id"
                                    className="form-control"
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col md={4}>
                                <label>Architect Project Id</label>
                                <Field
                                    name="architect_project_id"
                                    component="input"
                                    type="text"
                                    placeholder="Architect Project Id"
                                    className="form-control"
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col md={4}>
                                <label>Start Date</label>
                                <Field
                                    name="start_date_ymd"
                                    component="input"
                                    type="date"
                                    className="form-control"
                                    initialValue={(initialValues.start_date) ? dayjs.utc(initialValues.start_date).format('YYYY-MM-DD') : ''}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col md={4}>
                                <label>Complete Date</label>
                                <Field
                                    name="complete_date_ymd"
                                    component="input"
                                    type="date"
                                    className="form-control"
                                    initialValue={(initialValues.complete_date) ? dayjs.utc(initialValues.complete_date).format('YYYY-MM-DD') : ''}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col md={4}>
                                <label>Estimated Cost</label>
                                <Field
                                    name="estimated_cost"
                                    component="input"
                                    type="text"
                                    placeholder="Estimated Cost"
                                    className="form-control"
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col md={4}>
                                <label>Owner</label>
                                <Field
                                    name="owner_id"
                                    component="select"
                                    className="form-control"
                                >
                                    <option value={''}>{values.owner_id === null ? 'Select Owner' : 'No Owner'}</option>
                                    {contacts.map(contact => {
                                        return (<option value={contact.id} key={contact.id}>{contact.full_name}</option>);
                                    })}
                                </Field>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col md={4}>
                                <label>Comm Authority</label>
                                <Field
                                    name="comm_authority_id"
                                    component="select"
                                    className="form-control"
                                >
                                    <option value={''}>{values.comm_authority_id === initialValues?.comm_authority_id ? 'Select Comm Authority' : 'No Comm Authority'}</option>
                                    {contacts.map(contact => {
                                        return (<option value={contact.id} key={contact.id}>{contact.full_name}</option>);
                                    })}
                                </Field>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col md={4}>
                                <label>Engineer</label>
                                <Field
                                    name="engineer_id"
                                    component="select"
                                    className="form-control"
                                >
                                    <option value={''}>{values.engineer_id === initialValues?.engineer_id ? 'Select Engineer' : 'No Engineer'}</option>
                                    {contacts.map(contact => {
                                        return (<option value={contact.id} key={contact.id}>{contact.full_name}</option>);
                                    })}
                                </Field>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col md={4}>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div>
                                        <Button variant="primary" type="submit">
                                            Submit
                                        </Button>
                                    </div>
                                    <div>
                                        <Link to="/projects">
                                            <Button variant="secondary">
                                                Cancel
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </FormGroup>
                    </form>
                )}
            </Form>
        </>
    );
}