import React, {useCallback} from 'react'
import {Button} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import {SyrxControllerBACnetPointSelectionTree} from '../../bacnetPoints/syrxControllerBACnetPointSelectionTree';
import {useDispatch} from 'react-redux';
import {
    syrxControllerBuildingPressureControlServicePnormOutputEditorActions,
    useSyrxControllerBuildingPressureControlServicePnormOutputEditorSelector
} from './syrxControllerBuildingPressureControlServicePnormOutputEditorSlice';
import {LoadingSpinner} from '../../../loadingSpinner/LoadingSpinner';
import {SyrxControllerBACnetPointInfoTable} from '../../bacnetPoints/syrxControllerBACnetPointInfoTable';

export interface SyrxControllerBuildingPressureControlServicePnormOutputProps {
    syrxControllerId: string;
    pnormOutputSyrxControllerBacnetPointId: string | null;
    editMode?: boolean;
    onSubmit?: (pnormOutputSyrxControllerBacnetPointId: string | null) => void;
    cancelLinkTo?: string;
}

const EditMode: React.FunctionComponent<SyrxControllerBuildingPressureControlServicePnormOutputProps> = props => {
    const {
        syrxControllerId,
        onSubmit,
        cancelLinkTo
    } = props;

    const dispatch = useDispatch();
    const isSelectedPointUpdating = useSyrxControllerBuildingPressureControlServicePnormOutputEditorSelector(x => x.isSelectedPointUpdating);
    const pnormOutputSyrxControllerBacnetPointId = useSyrxControllerBuildingPressureControlServicePnormOutputEditorSelector(x => x.pnormOutputSyrxControllerBacnetPointId);

    const onSyrxControllerBACnetPointSelected = useCallback((selectedSyrxControllerBacnetPointId: string) => {
        dispatch(syrxControllerBuildingPressureControlServicePnormOutputEditorActions.setPnormOutputSyrxControllerBacnetPointId(selectedSyrxControllerBacnetPointId));
    }, [dispatch]);

    const submit = useCallback(() => {
        if (onSubmit != null) {
            onSubmit(pnormOutputSyrxControllerBacnetPointId);
        }
    }, [onSubmit, pnormOutputSyrxControllerBacnetPointId]);

    return (
        <>
            {isSelectedPointUpdating ? <LoadingSpinner /> : null}
            <DisplayMode
                {...props}
            />
            <SyrxControllerBACnetPointSelectionTree
                syrxControllerId={syrxControllerId}
                onSyrxControllerBACnetPointSelected={onSyrxControllerBACnetPointSelected}
                selectedSyrxControllerBACnetPointId={pnormOutputSyrxControllerBacnetPointId}
            />
            <Button variant="primary" type="button" onClick={submit}>Submit</Button>
            {cancelLinkTo != null ? (
                <LinkContainer to={cancelLinkTo} exact={true}>
                    <Button variant="outline-secondary">Cancel</Button>
                </LinkContainer>
            ) : null}
        </>
    );
};

const DisplayMode: React.FunctionComponent<SyrxControllerBuildingPressureControlServicePnormOutputProps> = props => {
    const {
        syrxControllerId,
        pnormOutputSyrxControllerBacnetPointId
    } = props;

    return (
        <SyrxControllerBACnetPointInfoTable syrxControllerId={syrxControllerId} syrxControllerBacnetPointId={pnormOutputSyrxControllerBacnetPointId} />
    );
};

export const SyrxControllerBuildingPressureControlServicePnormOutputTable: React.FunctionComponent<SyrxControllerBuildingPressureControlServicePnormOutputProps> = props => {
    const {
        editMode
    } = props;

    return editMode ? (
        <EditMode {...props} />
    ) : (
        <DisplayMode {...props} />
    )
};