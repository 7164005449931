import React, {useMemo} from 'react'
import {LineChart, Line, CartesianGrid, XAxis, YAxis, Legend} from 'recharts';
import {
    useSyrxControllerDiagnosticServiceMetricsSelector
} from './syrxControllerDiagnosticServiceMetricsSlice';

const lineColors = [
    "#003f5c",
    "#58508d",
    "#bc5090",
    "#ff6361",
    "#ffa600"
];

export const SyrxControllerDiagnosticServiceMetricsChart: React.FunctionComponent = () => {
    const chartData = useSyrxControllerDiagnosticServiceMetricsSelector(state => state.chartData);

    const {labels, metrics} = chartData ?? {labels: [] as string[], metrics: null};

    const metric = useSyrxControllerDiagnosticServiceMetricsSelector(state => state.metric);
    const aggregation = useSyrxControllerDiagnosticServiceMetricsSelector(state => state.aggregation);

    const lineChartData = useMemo(() => metrics?.map(processInformationRecord => ({
        date: processInformationRecord.date,
        ...Object.fromEntries(processInformationRecord.groups.map(group => [group.group_name, (group.aggregates as any)[metric][aggregation]]))
    })), [metrics, metric, aggregation]);

    const allLabels = Array.from(new Set([
        ...labels,
        ...(metrics?.flatMap(x => x.groups).map(x => x.group_name) ?? [])
    ]));

    return (
        <>
            <LineChart width={800} height={500} data={lineChartData}>
                {allLabels.map((label, i) => <Line key={label} type="monotone" dataKey={label} stroke={lineColors[i % lineColors.length]} />)}
                <CartesianGrid stroke="#ccc" />
                <XAxis dataKey="command" />
                <YAxis />
                <Legend />
            </LineChart>
        </>
    )
};