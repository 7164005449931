import React, {useEffect, useState} from 'react';
import {getSyrxControllerBacnetDevice, getSyrxControllerBacnetPoint} from '../../../services/syrxControllersService';
import {SyrxControllerBacnetPoint} from '../../../models/syrxControllerBacnetPoint';
import {SyrxControllerBacnetDevice} from '../../../models/syrxControllerBacnetDevice';
import {LoadingSpinner} from '../../loadingSpinner/LoadingSpinner';

export interface SyrxControllerBACnetPointInfoTableProps {
    syrxControllerId: string;
    syrxControllerBacnetPointId: string | null;
    onLoaded?: () => void;
}

export const SyrxControllerBACnetPointInfoTable: React.FunctionComponent<SyrxControllerBACnetPointInfoTableProps> = props => {
    const {
        syrxControllerId,
        syrxControllerBacnetPointId,
        onLoaded
    } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [bacnetPoint, setBacnetPoint] = useState(null as SyrxControllerBacnetPoint | null);
    const [bacnetDevice, setBacnetDevice] = useState(null as SyrxControllerBacnetDevice | null);

    useEffect(() => {
        async function doTheThing() {
            setIsLoading(true);
            try {
                if (syrxControllerBacnetPointId == null) {
                    setBacnetPoint(null);
                    setBacnetDevice(null);
                    return;
                }

                const newBacnetPoint = await getSyrxControllerBacnetPoint(syrxControllerId, syrxControllerBacnetPointId);

                if (newBacnetPoint == null) {
                    setBacnetPoint(null);
                    setBacnetDevice(null);
                    return;
                }

                const newBacnetDevice = await getSyrxControllerBacnetDevice(syrxControllerId, newBacnetPoint.syrx_controller_bacnet_device_id);

                if (newBacnetDevice == null) {
                    setBacnetPoint(null);
                    setBacnetDevice(null);
                    return;
                }

                setBacnetPoint(newBacnetPoint);
                setBacnetDevice(newBacnetDevice);

            } finally {
                setIsLoading(false);
                if (onLoaded != null) {
                    onLoaded();
                }
            }
        }

        void doTheThing();
    }, [syrxControllerId, syrxControllerBacnetPointId, onLoaded])

    return (
        <>
            {isLoading ? <LoadingSpinner /> : null}
            <table className="table">
                <tbody>
                <tr>
                    <td>MAC address</td>
                    <td>{bacnetDevice != null ? `${bacnetDevice.mac_address} [${bacnetDevice.network}]` : "n/a"}</td>
                </tr>
                <tr>
                    <td>Object ID</td>
                    <td>{bacnetPoint?.object_id ?? "n/a"}</td>
                </tr>
                <tr>
                    <td>Device name</td>
                    <td>{bacnetDevice?.name ?? "n/a"}</td>
                </tr>
                <tr>
                    <td>Point name</td>
                    <td>{bacnetPoint?.name ?? "n/a"}</td>
                </tr>
                </tbody>
            </table>
        </>
    );
}