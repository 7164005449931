import {CaseReducer, createSlice} from '@reduxjs/toolkit';

import {buildSubStateSelector} from '../../../store/utilities/useAppStateSelector';

const initialState = {
    isSubmitting: false,
    isDoneSubmitting: false
};

export type AppStateSyrxControllerBacnetDeviceEditor = typeof initialState;

export const slice = createSlice<AppStateSyrxControllerBacnetDeviceEditor, {
    startSubmitting: CaseReducer<AppStateSyrxControllerBacnetDeviceEditor>;
    cancelSubmitting: CaseReducer<AppStateSyrxControllerBacnetDeviceEditor>;
    finishSubmitting: CaseReducer<AppStateSyrxControllerBacnetDeviceEditor>;
    editorUnload: CaseReducer<AppStateSyrxControllerBacnetDeviceEditor>;
}>({
    name: "syrxControllerBacnetDeviceEditor",
    initialState,
    reducers: {
        startSubmitting(state) {
            state.isSubmitting = true;
            state.isDoneSubmitting = false;
        },
        cancelSubmitting(state) {
            state.isSubmitting = false;
        },
        finishSubmitting(state) {
            state.isSubmitting = false;
            state.isDoneSubmitting = true;
        },
        editorUnload(state) {
            state.isSubmitting = false;
            state.isDoneSubmitting = false;
        }
    }
});

export const useSyrxControllerBacnetDeviceEditorSelector = buildSubStateSelector(state => state.syrxControllerBacnetDeviceEditor);
export const syrxControllerBacnetDeviceEditorActions = slice.actions;
export const syrxControllerBacnetDeviceEditorReducer = slice.reducer;