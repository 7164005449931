import React from 'react'
import {GroupRolesListGrid} from './groupRolesListGrid';
import {LinkContainer} from 'react-router-bootstrap';
import {Button} from 'react-bootstrap';

export const GroupRolesList: React.FunctionComponent = () => {
    return (
        <>
            <h3>Group roles</h3>
            <LinkContainer to={"/admin/group-roles/new"}><Button>Create new</Button></LinkContainer>
            <GroupRolesListGrid />
        </>
    );
};