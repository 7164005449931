import {AuthService} from './authService';
import {BuildingMeterAccountsService} from './buildingMeterAccountsService';
import {ComponentsService} from './componentsService';
import {ContactsService} from './contactsService';
import {EquipmentsService} from './equipmentsService';
import {GroupPermissionsService} from './groupPermissionsService';
import {GroupRolesService} from './groupRolesService';
import {ProjectsService} from './projectsService';
import {PurchasingService} from './purchasingService';
import {RolesService} from './rolesService';

export const NewApiServices = {
    authService: new AuthService(),
    buildingMeterAccountsService: new BuildingMeterAccountsService(),
    componentsService: new ComponentsService(),
    contactsService: new ContactsService(),
    equipmentsService: new EquipmentsService(),
    groupPermissionsService: new GroupPermissionsService(),
    groupRolesService: new GroupRolesService(),
    projectsService: new ProjectsService(),
    purchasingService: new PurchasingService(),
    rolesService: new RolesService()
}