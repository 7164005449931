import React, {useCallback, useEffect, useState} from 'react'
import {Button, Modal} from "react-bootstrap";
import {SyrxControllerServiceVersion} from '../../../models/syrxControllerServiceVersion';
import {SyrxControllerServiceVersionsService} from '../../../services/syrxControllerServiceVersionsService';
import {LoadingSpinner} from '../../loadingSpinner/LoadingSpinner';
import {toast} from 'react-toastify';

export interface SyrxControllerServiceVersionDeleteModalProps {
    serviceId: string;
    versionName: string;
    onClose: (deleteSuccessful: boolean) => void;
}

export const SyrxControllerServiceVersionDeleteModal: React.FunctionComponent<SyrxControllerServiceVersionDeleteModalProps> = (props) => {
    const {
        serviceId,
        versionName,
        onClose
    } = props;

    const [isLoaded, setIsLoaded] = useState(false);
    const [syrxControllerServiceVersion, setSyrxControllerServiceVersion] = useState(null as SyrxControllerServiceVersion | null);

    const doDismiss = useCallback(() => onClose(false), [onClose]);

    const doSubmit = useCallback(() => {
        async function doTheThing() {
            setIsLoaded(false);
            try {
                await SyrxControllerServiceVersionsService.deleteServiceVersion(serviceId, versionName);
                onClose(true);
            } catch (e) {
                toast.error("An error occurred while trying to delete the service version");
                setIsLoaded(true);
            } finally {
            }
        }

        void doTheThing();
    }, [serviceId, versionName, onClose]);

    useEffect(() => {
        async function doTheThing() {
            setIsLoaded(false);
            setSyrxControllerServiceVersion(null);
            const _syrxControllerServiceVersion = await SyrxControllerServiceVersionsService.getServiceVersionForServiceTypeAndVersionName(serviceId, versionName);
            if (_syrxControllerServiceVersion == null) {
                doDismiss();
                return;
            }
            setSyrxControllerServiceVersion(_syrxControllerServiceVersion);
            setIsLoaded(true);
        }

        void doTheThing();
    }, [serviceId, versionName, doDismiss]);

    return (
        <Modal show={true} onHide={doDismiss} size="xl">
            {!isLoaded ? <LoadingSpinner /> : null}
            <Modal.Header closeButton>
                <Modal.Title>Delete Service Version</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {syrxControllerServiceVersion != null ? (
                    <>
                        <p>Delete this service version?</p>
                        <table className="table">
                            <tbody>
                                <tr>
                                    <th>Service</th>
                                    <td>{syrxControllerServiceVersion.service_id}</td>
                                </tr>
                                <tr>
                                    <th>Version</th>
                                    <td>{syrxControllerServiceVersion.version_name}</td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                ) : null}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={doSubmit}>
                    Delete
                </Button>
                <Button variant="secondary" onClick={doDismiss}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};