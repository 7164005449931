import {CaseReducer, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {buildSubStateSelector} from '../../../../store/utilities/useAppStateSelector';

export interface QueueEntry {
    id: string;
    sourcePointName: string;
    sourceVendorPointId: string | null;
    sourcePcsPointId: string | null;
    sourceSyrxControllerBacnetPointId: string | null;
    destinationPointName: string;
    destinationVendorPointId: string | null;
    destinationPcsPointId: string | null;
    destinationSyrxControllerBacnetPointId: string | null;
    fullTimeRange: boolean;
    startDate: string;
    endDate: string;
    sourceRecordHandling: "copy" | "move",
    remapEquipmentPoints: boolean;
}

const initialState = {
    isCompleted: false,
    showLoadingSpinner: false,
    queue: [] as QueueEntry[]
};

export type AppStateMoveVendorPointDataNewJob = typeof initialState;

export const slice = createSlice<AppStateMoveVendorPointDataNewJob, {
    setIsCompleted: CaseReducer<AppStateMoveVendorPointDataNewJob, PayloadAction<boolean>>;
    setShowLoadingSpinner: CaseReducer<AppStateMoveVendorPointDataNewJob, PayloadAction<boolean>>;
    clearQueue: CaseReducer<AppStateMoveVendorPointDataNewJob, PayloadAction>;
    addToQueue: CaseReducer<AppStateMoveVendorPointDataNewJob, PayloadAction<QueueEntry>>;
    updateQueueEntry: CaseReducer<AppStateMoveVendorPointDataNewJob, PayloadAction<{id: string, queueEntry: QueueEntry}>>;
    removeFromQueue: CaseReducer<AppStateMoveVendorPointDataNewJob, PayloadAction<{id: string}>>;
    updateQueueEntryFullTimeRange: CaseReducer<AppStateMoveVendorPointDataNewJob, PayloadAction<{id: string, fullTimeRange: boolean}>>;
    updateQueueEntryStartDate: CaseReducer<AppStateMoveVendorPointDataNewJob, PayloadAction<{id: string, startDate: string}>>;
    updateQueueEntryEndDate: CaseReducer<AppStateMoveVendorPointDataNewJob, PayloadAction<{id: string, endDate: string}>>;
    updateQueueEntrySourceRecordHandling: CaseReducer<AppStateMoveVendorPointDataNewJob, PayloadAction<{id: string, sourceRecordHandling: "copy" | "move"}>>;
    updateQueueEntryRemapEquipmentPoints: CaseReducer<AppStateMoveVendorPointDataNewJob, PayloadAction<{id: string, remapEquipmentPoints: boolean}>>;
}>({
    name: "moveVendorPointData",
    initialState,
    reducers: {
        setIsCompleted(state, {payload}) {
            state.isCompleted = payload;
        },
        setShowLoadingSpinner(state, {payload}) {
            state.showLoadingSpinner = payload;
        },
        clearQueue(state) {
            state.queue = [];
        },
        addToQueue(state, {payload}) {
            state.queue.push(payload);
        },
        updateQueueEntry(state, {payload: {id, queueEntry}}) {
            state.queue = state.queue.map(existingQueueEntry => existingQueueEntry.id === id ? queueEntry : existingQueueEntry);
        },
        removeFromQueue(state, {payload: {id}}) {
            state.queue = state.queue.filter(existingQueueEntry => existingQueueEntry.id !== id);
        },
        updateQueueEntryFullTimeRange(state, {payload: {id, fullTimeRange}}) {
            state.queue = state.queue.map(existingQueueEntry => existingQueueEntry.id === id ? {
                ...existingQueueEntry,
                fullTimeRange
            } : existingQueueEntry);
        },
        updateQueueEntryStartDate(state, {payload: {id, startDate}}) {
            state.queue = state.queue.map(existingQueueEntry => existingQueueEntry.id === id ? {
                ...existingQueueEntry,
                startDate
            } : existingQueueEntry);
        },
        updateQueueEntryEndDate(state, {payload: {id, endDate}}) {
            state.queue = state.queue.map(existingQueueEntry => existingQueueEntry.id === id ? {
                ...existingQueueEntry,
                endDate
            } : existingQueueEntry);
        },
        updateQueueEntrySourceRecordHandling(state, {payload: {id, sourceRecordHandling}}) {
            state.queue = state.queue.map(existingQueueEntry => existingQueueEntry.id === id ? {
                ...existingQueueEntry,
                sourceRecordHandling
            } : existingQueueEntry);
        },
        updateQueueEntryRemapEquipmentPoints(state, {payload: {id, remapEquipmentPoints}}) {
            state.queue = state.queue.map(existingQueueEntry => existingQueueEntry.id === id ? {
                ...existingQueueEntry,
                remapEquipmentPoints
            } : existingQueueEntry);
        },
    }
});

export const useMoveVendorPointDataNewJobSelector = buildSubStateSelector(state => state.moveVendorPointDataNewJob);
export const moveVendorPointDataNewJobActions = slice.actions;
export const moveVendorPointDataNewJobReducer = slice.reducer;