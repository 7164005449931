import React, {useEffect} from 'react'
import {Button, Col} from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import {
    syrxControllerBuildingPressureControlServiceDetailsActions,
    useSyrxControllerBuildingPressureControlServiceDetailsSelector
} from './syrxControllerBuildingPressureControlServiceDetailsSlice';
import {syrxControllerBuildingPressureControlServiceDetailsActionCreators} from './syrxControllerBuildingPressureControlServiceDetailsActionCreators';
import {LoadingSpinner} from '../../loadingSpinner/LoadingSpinner';
import {SyrxControllerBuildingPressureControlServiceBasicConfigurationTable} from './syrxControllerBuildingPressureControlServiceBasicConfigurationTable';
import {SyrxControllerBuildingPressureControlServiceOatSensorTable} from './oatSensor/syrxControllerBuildingPressureControlServiceOatSensorTable';
import {LinkContainer} from 'react-router-bootstrap';
import {SyrxControllerBuildingPressureTransmittersGrid} from './buildingPressureTransmitters/syrxControllerBuildingPressureTransmittersGrid';
import {SyrxControllerBuildingPressureControlServicePnormOutputTable} from './pnormOutput/syrxControllerBuildingPressureControlServicePnormOutputTable';
import {SyrxControllerBuildingPressureControlServiceStatusTable} from './syrxControllerBuildingPressureControlServiceStatusTable';

export const SyrxControllerBuildingPressureControlServiceDetails: React.FunctionComponent<{ syrxControllerId: string }> = ({syrxControllerId}) => {
    const dispatch = useDispatch();
    const isLoading = useSyrxControllerBuildingPressureControlServiceDetailsSelector(x => x.isLoading);
    const isDoneLoading = useSyrxControllerBuildingPressureControlServiceDetailsSelector(x => x.isDoneLoading);
    const syrxControllerBuildingPressureControlServiceConfiguration = useSyrxControllerBuildingPressureControlServiceDetailsSelector(x => x.syrxControllerBuildingPressureControlServiceConfiguration);

    useEffect(() => {
        dispatch(syrxControllerBuildingPressureControlServiceDetailsActionCreators.loadConfiguration(syrxControllerId));
        return () => {
            dispatch(syrxControllerBuildingPressureControlServiceDetailsActions.resetState())
        };
    }, [dispatch, syrxControllerId]);

    return (
        <>
            {isLoading ? <LoadingSpinner /> : null}
            {isDoneLoading ? (
                <Col md={12} style={{marginTop: "1rem"}}>

                    <h4>Status</h4>
                    <SyrxControllerBuildingPressureControlServiceStatusTable
                        syrxControllerBuildingPressureControlServiceConfiguration={syrxControllerBuildingPressureControlServiceConfiguration}
                    />

                    <div style={{marginTop: "20px"}}>
                        <h4 style={{display: "inline-block", marginRight: "20px"}}>Service configuration</h4>
                        <LinkContainer to={`/admin/syrx-controllers/${syrxControllerId}/services/Pathian.Services.BuildingPressureControlService/configuration/edit`}><Button variant="outline-info">Edit</Button></LinkContainer>
                    </div>
                    <SyrxControllerBuildingPressureControlServiceBasicConfigurationTable
                        syrxControllerBuildingPressureControlServiceBasicConfiguration={syrxControllerBuildingPressureControlServiceConfiguration}
                    />

                    <div style={{marginTop: "20px"}}>
                        <h4 style={{display: "inline-block", marginRight: "20px"}}>Outside air temperature sensor</h4>
                        <LinkContainer to={`/admin/syrx-controllers/${syrxControllerId}/services/Pathian.Services.BuildingPressureControlService/oat-sensor/edit`}><Button variant="outline-info">Edit</Button></LinkContainer>
                    </div>
                    <SyrxControllerBuildingPressureControlServiceOatSensorTable
                        syrxControllerId={syrxControllerId}
                        oatSensorSyrxControllerBacnetPointId={syrxControllerBuildingPressureControlServiceConfiguration?.oat_syrx_controller_bacnet_point_id ?? null}
                    />

                    <div style={{marginTop: "20px"}}>
                        <h4 style={{display: "inline-block", marginRight: "20px"}}>Normalized building pressure output (P_norm)</h4>
                        <LinkContainer to={`/admin/syrx-controllers/${syrxControllerId}/services/Pathian.Services.BuildingPressureControlService/pnorm-output/edit`}><Button variant="outline-info">Edit</Button></LinkContainer>
                    </div>
                    <SyrxControllerBuildingPressureControlServicePnormOutputTable
                        syrxControllerId={syrxControllerId}
                        pnormOutputSyrxControllerBacnetPointId={syrxControllerBuildingPressureControlServiceConfiguration?.pnorm_output_syrx_controller_bacnet_point_id ?? null}
                    />

                    <div style={{marginTop: "20px"}}>
                        <h4 style={{display: "inline-block", marginRight: "20px"}}>Building pressure transmitters</h4>
                        <LinkContainer to={`/admin/syrx-controllers/${syrxControllerId}/services/Pathian.Services.BuildingPressureControlService/building-pressure-transmitters/new`}><Button variant="outline-info">Create</Button></LinkContainer>
                    </div>
                    <SyrxControllerBuildingPressureTransmittersGrid syrxControllerId={syrxControllerId} />
                </Col>
            ) : null}

        </>
    )
};