import { Dispatch } from "redux";
import { toast } from "react-toastify";

import { NewApiServices } from "../newApiServices";
import { projectScheduleActions } from "../features/projects/projectScheduleSlice";
import { Equipment, ComponentScheduleTree } from "../features/projects/types";

export const projectScheduleActionCreators = {
    get: (projectId: number) => async (dispatch: Dispatch<any>) => {
        const project = await NewApiServices.projectsService.getOne(projectId);
        dispatch(projectScheduleActions.setProject(project));
    },
    getComponentScheduleTree: () => async (dispatch: Dispatch<any>) => {
        const componentScheduleTree = await NewApiServices.componentsService.getComponentScheduleTree();
        dispatch(projectScheduleActions.setComponentScheduleTree(componentScheduleTree));
    },
    openEquipmentEditor: (componentId: string, equipmentId?: number) => async (dispatch: Dispatch<any>) => {
        dispatch(projectScheduleActions.setEquipmentEditor({componentId, equipmentId}));
    },
    closeEquipmentEditor: () => async (dispatch: Dispatch<any>) => {
        dispatch(projectScheduleActions.resetEquipmentEditor());
    },
    createEquipment: (equipment: Equipment, subComponents: string[], projectId: number) => async (dispatch: Dispatch<any>) => {
        try {
            const body = {
                equipment,
                sub_components: subComponents,
                project_id: projectId
            }
            const newEquipment = await NewApiServices.equipmentsService.submitEquipment(body);
            dispatch(projectScheduleActions.addEquipment(newEquipment));
            dispatch(projectScheduleActions.resetEquipmentEditor());
            dispatch(projectScheduleActions.setPointEditor(newEquipment.id));
        } catch(err) {
            toast.error('Equipment Creation Failed. Please try again later.');
        }
    },
    getProjectSchedule: (projectId: number) => async (dispatch: Dispatch<any>) => {
        const projectSchedule = await NewApiServices.projectsService.getProjectSchedule(projectId);
        dispatch(projectScheduleActions.setProjectSchedule(projectSchedule));
    },
    deleteEquipment: (equipmentId: number) => async (dispatch: Dispatch<any>) => {
        try {
            await NewApiServices.equipmentsService.deleteEquipment(equipmentId);
            dispatch(projectScheduleActions.removeEquipment(equipmentId));
        } catch(err) {
            toast.error('Equipment Deletion Failed. Please try again later.');
        }
    },
    updateEquipment: (equipmentId: number, equipment: Equipment) => async (dispatch: Dispatch<any>) => {
        try {
            const body = {
                equipment
            }
            const updatedEquipment = await NewApiServices.equipmentsService.updateEquipment(equipmentId, body);
            dispatch(projectScheduleActions.updateEquipment(updatedEquipment));
            dispatch(projectScheduleActions.resetEquipmentEditor());
        } catch(err) {
            toast.error('Equipment Update Failed. Please try again later.');
        }
    },
    showSubcomponentModal: (componentId: string) => async(dispatch: Dispatch<any>) => {
        dispatch(projectScheduleActions.showSubcomponentModal(componentId));
    },
    hideSubcomponentModal: () => async(dispatch: Dispatch<any>) => {
        dispatch(projectScheduleActions.hideSubcomponentModal());
    },
    addSelectedSubcomponent: (component: ComponentScheduleTree) => async(dispatch: Dispatch<any>) => {
        dispatch(projectScheduleActions.addSelectedSubcomponent(component));
    },
    popSelectedSubcomponent: () => async(dispatch: Dispatch<any>) => {
        dispatch(projectScheduleActions.popSelectedSubcomponent());
    },
    openPointEditor: (equipmentId: number) => async (dispatch: Dispatch<any>) => {
        dispatch(projectScheduleActions.setPointEditor(equipmentId));
    },
    closePointEditor: () => async (dispatch: Dispatch<any>) => {
        dispatch(projectScheduleActions.resetPointEditor());
    },
    addExistReplaceEquipmentTasks: (projectId: number, equipmentId: number, equipmentPointsObject: {[key: string]: string}) => async(dispatch: Dispatch<any>) => {
        const equipmentPoints = Object.entries(equipmentPointsObject).map(([componentPointId, addExistReplace]) => {
            return {
                component_point_id: componentPointId,
                add_exist_replace: addExistReplace
            }
        });
        await NewApiServices.projectsService.addExistReplaceEquipmentTasks(projectId, {equipment_id: equipmentId, equipment_points: equipmentPoints});
        dispatch(projectScheduleActions.resetPointEditor());
    }
}