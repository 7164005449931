 import { AppState } from '../store/appState';
import { getLoggedInSession, setCurrentGroup } from '../services/authService';
import { authSessionActions } from '../features/authSession/authSessionSlice';
import { Dispatch } from "redux";

export const loadLoggedInSession = () => async (dispatch: Dispatch<any>) => {
    try {
        dispatch(authSessionActions.setInitializationState("initializing"));

        const {data: user} = await getLoggedInSession();

        dispatch(authSessionActions.updateUser(user));
        dispatch(authSessionActions.updateCurrentGroup(user.commissioning_group));
        dispatch(authSessionActions.setInitializationState("initialized"));
    } catch (e) {
        if (e.response && e.response.status === 403) {
            dispatch(authSessionActions.updateUser(null));
            dispatch(authSessionActions.updateCurrentGroup(null));
            dispatch(authSessionActions.setInitializationState("initialized"));
        } else {
            throw e;
        }
    }
};

export const submitCurrentGroupSelectionDialog = (groupId: number) => async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    await setCurrentGroup(state.authSession.user.id, groupId);
    await dispatch(loadLoggedInSession());
    dispatch(authSessionActions.setShowCurrentGroupSelectionDialog(false));
};