import { Dispatch } from "redux";
import dayjs from 'dayjs';
import {
    getMetricsForController,
    getMetricsGroupsForSyrxController
} from '../../../services/syrxControllersService';
import {syrxControllerDiagnosticServiceMetricsActions} from './syrxControllerDiagnosticServiceMetricsSlice';
import {AppState} from '../../../store/appState';
import {MetricsAggregatesWithStringDate} from '../../../models/metricsAggregates';

export const syrxControllerDiagnosticServiceMetricsActionCreators = {
    loadMetricsGroups: (syrxControllerId: string) => async (dispatch: Dispatch<any>, getState: () => AppState) => {
        dispatch(syrxControllerDiagnosticServiceMetricsActions.setIsUpdating(true));

        try {
            const {startDate, endDate, groupType} = getState().syrxControllerDiagnosticServiceMetrics;

            if (startDate != null && endDate != null && groupType !== "overall") {
                const availableGroups = await getMetricsGroupsForSyrxController(groupType, syrxControllerId, dayjs.utc(startDate).toDate(), dayjs.utc(endDate).toDate());
                dispatch(syrxControllerDiagnosticServiceMetricsActions.setAvailableGroups(availableGroups));
            }
        } finally {
            dispatch(syrxControllerDiagnosticServiceMetricsActions.setIsUpdating(false));
        }
    },

    loadMetrics: (syrxControllerId: string) => async (dispatch: Dispatch<any>, getState: () => AppState) => {
        dispatch(syrxControllerDiagnosticServiceMetricsActions.setIsUpdating(true));

        try {
            const {startDate, endDate, selectedGroups, groupType, resolution} = getState().syrxControllerDiagnosticServiceMetrics;
            dispatch(syrxControllerDiagnosticServiceMetricsActions.setChartData(null));

            if (startDate != null && endDate != null) {
                const metrics = await getMetricsForController(groupType, syrxControllerId, dayjs.utc(startDate).toDate(), dayjs.utc(endDate).toDate(), selectedGroups, resolution);
                const metricsWithStringDates: MetricsAggregatesWithStringDate = metrics.map(x => ({
                    ...x,
                    date: dayjs.utc(x.date).format()
                }));
                dispatch(syrxControllerDiagnosticServiceMetricsActions.setChartData({
                    labels: selectedGroups,
                    metrics: metricsWithStringDates
                }));
            }
        } finally {
            dispatch(syrxControllerDiagnosticServiceMetricsActions.setIsUpdating(false));
        }
    }
}
