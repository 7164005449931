import React, {useEffect} from 'react'
import {Col, Tabs, Tab} from 'react-bootstrap';

import {SyrxControllerDiagnosticServiceMetrics} from './syrxControllerDiagnosticServiceMetrics';
import {SyrxControllerDiagnosticServiceNetworkInterfaceInformation} from './syrxControllerDiagnosticServiceNetworkInterfaceInformation';
import {SyrxControllerDiagnosticServiceRabbitmqMetrics} from './syrxControllerDiagnosticServiceRabbitmqMetrics';
import {loadLatestDiagnosticServiceProcessStatusRecord} from '../../../actionCreators/syrxControllerDiagnosticServiceDetailsActionCreators';
import {useDispatch} from 'react-redux';

export interface SyrxControllerDiagnosticServiceDetailsProps {
    syrxControllerId: string;
}


export const SyrxControllerDiagnosticServiceDetails: React.FunctionComponent<SyrxControllerDiagnosticServiceDetailsProps> = ({syrxControllerId}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        async function fetchData() {
            dispatch(loadLatestDiagnosticServiceProcessStatusRecord(syrxControllerId));
        }

        const timerHandle = setInterval(fetchData, 5000);
        void fetchData();

        return () => {
            clearInterval(timerHandle);
        };
    }, [dispatch, syrxControllerId]);

    return (
        <>
            <Col md={12} style={{marginTop: "1rem"}}>
                <Tabs defaultActiveKey="processInformation" id="abc">
                    <Tab eventKey="processInformation" title="Process information">
                        <SyrxControllerDiagnosticServiceMetrics syrxControllerId={syrxControllerId} />
                    </Tab>
                    <Tab eventKey="networkInterfaceInformation" title="Network interface information">
                        <SyrxControllerDiagnosticServiceNetworkInterfaceInformation syrxControllerId={syrxControllerId} />
                    </Tab>
                    <Tab eventKey="rabbitmqMetrics" title="RabbitMQ metrics">
                        <SyrxControllerDiagnosticServiceRabbitmqMetrics />
                    </Tab>
                </Tabs>
            </Col>
        </>
    )
};