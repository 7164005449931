import React from "react";

export const LoadingSpinner: React.FunctionComponent = () => (
    <div style={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 10000
    }}>
        <div style={{
            height: "100%",
            width: "100%",
            display: "table",
            backgroundColor: "rgba(255, 255, 255, 0.5)"
        }}>
            <div style={{
                display: "table-cell",
                verticalAlign: "middle"
            }}>
                <div style={{
                    width: "75px",
                    height: "75px",
                    margin: "auto"
                }}>
                    <div style={{
                        width: "100%",
                        height: "100%"
                    }}
                         className="loading">
                        <i className="fas fa-spinner fa-spin" style={{fontSize: '50px'}} />
                    </div>
                </div>
            </div>
        </div>
    </div>
);