import React from 'react'
import {NewApiServices} from '../../../../newApiServices';
import {DeleteModal} from '../../../../modals/deleteModal';

export interface RoleDeleteModalProps {
    roleId: number;
    onClose: (deleteSuccessful: boolean) => void;
}

export const RoleDeleteModal: React.FunctionComponent<RoleDeleteModalProps> = ({roleId, onClose}) => {
    return (
        <DeleteModal
            title="Delete role"
            message="Delete this role?"
            loadItem={() => NewApiServices.rolesService.getRoleById(roleId)}
            performDeletion={() => NewApiServices.rolesService.deleteRoleById(roleId)}
            onClose={onClose}
        >
            {item => (
                <table className="table">
                    <tbody>
                        <tr>
                            <th>Role</th>
                            <td>{item.name}</td>
                        </tr>
                    </tbody>
                </table>
            )}
        </DeleteModal>
    )
}