import React, {useCallback, useEffect, useMemo} from 'react'
import {Button, Col, Row} from 'react-bootstrap';
import {Tree} from '@sht-dev/fancy-tree';
import {v4 as uuid} from 'uuid';
import {getTreeItems, isTreeElementAPoint} from './treeBuildingUtilityFunctions';
import {Draggable} from './draggable';
import {Dropzone} from './dropzone';
import {useMoveVendorPointDataNewJobSelector} from './moveVendorPointDataNewJobSlice';
import dayjs from 'dayjs';
import {useDispatch} from 'react-redux';
import {LoadingSpinner} from '../../../loadingSpinner/LoadingSpinner';
import {moveVendorPointDataNewJobActionCreators} from './moveVendorPointDataNewJobActionCreators';
import {MoveVendorPointDataNewJobSummaryTable} from './moveVendorPointDataNewJobSummaryTable';
import {Redirect} from 'react-router';

export const MoveVendorPointDataNewJob: React.FunctionComponent = () => {
    const dispatch = useDispatch();

    const queue = useMoveVendorPointDataNewJobSelector(x => x.queue);
    const showLoadingSpinner = useMoveVendorPointDataNewJobSelector(x => x.showLoadingSpinner);
    const isCompleted = useMoveVendorPointDataNewJobSelector(x => x.isCompleted);

    const onDrop = useCallback((sourcePoint: any, destinationPoint: any) => {
        dispatch(moveVendorPointDataNewJobActionCreators.addToQueue({
            id: uuid(),
            sourcePointName: sourcePoint.pointName,
            sourceVendorPointId: sourcePoint.vendorPointId ?? null,
            sourcePcsPointId: sourcePoint.pcsPointId ?? null,
            sourceSyrxControllerBacnetPointId: sourcePoint.syrxControllerBacnetPointId ?? null,
            destinationPointName: destinationPoint.pointName,
            destinationVendorPointId: destinationPoint.vendorPointId ?? null,
            destinationPcsPointId: destinationPoint.pcsPointId ?? null,
            destinationSyrxControllerBacnetPointId: destinationPoint.syrxControllerBacnetPointId ?? null,
            fullTimeRange: true,
            startDate: dayjs().local().format("YYYY-MM-DD"),
            endDate: dayjs().local().format("YYYY-MM-DD"),
            sourceRecordHandling: "copy",
            remapEquipmentPoints: true
        }));
    }, [dispatch]);

    const submit = useCallback(() => {
        dispatch(moveVendorPointDataNewJobActionCreators.submitVendorPointRecordMoveJobs());
    }, [dispatch]);

    useEffect(() => {
        dispatch(moveVendorPointDataNewJobActionCreators.initialize());
        return () => {
            dispatch(moveVendorPointDataNewJobActionCreators.initialize());
        };
    }, [dispatch]);

    const disableSubmitButton = useMemo(() => queue.length === 0, [queue.length]);

    return (
        <>
            {showLoadingSpinner ? <LoadingSpinner /> : null}
            {isCompleted ? <Redirect to={"/admin/move-vendor-point-data"} /> : null}
            <h3>Move vendor point data</h3>
            <Row>
                <Col md={6} style={{height: "600px", overflowY: "auto"}}>
                    <Tree
                        getChildren={getTreeItems}
                        renderTreeElement={treeElement => isTreeElementAPoint(treeElement) ? <Draggable text={treeElement.name} type="point" id={treeElement.id} source={treeElement} /> : treeElement.name}
                    />
                </Col>
                <Col md={6} style={{height: "600px", overflowY: "auto"}}>
                    <Tree
                        getChildren={getTreeItems}
                        renderTreeElement={treeElement => isTreeElementAPoint(treeElement) ? <Dropzone text={treeElement.name} accept="point" id={treeElement.id} onDrop={sourcePoint => onDrop(sourcePoint.source, treeElement)} /> : treeElement.name}
                    />
                </Col>
            </Row>
            <div>
                <MoveVendorPointDataNewJobSummaryTable />
            </div>
            <div>
                <Button disabled={disableSubmitButton} onClick={submit} variant="primary">Submit</Button>
            </div>
        </>
    );
};