import { Dispatch } from "redux";
import { NewApiServices } from '../newApiServices';
import {userRegistrationActions} from '../features/userRegistration/userRegistrationSlice';
import {NewUserModel} from '../features/userRegistration/types';


export const submitNewUserActionCreator = (newUser:NewUserModel) => async (dispatch: Dispatch<any>) => {
    dispatch(userRegistrationActions.submit(newUser));

    try {
        await NewApiServices.authService.createNewUser(newUser);

        dispatch(userRegistrationActions.success());
    } catch (e) {
        dispatch(userRegistrationActions.error({exception: e}));
        return;
    }
};