import React, { CSSProperties, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { purchasingActionCreators } from '../../actionCreators/purchasingActionCreators';
import { usePurchasingSelector } from './purchasingSlice';

const row: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    background: "linear-gradient(to bottom, #227EA8 0%, #155080 100%)",
    marginBottom: 15
}

const item: CSSProperties = {
    fontSize: 18,
    color: 'white'
}

const selectedItem: CSSProperties = {
    fontSize: 18,
    color: 'white',
    background: "transparent linear-gradient(180deg, #FE761B 0%, #E15613 100%)"
}

export const PurchasingNavBar: React.FunctionComponent = () => {
    const { currentPage, pages } = usePurchasingSelector(x => x);
    const dispatch = useDispatch();

    useEffect(() => {
        async function fetchData() {
            dispatch(purchasingActionCreators.getSyrxControllerNodeCategories());
        }

        fetchData();
    }, [dispatch]);

    return (
        <div style={row}>
            {
                pages.map(page => {
                    return (
                        <div style={currentPage.id === page.id ? selectedItem : item} key={page.id}>
                            {page.name}
                        </div>
                    )
                })
            }
        </div>
    )
}