import { Dispatch } from "redux";
import {syrxControllerServicesManagerListActions} from './syrxControllerServicesManagerListSlice';
import {SyrxControllerServiceTypesService} from '../../../services/syrxControllerServiceTypesService';
import {SyrxControllerServiceVersionsService} from '../../../services/syrxControllerServiceVersionsService';

export const syrxControllerServicesManagerListActionCreators = {
    loadSyrxControllerServiceTypes: () => async (dispatch: Dispatch<any>) => {
        dispatch(syrxControllerServicesManagerListActions.startLoading());

        try {

            const syrxControllerServiceTypes = await SyrxControllerServiceTypesService.getServiceTypes();
            for (const syrxControllerServiceType of syrxControllerServiceTypes) {
                syrxControllerServiceType.syrx_controller_service_versions = await SyrxControllerServiceVersionsService.getServiceVersionsForServiceType(syrxControllerServiceType.id);
            }
            dispatch(syrxControllerServicesManagerListActions.setSyrxControllerServiceTypes(syrxControllerServiceTypes));
        } finally {
            dispatch(syrxControllerServicesManagerListActions.finishLoading());
        }
    }
}
