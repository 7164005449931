import { BaseApiService } from "./baseApiService";
import qs from "qs";

import { SortCollection } from '@sht-dev/fancy-grid';
import { Project } from '../features/projects/types';

export class ProjectsService extends BaseApiService {
    async getAllByGroupId(groupId: number, pageNumber: number, pageSize: number, sort: SortCollection) {
        const queryString = qs.stringify({
            skip: pageNumber * pageSize,
            take: pageSize,
            sort: sort.map(x => ({
                field: x.fieldName,
                dir: x.dir
            }))
        });
        const res = await this.axios.get(`/groups/${groupId}/projects?${queryString}`);
        return res.data;
    }
    async delete(projectId: number) {
        const res = await this.axios.delete(`/projects/${projectId}`, {
            validateStatus: null! //this is due to a problem with axios, it expects undefined here, but it only works with null, but the type definition doesn't support this yet, making the '!' required (https://github.com/axios/axios/issues/2239)
        });
        return res.status === 204;
    }
    async getOne(projectId: number) {
        const res = await this.axios.get(`/projects/${projectId}`);
        return res.data;
    }
    async create(project: Partial<Project>) {
        const res = await this.axios.post(`/projects`, {
            ...project
        });
        return res.data;
    }
    async update(project: Project) {
        const {id, ...body} = project;
        const res = await this.axios.put(`/projects/${id}`, {
            ...body
        });
        return res.data;
    }
    async getProjectSchedule(projectId: number) {
        const res = await this.axios.get(`/projects/${projectId}/schedule`);
        return res.data;
    }
    async addExistReplaceEquipmentTasks(projectId: number, body: {equipment_id: number, equipment_points: {component_point_id: string, add_exist_replace: string}[]}) {
        const res = await this.axios.post(`/projects/${projectId}/equipment-tasks`, body);
        return res.data;
    }
}
